import React, { useState, useCallback, useEffect } from "react";
import { Stack, Heading, HStack, useBoolean, IconButton } from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import DIVERSITY_CERTS from "../constants/DiversityCerts";

import { formatValue, convertArrayToMap } from "../../../helperFunctions";
const diversityCertMap = convertArrayToMap(DIVERSITY_CERTS, "label");

export default function DiversityVendorDrawerItem({ cert, diversityCerts = [], spacing, i, deleteVendorCert }) {
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [certificate, setCertificate] = useState(cert);
	const [deleteHover, setDeleteHover] = useBoolean();

	const updateDiversityCert = useCallback(
		(attr, value) => {
			cert.update(attr, value);
			setUpdateTrigger(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cert]
	);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const deleteCertificate = (certif) => {
		deleteVendorCert(certif);
		setCertificate(null);
	};

	return (
		certificate && (
			<Stack
				p={3}
				spacing={spacing.vertSpacing}
				bg={"blackAlpha." + (i + 1) + "00"}
				borderColor={deleteHover ? "red.500" : "blackAlpha.100"}
				borderWidth={2}
				rounded="lg"
			>
				<HStack
					w="full"
					pb={1}
					borderBottomWidth={2}
					borderBottomColor="blackAlpha.300"
					justify="space-between"
				>
					<Heading w="full" color={"gray.500"} textTransform="uppercase" fontSize="md" letterSpacing={2}>
						{diversityCertMap[cert?.diversityCert]?.description ?? "None Selected"}
					</Heading>

					<IconButton
						onMouseLeave={setDeleteHover.off}
						onMouseEnter={setDeleteHover.on}
						onClick={() => deleteCertificate(cert?.keyID)}
						bg="whiteAlpha.800"
						_hover={{ bg: "white", borderColor: "red.500", color: "red.500" }}
						color="gray.400"
						boxSize="25px"
						minW="25px"
						p={2}
						borderWidth={2}
						icon={<i className="fas fa-times fa-fw " />}
					></IconButton>
				</HStack>

				<Stack spacing={spacing.horzSpacing} direction="row" w="full" pb={2}>
					<DataInput
						key={cert?.keyID + "-diversityCerts-" + cert?.diversityCert}
						name="diversityCert"
						type="select"
						placeholder="Select Certification"
						label="Certification"
						searchable
						options={diversityCerts}
						value={cert?.diversityCert}
						optionValue="label"
						optionText="label"
						optionSubheading="description"
						onOptionSelect={(ev) => {
							if (ev !== cert?.diversityCert) {
								updateDiversityCert("diversityCert", ev);
							}
						}}
						flex={spacing.leftFlex}
						variant="outline"
						isModal
					/>
					<DataInput
						key={cert?.keyID + "-dateExpires-" + cert?.dateExpires}
						name="dateExpires"
						type="date"
						label="Expiration Date"
						placeholder={"mm/dd/yyyy"}
						value={formatValue(cert?.dateExpires, 0, "date")}
						onChange={(ev) => {
							let oldDate = formatValue(new Date(cert?.dateExpires), 0, "moment");
							let newDate = formatValue(new Date(ev), 0, "moment");
							if (
								newDate?.isValid() &&
								formatValue(newDate, 0, "date") !== formatValue(oldDate, 0, "date")
							) {
								updateDiversityCert("dateExpires", ev);
							}
						}}
						flex={spacing.rightFlex}
						variant="outline"
						inputProps={{ textAlign: "center", width: "100%" }}
						isModal
					/>
				</Stack>
			</Stack>
		)
	);
}
