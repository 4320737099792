import React, { useState, useMemo } from "react";
import { Divider, VStack, Stack, Collapse } from "@chakra-ui/react";
import moment from "moment";
import SectionObj from "../../../core/Reports/classes/Section";
import SectionTemplateMFRContractStatusCRS, {
	SectionTemplateMFRProjectAnalysisSummaryCRS,
} from "../templates/SectionTemplateMFRContractStatusCRS";
import Section from "../../../core/Reports/components/Section";
import ProjectInfo from "../components/MFRProjectInfoSection";
import Safety from "../components/MFRSafetySection";
import ContractStatus from "../components/MFRContractStatusSection";
import MarginTrendGraph from "../components/MFRMarginTrendGraph";
import Accounting from "../components/MFRAccountingSection";
import AccountingSummary from "../components/MFRAccountingSummarySection";
import BilledVsCostGraph from "../components/MFRBilledVsCostGraph";
import ProjectAnalysis from "../components/MFRProjectAnalysisSection";
import ProjectAnalysisCRS from "../components/MFRProjectAnalysisCRSSection";
// import ProjectAnalysisCRSSummary from "../components/MFRProjectAnalysisSummaryCRSSection";
// import MFRProjectAnalysisSummarySection from "../components/MFRProjectAnalysisSummarySection";
import Schedule from "../components/MFRScheduleSection";
import MFRCloseOutSection from "../components/MFRCloseOutSection";
import MFRCommentsSection from "../components/MFRCommentsSection";

// import { portalUser } from "../../../../App";
// import { log } from "../../../helperFunctions";

export default function MonthlyForecastReport(props) {
	// log("mfrProps", props);
	let pagebreak = props.pagebreak ?? 0;

	// Default State Variables
	const contract = useMemo(() => props.contract, [props.contract]);
	const [contractTotalsByMth, setContractTotalsByMth] = useState([]);

	const [contractStatus, setContractStatus] = useState(null);

	const [prevContractStatus, setPrevContractStatus] = useState(null);
	const [billings, setBillings] = useState([]);
	const [retention, setRetention] = useState([]);
	const [earlyLateAvg, setEarlyLateAvg] = useState(0);

	const [projectAnalysisCRS, setProjectAnalysisCRS] = useState(
		new SectionObj(new SectionTemplateMFRContractStatusCRS([], "MFRProjectAnalysisCRS"))
	);
	const [projectAnalysisSummaryCRS, setProjectAnalysisSummaryCRS] = useState(
		new SectionObj(new SectionTemplateMFRProjectAnalysisSummaryCRS([]))
	);

	// const [region, setRegion] = useState({});

	useMemo(() => {
		let contractTotalsByMth = props.contractData?.filter((d) => d.queryData === "ContractTotalsByMth") ?? [];
		contractTotalsByMth = contractTotalsByMth?.filter(
			(d) => new Date(d.mth).getTime() <= new Date(props.thruMth).getTime()
		);

		// setRegion(portalUser?.getRegions()?.filter((p) => p.regionID === props.contract?.regionID)[0] ?? {});

		let itemTypeData = props.contractData?.filter((p) => ["ItemTypeTotalsByMth"].includes(p.queryData)) ?? [];
		itemTypeData =
			itemTypeData?.filter(
				(p) => moment(new Date(p.mth)).format("MM/YY") === moment(new Date(props.thruMth)).format("MM/YY")
			) ?? [];

		let contractStatus =
			contractTotalsByMth?.filter(
				(d) => moment(new Date(d.mth)).format("MM/YY") === moment(new Date(props.thruMth)).format("MM/YY")
			)[0] ?? null;

		setProjectAnalysisCRS(
			new SectionObj(new SectionTemplateMFRContractStatusCRS(itemTypeData, "MFRProjectAnalysisCRS"))
		);
		setProjectAnalysisSummaryCRS(
			new SectionObj(new SectionTemplateMFRProjectAnalysisSummaryCRS(itemTypeData, contractStatus))
		);

		let billingsByMth = props.contractData?.filter((d) => d.queryData === "BillingsByMth") ?? [];
		billingsByMth = billingsByMth?.filter((d) => new Date(d.mth).getTime() <= new Date(props.thruMth).getTime());

		let earlyLateAvg = 0;
		let billings = billingsByMth?.filter((d) => d.arTransGroup !== "R");
		let retention = billingsByMth?.filter((d) => d.arTransGroup === "R");
		let b = 0;
		for (let i = 0; i < billings?.length; i++) {
			if (Boolean(billings[i]?.datePaidOn) && b < 3) {
				earlyLateAvg =
					earlyLateAvg + (isNaN(parseFloat(billings[i]?.earlyLate ?? 0)) ? 0 : billings[i]?.earlyLate);
				b = b + 1;
			}
			billings[i].billNo = billings?.length - i;
		}
		earlyLateAvg = parseFloat(earlyLateAvg ?? 0) / 3;

		setBillings(billings);
		setRetention(retention);
		setEarlyLateAvg(earlyLateAvg);

		let prevContractStatus =
			contractTotalsByMth?.filter(
				(d) =>
					moment(new Date(d.mth)).format("MM/YY") ===
					moment(new Date(props.thruMth)).add(-1, "months").format("MM/YY")
			)[0] ?? null;
		setContractTotalsByMth(contractTotalsByMth);
		setContractStatus(contractStatus);
		setPrevContractStatus(prevContractStatus);
		// log("prevContractStatus", prevContractStatus);
	}, [props.contractData, props.thruMth]);

	return (
		<>
			{pagebreak === 0 && (
				<VStack w="full" spacing={4} px={4}>
					<ProjectInfo contract={contract} selectContract={props.selectContract} />
					<Divider borderBottomWidth={"2px"} borderBottomColor={"gray.400"} />
					<Safety contract={contract} />
					<Divider borderBottomWidth={"2px"} borderBottomColor={"gray.400"} />
					<Collapse in={!Boolean(["2", "4", "5"].includes(contract?.contractTypeID))}>
						<Stack w="full" spacing={2}>
							<ContractStatus
								contract={contract}
								contractStatus={contractStatus}
								prevContractStatus={prevContractStatus}
								changeTab={props.changeTab}
								selectContract={props.selectContract}
							/>

							{Boolean(["1", "3"].includes(contract?.contractTypeID)) && (
								<MarginTrendGraph contractTotalsByMth={contractTotalsByMth} />
							)}
							<Divider borderBottomWidth={"2px"} borderBottomColor={"gray.400"} />
						</Stack>
					</Collapse>

					<Accounting
						contract={contract}
						contractStatus={contractStatus}
						billings={billings}
						retention={retention}
						earlyLateAvg={earlyLateAvg}
					/>
					<AccountingSummary
						contract={contract}
						contractStatus={contractStatus}
						prevContractStatus={prevContractStatus}
						earlyLateAvg={earlyLateAvg}
					/>
					<BilledVsCostGraph contractTotalsByMth={contractTotalsByMth} />

					{Boolean(["2", "4", "5"].includes(contract?.contractTypeID)) && (
						<Schedule
							contract={contract}
							contractStatus={contractStatus}
							prevContractStatus={prevContractStatus}
							selectContract={props.selectContract}
						/>
					)}
				</VStack>
			)}
			{pagebreak === 1 && (
				<VStack w="full" spacing={4} px={4}>
					{!Boolean(["2", "4", "5"].includes(contract?.contractTypeID)) ? (
						<ProjectAnalysis contract={contract} contractStatus={contractStatus} />
					) : (
						<>
							<Section section={projectAnalysisCRS} />
							{/* <Section section={projectAnalysisSummaryCRS} /> */}
							<ProjectAnalysisCRS contract={contract} contractStatus={contractStatus} />
							{/* <ProjectAnalysisCRSSummary contract={contract} contractStatus={contractStatus} /> */}
						</>
					)}

					{!Boolean(["2", "4", "5"].includes(contract?.contractTypeID)) && (
						<Schedule
							contract={contract}
							contractStatus={contractStatus}
							prevContractStatus={prevContractStatus}
							selectContract={props.selectContract}
						/>
					)}

					<MFRCloseOutSection contract={contract} />
				</VStack>
			)}
			{pagebreak === 2 && (
				<VStack w="full" spacing={4} px={4}>
					<MFRCommentsSection contract={contract} />
				</VStack>
			)}
		</>
	);
}
