import PayrollAPI from "../../../APIs/PayrollAPI";
import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";
import moment from "moment";

export default class TimecardUser {
	constructor(user) {
		if (user instanceof TimecardUser) {
			Object.assign(this, user);
		} else {
			this.payrollAPI = new PayrollAPI();
			this.prEndDate = formatValue(user?.prEndDate ?? portalUser.user?.prEndDate, 0, "date");
			this.payPeriods = this.initPayPeriods([...(user?.payPeriods ?? [])]);
			this.jobs = this.initTimecardJobs([...(user?.jobs ?? [])]);
			this.phases = this.initTimecardJobPhases([...(user?.phases ?? [])]);
			this.groups = user?.groups ?? [];
			this.groupMembers = user?.groupMembers ?? [];
			this.reviewJobs = user?.reviewJobs ?? [];
			this.reviewPhases = user?.reviewPhases ?? [];
		}
	}

	async getTimecardUser(employeeUID = portalUser.user?.employeeUID, prEndDate = this.prEndDate) {
		let timecardUser = this;
		timecardUser.prEndDate = formatValue(prEndDate, 0, "date");
		timecardUser.payPeriods = await timecardUser.getPayPeriods(employeeUID);
		timecardUser.jobs = await timecardUser.getTimecardJobs(employeeUID);
		timecardUser.reviewJobs = await timecardUser.getReviewJobs(employeeUID);
		return timecardUser;
	}

	async getPayPeriods(employeeUID = portalUser.user?.employeeUID) {
		let payPeriods = this.payPeriods;
		if (payPeriods?.length === 0) {
			let result = await this.payrollAPI.GetPayPeriods(employeeUID);
			if (result?.status === 200 && result?.value?.length > 0) {
				payPeriods = this.initPayPeriods(result.value);
			} else {
				payPeriods = this.payPeriods;
			}
			this.payPeriods = payPeriods;
		}
		return payPeriods;
	}

	async getTimecardJobs(employeeUID = portalUser.user?.employeeUID, prco) {
		let jobs = this.jobs;
		if (jobs?.length === 0) {
			let result = await this.payrollAPI.GetTimecardJobs(employeeUID, prco);
			if (result?.status === 200 && result?.value?.length > 0) {
				jobs = this.initTimecardJobs(result.value);
			} else {
				jobs = this.jobs;
			}
			this.jobs = jobs;
		}
		return jobs;
	}

	async getTimecardJobPhases(job = null) {
		if (Boolean(job?.keyID)) {
			let phases = this.phases;
			let newPhases = phases?.filter((d) => d.jcco === job?.jcco && d.job === job?.job) ?? [];

			if (Boolean(job?.jcco) && Boolean(job?.job) && newPhases?.length === 0) {
				let result = await this.payrollAPI.GetTimecardJobPhases(job?.job, job?.jcco);
				if (result?.status === 200 && result?.value?.length > 0) {
					newPhases = this.initTimecardJobPhases(result.value);
					phases = phases?.filter((d) => d.jcco !== job?.jcco && d.job !== job?.job);
				} else {
					newPhases = [];
				}
				this.phases = [...phases, ...newPhases];
			}
		}
		return this;
	}

	async getReviewJobs(employeeUID = portalUser.user?.employeeUID, prEndDate = this.prEndDate) {
		let reviewJobs = this.reviewJobs;
		if (reviewJobs?.length === 0) {
			let result = await this.payrollAPI.GetTimecardReviewJobs(employeeUID, prEndDate);
			if (result?.status === 200 && result?.value?.length > 0) {
				reviewJobs = result.value;
			} else {
				reviewJobs = this.reviewJobs;
			}
			this.reviewJobs = reviewJobs;
		}
		return reviewJobs;
	}

	initPayPeriods(data = []) {
		let payPeriods = [];
		if (data?.length > 0) {
			for (let i = 0; i < data?.length; i++) {
				let payPeriod = new PayPeriod(data[i]);
				payPeriods.push(payPeriod);
			}
		}
		return payPeriods;
	}

	initTimecardJobs(data = []) {
		let jobs = [];
		if (data?.length > 0) {
			for (let i = 0; i < data?.length; i++) {
				let job = new TimecardJob(data[i]);
				jobs.push(job);
			}
		}
		return jobs;
	}

	initTimecardJobPhases(data = []) {
		let phases = [];
		if (data?.length > 0) {
			for (let i = 0; i < data?.length; i++) {
				let phase = new TimecardJobPhase(data[i]);
				phases.push(phase);
			}
		}
		return phases;
	}
}

class PayPeriod {
	constructor(payPeriod) {
		if (payPeriod instanceof PayPeriod) {
			Object.assign(this, payPeriod);
		} else {
			this.prEndDate =
				formatValue(payPeriod?.prEndDate ?? payPeriod?.PREndDate ?? portalUser.user?.prEndDate, 0, "date") ??
				null;

			this.isCurrentPeriod = payPeriod?.IsCurrentPeriod ?? 0;
			this.dates = this.getDates();
		}
	}

	getDates(prEndDate = this.prEndDate, days = 7) {
		var startDate = null;
		var endDate = null;
		var dates = [
			{ weekDay: "Sun", date: null },
			{ weekDay: "Mon", date: null },
			{ weekDay: "Tues", date: null },
			{ weekDay: "Wed", date: null },
			{ weekDay: "Thur", date: null },
			{ weekDay: "Fri", date: null },
			{ weekDay: "Sat", date: null },
		];
		prEndDate = formatValue(prEndDate, 0, "date") ?? null;

		if (Boolean(prEndDate)) {
			endDate = formatValue(prEndDate);
			startDate = moment(endDate, "M/D/YYYY").add(-days + 1, "days");

			dates = [];
			for (let i = 0; i < days; i++) {
				let date = moment(startDate, "M/D/YYYY").add(i, "days");
				dates.push({
					weekDay: date.format("ddd"),
					date: date.format("M/D"),
				});
			}
		}
		return dates;
	}
}

class TimecardJob {
	constructor(job) {
		if (job instanceof TimecardJob) {
			Object.assign(this, job);
		} else {
			this.keyId = job?.keyId ?? job?.KeyID ?? null;
			this.description = job?.description ?? job?.Description ?? null;
			this.jcco = job?.jcco ?? job?.JCCo ?? null;
			this.job = job?.job ?? job?.Job ?? null;
			this.location = job?.location ?? job?.Location ?? null;
			this.key = job?.key ?? null;
			this.value = job?.value ?? null;
			this.phases = job?.phases ?? [];
		}
	}
}

class TimecardJobPhase {
	constructor(phase) {
		if (phase instanceof TimecardJobPhase) {
			Object.assign(this, phase);
		} else {
			this.jcco = phase?.jcco ?? phase?.JCCo ?? "1";
			this.job = phase?.job ?? phase?.Job ?? "1";
			this.phaseGroup = phase?.phaseGroup ?? phase?.PhaseGroup ?? "1";
			this.phase = phase?.phase ?? phase?.Phase ?? null;
			this.description = phase?.description ?? phase?.Description ?? null;
			this.levelAreaID = phase?.levelAreaID ?? phase?.LevelAreaID ?? null;
			this.levelAreaDesc = phase?.levelAreaDesc ?? phase?.LevelAreaDesc ?? null;
			this.codeVersion = phase?.codeVersion ?? phase?.CodeVersion ?? "2021";
			this.codeOrder = phase?.codeOrder ?? phase?.CodeOrder ?? null;
			this.key = phase?.key ?? null;
			this.value = phase?.value ?? null;
		}
	}
}
