import PayrollAPI from "../../../APIs/PayrollAPI";
import TimecardHeaderClassLegacy from "./TimecardHeaderClassLegacy";
import TimecardEntryRowClassLegacy from "./TimecardEntryRowClassLegacy";
import { mergeSort, log } from "../../../helperFunctions";

export default class TimecardClassLegacy {
	constructor(employee, prEndDate, timecardHeader = null, timecardList = null) {
		this.payrollAPI = new PayrollAPI();
		this.employee = employee;
		this.prEndDate = prEndDate;
		this.timecardHeader = Boolean(timecardHeader) ? new TimecardHeaderClassLegacy(timecardHeader) : null;
		this.isExpanded = false;
		this.sortAttribute = "";
		this.payweekHours = [];

		if (timecardList != null) {
			for (let i = 0; i < timecardList?.length; i++) {
				let timecardRow = new TimecardEntryRowClassLegacy(timecardList[i]);
				this.payweekHours.push(timecardRow);
			}
		}
	}

	async loadTimecardInfo() {
		let timecardHeader = await this.payrollAPI.GetTimecardHeaders(
			this.employee.employeeUID,
			this.employee.prco,
			this.prEndDate
		);

		if (timecardHeader.status === 200 && timecardHeader?.value?.length > 0) {
			timecardHeader = new TimecardHeaderClassLegacy(timecardHeader.value[0]);
			this.timecardHeader = timecardHeader;
		}
	}

	async loadPayweekTime() {
		let payweekResults = await this.payrollAPI.GetTimecardRows(
			this.employee.employeeUID,
			this.employee.prco,
			this.prEndDate
		);
		if (payweekResults.status === 200) {
			payweekResults = payweekResults.value;
		}

		for (var i = 0; i < payweekResults.length; i++) {
			let timecardRow = new TimecardEntryRowClassLegacy(payweekResults[i]);
			this.payweekHours.push(timecardRow);
		}
	}

	loadTimeFromArray(timecardList) {
		let hours = [];
		for (let i = 0; i < this.payweekHours.length; i++) {
			if (this.payweekHours[i].isFilled() || Boolean(this.payweekHours[i].keyId)) {
				hours.push(this.payweekHours[i]);
			}
		}
		this.payweekHours = hours;
		for (let i = 0; i < timecardList.length; i++) {
			let timecardRow = new TimecardEntryRowClassLegacy(timecardList[i]);
			this.payweekHours.push(timecardRow);
		}
		return true;
	}

	setPayweekHours(payweekHours) {
		this.payweekHours = payweekHours;
	}

	//DUSTIN LOOK HERE!!
	isUnique(timecardRow) {
		log("timecardRow", timecardRow);
		let unique = timecardRow.isFilled();
		log("unique", unique);

		if (unique) {
			for (let i = 0; i < this.payweekHours.length; i++) {
				let checkAgainstRow = this.payweekHours[i].isUndefined();

				if (!checkAgainstRow) {
					continue;
				} else if (timecardRow.equals(this.payweekHours[i])) {
					unique = false;
					break;
				}
			}
		}
		return unique;
	}

	addItem() {
		this.payweekHours.push(new TimecardEntryRowClassLegacy(null, this.employee, this.prEndDate));
	}

	deleteItem(index) {
		this.payweekHours.splice(index, 1);
	}

	getDayTotals() {
		let dayTotals = [0, 0, 0, 0, 0, 0, 0];
		for (let i = 0; i < this.payweekHours.length; i++) {
			for (let j = 0; j < this.payweekHours[i]?.hours?.length; j++) {
				let hours = this.payweekHours[i]?.hours[j];
				hours = isNaN(hours) ? 0 : hours;
				dayTotals[j] += isNaN(parseFloat(hours)) ? 0 : parseFloat(hours);
			}
		}
		return dayTotals;
	}

	getWeekTotal() {
		let weekTotal = 0;
		for (let i = 0; i < this.payweekHours.length; i++) {
			weekTotal += this.payweekHours[i].getTotalHours();
		}
		return weekTotal;
	}

	getTimecardListBatchID() {
		let hasBatchID = true;
		for (let i = 0; i < this.payweekHours.length; i++) {
			if (this.payweekHours[i].isFilled() && this.payweekHours[i].batchID === null) {
				hasBatchID = false;
				break;
			}
		}

		return hasBatchID;
	}

	getTotalTimeByEarnCode(earnCode) {
		let total = 0;
		for (let i = 0; i < this.payweekHours.length; i++) {
			if (this.payweekHours[i].earnCode === earnCode) {
				total += this.payweekHours[i].getTotalHours();
			}
		}
		return total;
	}

	hasNotes() {
		if (this.timecardHeader?.weekNotes?.length > 0) {
			return true;
		}

		for (let i = 0; i < this.payweekHours.length; i++) {
			if (this.payweekHours[i].hasNotes()) {
				return true;
			}
		}
		return false;
	}

	async updateTimecardEntry(update) {
		let status;
		if (update.attr === "earnCode") {
			status = await this.payweekHours[update.rowIndex].changeEarnCode(update.value);
		} else if (update.attr === "job") {
			status = await this.payweekHours[update.rowIndex].changeJob(update.value);
		} else if (update.attr === "phase") {
			status = await this.payweekHours[update.rowIndex].changePhase(update.value);
		} else if (update.attr === "shift") {
			status = await this.payweekHours[update.rowIndex].changeShift(update.value);
		} else if (update.attr === "hours") {
			status = await this.payweekHours[update.rowIndex].updateHours(
				update.hourIndex,
				update.value,
				update.upload
			);
		} else if (update.attr === "notes") {
			status = await this.payweekHours[update.rowIndex].updateNotes(
				update.notesIndex,
				update.value,
				update.upload
			);
		} else if (update.attr === "delete") {
			status = await this.payweekHours[update.rowIndex].deleteHours(update.keyId);
			if (status) {
				this.payweekHours.splice(update.rowIndex, 1);
			}
		}
		if (!Boolean(this.payweekHours[update.rowIndex]?.keyId) && update.attr !== "delete") {
			log("am i getting here?", this.payweekHours[update.rowIndex]);
			let isUnique = this.isUnique(this.payweekHours[update.rowIndex]);
			if (isUnique) {
				let result = await this.payweekHours[update.rowIndex].insertTimecardEntry();
				status = result;
			}
		}

		return status;
	}

	async updateTimecardInfo(update) {
		let status;
		status = await this.timecardHeader?.UpdateTimecardHeader(update);
		return status;
	}

	getStatus() {
		if (this.timecardHeader?.batchID !== null && !this.getTimecardListBatchID()) {
			return { status: "Missed Time", icon: "far fa-calendar-times fa-lg", color: "orange" };
		} else if (
			this.timecardHeader?.batchID !== null &&
			this.getTimecardListBatchID() &&
			this.timecardHeader?.status === 5 &&
			this.payweekLength() > 0
		) {
			return { status: "Time Posted", icon: "fas fa-money-bill fa-lg", color: "teal" };
		} else if (
			this.timecardHeader?.batchID !== null &&
			this.getTimecardListBatchID() &&
			this.timecardHeader?.status !== 5
		) {
			return { status: "Time Uploaded", icon: "fas fa-cloud-upload-alt fa-lg", color: "blue" };
		} else if (this.timecardHeader?.batchID === null && this.timecardHeader?.isApproved()) {
			return { status: "Time Approved", icon: "far fa-calendar-check fa-lg", color: "green" };
		} else if (
			this.timecardHeader?.batchID === null &&
			!this.timecardHeader?.isApproved() &&
			this.getWeekTotal() >= 40
		) {
			return { status: "Time Entered", icon: "far fa-calendar-alt fa-lg", color: "blue" };
		} else if (
			(this.timecardHeader?.batchID === null &&
				!this.timecardHeader?.isApproved() &&
				this.getWeekTotal() === 0) ||
			(this.timecardHeader?.batchID !== null && this.payweekLength() === 0)
		) {
			return { status: "Zero Hours", icon: "far fa-calendar fa-lg", color: "gray" };
		} else {
			return { status: "In Progress", icon: "far fa-calendar-alt fa-lg", color: "cyan" };
		}
	}

	getStatusLevel() {
		if (this.timecardHeader?.batchID !== null && !this.getTimecardListBatchID()) {
			return 0; // Missed Time
		} else if (
			this.timecardHeader?.batchID !== null &&
			this.getTimecardListBatchID() &&
			this.timecardHeader?.status === 5
		) {
			return 6; // Time Posted
		} else if (
			this.timecardHeader?.batchID !== null &&
			this.getTimecardListBatchID() &&
			this.timecardHeader?.status !== 5
		) {
			return 5; // Time Uploaded
		} else if (this.timecardHeader?.batchID === null && this.timecardHeader?.isApproved()) {
			return 4; // Time Approved
		} else if (
			this.timecardHeader?.batchID === null &&
			!this.timecardHeader?.isApproved() &&
			this.getWeekTotal() >= 40
		) {
			return 3; // Time Entered
		} else if (
			this.timecardHeader?.batchID === null &&
			!this.timecardHeader?.isApproved() &&
			this.getWeekTotal() === 0
		) {
			return 1; // Zero Hours
		} else {
			return 2; // In Progress
		}
	}

	updatePaySequence(paySeq) {
		for (let i = 0; i < this.payweekHours.length; i++) {
			this.payweekHours[i].paySeq = paySeq;
		}
	}

	isTimePosted() {
		if (this.payweekHours.length === 0) {
			return false;
		}
		for (let i = 0; i < this.payweekHours.length; i++) {
			if (this.payweekHours[i].paySeq === null) {
				return false;
			}
		}
		return true;
	}

	setBatchInfo(batchInfo) {
		this.timecardHeader.batchID = batchInfo.BatchID;
		for (let i = 0; i < this.payweekHours.length; i++) {
			this.payweekHours[i].batchID = batchInfo.BatchID;
			this.payweekHours[i].paySeq = batchInfo.PaySeq;
		}
	}

	payweekLength() {
		let count = 0;
		for (let i = 0; i < this.payweekHours.length; i++) {
			if (this.payweekHours[i].isFilled()) {
				count++;
			}
		}
		return count;
	}

	sortPayweekHours(sortBy) {
		let attribute = { name: sortBy, type: "number" };
		let sortOrder = this.sortOrder ?? 1;

		if (sortBy === this.sortAttribute) {
			if (sortOrder === 0) {
				sortOrder = 1;
			} else {
				sortOrder = 0;
			}
		} else {
			sortOrder = 1;
		}
		this.sortOrder = sortOrder;
		this.sortAttribute = sortBy;
		this.payweekHours = mergeSort(this.payweekHours, attribute, sortOrder);
		return;
	}
}
