import { portalUser } from "../../../App";
import { v4 } from "uuid";
import {
	convertArrayToMap,
	formatValue,
	// log
} from "../../../helperFunctions";
import TIME_OFF_RELEVANCE_LEVELS from "../constants/TimeOffRelevanceLevels";
import TIME_OFF_STATUSES from "../constants/TimeOffStatuses";

const levelMap = convertArrayToMap(TIME_OFF_RELEVANCE_LEVELS, "level");
const statusMap = convertArrayToMap(TIME_OFF_STATUSES, "status");

export default class TimeOffRequest {
	constructor(request) {
		if (request instanceof TimeOffRequest) {
			Object.assign(this, request);
		} else {
			this.queryData = request?.queryData ?? request?.QueryData ?? "TimeOffRequest";
			this.keyID = request?.keyID ?? request?.KeyID ?? null;
			this.requestUID = (request?.requestUID ?? request?.RequestUID)?.toLowerCase() ?? v4();
			this.employeeUID =
				(request?.employeeUID ?? request?.EmployeeUID ?? portalUser?.user?.employeeUID)?.toLowerCase() ?? null;
			this.name = request?.name ?? null;
			this.type = request?.type ?? request?.Type ?? "Vacation";

			this.startDate = formatValue(request?.StartDate ?? request?.startDate ?? null, 0, "date");
			this.endDate = formatValue(request?.endDate ?? request?.EndDate ?? null, 0, "date");

			this.status = request?.status ?? request?.Status ?? -1;
			this.statusDesc = request?.statusDesc ?? "Pending";

			this.level = request?.level ?? request?.Level ?? 1;
			this.levelDesc = request?.requestLevel ?? null;

			this.requestedHours = request?.requestedHours ?? request?.RequestedHours ?? 0;
			this.requestNote = request?.RequestNote ?? request?.requestNote ?? null;

			this.note = request?.note ?? request?.Note ?? null;

			this.approverUID = (request?.approverUID ?? request?.ApproverUID)?.toLowerCase() ?? null;
			this.approverName = request?.approverName ?? null;

			this.sentTo = (request?.sentTo ?? request?.SentTo)?.toLowerCase() ?? null;
			this.sentToName = request?.sentToName ?? null;

			this.secondarySentTo = (request?.secondarySentTo ?? request?.SecondarySentTo)?.toLowerCase() ?? null;

			this.dateCreated = formatValue(request?.dateCreated ?? request?.DateCreated ?? new Date(), 0, "date");
			this.mthCreated = request?.mthCreated ?? null;

			this.title = request?.title ?? null;
			this.description = request?.description ?? null;
			this.eventType = "Time Off";

			this.dateAcknowledged = request?.dateAcknowledged ?? request?.DateAcknowledged ?? null;
			this.acknowledgedBy = (request?.acknowledgedBy ?? request?.AcknowledgedBy)?.toLowerCase() ?? null;
			this.acknowledgedByName = request?.acknowledgedByName ?? null;
		}
		this.getMthCreated();
		this.getRequestStatus();
		this.getRequestLevel();
		this.getNamesAndTitles();
	}

	getNamesAndTitles() {
		let usersMap = convertArrayToMap(
			portalUser?.userData?.filter((d) => d.queryData === "User"),
			"employeeUID"
		);
		this.name = usersMap[this.employeeUID]?.name;
		this.approverName = usersMap[this.approverUID]?.name;
		this.sentToName = usersMap[this.sentTo]?.name;
		this.acknowledgedByName = usersMap[this.acknowledgedBy]?.name;
		this.title = "Time Off Request - " + this.name;
		this.description = this.levelDesc + " - " + this.statusDesc;
	}

	getMthCreated() {
		let mthCreated = formatValue(new Date(this.dateCreated), 0, "moment")?.startOf("month").format("MM/DD/YYYY");
		this.mthCreated = mthCreated;
		return mthCreated;
	}

	getRequestStatus() {
		let requestStatus = statusMap[this.status] ?? null;
		this.statusDesc = requestStatus?.statusDesc ?? this.statusDesc;
		return requestStatus;
	}

	getRequestLevel() {
		let requestLevel = levelMap[this.level] ?? null;
		this.levelDesc = requestLevel?.levelDesc ?? this.levelDesc;
		return requestLevel;
	}
}
