import React, { useMemo } from "react";
import { Box, Flex, Heading, Text, HStack, Stack, useBoolean } from "@chakra-ui/react";
import FormInput from "./FormInput";
import ToggleButton from "../../ReactTable/buttons/ToggleButton";
import FORM_INPUT_TYPES from "../constants/FormInputTypes";
import { convertArrayToMap, sortArrayBy, getSubtotal } from "../../../helperFunctions";
const inputTypeMap = convertArrayToMap(FORM_INPUT_TYPES, "inputType");

export default function FormQuestion(props) {
	const formData = useMemo(() => props.formData, [props.formData]);
	const form = useMemo(() => props.form ?? null, [props.form]);
	const section = useMemo(() => props.section ?? null, [props.section]);
	const question = useMemo(() => props.question ?? null, [props.question]);
	const questionNum = useMemo(() => props.questionNum ?? 0, [props.questionNum]);
	const usersMap = useMemo(() => props.usersMap, [props.usersMap]);

	const ratings = useMemo(() => {
		let ratings = [];
		if (Boolean(question?.questionUID)) {
			ratings = formData?.getFormRatings(question?.questionUID) ?? [];
		}

		return ratings;
	}, [formData, question?.questionUID]);

	const inputs = useMemo(() => {
		let inputs = [];
		if (Boolean(question?.questionUID)) {
			let params = { questionUID: question?.questionUID };
			// if (form?.formType === "3") {
			// 	params.parentFormUID = form.formUID;
			// } else {
			// 	params.formUID = form.formUID;
			// }
			inputs = formData?.getFormInputs(params) ?? [];

			inputs = sortArrayBy(inputs, "order", 1);
		}
		return inputs;
	}, [formData, question?.questionUID]);

	const inputsMap = useMemo(() => (inputs?.length > 0 ? convertArrayToMap(inputs, "formUID", true) : null), [inputs]);

	const score = useMemo(() => {
		let ratingVals = ratings?.map((d) => (isNaN(parseInt(d.heading)) ? null : parseInt(d.heading))) ?? [];
		ratingVals = ratingVals.filter((d) => Boolean(d)) ?? [];

		let inputVals =
			inputsMap[form?.formUID]?.map((d) => (isNaN(ratingVals[d.value - 1]) ? null : ratingVals[d.value - 1])) ??
			[];
		inputVals = inputVals.filter((d) => Boolean(d)) ?? [];

		let maxscore = getSubtotal(ratingVals, null, "max") * (inputVals?.length ?? 0);
		let score = getSubtotal(inputVals, null, "sum");

		return score + "/" + maxscore;
	}, [form?.formUID, inputsMap, ratings]);

	const isPrinting = useMemo(() => props.isPrinting ?? false, [props.isPrinting]);
	const isComparing = useMemo(() => props.isComparing ?? false, [props.isComparing]);
	const isEditing = useMemo(() => props.isEditing ?? false, [props.isEditing]);

	const isReadOnly = useMemo(() => {
		if (Boolean(form)) {
			return form?.getIsReadOnly();
		} else {
			return true;
		}
	}, [form]);

	const styles = useMemo(() => {
		let flexDirection = isPrinting ? "row" : { base: "column", md: "row" };

		let headingFontColor = isPrinting ? "var(--chakra-colors-gray-800)" : "gray.500";
		let headingFontSize = isPrinting ? "18px" : "xl";
		let headingLetterSpacing = 2;
		let headingTextTransform = "uppercase";

		let subheadingTextAlign = "right";
		let subheadingMinW = isPrinting ? "150px" : 32;
		let subheadingMaxW = isPrinting ? "250px" : 40;
		let subheadingFontColor = isPrinting ? "var(--chakra-colors-gray-800)" : "gray.500";
		let subheadingFontSize = isPrinting ? "12px" : "md";
		let subheadingLetterSpacing = 1;

		let buttonFontSize = "sm";

		let labelTextAlign = "right";
		let labelMinW = isPrinting ? "150px" : 32;
		let labelMaxW = isPrinting ? "250px" : 40;
		let labelFontColor = isPrinting ? "var(--chakra-colors-gray-800)" : "gray.400";
		let labelFontSize = isPrinting ? "16px" : "lg";

		let valueTextAlign = "left";
		let valueMinW = isPrinting ? "150px" : "2xs";
		let valueMaxW = isPrinting ? "200px" : "xs";
		let valueFontColor = isPrinting ? "black" : "gray.600";
		let valueFontSize = isPrinting ? "16px" : "lg";
		let valueFontWeight = isPrinting ? "normal" : form?.assignedFor ? "semibold" : "normal";
		let valueFontStyle = isPrinting ? "normal" : form?.assignedFor ? "normal" : "italic";
		let valueBottomWidth = 2;

		return {
			headingFontColor,
			headingFontSize,
			headingLetterSpacing,
			headingTextTransform,

			flexDirection,

			subheadingMinW,
			subheadingMaxW,
			subheadingTextAlign,
			subheadingFontColor,
			subheadingFontSize,
			subheadingLetterSpacing,

			buttonFontSize,

			labelMinW,
			labelMaxW,
			labelTextAlign,
			labelFontColor,
			labelFontSize,

			valueMinW,
			valueMaxW,
			valueTextAlign,
			valueFontColor,
			valueFontSize,
			valueFontWeight,
			valueFontStyle,
			valueBottomWidth,
		};
	}, [form?.assignedFor, isPrinting]);

	const [showComments, setShowComments] = useBoolean(false);
	const commentsButton = useMemo(() => {
		let commentsButton = null;

		commentsButton = (
			<ToggleButton
				toggleIsOpen={showComments}
				onClick={setShowComments.toggle}
				toggleOptions={[
					{
						isOpen: true,
						label: isReadOnly ? "" : "Hide Comments",
						icon: "fas fa-comment-alt",
						color: "whiteAlpha.800",
						bg: "blue.400",
						hover: { bg: "gray.300", color: "whiteAlpha.800" },
					},
					{
						isOpen: false,
						label: isReadOnly ? "" : "Show Comments",
						icon: "far fa-comment-alt",
						color: "gray.400",
						bg: "whiteAlpha.800",
						hover: { bg: "blue.50", color: "blue.300" },
					},
				]}
			/>
		);

		return commentsButton;
	}, [isReadOnly, setShowComments.toggle, showComments]);

	return (
		<Box
			key={"question" + question?.questionUID}
			id={"question" + question?.questionUID}
			className="page-break"
			w="full"
			rounded="md"
			p={4}
		>
			<Stack w="full" spacing={1}>
				{question?.inputType !== "rating" && (
					<Flex w="full" flex={1} justify="space-between">
						{Boolean(question?.heading) && !isEditing && (
							<HStack>
								{section?.heading !== "Employee Feedback" && (
									<Heading
										minW="var(--chakra-sizes-6)"
										fontSize={styles.headingFontSize}
										color={styles.headingFontColor}
										letterSpacing={styles.headingLetterSpacing}
										textTransform={styles.headingTextTransform}
										whiteSpacing="nowrap"
									>
										{questionNum + 1 + "."}
									</Heading>
								)}

								<Heading
									fontSize={styles.headingFontSize}
									color={styles.headingFontColor}
									letterSpacing={styles.headingLetterSpacing}
									textTransform={styles.headingTextTransform}
								>
									{question?.heading}
								</Heading>
							</HStack>
						)}
						{Boolean(question?.inputType) && (
							<HStack
								fontSize={styles.headingFontSize}
								color={styles.headingFontColor}
								justify="flex-end"
							>
								{Boolean(question?.inputType) && !isPrinting && isEditing && (
									<HStack
										fontSize={styles.buttonFontSize}
										// color={styles.headingFontColor}
										justify="flex-end"
										align="center"
										spacing={2}
										borderColor={inputTypeMap[question?.inputType]?.color + ".500"}
										borderWidth={2}
										color={!isPrinting && inputTypeMap[question?.inputType]?.color + ".500"}
										bg={!isPrinting && inputTypeMap[question?.inputType]?.color + ".50"}
										p={2}
										rounded="md"
									>
										<Text
											color={!isPrinting && inputTypeMap[question?.inputType]?.color + ".500"}
											className={inputTypeMap[question?.inputType]?.icon}
										/>
										<Heading
											fontSize={styles.buttonFontSize}
											letterSpacing={styles.headingLetterSpacing}
											textTransform={styles.headingTextTransform}
											lineHeight="none"
										>
											{inputTypeMap[question?.inputType]?.name}
										</Heading>
									</HStack>
								)}
							</HStack>
						)}
					</Flex>
				)}
				<Stack w="full" spacing={1}>
					{question?.inputType !== "rating" && !isEditing && (
						<HStack align="center">
							<Heading
								minW="var(--chakra-sizes-6)"
								fontSize={styles.headingFontSize}
								color={styles.headingFontColor}
								letterSpacing={styles.headingLetterSpacing}
								textTransform={styles.headingTextTransform}
							></Heading>

							{/* {Boolean(question?.subheading) && ( */}
							<Text
								fontStyle="italic"
								fontWeight={styles.valueFontWeight}
								fontSize={styles.subheadingFontSize}
								color={styles.labelFontColor}
								minH={4}
							>
								{question?.subheading}
							</Text>
							{/* )} */}
						</HStack>
					)}
					<Flex align="center" w="full" flex={1}>
						{question?.inputType === "rating" && (
							<HStack w="full" flex={1}>
								<Flex
									fontSize={styles.headingFontSize}
									color={styles.headingFontColor}
									justify="flex-start"
									align="center"
									flex={1}
								>
									<HStack
										fontSize={styles.headingFontSize}
										color={styles.headingFontColor}
										justify="flex=start"
										align="center"
										flex={3}
									>
										{Boolean(question?.heading) && !isEditing && (
											<HStack>
												<Heading
													minW="var(--chakra-sizes-6)"
													fontSize={styles.headingFontSize}
													color={styles.headingFontColor}
													letterSpacing={styles.headingLetterSpacing}
													textTransform={styles.headingTextTransform}
													whiteSpace="nowrap"
												>
													{questionNum + 1 + "."}
												</Heading>

												<Heading
													fontSize={styles.valueFontSize}
													color={styles.headingFontColor}
													letterSpacing={styles.headingLetterSpacing}
													textTransform={styles.headingTextTransform}
												>
													{question?.heading}
												</Heading>
											</HStack>
										)}
									</HStack>
									{ratings?.map((rating, i) => (
										<HStack
											borderLeftWidth={2}
											borderLeftColor="white"
											key={i}
											flex={1}
											justify="center"
											align="center"
											bg="teal.500"
											py={1}
											rounded="md"
											minH="40px"
											isTruncated
										>
											<Text
												color="white"
												textShadow={"1px 1px 2px var(--chakra-colors-teal-800)"}
												fontSize="xs"
												fontWeight="semibold"
												letterSpacing={1}
												textTransform={"uppercase"}
												isTruncated
												px={2}
											>
												{rating?.heading}
											</Text>
										</HStack>
									))}
								</Flex>
								{form?.formType === "3" && (
									<Flex minW={"35px"} justify="center" maxW="35px" w="35px">
										<Text fontWeight="semibold" color="gray.500">
											{score === "0/0" ? "N/A" : score}
										</Text>
									</Flex>
								)}
							</HStack>
						)}

						{form?.formType !== "1" && !isPrinting && question?.inputType === "rating" && (
							<HStack
								fontSize={styles.headingFontSize}
								color={styles.headingFontColor}
								justify="center"
								align="flex-end"
								opacity={0}
								px={1}
							>
								{commentsButton}
							</HStack>
						)}
					</Flex>

					{inputsMap[form?.formUID]?.map((input, i) => (
						<FormInput
							formData={formData}
							key={i + "-" + input?.inputUID}
							form={form}
							section={section}
							questionNum={question}
							question={question}
							ratings={ratings}
							inputs={inputs?.filter((d) => d.order === input.order) ?? []}
							inputNum={i}
							input={input}
							usersMap={usersMap}
							isPrinting={isPrinting}
							isEditing={isEditing}
							isComparing={isComparing}
						/>
					))}
				</Stack>
			</Stack>
		</Box>
	);
}
