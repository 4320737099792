import { ajaxGET, ajaxPOST, ajaxPATCH, ajaxDELETE, testGET, testPOST, setQueryParams } from "./api-functions";
import { portalUser } from "../App";

const apiBaseURL =
	(process.env.NODE_ENV === "development"
		? "https://ppmportalappnodejs-api.azurewebsites.net"
		: window.location.origin) + "/api/operations/";

export default class OperationsAPI {
	async TestGET(headers = {}) {
		let response = await testGET(apiBaseURL, headers);
		return response;
	}

	async TestPOST(body = {}, headers = {}) {
		let response = await testPOST(apiBaseURL, body, headers);
		return response;
	}

	//GET Form Data
	async GetOperationsData(params = {}, query = { msUserID: portalUser.user?.msUserID }) {
		var apiRouteURL = `${apiBaseURL}/data`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Projects
	async GetProjects(params = {}, query = { msUserID: portalUser.user?.msUserID }) {
		var apiRouteURL = `${apiBaseURL}/projects`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Customers
	async GetCustomers(params = {}, query = { msUserID: portalUser.user?.msUserID }) {
		var apiRouteURL = `${apiBaseURL}/customers`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//GET Vendors
	async GetVendors(params = {}, query = { msUserID: portalUser.user?.msUserID }) {
		var apiRouteURL = `${apiBaseURL}/vendors`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//UPDATE Vendor
	async UpdateVendor(params = { apvmKeyID: null }, data = {}) {
		var apiRouteURL = `${apiBaseURL}/vendors/${params?.apvmKeyID}`;
		if (Boolean(params?.apvmKeyID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		}
	}

	//GET DiversityCerts
	async GetDiversityCerts(params = {}, query = { msUserID: portalUser.user?.msUserID }) {
		var apiRouteURL = `${apiBaseURL}/diversityCerts`;
		apiRouteURL = setQueryParams(apiRouteURL, query);
		let response = await ajaxGET(apiRouteURL);
		return response;
	}

	//INSERT Diversity Cert
	async InsertDiversityCert(params = {}, data = {}) {
		var apiRouteURL = `${apiBaseURL}/diversityCerts`;
		if (Object.keys(data)?.length > 0) {
			let response = await ajaxPOST(apiRouteURL, data);
			return response;
		}
	}

	//UPDATE Diversity Certs
	async UpdateDiversityCert(params = { keyID: null }, data = {}) {
		var apiRouteURL = `${apiBaseURL}/diversityCerts/${params?.keyID}`;
		if (Boolean(params?.keyID) && Object.keys(data)?.length > 0) {
			let response = await ajaxPATCH(apiRouteURL, data);
			return response;
		}
	}

	//DELETE Diversity Cert
	async DeleteDiversityCert(params = { keyID: null }) {
		var apiRouteURL = `${apiBaseURL}/diversityCerts/${params?.keyID}`;
		if (Boolean(params?.keyID)) {
			let response = await ajaxDELETE(apiRouteURL);
			return response;
		}
	}

	//GET GetProjectMgrs
	// async GetProjectMgrs(msUserID = portalUser.user?.msUserID) {
	// 	var apiRouteURL = apiBaseURL + `userData/${msUserID}/projectMgrs`;
	// 	let response = await ajaxGET(apiRouteURL);
	// 	return response;
	// }
}
