import React, { useState } from "react";
import {
	HStack,
	Container,
	Box,
	Center,
	Flex,
	Text,
	Heading,
	Skeleton,
	Tooltip,
	Spacer,
	Input,
	Button,
	Stack,
	Spinner,
	SimpleGrid,
	SlideFade,
} from "@chakra-ui/react";
import Modal from "../../../core/Layout/modals/Modal";
import DataInput from "../../../core/Inputs/data/DataInput";
import ToastPopup from "../../../core/Alerts/ToastPopup";
import VirtualListV2 from "../../../core/ReactTable/components/VirtualListV2";

import ResourceGroupsVirtualList from "../common/ResourceGroupsVirtualList";
import ResourceGroupMemberRow from "./ResourceGroupMemberRow";

import PayrollAPI from "../../../APIs/PayrollAPI";
import { ResourceGroupsObjClassLegacy2, ResourceGroupMemberList } from "../classes/ResourceGroupsObjClassLegacy";

import { portalUser } from "../../../App";
import { getDeviceType, searchFilter, formatValue, convertArrayToMap } from "../../../helperFunctions";
import { v4 } from "uuid";

export default class ResourceGroupsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			payrollAPI: new PayrollAPI(),
			resourceGroups: null,
			members: null,
			allUsers: null,
			tableFilters: {},
			transferListIsOpen: false,
			uploadStatus: null,
			updates: null,
			updateTitle: "",
			transferring: false,
			openMemberList: false,
			dataView: "list",
			resourceGroupFilterVal: "",
			groupFilterTimer: null,
			selectedMembers: [],
			editGroup: false,
			ready: false,
			draggingEmployee: null,
			searchBarTimer: null,
			searchBarVal: "",
			activeGroup: this.props.resourceGroup,
		};

		this.init = this.init.bind(this);
		this.getGroupMembers = this.getGroupMembers.bind(this);
		this.sortTable = this.sortTable.bind(this);
		this.updateMemberRole = this.updateMemberRole.bind(this);
		this.addFilter = this.addFilter.bind(this);
		this.getPayrollEmployees = this.getPayrollEmployees.bind(this);
		this.openTransferList = this.openTransferList.bind(this);
		this.closeTransferList = this.closeTransferList.bind(this);
		this.handleMemberTransfer = this.handleMemberTransfer.bind(this);
		this.toastShown = this.toastShown.bind(this);
		this.openMemberList = this.openMemberList.bind(this);
		this.closeMemberList = this.closeMemberList.bind(this);
		this.changeDataView = this.changeDataView.bind(this);
		this.handleGroupSearchBar = this.handleGroupSearchBar.bind(this);
		this.handleMemberSelect = this.handleMemberSelect.bind(this);
		this.toggleEditGroup = this.toggleEditGroup.bind(this);
		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnter = this.onDragEnter.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
		this.updateSearchBarValue = this.updateSearchBarValue.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentDidUpdate() {
		if (
			JSON.stringify(this.props.resourceGroup) !== JSON.stringify(this.state.activeGroup) &&
			portalUser.user?.isPortalAdminYN === "N"
		) {
			this.setState(
				{
					activeGroup: this.props.resourceGroup,
				},
				() => {
					this.init();
				}
			);
		}
	}

	async init() {
		let resourceGroups = [];
		// if (Boolean(this.props.resourceGroup)) {
		// 	resourceGroups = [this.props.resourceGroup];
		// 	log("resourceGroup123s", resourceGroups);
		// } else {
		let resourceGroupResult = await this.state.payrollAPI.GetAllResourceGroups(portalUser.user?.employeeUID);
		if (resourceGroupResult.status === 200) {
			resourceGroups = resourceGroupResult.value;
		}
		// }
		let resourceGroupList = new ResourceGroupsObjClassLegacy2(portalUser.user?.employeeUID, "");
		resourceGroupList.setList(resourceGroups);
		this.setState(
			{
				resourceGroups: resourceGroupList,
			},
			() => {
				if (Boolean(resourceGroupList?.activeGroup?.groupUID)) {
					this.getGroupMembers(resourceGroupList.activeGroup.groupUID);
				}
			}
		);
	}

	async getGroupMembers(groupUID) {
		if (typeof groupUID === "object") {
			groupUID = groupUID.groupUID;
		}

		var resourceGroups = this.state.resourceGroups;
		resourceGroups?.setActiveGroup(groupUID);
		this.setState({
			members: null,
			resourceGroups: resourceGroups,
			showMemberList: true,
			editGroup: false,
		});

		let groupMembersResult = await this.state.payrollAPI.GetResourceGroupMembers(
			portalUser.user?.employeeUID,
			groupUID,
			"",
			""
		);
		if (groupMembersResult.status === 200) {
			let groupMembers = new ResourceGroupMemberList(groupMembersResult.value);
			this.setState({
				members: groupMembers,
				ready: true,
			});
		}
	}

	async getPayrollEmployees() {
		let allUserResult = await this.state.payrollAPI.GetPayrollEmployees();
		if (allUserResult.status === 200) {
			let allUsers = new ResourceGroupMemberList(allUserResult.value);
			this.setState({ allUsers: allUsers });
		}
	}

	async updateMemberRole(role, member) {
		let members = this.state.members;

		for (let i = 0; i < members.list.length; i++) {
			if (members.list[i].employeeUID === member.employeeUID) {
				await members.list[i].updateTimecardRole(
					this.state.resourceGroups.activeGroup.groupUID,
					role,
					!member[role]
				);
				break;
			}
		}
		this.setState({
			members: members,
		});
	}

	addFilter(ev, attrName, value) {
		let tableFilters = this.state.tableFilters;
		tableFilters[attrName] = value;
		this.setState({
			tableFilters: tableFilters,
		});
	}

	renderTableRow(member) {
		for (let attr in this.state.tableFilters) {
			if (this.state.tableFilters[attr] !== "") {
				if (!member[attr].toLowerCase().includes(this.state.tableFilters[attr].toLowerCase())) {
					return false;
				}
			}
		}
		return true;
	}

	openTransferList() {
		if (this.state.allUsers === null) {
			this.getPayrollEmployees();
		}
		this.setState({
			transferListIsOpen: true,
		});
	}

	closeTransferList() {
		this.setState({
			transferListIsOpen: false,
		});
	}

	async toggleEditGroup() {
		if (this.state.allUsers === null) {
			await this.getPayrollEmployees();
		}

		this.setState({
			editGroup: !this.state.editGroup,
		});
	}

	async handleMemberTransfer(direction, selectedMembers) {
		let members = this.state.members;
		let activeGroup;
		if (this.state.activeGroup) {
			activeGroup = this.state.activeGroup;
		}
		this.setState({ transferring: true });

		for (let i = 0; i < selectedMembers.length; i++) {
			let status;
			if (direction === "right") {
				status = await members.addMember(this.state.resourceGroups?.activeGroup.groupUID, selectedMembers[i]);
				if (this.state.activeGroup) {
					activeGroup.members.push(selectedMembers[i].employeeUID);
				}
			} else if (direction === "left") {
				status = await members.deleteMember(
					this.state.resourceGroups?.activeGroup.groupUID,
					selectedMembers[i]
				);
				if (this.state.activeGroup) {
					activeGroup?.members?.splice(activeGroup?.members.indexOf(selectedMembers[i].employeeUID));
				}
			}

			if (status === true) {
				this.setState({
					uploadStatus: "Success",
					updates: { uploadId: v4() },
					updateTitle:
						selectedMembers[i].firstName + " " + selectedMembers[i].lastName + " Transferred Successfully",
				});
			}
		}

		this.setState({
			members: members,
			transferring: false,
			activeGroup: activeGroup,
		});
	}

	toastShown() {
		this.setState({
			uploadStatus: null,
			updateTitle: "",
		});
	}

	openMemberList() {
		this.setState({ showMemberList: true });
	}

	closeMemberList() {
		this.setState({ showMemberList: false });
	}

	changeDataView(view) {
		this.setState({ dataView: view });
	}

	handleGroupSearchBar(searchValue) {
		this.setState({ resourceGroupFilterVal: searchValue });
	}

	updateSearchBarValue(ev) {
		let value = ev.target.value;

		let timer = this.state.searchBarTimer;
		clearTimeout(timer);
		timer = setTimeout(() => {
			this.handleGroupSearchBar(value);
		}, 300);
		this.setState({
			searchBarVal: value,
			searchBarTimer: timer,
		});
	}

	handleMemberSelect(member) {
		let selectedMembers = this.state.selectedMembers;
		let findIndex = selectedMembers?.indexOf(member.employeeUID);

		if (findIndex === -1) {
			selectedMembers.push(member.employeeUID);
		} else {
			selectedMembers.splice(findIndex, 1);
		}
		this.setState({
			selectedMembers: selectedMembers,
		});
	}

	sortTable() {}

	onDragStart(type, employee) {
		let draggingEmployee = {
			type: type,
			employee: employee,
		};
		this.setState({
			draggingEmployee: draggingEmployee,
		});
	}

	onDrop(ev, listType) {
		ev.preventDefault();
		let draggingEmployee = this.state.draggingEmployee;
		let direction = "right";
		if (draggingEmployee.type === "member") {
			direction = "left";
		}
		if (listType !== draggingEmployee.type) {
			this.handleMemberTransfer(direction, [draggingEmployee.employee]);
		}
	}

	onDragEnter(ev) {
		ev.preventDefault();
	}

	onDragLeave(ev) {
		ev.preventDefault();
		//setPotential(false);
	}

	render() {
		let resourceGroupMemberItems = [];
		let resourceGroupMemberAllItems = [];
		let filterAttributes = ["firstName", "lastName", "dept", "title", "region", "trade", "craft", "class"];

		let departments = portalUser.getDepartments();
		let deptMap = convertArrayToMap(departments, "deptID");

		if (this.state.dataView === "list") {
			for (var i = 0; i < this.state.members?.list.length; i++) {
				if (searchFilter(this.state.members.list[i], filterAttributes, this.state.searchBarVal)) {
					resourceGroupMemberItems.push(
						<ResourceGroupMemberRow
							editGroup={this.state.editGroup}
							member={this.state.members.list[i]}
							listItemHeight="80"
							updateMemberRole={this.updateMemberRole}
							handleMemberSelect={this.handleMemberSelect}
							selectedMembers={this.state.selectedMembers}
							onDragStart={this.onDragStart}
							type="member"
							deptMap={deptMap}
						/>
					);
				}
			}

			for (var j = 0; j < this.state.allUsers?.list.length; j++) {
				if (searchFilter(this.state.allUsers.list[j], filterAttributes, this.state.searchBarVal)) {
					var include = false;
					for (var k = 0; k < this.state.members?.list.length; k++) {
						if (this.state.members.list[k].employeeUID === this.state.allUsers.list[j].employeeUID) {
							include = true;
							break;
						}
					}
					if (!include) {
						resourceGroupMemberAllItems.push(
							<ResourceGroupMemberRow
								editGroup={this.state.editGroup}
								member={this.state.allUsers.list[j]}
								listItemHeight="80"
								updateMemberRole={this.updateMemberRole}
								handleMemberSelect={this.handleMemberSelect}
								selectedMembers={this.state.selectedMembers}
								onDragStart={this.onDragStart}
								type="employee"
								deptMap={deptMap}
							/>
						);
					}
				}
			}
		}

		let inputStyles = {
			fontSize: "lg",
			fontWeight: "semibold",
			color: "gray.400",
			spellCheck: "false",
			minW: "xs",
		};
		if (Boolean(this.state.resourceGroups?.activeGroup?.groupUID)) {
			inputStyles = {
				fontSize: "xl",
				fontWeight: "semibold",
				color: "gray.500",
				spellCheck: "false",
				minW: "xs",
			};
		}

		return (
			<Container maxW="8xl" minW="xl" px={{ base: "4", md: "4" }} py="6">
				<Stack spacing={4}>
					<Stack spacing={4} direction={{ base: "column", md: "row" }}>
						<Flex align="center">
							<Heading color="gray.500" size="lg" fontWeight="semibold" isTruncated letterSpacing={2}>
								RESOURCE GROUP
							</Heading>
						</Flex>
						<DataInput
							name="resourceGroups"
							searchable
							options={this.state.resourceGroups?.list}
							optionText="groupName"
							optionSubheading="groupDescription"
							optionValue="groupUID"
							placeholder="Select Group..."
							// label="Resource Groups"
							type="select"
							value={this.state.resourceGroups?.activeGroup?.groupUID}
							onOptionSelect={this.getGroupMembers}
							inputProps={inputStyles}
							color="gray.500"
						/>
					</Stack>

					<Skeleton rounded="md" minH={"5xl"} minW="xl" w="full" isLoaded={this.state.members !== null}>
						<Stack spacing={4}>
							<Input
								bg="white"
								placeholder="Search Employees..."
								onChange={this.updateSearchBarValue}
								value={this.state.searchBarVal}
							/>

							<SimpleGrid spacing={6} columns={this.state.editGroup ? "2" : "1"}>
								{this.state.editGroup && (
									<SlideFade in={this.state.editGroup} offsetX="50px">
										<Flex flex={1} direction="column" minH="full">
											<Flex my={2} justify="space-between">
												<HStack color="gray.500">
													<Heading isTruncated as="h5" size="sm">
														Add Employees
													</Heading>
												</HStack>
												<Spacer />
												<HStack spacing={6}>
													<Tooltip label="Total Employees in Group" placement="top">
														<HStack
															color="gray.500"
															isTruncated
															fontSize="md"
															textTransform={"uppercase"}
															fontWeight="semibold"
														>
															<i className="fas fa-users fa-lg fa-fw" />

															<Text>
																{formatValue(
																	this.state.resourceGroups?.activeGroup
																		?.employeeCount,
																	0,
																	"number"
																)}
															</Text>
														</HStack>
													</Tooltip>
												</HStack>
											</Flex>

											<Flex w="full" bg="whitesmoke" rounded="md" maxW="8xl">
												<Box
													minH="full"
													minW="full"
													py="4"
													px={{ base: "0", xl: "4" }}
													onDragEnter={this.onDragEnter}
													onDragLeave={this.onDragLeave}
													onDrop={(ev) => {
														this.onDrop(ev, "employee");
													}}
													onDragOver={(ev) => {
														ev.preventDefault();
													}}
												>
													<VirtualListV2 rows={resourceGroupMemberAllItems} />
												</Box>
											</Flex>
										</Flex>
									</SlideFade>
								)}

								<Flex flex={1} direction="column" minH="full">
									<Flex my={2} justify="space-between" wrap="wrap">
										<Stack spacing={1}>
											<Heading size="md" color="gray.400">
												{this.state.resourceGroups?.activeGroup.groupName}
											</Heading>
											<Heading size="sm" color="gray.500">
												{this.state.resourceGroups?.activeGroup.groupDescription}
											</Heading>
										</Stack>
										<Spacer />
										<HStack spacing={6}>
											<Tooltip label="Total Employees in Group" placement="top">
												<HStack
													color="gray.500"
													isTruncated
													fontSize="md"
													textTransform={"uppercase"}
													fontWeight="semibold"
												>
													<i className="fas fa-users fa-lg fa-fw"></i>
													<Text>
														{formatValue(
															this.state.resourceGroups?.activeGroup.employeeCount,
															0,
															"number"
														)}
													</Text>
												</HStack>
											</Tooltip>
											<Tooltip label="Employees w/ Crew Time Access" placement="top">
												<HStack
													color="gray.500"
													isTruncated
													fontSize="md"
													textTransform={"uppercase"}
													fontWeight="semibold"
												>
													<i className="fas fa-hard-hat fa-lg fa-fw"></i>

													<Text>
														{formatValue(
															this.state.resourceGroups?.activeGroup.crewEntryCount,
															0,
															"number"
														)}
													</Text>
												</HStack>
											</Tooltip>
											<Tooltip label="Employees w/ Approve Time Access" placement="top">
												<HStack
													color="gray.500"
													isTruncated
													fontSize="md"
													textTransform={"uppercase"}
													fontWeight="semibold"
												>
													<i className="fas fa-check-circle fa-lg fa-fw"></i>

													<Text>
														{formatValue(
															this.state.resourceGroups?.activeGroup.approverCount,
															0,
															"number"
														)}
													</Text>
												</HStack>
											</Tooltip>
										</HStack>
									</Flex>

									<Flex w="full" bg="whitesmoke" rounded="md" maxW="8xl">
										<Box
											minH="full"
											minW="full"
											py="4"
											px={{ base: "0", xl: "4" }}
											onDragEnter={this.onDragEnter}
											onDragLeave={this.onDragLeave}
											onDrop={(ev) => {
												this.onDrop(ev, "member");
											}}
											onDragOver={(ev) => {
												ev.preventDefault();
											}}
										>
											<VirtualListV2 rows={resourceGroupMemberItems} defaultHeight={107} />
										</Box>
									</Flex>

									{this.state.ready && (
										<Flex
											py="4"
											justify="flex-end"
											w="full"
											display={
												this.state.resourceGroups?.activeGroup.autoUpdate ? "none" : "flex"
											}
										>
											<Tooltip label="Add/Remove Group Members" placement="top">
												<Button
													variant="solid"
													colorScheme="teal"
													size="md"
													mr="2"
													onClick={this.toggleEditGroup}
												>
													<HStack align="center">
														<i className="fas fa-edit fa-lg fa-fw" />
														<Text
															fontSize="md"
															textTransform={"uppercase"}
															fontWeight="semibold"
															letterSpacing={1}
														>
															EDIT GROUP
														</Text>
													</HStack>
												</Button>
											</Tooltip>
										</Flex>
									)}
								</Flex>
							</SimpleGrid>
						</Stack>
					</Skeleton>
				</Stack>
				<Modal
					isOpen={this.state.transferListIsOpen}
					onClose={this.closeTransferList}
					title="Add/Remove Group Members"
					size="5xl"
				>
					<TransferList
						leftList={this.state.allUsers?.list}
						rightList={this.state.members?.list}
						headerValues={["employeeName"]}
						subHeaderValues={["title", "trade", "region"]}
						handleTransfer={this.handleMemberTransfer}
						transferring={this.state.transferring}
					/>
				</Modal>
				<ToastPopup
					status={this.state.uploadStatus}
					updates={this.state.updates}
					updateTitle={this.state.updateTitle}
					toastShown={this.toastShown}
				/>
			</Container>
		);
	}
}

function TransferList(props) {
	const [filter, setFilter] = useState("");
	const [filterTimer, setFilterTimer] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);

	const itemInPrimary = (item, compareList) => {
		// let hide = false;
		let equalIndex = -1;
		for (let i = 0; i < compareList?.length; i++) {
			let included = true;
			if (props.headerValues) {
				for (let j = 0; j < props.headerValues?.length; j++) {
					if (item[props.headerValues[j]] !== compareList[i][props.headerValues[j]]) {
						included = false;
						break;
					}
				}
			}

			if (props.subHeaderValues) {
				for (let j = 0; j < props.subHeaderValues?.length; j++) {
					if (item[props.subHeaderValues[j]] !== compareList[i][props.subHeaderValues[j]]) {
						included = false;
						break;
					}
				}
			}

			if (included) {
				// hide = true;
				equalIndex = i;
				break;
			}
		}
		return equalIndex;
	};

	const handleSearchChange = (ev) => {
		let value = ev.target.value;
		setFilterTimer(clearTimeout(filterTimer));
		setFilterTimer(
			setTimeout(() => {
				setFilter(value.toLowerCase());
			}, 200)
		);
	};

	const selectItem = (index, item) => {
		let newSelectedItems = [...selectedItems];
		let includedItemIndex = itemInPrimary(item, selectedItems);
		if (includedItemIndex !== -1) {
			newSelectedItems.splice(includedItemIndex, 1);
		} else {
			newSelectedItems.push(item);
		}
		setSelectedItems(newSelectedItems);
	};

	const transferItems = (direction) => {
		props.handleTransfer(direction, selectedItems);
		setSelectedItems([]);
	};

	let leftListItems = [];
	let rightListItems = [];

	for (let i = 0; i < props.leftList?.length; i++) {
		let header = "";
		let subHeader = "";

		if (props.headerValues) {
			for (let j = 0; j < props.headerValues?.length; j++) {
				if (props.leftList[i][props.headerValues[j]] === undefined) {
					continue;
				}
				header += props.leftList[i][props.headerValues[j]];
				if (j !== props.headerValues?.length - 1) {
					header += " - ";
				}
			}
		}
		if (props.subHeaderValues) {
			for (let j = 0; j < props.subHeaderValues?.length; j++) {
				if (props.leftList[i][props.subHeaderValues[j]] === undefined) {
					continue;
				}
				subHeader += props.leftList[i][props.subHeaderValues[j]];
				if (j !== props.subHeaderValues?.length - 1) {
					subHeader += " - ";
				}
			}
		}

		if (itemInPrimary(props.leftList[i], props.rightList) === -1) {
			leftListItems.push({
				component: (
					<TransferListItem
						item={props.leftList[i]}
						itemHeight="65"
						header={header}
						subHeader={subHeader}
						index={i}
						selectItem={selectItem}
						checked={itemInPrimary(props.leftList[i], selectedItems) !== -1}
					/>
				),
				filterStr: header + " " + subHeader,
			});
		}
	}

	for (let i = 0; i < props.rightList?.length; i++) {
		let header = "";
		let subHeader = "";

		if (props.headerValues) {
			for (let j = 0; j < props.headerValues?.length; j++) {
				if (props.rightList[i][props.headerValues[j]] === undefined) {
					continue;
				}
				header += props.rightList[i][props.headerValues[j]];
				if (j !== props.headerValues?.length - 1) {
					header += " - ";
				}
			}
		}
		if (props.subHeaderValues) {
			for (let j = 0; j < props.subHeaderValues?.length; j++) {
				if (props.rightList[i][props.subHeaderValues[j]] === undefined) {
					continue;
				}
				subHeader += props.rightList[i][props.subHeaderValues[j]];
				if (j !== props.subHeaderValues.length - 1) {
					subHeader += " - ";
				}
			}
		}

		rightListItems.push({
			component: (
				<TransferListItem
					item={props.rightList[i]}
					itemHeight="65"
					header={header}
					subHeader={subHeader}
					index={i}
					selectItem={selectItem}
					checked={itemInPrimary(props.rightList[i], selectedItems) !== -1}
				/>
			),
			filterStr: header + " " + subHeader,
		});
	}

	return (
		<Box>
			<Box>
				<Input variant="outline" placeholder="Search..." onChange={handleSearchChange} />
			</Box>

			<Flex wrap="wrap">
				<Box flex={1} className={getDeviceType() === "mobile" ? "col-12" : ""}>
					<Text className="small">All Employees:</Text>
					<ResourceGroupsVirtualList
						height={getDeviceType() === "mobile" ? "250" : "350"}
						listItemHeight="65"
						listItems={leftListItems}
						filterable={true}
						filter={filter}
						loadingData={props.leftList === null}
						searchBar={false}
					/>
				</Box>

				<Center p={2} className={getDeviceType() === "mobile" ? "col-12" : ""}>
					{props.transferring && <Spinner />}
					{!props.transferring && (
						<Stack spacing={6} direction={{ base: "row", sm: "column" }}>
							<Box>
								<Tooltip label="Add Selected Users" placement="top">
									<Button
										variant="solid"
										size="sm"
										onClick={() => {
											transferItems("right");
										}}
									>
										<i
											className={
												"fas fa-fw fa-lg " +
												(getDeviceType() === "mobile" ? "fa-angle-down" : "fa-angle-right")
											}
										></i>
									</Button>
								</Tooltip>
							</Box>
							<Box>
								<Tooltip label="Remove Selected Users" placement="top">
									<Button
										variant="solid"
										size="sm"
										onClick={() => {
											transferItems("left");
										}}
									>
										<i
											className={
												"fas fa-fw fa-lg " +
												(getDeviceType() === "mobile" ? "fa-angle-up" : "fa-angle-left")
											}
										></i>
									</Button>
								</Tooltip>
							</Box>
						</Stack>
					)}
				</Center>

				<Box flex={1} className={getDeviceType() === "mobile" ? "col-12" : ""}>
					<Text className="small">Current Group Members:</Text>
					<ResourceGroupsVirtualList
						height={getDeviceType() === "mobile" ? "250" : "350"}
						listItemHeight="65"
						listItems={rightListItems}
						filterable={true}
						filter={filter}
						loadingData={props.rightList === null}
						searchBar={false}
					/>
				</Box>
			</Flex>
		</Box>
	);
}

function TransferListItem(props) {
	let header = props.header;
	let subHeader = props.subHeader;

	if (props.key) {
		header = props.key;
	}
	if (props.value) {
		subHeader = props.value;
	}

	return (
		<Flex
			key={v4()}
			className="hoverPointerLight"
			onClick={() => {
				props.selectItem(props.index, props.item);
			}}
			height={props.itemHeight + "px"}
		>
			<Center>
				<span>
					<i className={props.checked ? "fas fa-check-square fa-lg" : "far fa-square fa-lg"}></i>
				</span>
			</Center>
			<Center>
				<Box whiteSpace="nowrap" overflow="hidden">
					<Text>{header}</Text>
					<Text color="darkgray">{subHeader}</Text>
				</Box>
			</Center>
		</Flex>
	);
}
