import { portalUser } from "../../../App";
import { formatValue } from "../../../helperFunctions";
import { v4 } from "uuid";

export default class FormComment {
	constructor(comment) {
		if (comment instanceof FormComment) {
			Object.assign(this, comment);
		} else {
			this.queryData = comment?.queryData ?? "FormComment";

			//FormUID
			this.templateUID = comment?.templateUID ?? null;
			this.formUID = comment?.formUID ?? null;
			this.inputUID = comment?.inputUID ?? null;
			this.commentUID = comment?.commentUID ?? v4();
			this.employeeUID = comment?.employeeUID ?? portalUser.user?.employeeUID;

			//Integer
			this.keyID = comment?.keyID ?? null;

			//String
			this.value = comment?.value ?? null;

			//EmployeeUID

			//Dates
			this.commentDate = formatValue(comment?.commentDate ?? new Date(), 0, "moment");

			//Boolean
			this.isVisibleYN = comment?.isVisibleYN ?? "Y";

			this.error = false;
		}
	}

	async insertFormComment() {
		let comment = this;

		if (
			Boolean(comment?.formUID) &&
			Boolean(comment?.inputUID) &&
			Boolean(comment?.commentUID) &&
			!Boolean(comment?.keyID)
		) {
			let params = { formUID: comment?.formUID, commentUID: this.commentUID };
			let data = {
				inputUID: comment.inputUID,
				employeeUID: comment.employeeUID,
				value: comment.value,
				commentDate: comment.commentDate?.format("MM/DD/YYYY"),
				isVisibleYN: comment.isVisibleYN === "Y" ? "1" : "0",
			};

			let result = await portalUser.formData?.formsAPI?.InsertFormComment(params, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				comment.keyID = result?.value[0]?.keyID ?? null;

				if (!Boolean(comment?.keyID)) {
					comment.error = true;
				} else {
					comment.error = false;
				}
			} else {
				comment.error = true;
			}
		} else {
			comment.error = true;
		}

		if (Boolean(comment?.keyID) && !Boolean(comment?.error)) {
			portalUser.formData.comments.push(comment);
		}
		return comment;
	}

	async updateFormComment(attr = null, value = this.value) {
		value = value?.toString();
		if (Boolean(attr) && this[attr] !== value) {
			if (typeof value === "string") {
				value = value?.replace(/'/gm, "`");
			}
			this[attr] = value;
			let params = { formUID: this.formUID, commentUID: this.commentUID };
			let update = {};
			if (attr === "value") {
				update = { Value: value };
			} else if (attr === "isVisibleYN") {
				if (value === "Y") {
					value = 1;
				} else {
					value = 0;
				}
				update = { IsVisible: value };
			}

			let result = await portalUser.formData?.formsAPI.UpdateFormComment(params, update);

			// let lastUpdated = new Date();
			if (result?.status === 200) {
				// let update = {
				// 	DateModified: formatValue(lastUpdated, 0, "datetimeSQL"),
				// 	ModifiedBy: portalUser.user?.employeeUID,
				// };
				// portalUser.formData?.formsAPI.UpdateForm(params, update);
				// this.lastUpdated = formatValue(lastUpdated, 0, "moment");
				return this;
			} else {
				this.error = true;
				return this;
			}
		} else {
			return this;
		}
	}

	async deleteFormComment() {
		let comment = this;
		if (Boolean(comment?.formUID) && Boolean(comment?.commentUID)) {
			//FormUID, CommentUID & keyID exist, need to Delete in SQL
			if (Boolean(comment?.keyID)) {
				let params = { formUID: comment?.formUID, commentUID: comment?.commentUID };
				let result = await portalUser.formData?.formsAPI?.DeleteFormComment(params);
				//SQL Delete Success
				if (result?.status === 200 && result?.value?.length === 0) {
					comment.keyID = null;
					comment.error = false;
				}
				//SQL Delete Error
				else {
					comment.error = true;
				}
			}
			//FormUID and CommentUID exist but no keyID, just need to filter
			else {
				comment.error = false;
			}
		}
		//Missing FormUID or CommentUID
		else {
			comment.error = true;
		}

		//If no error, filter out of cache
		if (!Boolean(comment.error)) {
			let comments = portalUser.formData?.comments ?? [];
			comments = comments.filter((d) => d.commentUID !== comment.commentUID) ?? [];
			portalUser.formData.comments = comments;
		}

		return comment;
	}

	validateComment(errorArray, inputUID) {
		//A comment must not be null/empty
		if (!Boolean(this.value) || this.value.trim().length === 0) {
			errorArray.push({
				...this,
				errorText: "A comment has no text. Please add your comments to it or delete it.",
			});
		}
		return errorArray;
	}
}
