// Portal User Objects
import AppData from "./AppData";
import UserData from "../modules/Employee/classes/UserData";
import DFData from "../modules/Schedules/classes/DFData";
import User from "../modules/Employee/classes/User";
// import TimecardUser from "../modules/Payroll/classes/TimecardUser";
import CalendarEventObj from "../core/Calendars/classes/CalendarEvent";
import CompanyObj from "./Company";
import RegionObj from "./Region";
import Contract from "../modules/Contracts/classes/Contract";
import PCO from "../modules/Contracts/classes/PCO";
import COItem from "../modules/Contracts/classes/COItem";

import DocumentObj from "../core/Reports/classes/Document";
import ReportObj from "../core/Reports/classes/Report";
import ReportTemplateMonthlyForecast from "../modules/MFR/templates/ReportTemplateMonthlyForecast";

import moment from "moment";
import { log, arrayToString, convertArrayToMap, formatDate } from "../helperFunctions";
import MSGraphAPI from "../APIs/MSGraphAPI";

import AppAPI from "../APIs/AppAPI";
import UsersAPI from "../APIs/UsersAPI";
import ProjectsAPI from "../APIs/ProjectsAPI";
import ReportTemplateMonthlyForecastCRS from "../modules/MFR/templates/ReportTemplateMonthlyForecastCRS";

const pageBGArr = [
	{ tabVal: 0, pageBG: "gray.200" },
	{ tabVal: 1, pageBG: "gray.300" },
	{ tabVal: 2, pageBG: "gray.400" },
	{ tabVal: 3, pageBG: "gray.500" },
	{ tabVal: 4, pageBG: "gray.600" },
	{ tabVal: 5, pageBG: "gray.700" },
];
const pageBGMap = convertArrayToMap(pageBGArr, "tabVal");

export class PortalUser {
	constructor(portalUser) {
		if (portalUser instanceof PortalUser) {
			Object.assign(this, portalUser);
		} else {
			this.app = null; //users, routes, logs/loginTime,
			this.appData = new AppData();
			this.appDataAPI = new AppAPI();
			this.projectsAPI = new ProjectsAPI();
			this.user = new User();
			this.usersAPI = null;
			this.userData = new UserData();

			this.logs = []; //make part of login time

			this.formData = null;

			this.msClient = null;

			this.msUser = {};

			this.timecardUser = null; //payperiods, prEndDate, timecard, resourceGroups,

			this.contractData = new AppData();
			this.DFData = new DFData();

			this.document = new DocumentObj();
			this.tempData = new AppData();
			this.tempUID = null;
			this.error = false;

			this.styles = {
				pageBG: pageBGMap[0]?.pageBG,
				pageBGMap: pageBGMap,
				pageSpacing: 8,
				pagePadding: 6,

				cardBG: "whiteAlpha.700",
				cardPadding: 6,
				cardBorderWidth: 2,
				cardBorderColor: "rgba(160, 174, 192, 0.5)",
				cardShadow: "0 10px 15px -3px rgba(26, 32, 44, 0.1), 0 4px 6px -2px rgba(26, 32, 44, 0.05)",
			};
		}
	}

	async initMSUser(msAuth = null) {
		let msUser = this.msUser;
		if (Boolean(msAuth) && !Boolean(this.msClient)) {
			msUser = msAuth.msUser;
			this.msAuth = msAuth;
			this.msClient = new MSGraphAPI();
			this.usersAPI = new UsersAPI();
		}
		this.msUser = msUser;
		return msUser;
	}

	async initMSUsers() {
		let msUsers = await this.msClient.GetAllMSUsers();
		// log("msUsers", msUsers);
		if (!Boolean(msUsers?.successful) || !Boolean(msUsers?.value?.length > 0)) {
			let errorMsg = "initMSUsers: ";
			errorMsg += "initMSUsers was not successful";
			this.error = true;
			log(errorMsg);
		} else {
			return msUsers.value;
		}
	}

	async initAppData() {
		let appData = await this.appDataAPI.PortalUser_GetAppData();

		if (appData?.status === 200) {
			appData = new AppData(appData.value);
			let userData = new UserData(
				[],
				appData?.filter((d) => ["User", "Leader"].includes(d.queryData))
			);
			appData = appData?.filter((d) => !["User", "Leader"].includes(d.queryData));

			this.appData = appData;
			this.userData = userData;
		} else {
			let errorMsg = "initAppData: ";
			errorMsg += "initAppData was not successful";
			this.error = true;
			log(errorMsg);
		}
	}

	// INIT USER DATA (PortalUser_GetUserData)
	async initUserData(msUserID = this.msUser.id, resetUser = false) {
		if (resetUser) {
			this.resetUser();
		}

		let userData = await this.usersAPI.PortalUser_GetUserData(msUserID);

		if (userData?.status === 200) {
			userData = new UserData(this.userData, userData.value);
			let contractData = userData?.filter((d) => ["Contract"].includes(d.queryData));
			userData = userData?.filter((d) => !["Contract"].includes(d.queryData));
			this.userData = userData;
			this.contractData = contractData;
		} else {
			let errorMsg = "initUserData: ";
			errorMsg += "initUserData was not successful";
			this.error = true;
			log(errorMsg);
		}
	}

	// TO DO: INIT USER
	async initUser(msUserID = this.msUser.id) {
		let user = await this.getUser(msUserID);
		if (!Boolean(user?.msUserID)) {
			// Future Note for dustin: this is were you would check for msUsers
			// to see if there is a valid msUser ID and add to SQL if missing
			let msUsers = await this.msClient.GetAllMSUsers();
			// log("msUSERS", msUsers);
			if (Boolean(msUsers?.successful) && !Boolean(msUsers?.value?.length > 0)) {
				msUsers = msUsers.value;
			} else {
				let errorMsg = "initUser: ";
				errorMsg += "initUser was not successful";
				this.error = true;
				log(errorMsg);
			}
		} else {
			this.user = user;
		}
	}

	// INIT User Jobs (PortalUser_GetUserJobs)
	async initUserJobs(msUserID = this.msUser.id, resetUser = false) {
		if (resetUser) {
			this.user.jobs = [];
		}

		if (this.user.jobs?.length === 0) {
			let jobs = await this.usersAPI.PortalUser_GetUserJobs(msUserID);

			if (jobs?.status === 200) {
				jobs = new UserData(jobs.value);

				this.user.jobs = jobs;
			} else {
				let errorMsg = "initUserJobs: ";
				errorMsg += "initUserJobs was not successful";
				this.error = true;
				log(errorMsg);
			}
		}
	}

	// INIT User Jobs (PortalUser_GetUserJobs)
	async getUserJobs(msUserID = this.user.msUserID) {
		let jobs = this.user.jobs;
		if (jobs === 0) {
			await this.initUserJobs(msUserID);
			jobs = this.user.jobs;
		}
		return jobs;
	}

	// INIT Projects GetContracts
	async initProjects(msUserID = this.msUser.id, contractStatus = "1", getAllContracts) {
		let contractData = this.contractData;
		let contracts = this.getContracts(contractStatus);
		if (Boolean(getAllContracts)) {
			let data = await this.projectsAPI.GetContracts(msUserID);

			if (data?.status === 200) {
				data = new UserData(data.value);
				this.contractData = data; //WAS this.contractData = [...contractData, ...data];
			} else {
				let errorMsg = "initProjects: ";
				errorMsg += "initProjects getAllContracts was not successful";
				this.error = true;
				log(errorMsg);
			}
			contracts = this.getContracts(contractStatus);
		}

		if (contracts?.length === 0) {
			let data = await this.projectsAPI.GetContracts(msUserID, contractStatus);
			if (data?.status === 200) {
				data = new UserData(data.value);
				this.contractData = [...contractData, ...data];
			} else {
				let errorMsg = "initProjects: ";
				errorMsg += "initProjects was not successful";
				this.error = true;
				log(errorMsg);
			}
			contracts = this.getContracts(contractStatus);
		}
		return contracts;
	}

	getUsers() {
		let users = this.userData?.filter((d) => d.queryData === "PREmployee") ?? [];
		users = users?.filter((d) => Boolean(d.msUserID)) ?? [];
		// this.getProfilePhotos();
		return users;
	}

	//MOVE TO MSGRAPH
	async getProfilePhoto(user = null) {
		if (
			Boolean(this.msClient) &&
			Boolean(user?.msUserID) &&
			Boolean(user?.initPhoto) &&
			!Boolean(user?.profilePhoto)
		) {
			let profilePhoto = await this.msClient.GetMSUserPhoto(user?.msUserID, false);
			if (Boolean(profilePhoto?.successful) && Boolean(profilePhoto?.size > 1)) {
				profilePhoto = URL.createObjectURL(profilePhoto);
				user.profilePhoto = profilePhoto;
			} else {
			}
			user.initPhoto = false;
		}
		return user;
	}

	async getUser(uid = this.msUser.id, prop = "msUserID") {
		let users = this.getPREmployees();
		let user = users?.find((u) => u[prop] === uid) ?? null;

		if (Boolean(user?.employeeUID) && !Boolean(user?.msUserID)) {
			let errorMsg = "getUser: ";
			errorMsg += "msUserID not found.";
			log(errorMsg);
		} else if (Boolean(user)) {
			user.getName();
			user.getCompany();
			user.getRegion();
			user.getDepartment();
			user.getRole();
			user.getLongevity();

			if (Boolean(user?.msUserID) && !Boolean(user?.profilePhoto) && user?.initPhoto) {
				user = await this.getProfilePhoto(user);
			}
		}
		return user;
	}

	resetUser() {
		this.userData = this.appData.filter((d) => ["User", "Leader"].includes(d.queryData));
		this.contractData = new AppData();
		this.user = new User();
		this.document = new DocumentObj();
		let errorMsg = "resetUser: ";
		errorMsg += "User has been reset";
		log(errorMsg);
	}

	async impersonateUser(msUserID = this.msUser.id) {
		let errorMsg = "impersonateUser: ";
		if (this.user.msUserID !== msUserID) {
			// Init the new ms user, with Reset user flag to Flush Current User Data from Cache
			await this.initUserData(msUserID, true);
			await this.initUser(msUserID);
			log("Init to User", this.user.getName());
		} else {
			errorMsg += "User Already Init'd";
			log(errorMsg, this.user.getName());
		}

		if (msUserID === this.msUser.id && this.user.msUserID !== this.msUser.id) {
			log("Impersonate User: Reset to Self");
		} else if (msUserID !== this.msUser.id && this.user.msUserID === this.msUser.id) {
			log("Impersonate User: Change From Self to New User");
		} else if (msUserID === this.msUser.id && this.user.msUserID === this.msUser.id) {
			log("Impersonate User: Current User is Self, No Change Needed");
		} else if (msUserID !== this.msUser.id && this.isImpersonating) {
			log("Impersonate User: Change from One User to another User ");
		}
	}

	/****************************************************************/
	/******************** CONTRACT DATA API ************************/
	/**************************************************************/

	async initContracts(jccmKeyID = null) {
		let contractData = await this.projectsAPI.GetContractData(jccmKeyID);

		if (contractData?.status === 200) {
			contractData = new AppData(contractData.value);

			return contractData;
		} else {
			let errorMsg = "initContracts: ";
			errorMsg += "initContracts was not successful";
			this.error = true;
			log(errorMsg);
		}
	}

	async getContractData(jccmKeyID = [], clearCache = false) {
		let selectedKeys = [...new Set(jccmKeyID)];
		selectedKeys = selectedKeys?.filter((d) => Boolean(d)) ?? [];

		let loadedData =
			this.contractData?.filter(
				(d) => selectedKeys?.includes(d.jccmKeyID) && !["Contract"].includes(d.queryData)
			) ?? [];
		let loadedKeys = [...new Set(loadedData?.map((key) => key.jccmKeyID))];
		loadedKeys = loadedKeys?.filter((d) => Boolean(d));
		if (Boolean(clearCache) && loadedKeys?.length > 0) {
			this.contractData = this.contractData.filter((d) => !selectedKeys.includes(d.jccmKeyID));
			loadedKeys = [];
		}

		let remainingKeys = [...new Set(selectedKeys?.filter((x) => !loadedKeys.includes(x)))];
		remainingKeys = remainingKeys?.filter((data) => Boolean(data));

		if (remainingKeys.length > 0) {
			this.contractData = this.contractData.filter((d) => !remainingKeys.includes(d.jccmKeyID));
			let contractData = await this.initContracts(arrayToString(remainingKeys, ","));

			// log("NEW CONTRACT DATA LOADED", contractData);

			let contractTotalsByMth = contractData?.filter((d) => d.queryData === "ContractTotalsByMth") ?? [];
			let manpowerProjectionsByMth =
				contractData?.filter((d) => d.queryData === "ManpowerProjectionsByMth") ?? [];
			for (let i = 0; i < manpowerProjectionsByMth?.length; i++) {
				manpowerProjectionsByMth[i].getRateToComplete(contractTotalsByMth);
			}

			let pcos = contractData?.filter((d) => d.queryData === "PCO") ?? [];
			let acos = contractData?.filter((d) => d.queryData === "ACO") ?? [];
			let coItems = contractData?.filter((d) => d.queryData === "COItem") ?? [];

			for (let i = 0; i < pcos?.length; i++) {
				let items = coItems.filter((d) => d.pmopKeyID === pcos[i].pmopKeyID) ?? [];
				pcos[i].coItems = items;
				pcos[i].getPCOItemTotals();
			}

			for (let i = 0; i < acos?.length; i++) {
				let items = coItems.filter((d) => d.pmohKeyID === acos[i].pmohKeyID) ?? [];
				acos[i].coItems = items;

				let pmopKeys = [...new Set(items?.map((d) => d.pmopKeyID))];
				pmopKeys = pmopKeys?.filter((d) => Boolean(d)) ?? [];

				let acoPCOs = pcos.filter((d) => pmopKeys.includes(d.pmopKeyID) && d.pcoStatus === "APPD") ?? [];
				acos[i].pcos = acoPCOs;

				acos[i].getACOItemTotals();
			}

			this.contractData.push(...contractData);
		}

		return this.getSelectedData(selectedKeys);
	}

	async initContractDetail(jccmKeyID = null) {
		let contractData = await this.projectsAPI.GetCostReimbursibleData(jccmKeyID);

		if (contractData?.status === 200) {
			contractData = new AppData(contractData.value);

			return contractData;
		} else {
			let errorMsg = "initContractDetail: ";
			errorMsg += "initContractDetail was not successful";
			this.error = true;
			log(errorMsg);
		}
	}

	async getContractDetail(jccmKeyID = []) {
		let contractData = this.contractData;
		// get unique list of currently selected keys
		let selectedKeys = [...new Set(jccmKeyID)];
		selectedKeys = selectedKeys?.filter((data) => Boolean(data));
		// log("selectedKeys", selectedKeys);

		// get unique list of keys that have already been loaded
		let data = contractData?.filter((data) => selectedKeys.includes(data.jccmKeyID)) ?? [];
		let loadedContracts = data?.filter((data) => data.queryData === "JobCostDetailByMth") ?? [];
		let loadedKeys = [...new Set(loadedContracts?.map((key) => key.jccmKeyID))];

		let remainingKeys = [...new Set(selectedKeys?.filter((x) => !loadedKeys.includes(x)))];
		remainingKeys = remainingKeys?.filter((data) => Boolean(data));

		if (remainingKeys.length > 0) {
			let contractData = await this.initContractDetail(arrayToString(jccmKeyID, ","));
			let itemTypeTotalsByMth = contractData?.filter((d) => d.queryData === "ItemTypeTotalsByMth") ?? [];
			let itemTypeDetailByMth = contractData?.filter((d) => d.queryData === "ItemTypeDetailByMth") ?? [];
			let contracts = this.getContracts();

			let contract = contracts?.find((d) => remainingKeys.includes(d.jccmKeyID));
			for (let i = 0; i < itemTypeTotalsByMth?.length; i++) {
				let itemTypeDetail =
					itemTypeDetailByMth.filter(
						(d) =>
							d.jccmKeyID === itemTypeTotalsByMth[i].jccmKeyID &&
							d.itemTypeID === itemTypeTotalsByMth[i].itemTypeID &&
							d.mth === itemTypeTotalsByMth[i].mth
					) ?? [];
				itemTypeTotalsByMth[i].itemTypeDetailByMth = itemTypeDetail;
				itemTypeTotalsByMth[i].getItemTypeDetailTotals(Boolean(["2", "4"].includes(contract?.contractTypeID)));
			}

			this.contractData.push(...contractData);
		}

		return this.getSelectedData(selectedKeys);
	}

	async syncContractData_ManpowerProjections(jccmKeyID = null) {
		let contractData = await this.projectsAPI.SyncManpowerProjections(jccmKeyID);

		if (contractData?.status === 200) {
			contractData = contractData.value;
			// log("sync ContractData", contractData);
			return contractData;
		} else {
			let errorMsg = "syncContractData: ";
			errorMsg += "sync Manpower Projections was not successful";
			this.error = true;
			log(errorMsg);
			return [];
		}
	}

	async syncContractDataManpowerProjections(jccmKeyID = []) {
		// get unique list of currently selected keys
		let selectedKeys = [...new Set(jccmKeyID)];
		selectedKeys = selectedKeys?.filter((data) => Boolean(data));
		// log("sync ContractData", selectedKeys);
		let syncData = [];
		if (selectedKeys.length > 0) {
			syncData = await this.syncContractData_ManpowerProjections(arrayToString(selectedKeys, ","));
		}

		return syncData;
	}

	/*****************************************************************/
	/************************ APP DATA  ****************************/
	/**************************************************************/
	getCompanies(jcco = null) {
		let companies = this.appData?.filter((d) => d.queryData === "Company") ?? [];
		if (!Boolean(jcco)) {
			return companies;
		} else {
			let company = companies?.find((d) => d.jcco === jcco) ?? new CompanyObj();
			return company;
		}
	}

	getRegions() {
		let regions = this.appData?.filter((d) => d.queryData === "Region") ?? [];
		return regions;
	}

	getDepartments() {
		let departments = this.appData?.filter((d) => d.queryData === "Department") ?? [];
		return departments;
	}

	getRoles() {
		let roles = this.appData?.filter((d) => d.queryData === "Role") ?? [];
		return roles;
	}

	getTrades() {
		let trades = this.appData?.filter((d) => d.queryData === "Trade") ?? [];
		return trades;
	}

	getScopes() {
		let scopes = this.appData?.filter((d) => d.queryData === "ScopePct") ?? [];
		return scopes;
	}

	getContractTypes() {
		let contractTypes = this.appData?.filter((d) => d.queryData === "ContractType") ?? [];
		return contractTypes;
	}

	getPlatforms() {
		let platforms = this.appData?.filter((d) => d.queryData === "Platform") ?? [];
		return platforms;
	}

	getProjectTypes() {
		let projectTypes = this.appData?.filter((d) => d.queryData === "ProjectType") ?? [];
		return projectTypes;
	}

	getInsuranceTypes() {
		let insuranceTypes = this.appData?.filter((d) => d.queryData === "InsuranceType") ?? [];
		return insuranceTypes;
	}

	getPCOStatuses() {
		let pcoStatuses = this.appData?.filter((d) => d.queryData === "PCOStatus") ?? [];
		return pcoStatuses;
	}

	getProjectAccts() {
		let projectAccts = this.appData?.filter((d) => d.queryData === "ProjectAcct") ?? [];
		return projectAccts;
	}

	getPurchasers() {
		let purchasers = this.appData?.filter((d) => d.queryData === "Purchaser") ?? [];
		return purchasers;
	}

	//TODO: CHANGE TO ASYNC with Get PR Employees if not loaded
	getPREmployees() {
		let employees = this.userData?.filter((d) => d.queryData === "PREmployee") ?? [];
		return employees;
	}

	/*****************************************************************/
	/************************ USER DATA  ****************************/
	/**************************************************************/

	getLeaders() {
		let leaders = this.userData?.filter((d) => d.queryData === "Leader") ?? [];

		return leaders;
	}

	getARCustomers() {
		let customers = this.userData?.filter((d) => d.queryData === "ARCustomer") ?? [];
		return customers;
	}

	async getAPVendors(msUserID = this.user?.msUserID) {
		// let vendorsResult = await this.usersAPI.PortalUser_GetAPVendors(msUserID);
		// let diversityCertsResult = await this.usersAPI.PortalUser_GetAPVendors_DiversityCerts(msUserID);
		// let vendors = vendorsResult?.value;
		// let diversityCerts = diversityCertsResult?.value;

		let vendors = this.userData?.filter((d) => d.queryData === "APVendor") ?? [];

		let diversityCerts = this.userData?.filter((d) => d.queryData === "DiversityCert") ?? [];

		for (let i = 0; i < vendors?.length; i++) {
			let certs =
				diversityCerts.filter(
					(d) => d.vendorGroup === vendors[i].vendorGroup && d.vendor === vendors[i].vendor
				) ?? [];
			vendors[i].diversityCerts = certs;
		}

		return vendors;
	}

	getJCProjectMgrs() {
		let projectMgrs = this.userData?.filter((d) => d.queryData === "JCProjectMgr") ?? [];
		return projectMgrs;
	}

	getTimeOffRequests() {
		let timeoff = this.userData?.filter((d) => d.queryData === "TimeOffRequest") ?? [];
		return timeoff;
	}

	getAnnouncements() {
		let announcements = this.userData?.filter((d) => d.queryData === "Announcement") ?? [];
		return announcements;
	}

	getLongevityAwards() {
		let longevityAwards = this.userData?.filter((d) => d.queryData === "LongevityAward") ?? [];
		return longevityAwards;
	}

	getNotifications(showAll = true) {
		let notifications = this.userData?.filter((d) => d.queryData === "Notification") ?? [];
		let timeOffRequests = this.getTimeOffRequests();

		for (let i = 0; i < notifications?.length; i++) {
			if (notifications[i]?.category === "timeoff" && !Boolean(notifications[i]?.timeOffRequest)) {
				let requestUID = notifications[i].requestUID;
				notifications[i].timeOffRequest = timeOffRequests?.find((a) => a.requestUID === requestUID) ?? null;
			}
		}

		notifications?.sort((a, b) => {
			return new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime();
		});
		if (!Boolean(showAll)) {
			notifications = notifications?.filter((d) => !Boolean(d.dateAcknowledged)) ?? [];
		}

		return notifications;
	}

	getCalendarEvents() {
		let userEvents = this.userData?.filter((d) => d.queryData === "CalendarEvent") ?? [];
		let regions = this.getRegions();
		let mfrScheduleEvents = this.getMFRScheduleEvents(userEvents, regions);
		let calendarEvents = [...userEvents, ...mfrScheduleEvents];
		for (let i = 0; i < calendarEvents?.length; i++) {
			calendarEvents[i].region =
				regions?.find((a) => a.regionID === calendarEvents[i].regionID) ?? new RegionObj();
		}
		return calendarEvents;
	}

	/*****************************************************************/
	/************************ CONTRACT DATA  ****************************/
	/**************************************************************/

	getContracts(contractStatus = null) {
		let contracts = this.contractData?.filter((d) => d.queryData === "Contract") ?? [];
		if (Boolean(contractStatus)) {
			contracts = contracts?.filter((d) => d.contractStatus === contractStatus) ?? [];
		}
		return contracts;
	}

	getContractItems(jccmKeyID = []) {
		let contractItems = this.contractData?.filter((d) => d.queryData === "ContractItem") ?? [];
		if (Boolean(jccmKeyID.length > 0)) {
			contractItems = contractItems?.filter((d) => jccmKeyID?.includes(d.jccmKeyID)) ?? [];
		}
		return contractItems;
	}

	getItemTypes() {
		return this.appData?.filter((d) => d.queryData === "ItemType") ?? [];
	}

	getContract(jccmKeyID = null) {
		if (Boolean(jccmKeyID)) {
			let contract = this.getContracts().filter((d) => d.jccmKeyID === jccmKeyID)[0];
			return contract;
		} else {
			return {};
		}
	}

	getCOItems(pmopKeyID = null) {
		let coItems = this.contractData?.filter((d) => d.queryData === "COItem") ?? [];
		if (Boolean(pmopKeyID)) {
			coItems = coItems.filter((d) => d.pmopKeyID === pmopKeyID) ?? [];
			return coItems;
		} else {
			return {};
		}
	}

	getRFIs() {
		let rfis = this.contractData?.filter((d) => d.queryData === "RFI") ?? [];
		return rfis;
	}

	getPhases() {
		let phases = this.appData?.filter((d) => d.queryData === "Phase") ?? [];
		return phases;
	}

	/*****************************************************************/
	/******************* PORTAL USER CALENDAR **********************/
	/**************************************************************/

	getMFRScheduleEvents(calendarEvents = [this.getCalendarEvents()], regions = this.getRegions()) {
		let mfrScheduleEvents = [];
		let mfrCalendarEvents = calendarEvents?.filter((d) => ["MFR", "QFR"].includes(d?.eventType)) ?? [];

		//Loop Over Regions & Months to Create MFR Schedule CalendarEvents for each Region
		for (let i = 0; i < regions?.length; i++) {
			let mfrMeetings = mfrCalendarEvents.filter((data) => data?.regionID === regions[i].regionID);

			let mths = [...new Set(mfrMeetings?.map((key) => moment(new Date(key?.startDate))?.format("MM/01/YYYY")))];
			for (let j = 0; j < mths?.length; j++) {
				let meetings =
					mfrMeetings?.filter((data) => moment(new Date(data?.startDate)).format("MM/01/YYYY") === mths[j]) ??
					[];

				if (meetings?.length > 0) {
					let startDate = meetings[0].startDate;
					let endDate = meetings[meetings?.length - 1].endDate;
					let calendarEvent = new CalendarEventObj({
						queryData: "CalendarEvent",
						startDate: startDate,
						endDate: endDate,
						allDay: true,
						title: regions[i].region + " MFRs",
						description:
							regions[i].region + " MFR Schedule - " + moment(new Date(startDate)).format("MMM YYYY"),
						eventType: "MFRSchedule",
						regionID: regions[i].regionID,
					});

					calendarEvent.meetings = meetings;
					mfrScheduleEvents.push(calendarEvent);
				}
			}
		}
		return mfrScheduleEvents;
	}

	async getOutlookEvents(startDate = new Date(), endDate = new Date()) {
		let startDateTime = moment(startDate).startOf("month").toISOString(true);
		let endDateTime = moment(endDate).endOf("month").toISOString(true);

		let outlookEvents = [];
		let result = await this.msClient.GetMSUserCalendarView(startDateTime, endDateTime);
		if (Boolean(result?.successful)) {
			outlookEvents = result.value;
		}

		let calendarEvents = [];
		for (let i = 0; i < outlookEvents?.length; i++) {
			let start = moment(new Date(formatDate(outlookEvents[i].start.dateTime, outlookEvents[i].isAllDay)));
			let end = start;
			if (!outlookEvents[i].isAllDay) {
				end = moment(new Date(formatDate(outlookEvents[i].end.dateTime, outlookEvents[i].isAllDay)));
			}
			let calendarEvent = new CalendarEventObj({
				queryData: "CalendarEvent",
				startDate: start.toDate(),
				endDate: end.toDate(),
				title: outlookEvents[i].subject,
				description: outlookEvents[i].body.content,
				eventType: "Outlook",
				allDay: outlookEvents[i].isAllDay,
				employeeUID: this.employeeUID,
				regionID: this.regionID,
			});
			calendarEvents.push(calendarEvent);
		}
		return calendarEvents;
	}

	//FILTER CONTRACT DATA by @keys (multiple keys in Array)
	getSelectedData(selectedKeys = []) {
		let selectedData = this.contractData?.filter((d) => selectedKeys?.includes(d.jccmKeyID)) ?? [];
		return selectedData;
	}

	//GET DOCUMENT OBJECT BY ReportType and @keyID (multiple keys in Array)
	async getDocument(template = "MonthlyForecast", keys = [], thruMth = moment(new Date()).startOf("month")) {
		let reportTemplate = new ReportObj();

		let selectedKeys = [...new Set(keys)];
		selectedKeys = selectedKeys?.filter((data) => data !== undefined && data !== null);

		let selectedData = [];
		let contracts = this.getContracts();
		let contractData = this.contractData;
		let reports = [];

		if (selectedKeys.length > 0) {
			contracts = contracts?.filter((d) => selectedKeys.includes(d.jccmKeyID)) ?? [];
			contractData = contractData.filter((d) => selectedKeys.includes(d.jccmKeyID)) ?? [];
			selectedData = [...contracts, ...contractData];
		}

		if (template === "MonthlyForecast") {
			reportTemplate = new ReportTemplateMonthlyForecast([], thruMth);
		} else if (template === "MonthlyForecastCRS") {
			reportTemplate = new ReportTemplateMonthlyForecastCRS([], thruMth);
		}
		let obj = reportTemplate.constructor;

		if (Boolean(selectedKeys.length > 0)) {
			for (let i = 0; i < selectedKeys?.length; i++) {
				let report = new obj(selectedData, thruMth);
				reports.push(report);
			}
		} else {
			reports.push(new obj(reportTemplate));
		}

		let document = new DocumentObj({ reports: reports });
		this.document = document;

		return document;
	}

	getReportTemplates(reportTemplate = null, isVisibleYN = "Y") {
		let document = this.document;
		if (Boolean(reportTemplate)) {
			if (reportTemplate === "ReportTemplate" || reportTemplate !== document.reportTemplate) {
				document = document.getReportTemplates(reportTemplate);
				document = new DocumentObj(document);
				this.document = document;
			}
			return document;
		} else {
			let reportTemplates = document.getReportTemplates();
			if (Boolean(isVisibleYN)) {
				reportTemplates = reportTemplates.filter((d) => d.isVisibleYN === isVisibleYN);
			}
			return reportTemplates;
		}
	}

	newPCO(jccmKeyID = null) {
		let contract = this.getContract(jccmKeyID);
		if (!Boolean(contract?.jccmKeyID)) {
			contract = new Contract();
		}
		let pco = new PCO({ jccmKeyID: contract?.jccmKeyID, jcco: contract?.jcco, contract: contract?.contract });
		let coItem = new COItem({ jccmKeyID: contract?.jccmKeyID, jcco: contract?.jcco, contract: contract?.contract });
		pco.coItems.push(coItem);
		this.tempData = [pco, coItem];

		return pco;
	}
}
