import React, { useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import DataInput from "../../../core/Inputs/data/DataInput";
import { portalUser } from "../../../App";
// import { log } from "../../../helperFunctions";

export default function HRReviewsSelect(props) {
	let params = useParams();
	let history = useHistory();
	const basepath = useMemo(() => {
		let basepath = history.location.pathname;
		if (basepath?.length > 0 && basepath?.charAt(0) === "/") {
			basepath = basepath?.slice(1);
			basepath = basepath?.substring(0, basepath?.indexOf("/"));
			return basepath;
		}
	}, [history.location.pathname]);

	const tab = useMemo(() => props?.tab, [props?.tab]);

	const formLabel = useMemo(() => {
		let label = "Select ";
		if (["forms", "reviews"].includes(params.tab)) {
			label += "Review";
		} else {
			label += "Template";
		}
		return label;
	}, [params.tab]);

	const formOptions = useMemo(() => {
		let forms = portalUser.formData?.forms ?? [];
		if (params?.tab === "reviews") {
			forms = forms?.filter((d) => d.queryData === "Form");
		} else {
			forms = forms?.filter((d) => d.queryData === "FormTemplate");
		}
		if (Boolean(props.selectedYear)) {
			forms = forms?.filter((d) => d.formYear?.toString() === props.selectedYear?.toString());
		}
		return forms;
	}, [params?.tab, props.selectedYear]);

	const selectForm = (formUID = null) => {
		if (Boolean(formUID)) {
			history.push(`/${basepath}/${tab}/${formUID}`);
		} else {
			history.push(`/${basepath}/${tab}`);
		}
	};

	return (
		<DataInput
			type="select"
			variant="outline"
			placeholder={formLabel}
			label={props.showLabel && "HR " + formLabel + "s"}
			labelProps={{ color: "gray.500", letterSpacing: 2, w: "full" }}
			searchable
			value={params?.formUID}
			options={formOptions}
			optionValue="formUID" //return only the prEndDate when selected, not the entire payPeriod
			optionText="formTitle" //show the user the prEndDate from the payPeriod Object
			optionSubheading="formSubtitle"
			onOptionSelect={props?.selectForm ?? selectForm}
			textAlign="left"
			minW="3xs"
			maxW="sm"
			textTransform="uppercase"
			inputProps={{
				bg: "whiteAlpha.900",
			}}
		/>
	);
}
