import React, { useMemo, useState, useCallback } from "react";
import { Avatar, Flex, Text, Stack, HStack, Heading, useBoolean } from "@chakra-ui/react";
import { useAsyncDebounce } from "react-table";
import DataInput from "../../Inputs/data/DataInput";
import ToggleButton from "../../ReactTable/buttons/ToggleButton";
import FormCommentClass from "../classes/FormComment";

import { portalUser } from "../../../App";
import { convertArrayToMap } from "../../../helperFunctions";

export default function FormComment(props) {
	// Memoized values
	const form = useMemo(() => props.form ?? null, [props.form]);
	const [comment, setComment] = useState(props.comment ?? null);
	const usersMap = useMemo(() => props.usersMap, [props.usersMap]);
	const deleteFormComment = useMemo(() => props.deleteFormComment, [props.deleteFormComment]);
	const isReadOnly = useMemo(() => form?.getIsReadOnly(), [form]);
	const canAddEditComments = useMemo(() => form?.canAddEditComments() ?? false, [form]);

	// State for commenter user
	const [commenterUser, setCommenterUser] = useState(null);
	// Convert departments to a map
	var deptMap = convertArrayToMap(portalUser?.getDepartments(), "deptID");

	// Debounced function to update comment
	const updateFormComment = useAsyncDebounce(async (ev, attr = null) => {
		let result;
		if (Boolean(attr)) {
			if (typeof ev === "object") {
				result = await comment.updateFormComment(attr, ev.target.value);
			} else if (typeof ev === "string") {
				result = await comment.updateFormComment(attr, ev);
			}
			setComment(new FormCommentClass(result));
		}
	}, 200);

	const getUser = useCallback(
		async (employeeUID = null) => {
			let user = await portalUser.getUser(employeeUID, "employeeUID");
			if (!Boolean(user)) {
				user = usersMap[employeeUID] ?? null;
			}

			setCommenterUser(user);
		},
		[usersMap]
	);

	useMemo(() => {
		if (comment?.employeeUID !== commenterUser?.employeeUID) {
			getUser(comment?.employeeUID);
		}
	}, [comment?.employeeUID, commenterUser?.employeeUID, getUser]);

	const [isVisible, setIsVisible] = useBoolean(Boolean(comment?.isVisibleYN === "Y"));

	useMemo(() => {
		if (isVisible && comment?.isVisibleYN === "N" && Boolean(comment?.keyID)) {
			updateFormComment("Y", "isVisibleYN");
		} else if (!isVisible && comment?.isVisibleYN === "Y" && Boolean(comment?.keyID)) {
			updateFormComment("N", "isVisibleYN");
		}
	}, [isVisible, comment?.isVisibleYN, comment?.keyID, updateFormComment]);

	return (
		<Flex
			key={"comment" + comment?.commentUID}
			w="full"
			flex={1}
			justify="center"
			rounded="md"
			role={canAddEditComments && "group"}
			_hover={{ opacity: isVisible ? 1 : 0.75 }}
			opacity={isVisible ? 1 : 0.5}
		>
			<HStack
				direction="row"
				justify="center"
				minW="md"
				maxW="container.lg"
				w="full"
				spacing={2}
				align="flex-start"
			>
				<Stack align="center" minW={"32px"}>
					<Text fontSize="2xl" color="gray.500" className={"fas fa-quote-left fa-fw"} />
				</Stack>

				<Stack color={"gray.600"} spacing={3} w="full" flex={1} rounded={"md"}>
					<Flex rounded="md" shadow="lg" borderWidth={1} borderColor="gray.400">
						<DataInput
							id={comment?.commentUID}
							type="textarea"
							placeholder="Comment..."
							defaultValue={comment?.value ?? null}
							inputBorder={"1px solid var(--chakra-colors-gray-600)"}
							onBlur={(ev) => updateFormComment(ev, "value")}
							rowsMin={2}
							inputProps={{ lineHeight: "var(--chakra-lineHeights-short)" }}
							isReadOnly={isReadOnly}
						/>
					</Flex>

					<HStack
						spacing={3}
						fontWeight="semibold"
						align="center"
						textTransform={"uppercase"}
						w="full"
						flex={1}
						justify="flex-end"
					>
						<Avatar
							size="sm"
							bg={deptMap[commenterUser?.deptID]?.color}
							color="whiteAlpha.900"
							name={commenterUser?.name ?? null}
							src={commenterUser?.profilePhoto ?? null}
						/>
						<Stack color={"gray.600"} spacing={0} align="flex-start">
							<HStack direction="row" align="center" justify="flex-end" lineHeight={"short"}>
								<Heading
									color="gray.500"
									textTransform="uppercase"
									letterSpacing={2}
									fontSize="sm"
									isTruncated
								>
									{commenterUser?.name}
								</Heading>
								<Text color="gray.500" fontSize="sm" fontWeight="semibold" letterSpacing={1}>
									{"(" + comment?.commentDate?.format("M/D/YYYY") + ")"}
								</Text>
							</HStack>
							<Text color="gray.400" fontSize="xs" fontWeight="semibold">
								{commenterUser?.title}
							</Text>
						</Stack>
					</HStack>
				</Stack>

				<Stack align="center" _groupHover={{ display: "none" }} minW={"32px"}>
					<Text fontSize="2xl" color="gray.500" className={"fas fa-quote-right fa-fw"} />
				</Stack>
				{canAddEditComments && (
					<Stack spacing={1} align="center" display="none" _groupHover={{ display: "flex" }}>
						<VisibleButton isOpen={isVisible} setIsOpen={setIsVisible} />;
						<DeleteButton isOpen={false} onClick={() => deleteFormComment(comment?.commentUID)} />
					</Stack>
				)}
			</HStack>
		</Flex>
	);
}

function VisibleButton({ isOpen = true, setIsOpen }) {
	if (Boolean(setIsOpen)) {
		return (
			<ToggleButton
				toggleIsOpen={isOpen}
				onClick={setIsOpen?.toggle}
				toggleOptions={[
					{
						isOpen: true,
						label: "Comment Visible",
						icon: "fas fa-eye",
						color: "green.500",
						borderColor: "green.500",
						borderWidth: 2,
						size: "sm",
						tabIndex: -1,
						hover: { borderColor: "green.500", bg: "whiteAlpha.900" },
					},
					{
						isOpen: false,
						label: "Comment Hidden",
						icon: "fas fa-eye-slash",
						borderColor: "gray.300",
						color: "gray.500",
						borderWidth: 2,
						bg: "whiteAlpha.700",
						size: "sm",
						tabIndex: -1,
						hover: { borderColor: "green.500", color: "green.500", bg: "whiteAlpha.900" },
					},
				]}
			/>
		);
	} else {
		return <></>;
	}
}

function DeleteButton({ isOpen = false, onClick }) {
	if (Boolean(onClick)) {
		return (
			<ToggleButton
				toggleIsOpen={isOpen}
				onClick={onClick}
				toggleOptions={[
					{
						isOpen: false,
						label: "Delete Comment",
						icon: "fas fa-trash-alt",
						color: "gray.500",
						bg: "whiteAlpha.700",
						borderColor: "gray.300",
						borderWidth: 2,
						size: "sm",
						tabIndex: -1,
						hover: { color: "red.500", borderColor: "red.500" },
					},
				]}
			/>
		);
	} else {
		return <></>;
	}
}
