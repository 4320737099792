// import moment from "moment";
// import { portalUser } from "../../../../../App";

import { getSubtotal, getPct, log } from "../../../helperFunctions";

export default class SectionTemplateMFRContractStatusCRS {
	constructor(data = [], sectionTemplate = "MFRContractStatusCRS") {
		let itemTypeTotalsByMth = [];

		if (Boolean(data?.length > 0)) {
			itemTypeTotalsByMth = data?.filter((p) => p.queryData === "ItemTypeTotalsByMth") ?? [];
		}

		this.template = sectionTemplate;
		this.name = Boolean(sectionTemplate === "MFRContractStatusCRS")
			? "Contract Status CRS"
			: "Project Analysis CRS";
		this.label = Boolean(sectionTemplate === "MFRContractStatusCRS") ? "II." : "IV.";
		this.heading = Boolean(sectionTemplate === "MFRContractStatusCRS")
			? "Contract Status CRS"
			: "Project Analysis CRS";
		this.pagebreak = 0;
		this.divider = false;
		this.type = "virtualTable";

		this.subSections = [new SubSectionTemplateMFRContractStatusCRS(itemTypeTotalsByMth, sectionTemplate)];
	}
}

class SubSectionTemplateMFRContractStatusCRS {
	constructor(data = [], sectionTemplate = "MFRContractStatusCRS") {
		let sortGroupFilter = this.getSortGroupFilter(data, sectionTemplate);
		this.template = sectionTemplate;
		this.type = "virtualTable";
		this.wrap = true;

		this.groupBy = sortGroupFilter.groupBy;
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy;

		this.columns = [
			{
				accessor: "jccmKeyID",
				Header: "JCCM Key ID",
				show: false,
				valueType: "integer",
			},
			{
				accessor: "jcco",
				Header: "JCCo",
				show: false,
				valueType: "integer",
			},
			{
				accessor: "contract",
				Header: "Contract",
				show: false,
			},
			{
				accessor: "rowType",
				Header: "Row Type",
				show: false,
				filter: "multiselect",
			},
			{
				accessor: "itemTypeID",
				Header: "Item Type ID",
				show: false,
				filter: "multiselect",
			},
			{
				accessor: "itemType",
				Header: "Contract Item",
				width: "200%",
				filter: "multiselect",
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
			},
			{
				accessor: "costType",
				Header: "Cost Type",
				show: false,
			},
			{
				accessor: "costTypeDesc",
				Header: "Cost Type Desc",
				show: false,
			},
			{
				accessor: "origContractAmt",
				Header: "Orig Contract",
				valueType: "currency",
				show: false,
				fontSize: 8,
			},
			{
				accessor: "adjOrigContractAmt",
				Header: "Adjust Orig Contract",
				valueType: "currency",
				show: false,
				fontSize: 8,
			},
			{
				accessor: "bidContractAmt",
				Header: "Bid Contract",
				valueType: "currency",
				show: Boolean(["MFRContractStatusCRS", "MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},

			{
				accessor: "acoAmt",
				Header: "Approved Changes",
				valueType: "currency",
				show: Boolean(["MFRContractStatusCRS", "MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},

			{
				accessor: "contractAmt",
				Header: "Current Contract",
				valueType: "currency",
				show: Boolean(["MFRContractStatusCRS", "MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},

			{
				accessor: "pcoConv",
				Header: "PCO Conversion",
				valueType: "currency",
				show: Boolean(["MFRContractStatusCRS", "MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},

			{
				accessor: "pcoEstCost",
				Header: "PCO Est Cost",
				valueType: "currency",
				// show: Boolean(["MFRContractStatusCRS", "MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
				show: false,
			},

			{
				accessor: "pcoActualCost",
				Header: "PCO Actual Cost",
				valueType: "currency",
				// show: Boolean(["MFRContractStatusCRS", "MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
				show: false,
			},
			{
				accessor: "projContractAmt",
				Header: "Projected Target",
				valueType: "currency",
				show: Boolean(["MFRContractStatusCRS", "MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},

			{
				accessor: "actualCost",
				Header: "Cost To Date",
				valueType: "currency",
				show: Boolean(["MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},
			{
				accessor: "remainCost",
				Header: "Cost To Complete",
				valueType: "currency",
				show: Boolean(["MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},
			{
				accessor: "projCost",
				Header: "Cost At Completion",
				valueType: "currency",
				show: Boolean(["MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},

			{
				accessor: "billedAmt",
				Header: "Orig Billed Amt",
				valueType: "currency",
				show: false,
				fontSize: 8,
			},

			{
				accessor: "adjBilledAmt",
				Header: "COW Adjustment",
				valueType: "currency",
				show: false,
				fontSize: 8,
			},

			{
				accessor: "newBilledAmt",
				Header: "Billed To Date",
				valueType: "currency",
				show: Boolean(["MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},

			{
				accessor: "billedCost",
				Header: "Billed Cost",
				valueType: "currency",
				show: false,
				fontSize: 8,
			},
			{
				accessor: "billedIndirectCost",
				Header: "Indirect Cost To Date",
				valueType: "currency",
				show: Boolean(["MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				fontSize: 8,
			},
			{
				accessor: "indirectPct",
				Header: "Indirect %",
				valueType: "percent",
				textAlign: "left",
				show: Boolean(["MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},
			{
				accessor: "unBilledCost",
				Header: "Unbilled Cost",
				valueType: "currency",
				show: false,
				fontSize: 8,
			},
			{
				accessor: "unBilledIndirectCost",
				Header: "Unbilled Indirects",
				valueType: "currency",
				show: false,
				fontSize: 8,
			},

			{
				accessor: "activeBilled",
				Header: "Active Billed",
				valueType: "currency",
				show: false,
				fontSize: 8,
			},
			{
				accessor: "preBilled",
				Header: "Pre Billed",
				valueType: "currency",
				show: false,
				fontSize: 8,
			},

			{
				accessor: "unBilledCostLessIndirects",
				Header: "Unbilled Cost Less Indirects",
				valueType: "currency",
				show: Boolean(["MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				fontSize: 8,
			},

			{
				accessor: "projRemainBilling",
				Header: "Projected Remaining Billing",
				valueType: "currency",
				show: Boolean(["MFRProjectAnalysisCRS"].includes(sectionTemplate)),
				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				fontSize: 8,
			},
			// {
			// 	accessor: "overUnder",
			// 	Header: "Over / (Under)",
			// 	textAlign: "right",
			// 	valueType: "accounting",
			// 	show: Boolean(["MFRContractStatusCRS", "MFRProjectAnalysisCRS"].includes(sectionTemplate)),
			// 	Footer: (rows = []) => {
			// 		return this.getFooter(rows);
			// 	},
			// 	fontSize: 8,
			// },
			{
				accessor: "finalContractAmt",
				Header: "Projected Final Contract",
				valueType: "currency",
				show: Boolean(["MFRContractStatusCRS", "MFRProjectAnalysisCRS"].includes(sectionTemplate)),

				Footer: (rows = []) => {
					return this.getFooter(rows);
				},
				fontSize: 8,
			},
		];

		let itemTypeData = [];

		for (let i = 0; i < data?.length; i++) {
			let totalRow = data[i];
			if (sectionTemplate === "MFRProjectAnalysisCRS") {
				totalRow.rowType = "Subtotal";
			} else if (sectionTemplate === "MFRContractStatusCRS") {
				totalRow.rowType = "Subtotal";
			}

			itemTypeData.push(totalRow);
			for (let j = 0; j < totalRow?.itemTypeDetailByMth?.length; j++) {
				let detailRow = totalRow?.itemTypeDetailByMth[j];
				detailRow.rowType = "Detail";
				detailRow.itemType = detailRow?.costTypeDesc;
				if (["COW", "GCS"].includes(detailRow.itemTypeID)) {
					let totalValue =
						parseFloat(detailRow?.projContractAmt ?? 0) +
						parseFloat(detailRow?.actualCost ?? 0) +
						parseFloat(detailRow?.remainCost ?? 0) +
						parseFloat(detailRow?.projCost ?? 0) +
						parseFloat(detailRow?.projRemainBilling ?? 0) +
						parseFloat(detailRow?.finalContractAmt ?? 0);

					// if (Boolean(detailRow?.indirectPct)) {
					// 	detailRow.billedIndirectCostRightAddon = detailRow.indirectPct;
					// }
					if (totalValue !== 0) {
						itemTypeData.push(detailRow);
					}
				}
			}
		}

		this.data = itemTypeData;

		let footer = this.getFooter(itemTypeData);
		this.footer = footer;
	}
	getSortGroupFilter(data = [], sectionTemplate = "MFRContractStatusCRS") {
		let groupBy = [];
		let sortBy = [];
		let filterBy = [
			{
				id: "itemTypeID",
				value: ["COW", "GCS", "FEE", "BUFF", "BON", "PBON"],
			},
		];
		if (sectionTemplate === "MFRContractStatusCRS") {
			filterBy.push({ id: "rowType", value: "Subtotal" });
		}
		let keys = [...new Set(data?.map((d) => d.jccmKeyID))];
		if (keys.length > 1) {
			groupBy = ["jccmKeyID"];
			sortBy = [
				{ id: "jcco", desc: false },
				{ id: "contract", desc: false },
			];
		}
		let sortGroupFilter = { sortBy: sortBy, groupBy: groupBy, filterBy: filterBy };
		return sortGroupFilter;
	}

	getFooter(rows = []) {
		rows = rows?.filter((d) => d.rowType === "Subtotal") ?? [];

		let feeRows = rows?.filter((d) => d.itemTypeID === "FEE") ?? [];

		let total = {
			itemType: "Contract Total",

			origContractAmt: getSubtotal(rows, "origContractAmt") ?? 0,
			adjOrigContractAmt: getSubtotal(rows, "adjOrigContractAmt") ?? 0,
			bidContractAmt: getSubtotal(rows, "bidContractAmt") ?? 0,
			contractAmt: getSubtotal(rows, "contractAmt") ?? 0,
			acoAmt: getSubtotal(rows, "acoAmt") ?? 0,
			pcoAmt: getSubtotal(rows, "pcoAmt") ?? 0,
			pcoConv: getSubtotal(rows, "pcoConv") ?? 0,
			projContractAmt: getSubtotal(rows, "projContractAmt") ?? 0,

			actualCost: getSubtotal(rows, "actualCost") ?? 0,
			remainCost: getSubtotal(rows, "remainCost") ?? 0,
			projCost: getSubtotal(rows, "projCost") ?? 0,
			newBilledAmt: getSubtotal(rows, "newBilledAmt") ?? 0,

			billedIndirectCost: getSubtotal(rows, "billedIndirectCost") ?? 0,

			activeBilled: getSubtotal(rows, "activeBilled") ?? 0,
			preBilled: getSubtotal(rows, "preBilled") ?? 0,
			unBilledCostLessIndirects:
				getSubtotal(rows, "unBilledCostLessIndirects") ??
				0 + (getSubtotal(rows, "activeBilled") ?? 0 - getSubtotal(rows, "preBilled") ?? 0),

			projRemainBilling: getSubtotal(rows, "projRemainBilling") ?? 0,
			// overUnder: getSubtotal(rows, "overUnder") ?? 0,
			finalContractAmt: getSubtotal(rows, "finalContractAmt") ?? 0,
		};
		let profit = {
			itemType: "Gross Profit $",
			bidContractAmt: getSubtotal(feeRows, "bidContractAmt") ?? 0,
			finalContractAmt:
				parseFloat(getSubtotal(rows, "finalContractAmt") ?? 0) - parseFloat(getSubtotal(rows, "projCost") ?? 0),
		};

		let margin = {
			itemType: "Gross Margin %",
			bidContractAmt: getPct(
				getSubtotal(feeRows, "bidContractAmt") ?? 0,
				getSubtotal(rows, "bidContractAmt") ?? 0
			),
			bidContractAmtType: "percent",
			bidContractAmtDecimals: 1,
			finalContractAmt: getPct(
				parseFloat(getSubtotal(rows, "finalContractAmt") ?? 0) - parseFloat(getSubtotal(rows, "projCost") ?? 0),
				parseFloat(getSubtotal(rows, "finalContractAmt") ?? 0)
			),
			finalContractAmtType: "percent",
			finalContractAmtDecimals: 1,
		};

		return [total, profit, margin];
	}
}

export class SectionTemplateMFRProjectAnalysisSummaryCRS {
	constructor(data = [], contractStatus) {
		let itemTypeTotalsByMth = [];

		if (Boolean(data?.length > 0)) {
			itemTypeTotalsByMth = data?.filter((p) => p.queryData === "ItemTypeTotalsByMth") ?? [];
		}

		this.template = "MFRProjectAnalysisSummaryMFR";
		this.name = "";
		this.label = "";
		this.minRows = 4;
		this.heading = "";
		this.pagebreak = 0;
		this.divider = false;
		this.type = "virtualTable";

		this.subSections = [
			new SubSectionTemplateMFRCommittedCostAndAPCRS(itemTypeTotalsByMth, contractStatus),
			// new EmptySpace(),
			// new SubSectionTemplateMFRProjectAnalysisSummaryCRS(itemTypeTotalsByMth),
		];
	}
}

class SubSectionTemplateMFRCommittedCostAndAPCRS {
	constructor(data = [], contractStatus) {
		let sortGroupFilter = this.getSortGroupFilter(data);
		this.template = "MFRProjectAnalysisSummaryMFR";
		this.type = "virtualTable";
		this.wrap = true;
		this.width = "30%";

		this.groupBy = sortGroupFilter.groupBy;
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy;

		let columns = [
			{
				accessor: "item",
				Header: "Item",
			},
			{
				accessor: "value",
				Header: "Value",
				valueType: "currency",
			},
		];

		let rows = [
			{
				item: "Remaining Committed Cost",
				value: contractStatus?.remainCmtdCost,
			},
			{ item: "Unapproved AP Amount", value: contractStatus?.unapprovedAPAmt },
		];

		// for (let key in contractStatus) {
		// 	let column = {
		// 		accessor: key,
		// 		Header: key,
		// 		valueType: "currency",
		// 		show: key === "remainCmtdCost" || key === "unapprovedAPAmt",
		// 	};
		// 	columns.push(column);
		// }
		this.columns = columns;
		this.data = rows;

		// let footer = this.getFooter(itemTypeData);
		// this.footer = footer;
	}
	getSortGroupFilter(data = [], sectionTemplate = "MFRContractStatusCRS") {
		let groupBy = [];
		let sortBy = [];
		let filterBy = [
			{
				id: "itemTypeID",
				value: ["COW", "GCS", "FEE", "BUFF", "BON", "PBON"],
			},
		];

		let keys = [...new Set(data?.map((d) => d.jccmKeyID))];
		if (keys.length > 1) {
			groupBy = ["jccmKeyID"];
			sortBy = [
				{ id: "jcco", desc: false },
				{ id: "contract", desc: false },
			];
		}
		let sortGroupFilter = { sortBy: sortBy, groupBy: groupBy, filterBy: filterBy };
		return sortGroupFilter;
	}
}

class SubSectionTemplateMFRProjectAnalysisSummaryCRS {
	constructor(data = []) {
		let sortGroupFilter = this.getSortGroupFilter(data);
		this.template = "MFRProjectAnalysisSummaryMFR";
		this.type = "virtualTable";
		this.wrap = true;
		this.width = "50%";

		this.groupBy = sortGroupFilter.groupBy;
		this.filterBy = sortGroupFilter.filterBy;
		this.sortBy = sortGroupFilter.sortBy;

		let columns = [
			{
				accessor: "cmwr",
				Header: "Crew Mix Wage Rate",
			},
		];

		let rows = [
			{ cmwr: "Orig Budgeted Rate", prefix: "origEst" },
			{ cmwr: "Curr Budgeted Rate", prefix: "currEst" },
			{ cmwr: "Current Rate", prefix: "actual" },
			{ cmwr: "Projected Rate", prefix: "proj" },
			{ cmwr: "Rate To Complete" },
			{ cmwr: "Variance on Rate" },
		];

		for (let i = 0; i < data?.length; i++) {
			if (data[i].itemTypeID !== "LS") {
				let column = {
					accessor: data[i].itemTypeID,
					Header: data[i].itemType,
					valueType: "currency",
					show: ["GCS", "COW"].includes(data[i].itemTypeID),
				};
				columns.push(column);

				for (let j = 0; j < rows?.length; j++) {
					if (Boolean(rows[j].prefix)) {
						rows[j][data[i].itemTypeID] = data[i][rows[j].prefix + "Cost"];
					} else {
						rows[j][data[i].itemTypeID] = 0;
					}
				}
			}
		}

		this.columns = columns;

		this.data = rows;

		// let footer = this.getFooter(itemTypeData);
		// this.footer = footer;
	}
	getSortGroupFilter(data = [], sectionTemplate = "MFRContractStatusCRS") {
		let groupBy = [];
		let sortBy = [];
		let filterBy = [
			{
				id: "itemTypeID",
				value: ["COW", "GCS", "FEE", "BUFF", "BON", "PBON"],
			},
		];

		let keys = [...new Set(data?.map((d) => d.jccmKeyID))];
		if (keys.length > 1) {
			groupBy = ["jccmKeyID"];
			sortBy = [
				{ id: "jcco", desc: false },
				{ id: "contract", desc: false },
			];
		}
		let sortGroupFilter = { sortBy: sortBy, groupBy: groupBy, filterBy: filterBy };
		return sortGroupFilter;
	}

	// getFooter(rows = []) {
	// 	rows = rows?.filter((d) => d.rowType === "Subtotal") ?? [];

	// 	let feeRows = rows?.filter((d) => d.itemTypeID === "FEE") ?? [];

	// 	let total = {
	// 		itemType: "Contract Total",

	// 		origContractAmt: getSubtotal(rows, "origContractAmt") ?? 0,
	// 		adjOrigContractAmt: getSubtotal(rows, "adjOrigContractAmt") ?? 0,
	// 		bidContractAmt: getSubtotal(rows, "bidContractAmt") ?? 0,
	// 		contractAmt: getSubtotal(rows, "contractAmt") ?? 0,
	// 		acoAmt: getSubtotal(rows, "acoAmt") ?? 0,
	// 		pcoAmt: getSubtotal(rows, "pcoAmt") ?? 0,
	// 		pcoConv: getSubtotal(rows, "pcoConv") ?? 0,
	// 		projContractAmt: getSubtotal(rows, "projContractAmt") ?? 0,

	// 		actualCost: getSubtotal(rows, "actualCost") ?? 0,
	// 		remainCost: getSubtotal(rows, "remainCost") ?? 0,
	// 		projCost: getSubtotal(rows, "projCost") ?? 0,
	// 		newBilledAmt: getSubtotal(rows, "newBilledAmt") ?? 0,

	// 		billedIndirectCost: getSubtotal(rows, "billedIndirectCost") ?? 0,

	// 		activeBilled: getSubtotal(rows, "activeBilled") ?? 0,
	// 		preBilled: getSubtotal(rows, "preBilled") ?? 0,
	// 		unBilledCostLessIndirects:
	// 			getSubtotal(rows, "unBilledCostLessIndirects") ??
	// 			0 + (getSubtotal(rows, "activeBilled") ?? 0 - getSubtotal(rows, "preBilled") ?? 0),

	// 		projRemainBilling: getSubtotal(rows, "projRemainBilling") ?? 0,
	// 		// overUnder: getSubtotal(rows, "overUnder") ?? 0,
	// 		finalContractAmt: getSubtotal(rows, "finalContractAmt") ?? 0,
	// 	};
	// 	let profit = {
	// 		itemType: "Gross Profit $",
	// 		bidContractAmt: getSubtotal(feeRows, "bidContractAmt") ?? 0,
	// 		finalContractAmt:
	// 			parseFloat(getSubtotal(rows, "finalContractAmt") ?? 0) - parseFloat(getSubtotal(rows, "projCost") ?? 0),
	// 	};

	// 	let margin = {
	// 		itemType: "Gross Margin %",
	// 		bidContractAmt: getPct(
	// 			getSubtotal(feeRows, "bidContractAmt") ?? 0,
	// 			getSubtotal(rows, "bidContractAmt") ?? 0
	// 		),
	// 		bidContractAmtType: "percent",
	// 		bidContractAmtDecimals: 1,
	// 		finalContractAmt: getPct(
	// 			parseFloat(getSubtotal(rows, "finalContractAmt") ?? 0) - parseFloat(getSubtotal(rows, "projCost") ?? 0),
	// 			parseFloat(getSubtotal(rows, "finalContractAmt") ?? 0)
	// 		),
	// 		finalContractAmtType: "percent",
	// 		finalContractAmtDecimals: 1,
	// 	};

	// 	return [total, profit, margin];
	// }
}

class EmptySpace {
	constructor() {
		this.template = "ProjectAnalysisEMpty Space";
		this.type = "labelValuePairs";
		this.width = "20%";
		this.columns = [
			{
				accessor: "label",
			},
		];
		this.data = [
			{
				label: null,
			},
			{
				label: null,
			},
			{
				label: null,
			},
			{
				label: null,
			},
			{
				label: null,
			},
			{
				label: null,
			},
		];
	}
}
