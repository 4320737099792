import React, { useState, useRef, forwardRef } from "react";
import {
	Box,
	Text,
	InputGroup,
	Input,
	Button,
	Stack,
	useBoolean,
	InputRightElement,
	IconButton,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
} from "@chakra-ui/react";

const TextInput = forwardRef((props, ref) => {
	const [currValue, setCurrValue] = useState("");
	const [isFocused, setFocus] = useBoolean();
	const innerRef = useRef();
	const [multipleLinesDetected, setMultiLineDetected] = useBoolean();
	const [pastedLines, setPastedLines] = useState(null);

	const handleChange = (ev) => {
		ev.stopPropagation();
		setCurrValue(ev.target.value);
	};

	const handleBlur = (ev) => {
		ev.stopPropagation();
		setFocus.off();
		handleChange(ev);
	};

	const handleEnter = (ev) => {
		ev.stopPropagation();
		let event = ev;
		event.target = innerRef.current;
		sendChange({ target: { name: props.name, value: currValue } });
	};

	const handleKeyPress = (ev) => {
		ev.stopPropagation();

		let keyCode = ev.keyCode;
		if (keyCode === 13) {
			sendChange(ev);
		}
	};

	const sendChange = (ev) => {
		ev.multiple = props.multiple;
		if (props.onChange) {
			props.onChange(ev);
		}
		setCurrValue("");
	};

	const handlePaste = (ev) => {
		ev.stopPropagation();
		let rows = ev.clipboardData.getData("text");
		rows = rows.split("\r\n");
		let actualRows = [];

		for (let i = 0; i < rows.length; i++) {
			if (rows[i].length > 0) {
				actualRows.push(rows[i]);
			}
		}
		if (actualRows.length > 1) {
			setPastedLines(actualRows);
			setMultiLineDetected.on();
		}
	};

	const sendMultipleRows = () => {
		for (let i = 0; i < pastedLines.length; i++) {
			sendChange({ target: { name: props.name, value: pastedLines[i] } });
		}
		setPastedLines(null);
		setMultiLineDetected.off();
	};

	if (props.multiple) {
		return (
			<Popover isLazy={true} isOpen={multipleLinesDetected} onClose={setMultiLineDetected.off} placement="top">
				<PopoverTrigger>
					<Stack spacing={0} w="full">
						<InputGroup>
							<Input
								_focus={{ border: "2px solid", borderColor: "teal.600" }}
								ref={ref}
								variant={props.variant ?? "flushed"}
								bg={props.inputProps?.bg ?? "white"}
								placeholder={props.placeholder}
								name={props.name}
								value={currValue}
								onChange={handleChange}
								onBlur={handleBlur}
								colorScheme={props.colorScheme}
								size={props.size}
								readOnly={props.readOnly ?? props.isReadOnly}
								key={props.name}
								{...props.inputProps}
								color="gray.600"
								onKeyUp={handleKeyPress}
								onFocus={setFocus.on}
								autoComplete="off"
								onPaste={handlePaste}
							/>

							<InputRightElement
								children={
									<IconButton
										variant="ghost"
										size="sm"
										icon={<i className="fas fa-chevron-right" />}
										color="gray.500"
										onClick={handleEnter}
									/>
								}
							/>
						</InputGroup>
						{isFocused && (
							<Box>
								<Text fontSize="xs" color="gray.600">
									* Press "ENTER" to add.
								</Text>
							</Box>
						)}
					</Stack>
				</PopoverTrigger>

				<PopoverContent>
					<PopoverArrow />
					<PopoverCloseButton />
					<PopoverBody>
						<Stack p={3}>
							<Text color="gray.600">Multiple Lines Detected</Text>
							<Button variant="outline" colorScheme="teal" size="sm" onClick={sendMultipleRows}>
								Create {pastedLines?.length} Lines
							</Button>
						</Stack>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		);
	} else {
		return (
			<Input
				isTruncated
				maxLength={props.maxLength ?? ""}
				ref={ref}
				variant={props.variant ?? "flushed"}
				placeholder={props.placeholder}
				name={props.name}
				px={props.inputProps?.px ?? 2}
				py={props.inputProps?.py ?? 2}
				defaultValue={props.defaultValue}
				value={props.value}
				onChange={props.onChange}
				onBlur={props.onBlur}
				colorScheme={props.colorScheme}
				_disabled={{
					color: "gray.500",
					textTransform: props.inputProps?.textTransform ?? "none",
					bg: "whiteAlpha.800",
					opacity: 1,
					cursor: "not-allowed",
				}}
				_focus={{ borderWidth: 0 }}
				_placeholder={{
					color: props.inputProps?.color ?? "gray.400",
					textTransform: props.inputProps?.textTransform ?? "none",
				}}
				className={
					props.className +
					" " +
					(props.error?.type === "error"
						? "focusBorderColorRed"
						: props.error?.type === "warning"
						? "focusBorderColorYellow"
						: props.error?.type === "info"
						? "focusBorderColorBlue"
						: "focusBorderColorTeal")
				}
				size={props.size}
				readOnly={props.readOnly ?? props.isReadOnly}
				bg={props.inputProps?.bg ?? "white"}
				key={props.name}
				{...props.inputProps}
				maxW={props.maxW ?? ""}
				textAlign={props.textAlign ?? "left"}
				color="gray.600"
				borderRadius={props.borderRadius ?? (props.variant === "flushed" ? 0 : "md")}
				borderBottomColor={props.borderBottomColor ?? "gray.200"}
				autoComplete="off"
			/>
			// {Boolean(props.inputProps?.rightAddOn) && (
			// 	<InputRightElement
			// 		ref={rightRef}
			// 		bg="whiteAlpha.400"
			// 		borderColor={"whiteAlpha.700"}
			// 		borderLeftWidth={2}
			// 		children={props.inputProps?.rightAddOn}
			// 	/>
			// )}
		);
	}
});

export default TextInput;
