import React from "react";
import { Container, Switch, Flex, Text, Heading, VStack, Stack, HStack, Skeleton } from "@chakra-ui/react";
import LongevityAwardsClassLegacy from "../classes/HRLongevityClassLegacy";
import moment from "moment";
import HRPageHeader from "../components/HRPageHeader";
import { portalUser } from "../../../App";
import HRLongevityTable from "../components/HRLongevityTable";
export default function HRLongevityPage(props) {
	return (
		<Stack
			spacing={0}
			p={portalUser.styles?.pagePadding}
			bg={portalUser.styles?.pageBG}
			w="full"
			h="full"
			flex={1}
			align="center"
		>
			<HRPageHeader tab={props.tab} />
			<Container
				direction="column"
				maxW={"full"}
				display={"flex"}
				minW="container.lg"
				rounded="lg"
				px={0}
				flex={1}
				justify="flex-start"
				h="full"
			>
				<HRLongevityAwards tabVal={0} />
			</Container>
		</Stack>
	);
}

class HRLongevityAwards extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tabPanelVal: 0,

			projMth: null,
			awardYears: [5, 7, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
			thresholdMth: 10,
			regions: [
				{ region: "SoCal", longevity: null, awardYears: null, tableWidth: null, yearRanges: null },
				{ region: "NorCal", longevity: null, awardYears: null, tableWidth: null, yearRanges: null },
				{ region: "Hawaii", longevity: null, awardYears: null, tableWidth: null, yearRanges: null },
			],
			longevity: null,
			showPrevYearsGuess: true,
			showAwardsThisYear: true,
			yearRanges: null,
		};

		this.init = this.init.bind(this);
		this.togglePrevYearGuess = this.togglePrevYearGuess.bind(this);
		this.toggleAwardsThisYear = this.toggleAwardsThisYear.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentDidUpdate() {}

	async init() {
		let projMth = "12/1/" + moment().year();

		let awardYears = this.state.awardYears;
		let thresholdMth = this.state.thresholdMth;

		let results = await portalUser.usersAPI.GetLongevityAwards(projMth);

		if (results?.status === 200) {
			let longevity = new LongevityAwardsClassLegacy(results.value);
			longevity = longevity.filter(
				(d) =>
					![
						"3B44CFDE-FE3B-4163-9EE0-4A0798C74946",
						"1BD4DD51-1E8B-47EF-8023-4D828BE5364E",
						"9DD5DE56-9BFF-4DC7-897B-96EBAAAF9992",
					].includes(d.employeeUID?.toUpperCase())
			);

			longevity = longevity.getAwardYearRange(awardYears, thresholdMth);
			let yearRanges = [...new Map(longevity.map((data) => [data.yearRange, data.yearRange])).values()];

			let regions = this.state.regions;
			for (let i = 0; i < regions?.length; i++) {
				let regionLongevity = longevity.filter((data) => data?.region === regions[i].region);
				regions[i].longevity = new LongevityAwardsClassLegacy(regionLongevity);
				regions[i].yearRanges = [
					...new Map(regionLongevity.map((data) => [data.yearRange, data.yearRange])).values(),
				];

				let maxProjLongevity = Math.max(...regionLongevity.map((o) => o.projLongevityDesc?.years));

				let longevityYears = [];
				for (let i = 0; i < awardYears?.length; i++) {
					if (awardYears[i] <= maxProjLongevity) {
						longevityYears.push(awardYears[i]);
					} else {
						longevityYears.push(awardYears[i]);
						break;
					}
				}
				let tableWidth = 250 + 150 * 3 + 100 * (longevityYears.length ?? 0) + "px";

				regions[i].awardYears = longevityYears;
				regions[i].tableWidth = tableWidth;
			}

			this.setState({
				longevity: longevity,
				regions: regions,
				yearRanges: yearRanges,
			});
		}
	}

	togglePrevYearGuess() {
		let showPrevYearsGuess = !this.state.showPrevYearsGuess;
		this.setState({ showPrevYearsGuess: showPrevYearsGuess });
	}

	toggleAwardsThisYear() {
		let showAwardsThisYear = !this.state.showAwardsThisYear;
		this.setState({ showAwardsThisYear: showAwardsThisYear });
	}

	render() {
		return (
			<VStack w="full" flex={1} h="full">
				<Skeleton
					isLoaded={this.state.regions[this.props.tabVal].longevity?.length > 0}
					h="full"
					w="full"
					rounded="lg"
					flex={1}
					maxW={this.state.regions[this.props.tabVal]?.tableWidth ?? "1600px"}
				>
					{this.state.regions[this.props.tabVal]?.longevity?.length > 0 &&
						this.state.regions[this.props.tabVal]?.awardYears?.length > 0 &&
						this.state.regions[this.props.tabVal].yearRanges?.length > 0 && (
							<>
								<Flex w="full" flex={1} justify="space-between" pr={2} pb="8">
									<Heading
										size="xl"
										textTransform="uppercase"
										letterSpacing={2}
										color="teal.600"
										textShadow="2px 2px white"
									>
										{"Longevity Awards - " + this.state.regions[this.props.tabVal].region}
									</Heading>
									<Stack spacing={2} align="flex-end">
										<HStack spacing={2} fontWeight="bold" w="full" justify="flex-end">
											<Text
												fontSize="sm"
												textTransform="uppercase"
												textAlign="right"
												color="gray.500"
											>
												Only Show Awards This Year
											</Text>
											<Switch
												colorScheme="teal"
												isChecked={this.state.showAwardsThisYear}
												onChange={() => this.toggleAwardsThisYear()}
											/>
										</HStack>
										<HStack spacing={2} fontWeight="bold" w="full" justify="flex-end">
											<Text
												fontSize="sm"
												textTransform="uppercase"
												textAlign="right"
												color="gray.500"
											>
												Show Previous Years
											</Text>
											<Switch
												colorScheme="teal"
												isChecked={this.state.showPrevYearsGuess}
												onChange={() => this.togglePrevYearGuess()}
											/>
										</HStack>
									</Stack>
								</Flex>

								<VStack w="full" flex={1} spacing={6}>
									{this.state.regions[this.props.tabVal].yearRanges?.map((range, i) => {
										let longevity = this.state.regions[this.props.tabVal].longevity.filter(
											(data) => data?.yearRange === range
										);
										if (this.state.showAwardsThisYear) {
											longevity = longevity.filter((data) => data?.awardThisYear === true);
										}

										return (
											<>
												{longevity?.length > 0 && (
													<HRLongevityTable
														projMth={this.state.projMth}
														longevity={longevity}
														awardYears={this.state.regions[this.props.tabVal].awardYears}
														showPrevYearsGuess={this.state.showPrevYearsGuess}
														showAwardsThisYear={this.state.showAwardsThisYear}
														yearRange={range}
													/>
												)}
											</>
										);
									})}
								</VStack>
							</>
						)}
				</Skeleton>
			</VStack>
		);
	}
}
