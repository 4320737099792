import SubSectionObj from "./core/Reports/classes/SubSection";
import moment from "moment";

//////////////////////////////////////////////////////////////////////////////////////////////////////////
////////// ADMIN DEBUG AND PORTAL USER HELPER FUNCTIONS FOR DISPLAY AND ACCESS  ////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export function log(primary, secondary = false, force = false) {
	if (process.env.NODE_ENV === "development" || force) {
		if (secondary) {
			console.log(primary);
			console.log(secondary);
		} else {
			console.log(primary);
		}
	}
}

export function getDeviceType() {
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		return "mobile";
	} else {
		return "pc";
	}
}

export function getScreenSize() {
	let innerWidth = window.innerWidth;
	switch (innerWidth) {
		case innerWidth <= 400:
			return "base";
		case innerWidth <= 800:
			return "sm";
		case innerWidth <= 1200:
			return "md";
		case innerWidth <= 1600:
			return "lg";
		case innerWidth <= 1920:
			return "xl";
		case innerWidth <= 2160:
			return "2xl";
		default:
			return "3xl";
	}
}

export function screenSizeWidth(sizes) {
	if (typeof sizes !== "object") {
		return false;
	}
	let sizeArr = Object.keys(sizes);
	let innerWidth = window.innerWidth;
	for (let i = sizeArr.length - 1; i >= 0; i--) {
		switch (sizeArr[i]) {
			case "2xl":
				if (innerWidth >= 1920) {
					return sizes[sizeArr[i]];
				}
				break;
			case "xl":
				if (innerWidth >= 1600) {
					return sizes[sizeArr[i]];
				}
				break;
			case "lg":
				if (innerWidth >= 1200) {
					return sizes[sizeArr[i]];
				}
				break;
			case "md":
				if (innerWidth >= 800) {
					return sizes[sizeArr[i]];
				}
				break;
			case "sm":
				if (innerWidth >= 400) {
					return sizes[sizeArr[i]];
				}
				break;
			case "base":
				if (innerWidth >= 0) {
					return sizes[sizeArr[i]];
				}
				break;
			default:
		}
	}
}

// TO DO: Remove from helper functions and add to User Object (portalUser.user)
export function isAuthorized(user, accessArr = []) {
	if (!Boolean(accessArr?.length > 0)) {
		return true;
	} else {
		for (let i = 0; i < accessArr?.length; i++) {
			let userAccess = user[accessArr[i]]?.toString() ?? "N";
			if (["Y", "1", "true"].includes(userAccess)) {
				return true;
			}
		}
		return false;
	}
}

export function sortArrayBy(array = [], attr = null, direction = 1, valueType = null) {
	const compareValues = (a, b) =>
		({
			numbernumber: (a - b) * direction,
			stringnumber: 1 * direction,
			numberstring: -1 * direction,
			stringstring: (a > b ? 1 : -1) * direction,
			datetimedatetime: (new Date(a).getTime() - new Date(b).getTime()) * direction,
		}[Boolean(valueType) ? valueType + valueType : typeof a + typeof b]);

	const compareAttributes = (a, b) =>
		({
			numbernumber: (a[attr] - b[attr]) * direction,
			stringnumber: 1 * direction,
			numberstring: -1 * direction,
			stringstring: (a[attr] > b[attr] ? 1 : -1) * direction,
			datetimedatetime: (new Date(a[attr]).getTime() - new Date(b[attr]).getTime()) * direction,
		}[Boolean(valueType) ? valueType + valueType : typeof a[attr] + typeof b[attr]]);

	if (array.length > 0) {
		array.sort(Boolean(attr) ? compareAttributes : compareValues);
	}

	// sortedArr.sort(function (a, b) {
	// 	return (
	// 		a.deptGroupID - b.deptGroupID ||
	// 		a.parentDeptOrder - b.parentDeptOrder ||
	// 		a.deptOrder - b.deptOrder ||
	// 		a.accessLevel - b.accessLevel ||
	// 		a.execOrder - b.execOrder ||
	// 		b.totalDeptCount - a.totalDeptCount ||
	// 		b.parentDeptCount - a.parentDeptCount ||
	// 		b.childDeptCount - a.childDeptCount ||
	// 		a.regionID - b.regionID ||
	// 		b.longevityMths - a.longevityMths ||
	// 		a.prco - b.prco
	// 	);
	// });
	return array;
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////  MATH HELPER FUNCTIONS FOR ARRAYS, VALUES AND DATES ///////////////////////////////
///////////// SUBTOTALS FROM ARRAYS (SUBTOTAL, COUNT, AVERAGE, MIN, MAX) ///////////////////////////////
///////////// RESULTS OF OPERATIONS BETWEEN VALUES (RATE, PERCENT, DAYS BETWEEEN DATES OR ADD DAYS TO DATE) ////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export function getSubtotal(array = [], prop = "costToDate", operation = "sum", distinct = false, filterBy = []) {
	let value = 0;
	if (Boolean(prop)) {
		array = array?.filter((row) => Boolean(row[prop])) ?? [];
		if (distinct) {
			array = [...new Set(array?.map((row) => row[prop]))] ?? [];
		} else {
			array = array?.map((row) => row[prop]) ?? [];
		}
	} else {
		if (distinct) {
			array = [...new Set(array)] ?? [];
		}
	}

	if (Boolean(array?.length === 0)) {
		return value;
	}

	if (operation === "count") {
		return array.length ?? 0;
	} else if (operation === "sum") {
		return array.reduce((prev, next) => parseFloat(prev ?? 0) + parseFloat(next ?? 0), 0);
	} else if (operation === "average") {
		let count = array?.length ?? 0;
		let sum = array.reduce((prev, next) => parseFloat(prev ?? 0) + parseFloat(next ?? 0), 0);
		if (count > 0) {
			return sum / count;
		} else {
			return 0;
		}
	} else if (operation === "min") {
		return array.reduce(
			(prev, next) => Math.min(parseFloat(prev ?? 0), parseFloat(next ?? 0)),
			parseFloat(array[0])
		);
	} else if (operation === "max") {
		return array.reduce(
			(prev, next) => Math.max(parseFloat(prev ?? 0), parseFloat(next ?? 0)),
			parseFloat(array[0])
		);
	}
}

export function sumProduct(array = [], prop1 = "costToDate", prop2 = "hoursToDate") {
	array = array?.map((row) => (row[prop1] ?? 0) * (row[prop2] ?? 0)) ?? [];

	return array.reduce((prev, next) => parseFloat(prev ?? 0) + parseFloat(next ?? 0), 0);
}

export function getRate(dollars = 0, hours = 0) {
	let rate = 0;
	if (dollars === null || hours === null || isNaN(dollars) || isNaN(hours)) {
		rate = null;
	} else if (parseFloat(hours) !== 0) {
		rate = parseFloat(dollars ?? 0) / parseFloat(hours);
	}
	return rate;
}

export function getPct(numerator = 0, denominator = 0) {
	let pct = 0;
	if (numerator === null || denominator === null) {
		pct = null;
	} else if (denominator !== 0) {
		pct = (numerator / denominator) * 100;
	}
	return pct;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////  DATE HELPER FUNCTIONS  ////////////////////////////////////////////////
////////////////// GET NUMBER OF DAYS BETWEEN DATES, ADD NUMBER OF DAYS TO DATE //////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export function dayDifference(startDate, endDate, includeWeekend) {
	let days = 0;
	if (typeof startDate === "string") {
		startDate = new Date(startDate);
	}
	if (typeof endDate === "string") {
		endDate = new Date(endDate);
	}

	if (startDate.getTime() <= endDate.getTime()) {
		let lowerDate = new Date(startDate);
		while (lowerDate.getTime() <= endDate.getTime()) {
			if (!includeWeekend && (lowerDate.getDay() === 6 || lowerDate.getDay() === 0)) {
				//continue;
			} else {
				days++;
			}
			lowerDate.setDate(lowerDate.getDate() + 1);
		}
	}
	return days;
}

export function addDaysToDate(date, days) {
	if (typeof date) {
		date = new Date(date);
	}
	date.setDate(date.getDate() + days);
	return date;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////  DATA TYPE CONVERSION HELPER FUNCTIONS  //////////////////////////////////
//////////////// CONVERT ARRAY TO CHARACTER DELIMITED STRING, CONVERT ARRAY TO MAP  //////////////////////
////////////////////////////// CONVERT BASE 65 TO BLOB AND URL TO BLOB  //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////

// Turn an array of values into a string, separatated by chosen delimeter
export function arrayToString(arr = [], delimiter = ",", addDuplicates = false, attr = null) {
	if (Boolean(attr)) {
		arr = arr?.map((obj) => obj[attr]) ?? [];
	}
	if (!addDuplicates) {
		arr = [...new Set(arr)];
	}

	arr = arr.filter((d) => Boolean(d));
	let string = "";
	for (let i = 0; i < arr?.length; i++) {
		string += arr[i];
		if (arr?.length > 1 && i !== arr?.length - 1) {
			string += delimiter;
		}
	}
	return string;
}

// Turn an array of objects into a map, with seccond parameter being the maps key and third parameter if the map value can have an array of items
export function convertArrayToMap(arr, keyAttr, addDuplicates = false) {
	let map = {};

	if (addDuplicates) {
		for (let i = 0; i < arr?.length; i++) {
			if (!Boolean(map[arr[i][keyAttr]])) {
				map[arr[i][keyAttr]] = [];
			}
			map[arr[i][keyAttr]].push(arr[i]);
		}
	} else {
		for (let i = 0; i < arr?.length; i++) {
			map[arr[i][keyAttr]] = arr[i];
		}
	}
	return map;
}

export function convertObjectsToString(input) {
	if (Array.isArray(input)) {
		return input.map((obj) => convertObjectToString(obj)).join(",");
	} else {
		return convertObjectToString(input);
	}
}

export function convertObjectToString(obj) {
	return Object.entries(obj)
		.map(([key, value]) => `${key}: '${value}'`)
		.join("|");
}

// Convert Base64 to Blob
export function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

// Convert URItoBlob
export function dataURItoBlob(dataURI) {
	var byteString = atob(dataURI.split(",")[1]);
	var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
	var ab = new ArrayBuffer(byteString.length);
	var ia = new Uint8Array(ab);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	var blob = new Blob([ab], { type: mimeString });
	return blob;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////  FORMATTING HELPER FUNCTIONS - FORMAT A VALUE OR GET COLOR /////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export function dateIsValid(value = null) {
	return moment(formatDate(value), "M/D/YYYY").isValid();
}

export function formatValue(value = null, decimals = 0, valueType = "string", currencySign = "standard") {
	if (value === null || ["string", "boolean"].includes(valueType)) {
		return value;
	} else if (valueType === "icon") {
		if (!value.includes("fa-fw")) {
			value += " fa-fw";
		}
		if (
			!value.includes("fa-2xs") &&
			!value.includes("fa-xs") &&
			!value.includes("fa-sm") &&
			!value.includes("fa-lg") &&
			!value.includes("fa-xl") &&
			!value.includes("fa-2xl")
		) {
			value += " fa-lg";
		}
		return <i className={value}></i>;
	} else if (["currency", "accounting"].includes(valueType)) {
		return formatNumber(value, decimals, valueType, currencySign);
	} else if (["number", "percent", "numeric", "integer"].includes(valueType)) {
		value =
			value
				?.toString()
				?.replace("$", "")
				?.replace(",", "")
				?.replace(" ", "")
				?.replace("%", "")
				?.replace("(", "")
				?.replace(" ", "")
				?.replace(")", "") ?? null;
		return formatNumber(value, decimals, valueType);
	} else if (
		[
			"year",
			"month",
			"monthDay",
			"date",
			"dateURL",
			"dateSQL",
			"dateTime",
			"datetimeAM",
			"datetime",
			"datetimeSQL",
			"moment",
		].includes(valueType)
	) {
		if (!Boolean(value)) {
			return null;
		}

		if (valueType === "year" && value?.length === 4 && !isNaN(parseInt(value))) {
			return formatNumber(parseInt(value), 0, "integer");
		}

		let momentDate = moment(new Date(formatDate(value)));
		if (
			["year", "month", "monthDay", "monthDay", "date", "dateURL", "dateSQL"].includes(valueType) &&
			momentDate?.isValid()
		) {
			if (valueType === "year") {
				return momentDate?.format("YYYY");
			} else if (valueType === "month") {
				return momentDate?.format("MMM YYYY");
			} else if (valueType === "monthDay") {
				return momentDate?.format("MMMM Do");
			} else if (valueType === "date") {
				return momentDate?.format("M/D/YYYY");
			} else if (valueType === "dateURL") {
				return momentDate?.format("M-D-yyyy");
			} else if (valueType === "dateSQL") {
				return momentDate?.format("YYYY-MM-DD");
			} else {
				return null;
			}
		}

		momentDate = moment(new Date(formatDate(value, true)));
		if (["dateTime", "datetimeAM", "datetime", "datetimeSQL"].includes(valueType) && momentDate?.isValid()) {
			if (["dateTime", "datetimeAM"].includes(valueType)) {
				return momentDate?.format("M/D/YYYY h:mm A");
			} else if (valueType === "datetime") {
				return momentDate?.format("MM/DD/YYYY hh:mm:ss");
			} else if (valueType === "datetimeSQL") {
				return momentDate?.format("YYYY-MM-DD hh:mm:ss");
			} else {
				return null;
			}
		}

		momentDate = moment(value);
		if (["moment"].includes(valueType) && Boolean(value) && momentDate?.isValid()) {
			return momentDate;
		}

		return null;
	} else {
		return value;
	}
}

export function formatNumber(value = null, decimals = 0, valueType = undefined, currencySign = "standard") {
	let parameters = {
		style: undefined,
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
		currency: "USD",
		currencySign: currencySign,
	};
	let rightAddon = null;

	value =
		value
			?.toString()
			?.replace("$", "")
			?.replace(",", "")
			?.replace(" ", "")
			?.replace("%", "")
			?.replace("(", "")
			?.replace(")", "") ?? null;

	value = parseFloat(value);
	if (decimals === 0 || valueType === "integer") {
		value = parseInt(value)?.toString();
		if (valueType === "integer") {
			return value;
		}
	}

	if (value !== null && !isNaN(value)) {
		if (["currency", "accounting"].includes(valueType)) {
			parameters.style = "currency";
			if (valueType === "accounting") {
				parameters.currencySign = "accounting";
			}
		} else if (valueType === "percent") {
			rightAddon = "%";
		} else if (valueType === "numeric") {
			parameters.useGrouping = false;
		}
		let formattedValue = new Intl.NumberFormat("en-US", parameters).format(value);
		if (Boolean(rightAddon)) {
			formattedValue += rightAddon;
		}
		return formattedValue;
	} else {
		return null;
	}
}

export function formatDate(dateStr, keepTime) {
	if (!Boolean(dateStr)) {
		return dateStr;
	}
	if (dateStr instanceof Date) {
		dateStr = dateStr.toISOString();
	}
	if (typeof dateStr !== "string") {
		dateStr = dateStr.toString();
	}
	// Format works with IOS
	let timeStr = " 00:00:00";
	if (keepTime) {
		timeStr = dateStr?.replace(/.*(\d{2}:\d{2}:\d{2}).*/gm, " $1Z");
		// log("timeStr", timeStr);
	}
	dateStr = dateStr?.replace(/T.*/gm, timeStr)?.replace(/-/gm, "/");

	return dateStr;
}

export function getValueColor(
	value = 0,
	defaultColor = "gray.700",
	positiveColor = "green.600",
	negativeColor = "red.600"
) {
	let val = parseFloat(value);
	let defColor = defaultColor;
	let posColor = positiveColor;
	let negColor = negativeColor;
	// Potentially test if color is RGB or css var

	if (defColor.charAt(0) !== "#") {
		defColor = defColor.replace(/\./g, "").toLowerCase();
		if (Boolean(chakraThemeColorsMap[defColor])) {
			defColor = chakraThemeColorsMap[defColor];
		} else {
			defColor = "black";
		}
	}

	if (posColor.charAt(0) !== "#") {
		posColor = posColor.replace(/\./g, "").toLowerCase();
		if (Boolean(chakraThemeColorsMap[posColor])) {
			posColor = chakraThemeColorsMap[posColor];
		} else {
			posColor = defColor;
		}
	}

	if (negColor.charAt(0) !== "#") {
		negColor = negColor.replace(/\./g, "").toLowerCase();
		if (Boolean(chakraThemeColorsMap[negColor])) {
			negColor = chakraThemeColorsMap[negColor];
		} else {
			negColor = defColor;
		}
	}

	if (isNaN(val) || !Boolean(val)) {
		return defColor;
	} else if (parseFloat(formatNumber(val, 0)) === parseFloat(formatNumber(0, 0))) {
		return defColor;
	} else if (parseFloat(formatNumber(val, 0)) > parseFloat(formatNumber(0, 0))) {
		return posColor;
	} else if (parseFloat(formatNumber(val, 0)) < parseFloat(formatNumber(0, 0))) {
		return negColor;
	}
}

export const chakraThemeColorsMap = {
	null: "black",
	default: "black",
	white: "white",
	whitesmoke: "whitesmoke",
	silver: "silver",
	dimgray: "dimgray",
	darkgray: "darkgray",
	black: "black",
	transparent: "transparent",
	none: "transparent",

	blackalpha200: "RGBA(0, 0, 0, 0.08)",

	// grayAlpha100:"rgba(237,242,247, 0.25)",

	grayAlpha50: "rgba(203,213,224,0.04)",
	grayAlpha100: "rgba(203,213,224,0.06)",
	grayAlpha200: "rgba(203,213,224,0.08)",
	grayAlpha300: "rgba(203,213,224,0.16)",
	grayAlpha400: "rgba(203,213,224,0.24)",
	grayAlpha500: "rgba(203,213,224,0.36)",
	grayAlpha600: "rgba(203,213,224,0.48)",
	grayAlpha700: "rgba(203,213,224,0.64)",
	grayAlpha800: "rgba(203,213,224,0.80)",
	grayAlpha900: "rgba(203,213,224,0.92)",

	gray: "gray",
	gray50: "#F7FAFC",
	gray100: "#EDF2F7",
	gray200: "#E2E8F0",
	gray300: "#CBD5E0",
	gray400: "#A0AEC0",
	gray500: "#718096",
	gray600: "#4A5568",
	gray700: "#2D3748",
	gray800: "#1A202C",
	gray900: "#171923",

	red: "red",
	red50: "#FFF5F5",
	red100: "#FED7D7",
	red200: "#FEB2B2",
	red300: "#FC8181",
	red400: "#F56565",
	red500: "#E53E3E",
	red600: "#C53030",
	red700: "#9B2C2C",
	red800: "#822727",
	red900: "#63171B",

	orange: "orange",
	orange50: "#FFFAF0",
	orange100: "#FEEBC8",
	orange200: "#FBD38D",
	orange300: "#F6AD55",
	orange400: "#ED8936",
	orange500: "#DD6B20",
	orange600: "#C05621",
	orange700: "#9C4221",
	orange800: "#7B341E",
	orange900: "#652B19",

	yellow: "yellow",
	yellow50: "#FFFFF0",
	yellow100: "#FEFCBF",
	yellow200: "#FAF089",
	yellow300: "#F6E05E",
	yellow400: "#ECC94B",
	yellow500: "#D69E2E",
	yellow600: "#B7791F",
	yellow700: "#975A16",
	yellow800: "#744210",
	yellow900: "#5F370E",

	green: "green",
	green50: "#F0FFF4",
	green100: "#C6F6D5",
	green200: "#9AE6B4",
	green300: "#68D391",
	green400: "#48BB78",
	green500: "#38A169",
	green600: "#2F855A",
	green700: "#276749",
	green800: "#22543D",
	green900: "#1C4532",

	teal: "teal",
	teal50: "#E6FFFA",
	teal100: "#B2F5EA",
	teal200: "#81E6D9",
	teal300: "#4FD1C5",
	teal400: "#38B2AC",
	teal500: "#319795",
	teal600: "#2C7A7B",
	teal700: "#285E61",
	teal800: "#234E52",
	teal900: "#1D4044",

	blue: "blue",
	blue50: "#EBF8FF",
	blue100: "#BEE3F8",
	blue200: "#90CDF4",
	blue300: "#63B3ED",
	blue400: "#4299E1",
	blue500: "#3182CE",
	blue600: "#2B6CB0",
	blue700: "#2C5282",
	blue800: "#2A4365",
	blue900: "#1A365D",

	cyan: "cyan",
	cyan50: "#EDFDFD",
	cyan100: "#C4F1F9",
	cyan200: "#9DECF9",
	cyan300: "#76E4F7",
	cyan400: "#0BC5EA",
	cyan500: "#00B5D8",
	cyan600: "#00A3C4",
	cyan700: "#0987A0",
	cyan800: "#086F83",
	cyan900: "#065666",

	purple: "purple",
	purple50: "#FAF5FF",
	purple100: "#E9D8FD",
	purple200: "#D6BCFA",
	purple300: "#B794F4",
	purple400: "#9F7AEA",
	purple500: "#805AD5",
	purple600: "#6B46C1",
	purple700: "#553C9A",
	purple800: "#44337A",
	purple900: "#322659",

	pink: "pink",
	pink50: "#FFF5F7",
	pink100: "#FED7E2",
	pink200: "#FBB6CE",
	pink300: "#F687B3",
	pink400: "#ED64A6",
	pink500: "#D53F8C",
	pink600: "#B83280",
	pink700: "#97266D",
	pink800: "#702459",
	pink900: "#521B41",
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////      REACT TABLE  HELPER FUNCTIONS  - COLUMN DEFAULTS FOR DATA  ////////////////////
//////////////////  GET DEFAULT HEADINGS, VALUE TYPES AND WIDTHS FOR AN ARRAY OF DATA ////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////

// Get Column Definitions from Dataset Array Row Object
export function getColumnDefs(data = [], debug = false) {
	// Loop over Objects keys (ignore properties where the values are objects)
	let obj = {};
	if (Array.isArray(data) && Boolean(data?.length > 0)) {
		obj = data[0];
	} else {
		obj = data;
	}
	let keys = Object?.keys(obj) ?? [];
	let columns = [];

	for (let i = 0; i < keys?.length; i++) {
		// Set Object Key equal to the accessor
		let accessor = keys[i];

		// Create the Header by adding spaces before every Capitalized letters
		// Apply addtional rules to Header for special cases like KeyIDs
		let header = accessor.replace(/([a-z])([A-Z])/g, "$1 $2");

		if (
			header.substring(0, 4) === "jccm" ||
			header.substring(0, 4) === "jcjm" ||
			header.substring(0, 4) === "ddds" ||
			header.substring(0, 4) === "jcco" ||
			header.substring(0, 4) === "jcpm" ||
			header.substring(0, 4) === "jcci" ||
			header.substring(0, 4) === "ddds" ||
			header.substring(0, 4) === "ddsg" ||
			header.substring(0, 4) === "ddsu" ||
			header.substring(0, 4) === "jcmp"
		) {
			header = header.slice(0, 4).toUpperCase() + header.slice(4);
		} else if (
			header.substring(0, 2) === "vp" ||
			header.substring(0, 2) === "pm" ||
			header.substring(0, 2) === "pa" ||
			header.substring(0, 2) === "px" ||
			header.substring(0, 2) === "um" ||
			header.substring(0, 2) === "ud" ||
			header.substring(0, 2) === "bg"
		) {
			header = header.slice(0, 2).toUpperCase() + header.slice(2);
		} else {
			header = header.charAt(0).toUpperCase() + header.slice(1);
		}
		header = header.replace("Uid", "Unique ID");
		header = header.replace("Key ID", "KeyID");
		header = header.replace("WIPJob", "WIP Job");

		// Create a Default Column Object with the new accessor and Header, Then
		// set properties like valueType, default width, and show for specific conditions
		let column = {
			id: accessor,
			accessor: accessor,
			Header: header,
			sortOrder: i,
			width: "100%",
			valueType: "string",
			minWidth: 150,
			textTransform: "none",
		};

		// STRINGS
		if (column.Header.includes("Query Data")) {
			column.width = "225%";

			column.show = debug;
			column.disableExport = debug;
		}
		if (
			column.Header === "Combo Type" ||
			column.Header === "Title" ||
			column.Header === "Project Type" ||
			column.Header === "Display Value" ||
			column.Header === "Database Value"
		) {
			column.width = "225%";
		}

		if (column.Header.includes("Name") || column.Header.includes("Category") || column.id === "role") {
			column.width = "250%";
		}
		if (column.Header.includes("BG") || column.Header.includes("Border") || column.Header.includes("Background")) {
			column.width = "250%";

			column.show = debug;
			column.disableExport = debug;
		}
		if (
			column.Header.includes("Address") ||
			column.Header.includes("Desc") ||
			column.Header.includes("Description") ||
			column.Header.includes("Notes") ||
			column.Header.includes("UserName") ||
			column.Header.includes("User Name") ||
			column.Header.includes("Email")
		) {
			column.width = "350%";

			column.valueType = "string";
		}
		if (column.Header.includes("Dev Category") || column.Header.includes("Dev Project Name")) {
			column.width = "300%";

			column.valueType = "string";
		}
		if (
			column.Header.includes("Dev Project Description") ||
			column.Header.includes("Dev Project Tag") ||
			column.Header.includes("TABLE_NAME") ||
			column.Header.includes("COLUMN_NAME") ||
			column.Header.includes("DATA_TYPE")
		) {
			column.width = "550%";

			column.valueType = "string";
		}
		if (column.Header.includes("UID") || column.id.includes("msUserID")) {
			column.width = "350%";

			column.show = debug;
			column.disableExport = debug;
		}

		// BOOLEANS
		if (column.Header.includes("YN")) {
			column.valueType = "boolean";
		}

		// INTEGERS
		if (column.Header === "Co" || column.Header === "Seq" || column.Header === "Ext") {
			column.valueType = "integer";
			column.width = "75%";
			column.minWidth = 75;
		}
		if (
			column.Header.includes("ID") ||
			column.Header.includes("Id") ||
			column.Header.includes("KeyID") ||
			column.Header.includes("Order") ||
			column.Header === "Qualifier" ||
			column.Header === "Instance" ||
			column.Header === "Cust Group" ||
			column.Header === "Customer"
		) {
			column.valueType = "integer";
			column.width = "125%";
			column.minWidth = 75;
			column.show = debug;
			column.disableExport = debug;
		}
		if (column.Header === "Cost Type" || column.Header === "Group Type") {
			column.valueType = "integer";
		}

		if (column.Header.includes("Security Group")) {
			column.valueType = "integer";
			column.width = "200%";
		}

		if (column.Header === "Contract Status") {
			column.valueType = "integer";
			column.width = "200%";
			column.show = debug;
		}

		// NUMBERS
		if (column.Header.includes("Hours") || column.Header.includes("Hrs") || column.Header.includes("Manpower")) {
			column.valueType = "number";
		}

		// CURRENCY
		if (
			column.Header.includes("Amt") ||
			column.Header.includes("Amount") ||
			(column.Header.includes("Over") && column.Header.includes("Under"))
		) {
			column.valueType = "currency";
		}

		if (column.Header === "Over Under") {
			column.Header = "Over / Under";
		}

		// PERCENTS
		if (column.Header.includes("%") || column.Header.includes("Pct")) {
			column.valueType = "percent";
		}

		// MONTH
		if ((column.Header.includes("Mth") || column.Header.includes("Month")) && !column.Header.includes("Name")) {
			column.valueType = "month";
		}

		// DATE
		if (column.Header.includes("Date") && !column.Header.includes("Time")) {
			column.valueType = "date";
		}
		if (column.Header.includes("Expected Collection")) {
			column.valueType = "date";
		}
		if (
			(header.slice(0, 2).toUpperCase() === "AD" || header.slice(0, 2).toUpperCase() === "DD") &&
			!column.Header.includes("KeyID") &&
			!column.Header.includes("Address")
		) {
			column.valueType = "date";
		}

		// DATETIME
		if (column.Header.includes("Date") && column.Header.includes("Time")) {
			column.valueType = "dateTime";
			column.width = "200%";
		}

		if (column.Header === "Query Data") {
			column.Header = "Dataset";
		}

		// PRODUCTIVITY HEADERS
		if (column.Header.substring(0, 2) === "U ") {
			column.valueType = "number";
			column.show = false;
			column.decimals = 2;
		} else if (column.Header.substring(0, 2) === "H ") {
			column.valueType = "number";
			column.decimals = 2;
		} else if (column.Header.substring(0, 3) === "LR ") {
			column.valueType = "number";
			column.decimals = 2;
		} else if (column.Header.substring(0, 5) === "Prod ") {
			column.valueType = "number";
			column.decimals = 2;
		} else if (column.Header.substring(0, 5) === "MCAA ") {
			column.valueType = "number";
			column.decimals = 2;
		} else if (column.Header.substring(0, 2) === "D ") {
			column.valueType = "currency";
			column.decimals = 2;
		}

		// Push columns where the values for those keys that are not objects (required by React-Table)
		if (typeof obj[keys[i]] !== "object" && typeof obj[keys[i]] !== "boolean") {
			columns.push(column);
		}
	}

	let columnDefs = new SubSectionObj({ type: "virtualTable", columns: columns, data: data });

	return columnDefs;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////    TO DO: REMOVE REFRENCES AND DELETE      ///////////////////////////////////
//////////////////////    MERGING SORTING AND FILTERING ARRAYS FOR SEARCH ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export function sum(array, attr) {
	let sum = 0;
	for (let i = 0; i < array?.length; i++) {
		if (attr) {
			sum += array[i][attr];
		} else {
			sum += array[i];
		}
	}

	return sum;
}

// Turn an array of objects into a map, with seccond parameter being the maps key.
// The third parameter allows duplicate values or objects to be added to the value of the map
function merge(left, right, attribute, sortOrder, getValueFunction) {
	var result = [];
	if (!Boolean(left) || !Boolean(right)) {
		return;
	}
	while (left?.length && right?.length) {
		var leftVal;
		var rightVal;

		if (getValueFunction !== undefined) {
			leftVal = getValueFunction(left[0], attribute["name"]);
			rightVal = getValueFunction(right[0], attribute["name"]);
		} else {
			leftVal = Boolean(attribute["name"]) ? left[0][attribute["name"]] : left[0];
			rightVal = Boolean(attribute["name"]) ? right[0][attribute["name"]] : right[0];
		}

		if (attribute["type"] === "string") {
			leftVal = leftVal.toLowerCase().replace(/[^a-z,^\d]/gm, "");
			rightVal = rightVal.toLowerCase().replace(/[^a-z,^\d]/gm, "");
		} else if (attribute["type"] === "number") {
			leftVal = isNaN(parseFloat(leftVal)) ? 0 : parseFloat(leftVal);
			rightVal = isNaN(parseFloat(rightVal)) ? 0 : parseFloat(rightVal);
		} else if (attribute["type"] === "boolean") {
			leftVal = leftVal === 0 ? 0 : 1;
			rightVal = rightVal === 0 ? 0 : 1;
		}

		if (sortOrder === 0) {
			if (leftVal <= rightVal) {
				result.push(left.shift());
			} else {
				result.push(right.shift());
			}
		} else {
			if (leftVal > rightVal) {
				result.push(left.shift());
			} else {
				result.push(right.shift());
			}
		}
	}

	return [...result, ...left, ...right];
}

export function mergeSort(arr, attribute, sortOrder, getValueFunction) {
	if (arr?.length < 2) {
		return arr;
	}

	var middle = parseInt(arr?.length / 2);
	var left = arr?.slice(0, middle);
	var right = arr?.slice(middle, arr?.length);

	return merge(
		mergeSort(left, attribute, sortOrder, getValueFunction),
		mergeSort(right, attribute, sortOrder, getValueFunction),
		attribute,
		sortOrder,
		getValueFunction
	);
}

export function searchFilter(data, dataAttributes, filterVal) {
	let render = false;
	let filteredData;

	filterVal = filterVal.toLowerCase();
	if (data instanceof Array) {
		filteredData = [];

		for (let i = 0; i < data?.length; i++) {
			for (let j = 0; j < dataAttributes?.length; j++) {
				if (data[i][dataAttributes[j]] === undefined) {
					render = true;
					break;
				}
				if (data[i][dataAttributes[j]]?.toLowerCase().includes(filterVal)) {
					render = true;
					filteredData.push(data[i]);
					break;
				}
			}
		}
	} else {
		for (let j = 0; j < dataAttributes?.length; j++) {
			if (data[dataAttributes[j]] === undefined) {
				render = true;
				break;
			}
			if (data[dataAttributes[j]]?.toLowerCase().includes(filterVal)) {
				render = true;
				break;
			}
		}
		filteredData = render;
	}

	return filteredData;
}

export function removeObjectProps(obj = {}) {
	const newObj = {};
	for (const key in obj) {
		if (typeof obj[key] !== "object") {
			newObj[key] = obj[key];
		}
	}
	return newObj;
}
