import { chakraThemeColorsMap, getSubtotal, getValueColor } from "../../../helperFunctions";
import moment from "moment";

export default class SectionTemplateBillingAndCostProjections {
	constructor(manpowerProjectionsByMth = [], contractTotals = null, pagebreak = 0, maxRows = 16) {
		this.template = "BillingProjections";
		this.name = "Billing & Cost Projections";
		this.heading = "Billing & Cost Projections";
		this.footNote = "*Click the Sync Button to Copy your Manpower & Billing Projections to iPPMech.com";
		this.tooltip = "Click the Sync Button to Copy your Manpower & Billing Projections to iPPMech.com";
		this.divider = false;
		this.pagebreak = pagebreak;
		this.wrap = true;

		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = maxRows;
		this.subSections = [new SubSectionTemplateBillingAndCostProjections(manpowerProjectionsByMth, contractTotals)];
	}
}

class SubSectionTemplateBillingAndCostProjections {
	constructor(manpowerProjectionsByMth = [], contractTotals = null) {
		this.template = "BillingAndCostProjections";
		this.type = "virtualTable";
		this.show = manpowerProjectionsByMth?.length > 0 ? true : false;
		this.heading = "Billing & Cost Projections";

		this.columns = [
			{
				accessor: "projKeyID",
				Header: "ProjKeyID",
				valueType: "integer",
				width: "85%",
				show: false,
			},
			{
				accessor: "year",
				Header: "Year",
				valueType: "integer",
				width: "85%",
				show: false,
			},
			{
				accessor: "mth",
				Header: "Month",
				valueType: "month",
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			// {
			// 	accessor: "pctProjManpowerField",
			// 	Header: "Field Manpower Dist (%)",
			// 	textAlign: "center",
			// 	valueType: "percent",
			// 	decimals: 2,
			// },

			// {
			// 	id: "projHours_delta",
			// 	accessor: this.projHours_delta,
			// 	Header: "Proj Hours",
			// 	textAlign: "right",
			// 	valueType: "number",
			// 	decimals: 1,
			// 	show: false,
			// },
			{
				id: "projLaborCost_delta",
				accessor: this.projLaborCost_delta,
				Header: "Labor Cost",
				textAlign: "right",
				valueType: "currency",
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			// {
			// 	accessor: "buyoutMaterialCost",
			// 	Header: "Material Buyout ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			// {
			// 	accessor: "distMaterialCost",
			// 	Header: "Material Dist ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			{
				accessor: "projMatCost_delta",
				Header: "Material Cost",
				textAlign: "right",
				valueType: "currency",
				editable: true,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
				bg: chakraThemeColorsMap.grayAlpha300,
			},
			// {
			// 	accessor: "buyoutSubsCost",
			// 	Header: "Subs Buyout ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			// {
			// 	accessor: "distSubsCost",
			// 	Header: "Subs Dist ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			{
				accessor: "projSubCost_delta",
				Header: "Subs Cost",
				textAlign: "right",
				valueType: "currency",
				editable: true,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
				bg: chakraThemeColorsMap.grayAlpha300,
			},
			// {
			// 	accessor: "buyoutEquipCost",
			// 	Header: "Rental Buyout ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			// {
			// 	accessor: "distEquipCost",
			// 	Header: "Rental Dist ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			{
				accessor: "projEquipCost_delta",
				Header: "Rental Cost",
				textAlign: "right",
				valueType: "currency",
				editable: true,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
				bg: chakraThemeColorsMap.grayAlpha300,
			},
			// {
			// 	accessor: "buyoutOtherJobCost",
			// 	Header: "Other Job Buyout ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			// {
			// 	accessor: "distOtherJobCost",
			// 	Header: "Other Job Dist ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			{
				accessor: "projOtherJobCost_delta",
				Header: "Other Job Cost",
				textAlign: "right",
				valueType: "currency",
				editable: true,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
				bg: chakraThemeColorsMap.grayAlpha300,
			},
			// {
			// 	accessor: "buyoutPCOCost",
			// 	Header: "PCO Buyout ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			// {
			// 	accessor: "distPCOCost",
			// 	Header: "PCO Dist ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			{
				accessor: "projPCOCost_delta",
				Header: "PCO Cost",
				textAlign: "right",
				valueType: "currency",
				editable: true,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
				bg: chakraThemeColorsMap.grayAlpha300,
			},

			{
				accessor: "projContCost_delta",
				Header: "Contingency",
				textAlign: "right",
				valueType: "currency",
				editable: true,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
				bg: chakraThemeColorsMap.grayAlpha300,
			},
			// {
			// 	accessor: "projContCost_delta",
			// 	Header: "PCO Cost",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// 	editable: true,
			// 	Footer: (rows = []) => {
			// 		return this.getFooter(rows, this.footer);
			// 	},
			// 	bg: chakraThemeColorsMap.grayAlpha300,
			// },
			// {
			// 	accessor: "buyoutProjCost",
			// 	Header: "Projected Buyout ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			// {
			// 	accessor: "remainProjCost",
			// 	Header: "Remaining Cost ($)",
			// 	textAlign: "right",
			// 	valueType: "currency",
			// },
			{
				id: "projCost_delta",
				accessor: this.projCost_delta,
				Header: "Projected Cost",
				textAlign: "right",
				valueType: "currency",
				bg: chakraThemeColorsMap.grayAlpha300,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
			{
				accessor: "projBilledAmt_delta",
				Header: "Projected Billing",
				textAlign: "right",
				valueType: "currency",
				editable: true,
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
				bg: chakraThemeColorsMap.grayAlpha300,
			},
			{
				id: "projBilledVsCost_delta",
				accessor: this.projBilledVsCost_delta,
				Header: "Billed Vs Cost",
				textAlign: "right",
				valueType: "currency",
				bg: chakraThemeColorsMap.grayAlpha300,
				color: getValueColor(this.projBilledVsCost_delta),
				Footer: (rows = []) => {
					return this.getFooter(rows, this.footer);
				},
			},
		];
		this.sortBy = [
			{ id: "mth", desc: false },
			{ id: "contract", desc: false },
		];

		this.data = this.getRightAddon(manpowerProjectionsByMth);

		this.footer = (rows = manpowerProjectionsByMth, totals = contractTotals) => {
			return this.getFooter(rows, totals);
		};
	}

	getRightAddon(data = []) {
		for (let i = 0; i < data?.length; i++) {
			if (
				moment(new Date(data[i].mth)).format("MM/DD/YYYY") ===
				moment(new Date()).startOf("month").format("MM/DD/YYYY")
			) {
				data[i].mthRightAddon = " (Projected)";
				break;
			}
		}

		return data;
	}
	projHours_delta(row) {
		return row.getProjHours_delta();
	}

	projLaborCost_delta(row) {
		return row.getProjLaborCost_delta();
	}

	projCost_delta(row) {
		return row.getProjCost_delta();
	}

	projBilledVsCost_delta(row) {
		return row.getProjBilledVsCost_delta();
	}

	getFooter(rows = [], totals = null) {
		let proj = {
			mth: "Projected Cost",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",
			projLaborCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projLaborCost_delta") : 0,
			projMatCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projMatCost_delta") : 0,
			projSubCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projSubCost_delta") : 0,
			projEquipCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projEquipCost_delta") : 0,
			projOtherJobCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projOtherJobCost_delta") : 0,
			projPCOCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projPCOCost_delta") : 0,
			projContCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projContCost_delta") : 0,
			projCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projCost_delta") : 0,
			projBilledAmt_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projBilledAmt_delta") : 0,
			projBilledVsCost_delta: Boolean(rows?.length > 0) ? getSubtotal(rows, "projBilledVsCost_delta") : 0,
		};

		let costToComp = {
			mth: "Cost To Complete",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",
		};

		if (Array.isArray(totals)) {
			let costToCompRow = totals?.filter((d) => d.mth === "Cost To Complete")[0] ?? null;
			if (Boolean(costToCompRow)) {
				costToComp = costToCompRow;
			}
		} else {
			if (Boolean(totals?.queryData === "ContractTotalsByMth" || rows?.length === 0)) {
				costToComp.projLaborCost_delta =
					totals?.getLaborCostToComplete() ?? 0 + totals?.getSubLabCostToComplete() ?? 0;
				costToComp.projMatCost_delta = totals?.getMatCostToComplete() ?? 0;
				costToComp.projSubCost_delta = totals?.getSubCostToComplete() ?? 0;
				costToComp.projEquipCost_delta = totals?.getEquipCostToComplete() ?? 0;
				costToComp.projOtherJobCost_delta = totals?.getOtherJobCostToComplete() ?? 0;
				costToComp.projPCOCost_delta = totals?.getPCOCostToComplete() ?? 0;
				costToComp.projContCost_delta = totals?.getContCostToComplete() ?? 0;
				costToComp.projCost_delta = totals?.getCostToCompleteWithPCOs();
				costToComp.projBilledAmt_delta = totals?.getProjBillingBal() ?? 0;
				costToComp.projBilledVsCost_delta = totals?.getProjBilledVsCost() ?? 0;
			}
		}

		let remain = {
			mth: "Remaining",
			mthType: "string",
			mthFontSize: 7,
			mthTextSize: "sm",
			projLaborCost_delta:
				parseFloat(costToComp.projLaborCost_delta ?? 0) - parseFloat(proj?.projLaborCost_delta ?? 0),
			projMatCost_delta: parseFloat(costToComp.projMatCost_delta ?? 0) - parseFloat(proj?.projMatCost_delta ?? 0),
			projSubCost_delta: parseFloat(costToComp.projSubCost_delta ?? 0) - parseFloat(proj?.projSubCost_delta ?? 0),
			projEquipCost_delta:
				parseFloat(costToComp.projEquipCost_delta ?? 0) - parseFloat(proj?.projEquipCost_delta ?? 0),
			projOtherJobCost_delta:
				parseFloat(costToComp.projOtherJobCost_delta ?? 0) - parseFloat(proj?.projOtherJobCost_delta ?? 0),
			projPCOCost_delta: parseFloat(costToComp.projPCOCost_delta ?? 0) - parseFloat(proj?.projPCOCost_delta ?? 0),
			projContCost_delta:
				parseFloat(costToComp.projContCost_delta ?? 0) - parseFloat(proj?.projContCost_delta ?? 0),
			projCost_delta: parseFloat(costToComp.projCost_delta ?? 0) - parseFloat(proj?.projCost_delta ?? 0),
			projBilledAmt_delta:
				parseFloat(costToComp.projBilledAmt_delta ?? 0) - parseFloat(proj?.projBilledAmt_delta ?? 0),
			projBilledVsCost_delta:
				parseFloat(costToComp.projBilledVsCost_delta ?? 0) - parseFloat(proj?.projBilledVsCost_delta ?? 0),
		};

		remain.projLaborCost_deltaTextColor = getValueColor(remain.projLaborCost_delta);
		remain.projMatCost_deltaTextColor = getValueColor(remain.projMatCost_delta);
		remain.projSubCost_deltaTextColor = getValueColor(remain.projSubCost_delta);
		remain.projEquipCost_deltaTextColor = getValueColor(remain.projEquipCost_delta);
		remain.projOtherJobCost_deltaTextColor = getValueColor(remain.projOtherJobCost_delta);
		remain.projPCOCost_deltaTextColor = getValueColor(remain.projPCOCost_delta);
		remain.projContCost_deltaTextColor = getValueColor(remain.projContCost_delta);
		remain.projCost_deltaTextColor = getValueColor(remain.projCost_delta);
		remain.projBilledAmt_deltaTextColor = getValueColor(remain.projBilledAmt_delta);
		remain.projBilledVsCost_deltaTextColor = getValueColor(remain.projBilledVsCost_delta);

		return [proj, costToComp, remain];
	}
}
