import OperationsAPI from "../../../APIs/OperationsAPI";
import { log } from "../../../helperFunctions";

export default class Vendor {
	constructor(vendor) {
		this.operationsAPI = new OperationsAPI();
		this.queryData = vendor?.queryData ?? "APVendor";
		this.apvmKeyID = vendor?.apvmKeyID ?? null;
		this.vendorGroup = vendor?.vendorGroup ?? null;
		this.vendorID = vendor?.vendorID ?? null;
		this.vendorName = vendor?.vendorName ?? null;
		this.vendorSortName = vendor?.vendorSortName ?? null;
		this.vendorPhone = vendor?.vendorPhone ?? null;
		this.poAddress = vendor?.poAddress ?? null;
		this.poAddress2 = vendor?.poAddress2 ?? null;
		this.poCity = vendor?.poCity ?? null;
		this.poState = vendor?.poState ?? null;
		this.poZip = vendor?.poZip ?? null;

		this.diversityURL = vendor?.diversityURL ?? null;
		this.diversityContact = vendor?.diversityContact ?? null;
		this.diversityEmail = vendor?.diversityEmail ?? null;
		this.diversityPhone = vendor?.diversityPhone ?? null;
		this.diversityServiceType = vendor?.diversityServiceType ?? null;

		this.sbe = vendor?.sbe ?? null;
		this.sbeStatus = vendor?.sbeStatus ?? null;
		this.lbe = vendor?.lbe ?? null;
		this.lbeStatus = vendor?.lbeStatus ?? null;
		this.mbe = vendor?.mbe ?? null;
		this.mbeStatus = vendor?.mbeStatus ?? null;
		this.wbe = vendor?.wbe ?? null;
		this.wbeStatus = vendor?.wbeStatus ?? null;
		this.dbe = vendor?.dbe ?? null;
		this.dbeStatus = vendor?.dbeStatus ?? null;
		this.dvbe = vendor?.dvbe ?? null;
		this.dvbeStatus = vendor?.dvbeStatus ?? null;
		this.cbe = vendor?.cbe ?? null;
		this.cbeStatus = vendor?.cbeStatus ?? null;
		this.lsbe = vendor?.lsbe ?? null;
		this.lsbeStatus = vendor?.lsbeStatus ?? null;

		this.diversityCerts = vendor?.diversityCerts ?? [];

		this.subtitle = vendor?.subtitle ?? this.getSubtitle();
		this.isDiversityVendorYN = this.getIsDiversityVendorYN();
	}

	getSubtitle() {
		return "(Co: " + this.vendorGroup + "  AP Vendor: " + this.vendorID + ")";
	}

	getIsDiversityVendorYN() {
		let isDiversityVendorYN = "N";
		if (
			Boolean(this.sbe || this.lbe || this.mbe || this.dbe || this.dvbe || this.cbe || this.lsbe || this.wbe) ||
			this.diversityCerts.length > 0
		) {
			isDiversityVendorYN = "Y";
		}
		return isDiversityVendorYN;
	}

	// SQL Insert Diversity Cert
	async insertDiversityCert() {
		this.cert = this.cert?.toString()?.toUpperCase();
		//!Boolean(this.diversityCerts.some((cert) => cert.keyID === keyID)) &&
		if (Boolean(this.apvmKeyID)) {
			let data = {
				vendorGroup: this.vendorGroup,
				vendor: this.vendorID,
				// certType: this.certType,
				// expiration: this.expiration,
			};
			await this.operationsAPI.PortalUser_InsertCert(data);
			return this;
		}
	}

	async deleteDiversityCert(keyID) {
		let diversityCerts = this.diversityCerts;
		let deleteItem = diversityCerts.find((data) => data?.keyID === keyID) ?? {};
		if (Boolean(deleteItem?.keyID)) {
			deleteItem.deleteDiversityCert();
		}
		this.diversityCerts = diversityCerts.filter((data) => data?.keyID !== keyID);
		return this;
	}

	getVendorAttr(attr) {
		// STRINGS
		if (attr === "diversityContact") {
			return "udDiversityContact";
		} else if (attr === "diversityPhone") {
			return "udDiversityPhone";
		} else if (attr === "diversityEmail") {
			return "udDiversityEmail";
		} else if (attr === "diversityURL") {
			return "udCertLink";
		} else if (attr === "diversityServiceType") {
			return "udServiceType";
		}
	}

	async updateVendor(updates) {
		let result = await this.operationsAPI.PortalUser_UpdateVendor(this.apvmKeyID, updates);
		log("DATABASE RESULT", result.value);
	}

	// Update the database
	async update(attr = null, value = null) {
		let strings = ["diversityContact", "diversityEmail", "diversityURL", "diversityServiceType", "diversityPhone"];
		let vendorAttributes = [
			"diversityContact",
			"diversityEmail",
			"diversityURL",
			"diversityServiceType",
			"diversityPhone",
		];
		let val = null;

		if (strings.includes(attr)) {
			val = value?.replace("'", "`") ?? null;
			if (attr !== "notes") {
				val = val?.replace(/\n/g, " ")?.trim() ?? null;
			}
			this[attr] = val;
		}

		if (Boolean(this.apvmKeyID) && vendorAttributes.includes(attr)) {
			let dbAttribute = this.getVendorAttr(attr);
			let update = { [dbAttribute]: val };
			let result = await this.updateVendor(update);
			return result;
		}
	}
}
