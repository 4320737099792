import React, { useState, useMemo, useCallback } from "react";
import {
	Box,
	Flex,
	Spacer,
	Table,
	Thead,
	Th,
	Tbody,
	Tfoot,
	Tr,
	Td,
	Text,
	Heading,
	Input,
	Skeleton,
	useBoolean,
	VStack,
	Container,
	HStack,
	Stack,
	Switch,
	Tag,
	Avatar,
	AvatarBadge,
	Tabs,
	Tab,
	TabList,
	Tooltip,
	SimpleGrid,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerCloseButton,
	IconButton,
	Modal,
	Collapse,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Button,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Icon,
	InputGroup,
	Center,
	InputLeftAddon,
	Checkbox,
} from "@chakra-ui/react";
import { Email, Span, renderEmail } from "react-html-email";
import { IoShieldHalfOutline } from "react-icons/io5";

import MainContent from "../App/components/MainContent";
import DataInput from "../../core/Inputs/data/DataInput";
import PPMLogo from "../../core/Logos/components/PPMLogo";

import PayrollAPI from "../../APIs/PayrollAPI";
import {
	EmployeeHistoryData,
	EmployeeHistoryList,
	EmployeeHistoryRow,
	AccessMap,
	RegionList,
} from "./classes/MeritIncreasesClassesLegacy";
import CELL_PHONE_CARRIERS from "../../modules/Employee/constants/CellPhoneCarriers";
import { JOYRIDE_PROPOSEDINCREASES } from "../../joyride";

import { portalUser } from "../../App";
import { formatValue, convertArrayToMap } from "../../helperFunctions";
import { v4 } from "uuid";
import moment from "moment";

const carriersMap = convertArrayToMap(CELL_PHONE_CARRIERS, "value");

export default class MeritIncreases extends React.Component {
	constructor(props) {
		super(props);

		this.timer = 0;
		this.codeVal = "";

		let tabs = ["Loading"];
		let proposalYear = moment().year() + 1;
		let isFullExecUser = false;
		if (portalUser.user?.isFullExecUserYN === "Y") {
			isFullExecUser = true;
			tabs = ["Salaries & Bonuses", "Region Summaries", "Security & Access"];
		} else {
			tabs = ["Salaries & Bonuses"];
		}

		this.state = {
			tabVal: 0,
			tabPanelVal: 0,

			authCode: null,
			enteredCode: null,
			authFailed: false,
			authType: null,

			isAuthorized: false,
			loadPage: false,
			isDisabled: true,

			payrollAPI: new PayrollAPI(),

			isFullExecUser: isFullExecUser,
			tabs: tabs,

			userAccess: null,
			userAccessRow: null,

			proposalYear: proposalYear,
			employeeHistory: null,
			regionArr: [
				{ regionID: -1, region: "All Regions", regionLabel: "All", color: "teal.600", showRegion: true },
				{ regionID: 0, region: "SoCal", regionLabel: "SoCal", color: "green.600", showRegion: true },
				{ regionID: 1, region: "NorCal", regionLabel: "NorCal", color: "orange.600", showRegion: true },
				{ regionID: 3, region: "Hawaii", regionLabel: "Hawaii", color: "blue.600", showRegion: true },
			],
			deptArr: null,
			deptGroupMap: [
				{
					deptGroup: "Loading",
					deptGroupDesc: "Loading Departments...",
					deptGroupIcon: "fas fa-spinner fa-spin",
					deptGroupColor: "gray",
					rows: [],
				},
			],
			deptTypeArr: [
				{ deptType: "G&A", description: "G&A", icon: "SoCal" },
				{ deptType: "Indirect", description: "Job Charge & Indirects", icon: "All Regions" },
				{ deptType: "Recharge", description: "PMs & Engineers", icon: "NorCal" },
			],
			leaderAccess: null,
			defaultOpen: true,
			showDetail: false,

			selectedEmployee: null,
			selectedLeader: null,
			selectedAccessLevel: null,

			levelsMap: null,
			levelsMapFiltered: null,

			runJoyride: false,
			joyrideSteps: JOYRIDE_PROPOSEDINCREASES,
		};

		this.handleTabChange = this.handleTabChange.bind(this);
		this.scrollTop = this.scrollTop.bind(this);
		this.handleTabPanelChange = this.handleTabPanelChange.bind(this);
		this.closeForm = this.closeForm.bind(this);
		this.startJoyride = this.startJoyride.bind(this);
		this.joyrideCallback = this.joyrideCallback.bind(this);

		this.handleAuthCodeChange = this.handleAuthCodeChange.bind(this);
		this.sendAuthEmail = this.sendAuthEmail.bind(this);

		this.init = this.init.bind(this);
		this.getLeaderAccess = this.getLeaderAccess.bind(this);

		this.getUserAccessRow = this.getUserAccessRow.bind(this);

		this.selectEmployee = this.selectEmployee.bind(this);
		this.closeEmployee = this.closeEmployee.bind(this);

		this.selectAccess = this.selectAccess.bind(this);
		this.selectLeader = this.selectLeader.bind(this);
		this.closeLeader = this.closeLeader.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	async init() {
		let employeeHistoryData = await this.state.payrollAPI.GetEmployeeHistory(portalUser.user.employeeUID);

		if (employeeHistoryData.status === 200) {
			let employeeHistory = new EmployeeHistoryData(employeeHistoryData.value);

			let proposalYear = employeeHistory?.getProposalYear() ?? moment().year() + 1;
			let regionArr = this.state.regionArr;
			let deptArr = employeeHistory.createDeptArr(regionArr);
			employeeHistory = employeeHistory.addDepartments(deptArr);
			this.setState(
				{
					proposalYear: proposalYear,
					employeeHistory: employeeHistory,
					deptArr: deptArr,
				},
				() => {
					this.getLeaderAccess(employeeHistory);
				}
			);
		}
	}

	async getLeaderAccess(employeeHistory) {
		let leaderAccess = this.state.leaderAccess;

		if (leaderAccess === null || leaderAccess?.length === 0) {
			let leaderData = await this.state.payrollAPI.GetDepartmentLeaders(portalUser.user?.employeeUID);
			if (leaderData.status === 200) {
				let leaderAccess = new AccessMap(leaderData.value);
				leaderAccess.getAccessLevel();

				let userAccess = leaderAccess.getEmployeeAccess(portalUser.user?.employeeUID);
				employeeHistory = employeeHistory.applyLeaderAccess(leaderAccess);
				let levelsMap = leaderAccess.createLevelsMap();
				levelsMap.addEmployeeHistory(employeeHistory);

				let deptByRegionMap = employeeHistory.createDepartmentMap("deptByRegion");
				deptByRegionMap = deptByRegionMap.getSubTotals();

				let deptGroupMap = deptByRegionMap.createDepartmentMap("deptGroup");
				deptGroupMap.getTotals();
				deptGroupMap.push(deptGroupMap.shift());

				this.setState(
					{
						employeeHistory: employeeHistory,
						leaderAccess: leaderAccess,
						userAccess: userAccess,
						deptGroupMap: deptGroupMap,
						levelsMap: levelsMap,
					}
					// () => {
					// 	this.getUserAccessRow();
					// }
				);
			}
		}
	}

	getUserAccessRow() {
		let deptGroupMap = this.state.deptGroupMap;
		let tabPanelVal = this.state.tabPanelVal;
		let userAccess = this.state.userAccess;
		let deptGroup = deptGroupMap[tabPanelVal];

		let execAccessRow = userAccess?.filter((data) => data?.deptGroupID === 1);
		let groupAccessRow = userAccess?.filter((data) => data?.deptGroupID === deptGroup?.deptGroupID);

		let userAccessRow = null;
		if (execAccessRow?.length > 0) {
			userAccessRow = execAccessRow[0];
		} else if (groupAccessRow?.length > 0) {
			userAccessRow = groupAccessRow[0];
		}

		this.setState({
			userAccessRow: userAccessRow,
		});
	}

	selectEmployee(employeeHistoryRow) {
		let employeeHistory = this.state.employeeHistory;

		if (employeeHistoryRow !== null) {
			let parentDeptID = Number(employeeHistoryRow?.parentDeptID);
			let regionID = Number(employeeHistoryRow?.regionID);

			let leaderAccessfiltered = this.state.leaderAccess;
			leaderAccessfiltered = leaderAccessfiltered.filter(
				(data) => data?.parentDeptID === parentDeptID || data?.parentDeptID === 1
			);
			leaderAccessfiltered = leaderAccessfiltered.filter(
				(data) => data?.regionID === regionID || data?.regionID === -1
			);
			leaderAccessfiltered.getAccessLevel();
			let levelsMapfiltered = leaderAccessfiltered.createLevelsMap();
			levelsMapfiltered.addEmployeeHistory(employeeHistory);

			this.setState({
				selectedEmployee: employeeHistoryRow,
				showDetail: true,
				defaultOpen: true,
				levelsMapFiltered: levelsMapfiltered,
			});
		} else {
			this.closeEmployee();
		}
	}

	closeEmployee() {
		this.setState({
			selectedEmployee: null,
			showDetail: false,
			levelsMapFiltered: null,
		});
	}

	selectAccess(levelsRow = null) {
		let selectedAccess;
		if (levelsRow !== null) {
			selectedAccess = levelsRow;
		}
		this.setState({
			selectedAccess: selectedAccess,
		});
	}

	selectLeader(employeeHistoryRow = null) {
		let selectedLeader;
		if (employeeHistoryRow === null) {
			selectedLeader = new EmployeeHistoryRow();
		} else {
			selectedLeader = employeeHistoryRow;
		}

		this.setState({
			selectedLeader: selectedLeader,
		});
	}

	closeLeader() {
		this.setState({
			selectedAccess: null,
			selectedLeader: null,
		});
	}

	handleTabChange(tabVal) {
		this.setState({ tabVal: tabVal });
	}

	scrollTop() {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}

	handleTabPanelChange(tabPanelVal) {
		this.scrollTop();

		this.setState(
			{
				tabPanelVal: tabPanelVal,
			},
			() => {
				this.getUserAccessRow();
			}
		);
	}

	closeForm() {
		this.handleTabPanelChange(0);
	}

	startJoyride() {
		this.setState({ runJoyride: true });
	}

	joyrideCallback(joyrideState) {
		if (
			joyrideState.action === "reset" ||
			joyrideState.action === "close" ||
			joyrideState.action === "skip" ||
			joyrideState.action === "last"
		) {
			this.setState({ runJoyride: false });
		}
	}

	handleAuthCodeChange(ev) {
		let authCode = this.state.authCode;
		let isAuthorized = this.state.isAuthorized;
		let authFailed = this.state.authFailed;

		//log('authCode', authCode);

		let enteredCode = ev.target.value.toUpperCase();
		enteredCode = enteredCode.replace(/\s+/g, "");
		enteredCode = enteredCode.substring(0, 6);

		if (!isAuthorized && authCode?.length === 6 && enteredCode?.length === 6) {
			if (authCode === enteredCode) {
				isAuthorized = true;
				authFailed = false;
			} else {
				isAuthorized = false;
				authFailed = true;
			}
		} else {
			isAuthorized = false;
			authFailed = false;
		}

		this.setState(
			{
				enteredCode: enteredCode,
				authFailed: authFailed,
				isAuthorized: isAuthorized,
			},
			() => {
				setTimeout(() => {
					this.setState({ loadPage: isAuthorized });
				}, 2000);
			}
		);
	}

	async sendAuthEmail(authType = "email") {
		let uid = v4();
		let authCode = uid.substr(0, 6).toUpperCase();
		if (authType === "email") {
		}

		let from = "PPM Portal<no-reply@ppmportal.com>";
		let to = portalUser.user?.email;
		let subject = "PPM Portal User Verification Code";
		let body;
		let msg;

		let phoneNumber = (portalUser.user?.cellPhone ?? "")?.replace(/\D/g, "");
		let carrier = carriersMap[portalUser.user?.cellCarrier]?.mms ?? null;

		if (authType === "text" && carrier !== "" && phoneNumber !== "") {
			to = phoneNumber + "@" + carrier;
			subject = "PPM Portal Verification Code:";

			body = authCode;
			msg = {
				from: from,
				to: to,
				//to: '5624459195@vtext.com',
				subject: subject,
				text: body,
			};
		} else {
			authType = "email";
			body = (
				<Email title={subject} style={{ width: "100%" }}>
					<Span>
						Please enter the following the 6-digit verification code into the PPM Portal to view employee
						salary and bonus information: <h3 style={{ color: "teal" }}>{authCode}</h3>
					</Span>
				</Email>
			);
			body = renderEmail(body);
			msg = {
				from: from,
				to: to,
				subject: subject,
				html: body,
			};
		}

		await this.state.payrollAPI.SendgridEmail(msg);

		this.setState({
			authCode: authCode,
			authType: authType,
			isAuthorized: false,
			authFailed: false,
		});
	}

	render() {
		let title =
			this.state.tabVal < 1
				? "Proposed Increases " + this.state.proposalYear
				: this.state.tabVal === 1
				? "Summaries By Region"
				: "Department Leader Access";

		let hasCellPhone = Boolean(
			portalUser.user?.cellPhone !== "" &&
				portalUser.user?.cellCarrier !== null &&
				portalUser.user?.cellCarrier !== "" &&
				portalUser.user?.cellCarrier !== null
		);
		let hiddenCellPhone = "(XXX) XXX-XXXX";
		if (hasCellPhone) {
			hiddenCellPhone = "(XXX) XXX-" + portalUser.user?.cellPhone.slice(-4);
		}
		let levelMap = this.state.levelsMapFiltered !== null ? this.state.levelsMapFiltered : this.state.levelsMap;
		levelMap = levelMap?.filter((data) => data?.accessLevel !== 0);
		levelMap = levelMap?.filter((data) => data?.accessLevel !== 5);
		levelMap = levelMap?.filter((data) => data?.showLevel === true);

		return (
			<Box>
				{!this.state.loadPage && (
					<MainContent
						onTabChange={this.handleTabChange}
						title="Verification Required"
						tabVal={this.state.tabVal}
						tabs={["Locked"]}
						{...this.props}
					>
						<Flex
							zIndex={10}
							direction="column"
							align="center"
							justify="center"
							pos="absolute"
							bg="teal.500"
							opacity={0.9}
							top="0"
							w="full"
							minH="100vh"
							maxH="100vh"
							overflow="hidden"
						>
							<Flex flex={1}></Flex>
							<Icon
								flex={8}
								ml={{ base: "0px", md: "-112px" }}
								p="6"
								as={IoShieldHalfOutline}
								color="whiteAlpha.800"
								boxSize="full"
								maxH="container.lg"
								maxW="container.lg"
							/>
							<Flex flex={4}></Flex>
						</Flex>

						<Flex
							zIndex={11}
							pos="absolute"
							top="0"
							direction="column"
							w="full"
							minH="100vh"
							maxH="100vh"
							h="full"
							bg="blackAlpha.500"
							align="center"
							justify="center"
							overflow="hidden"
						>
							<Container mt="25vh" w="full" h="full" px="0">
								<Center
									h="full"
									p="6"
									shadow="xl"
									rounded="10"
									maxH="sm"
									bg="whiteAlpha.900"
									maxW="xl"
									ml={{ base: "0px", md: "-84px" }}
								>
									<VStack spacing={6}>
										<PPMLogo color="teal.700" h="65px" />

										<VStack spacing={2} textAlign="center">
											{!this.state.isAuthorized && !this.state.authCode && (
												<Heading
													color="gray.500"
													size="sm"
													letterSpacing={1}
													textTransform="uppercase"
												>
													To access this page, please verify your identity
												</Heading>
											)}
											{this.state.authCode &&
												!this.state.authFailed &&
												!this.state.isAuthorized && (
													<Heading
														color="gray.500"
														size="xs"
														letterSpacing={1}
														textTransform="uppercase"
													>
														{"Please enter the code sent to " +
															(this.state.authType === "text"
																? hiddenCellPhone
																: "your email") +
															" below"}{" "}
													</Heading>
												)}
											{this.state.authCode &&
												this.state.authFailed &&
												!this.state.isAuthorized && (
													<Heading
														color="red.500"
														size="xs"
														letterSpacing={1}
														textTransform="uppercase"
													>
														{"Invalid Code, Please check your " +
															(this.state.authType === "text" ? "phone" : "email") +
															" and try again"}
													</Heading>
												)}
											{this.state.isAuthorized && (
												<Heading
													color="green.600"
													size="sm"
													letterSpacing={1}
													textTransform="uppercase"
												>
													Identify verification is complete, access granted
												</Heading>
											)}

											<Text as="i" color="gray.500" letterSpacing={1} fontSize="sm">
												If you leave this page, you will need to re-verify your identity
											</Text>
										</VStack>

										<Center>
											<InputGroup>
												<InputLeftAddon
													px="3"
													borderColor={
														this.state.authCode && this.state.authFailed
															? "red.500"
															: "gray.300"
													}
													border="2px"
													pointerEvents="none"
													children={
														<Heading
															key={"authorized-" + this.state.isAuthorized}
															color={
																this.state.authCode && this.state.authFailed
																	? "red.500"
																	: this.state.isAuthorized
																	? "green.600"
																	: "gray.500"
															}
															size="sm"
														>
															<i
																className={
																	"fas " +
																	(this.state.isAuthorized
																		? "fa-lock-open"
																		: this.state.authCode && this.state.authFailed
																		? "fa-exclamation-triangle"
																		: "fa-lock") +
																	" fa-fw fa-lg"
																}
															></i>
														</Heading>
													}
												/>
												<Input
													bg="white"
													color="teal.600"
													fontWeight="bold"
													letterSpacing={2}
													textTransform="uppercase"
													textAlign="center"
													focusBorderColor={this.state.authFailed ? "red.500" : "teal.600"}
													borderColor="gray.300"
													border="2px"
													fontSize="xl"
													placeholder="Verification Code"
													_placeholder={{ color: "gray.300", fontWeight: "normal" }}
													maxW="xs"
													value={this.state.enteredCode}
													isInvalid={this.state.authFailed}
													_invalid={{ color: "red", borderColor: "red.500" }}
													errorBorderColor="red.500"
													isDisabled={!this.state.authCode}
													_disabled={{ borderColor: "gray.300", cursor: "not-allowed" }}
													onChange={this.handleAuthCodeChange}
													maxLength="7"
												/>
											</InputGroup>
										</Center>

										<HStack spacing="4">
											<Button
												isDisabled={!hasCellPhone}
												textTransform="uppercase"
												onClick={() => {
													this.sendAuthEmail("text");
												}}
												size="md"
												pl="2"
												pr="3"
												colorScheme="blue"
												variant="solid"
												py={2}
											>
												<HStack spacing={1} align="center">
													<i className="fas fa-mobile-alt fa-fw fa-lg"></i>
													<Text letterSpacing={1}>
														{this.state.authCode && this.state.authType === "text"
															? "Re-Send Code"
															: "Text Code"}
													</Text>
												</HStack>
											</Button>

											<Button
												textTransform="uppercase"
												onClick={() => {
													this.sendAuthEmail("email");
												}}
												size="md"
												pl="2"
												pr="3"
												colorScheme="teal"
												variant="solid"
												py={2}
											>
												<HStack align="center">
													<i className="fas fa-envelope fa-fw fa-lg"></i>
													<Text letterSpacing={1}>
														{this.state.authCode && this.state.authType === "email"
															? "Re-Send Code"
															: "Email Code"}
													</Text>
												</HStack>
											</Button>
										</HStack>
										{!hasCellPhone && (
											<Text
												color="gray.400"
												size="xs"
												textAlign="center"
												letterSpacing={1}
												as="i"
											>
												To enable text message notifications, go to the <strong>Account</strong>{" "}
												tab on your <strong>Profile</strong> and add your{" "}
												<strong>Cell Phone</strong> and <strong>Carrier</strong>
											</Text>
										)}
									</VStack>
								</Center>
							</Container>
							<Spacer />
						</Flex>
					</MainContent>
				)}
				{this.state.loadPage && (
					<MainContent
						onTabChange={this.handleTabChange}
						tabVal={this.state.tabVal}
						title={title}
						tabs={this.state.tabs}
						joyrideID="joyrideStep7"
						{...this.props}
						runJoyride={this.state.runJoyride}
						joyrideSteps={this.state.joyrideSteps}
						joyrideCallback={this.joyrideCallback}
						startJoyride={this.startJoyride}
					>
						<Flex
							bg="blackAlpha.500"
							direction="column"
							flex={1}
							justify="flex-start"
							w="full"
							h="full"
							p={4}
						>
							<Container maxW="full" px="0">
								{this.state.tabVal === 0 && (
									<Tabs
										colorScheme="teal"
										index={this.state.tabPanelVal}
										onChange={this.handleTabPanelChange}
									>
										<TabList
											mx="4"
											color="gray.600"
											borderBottomColor="whiteAlpha.500"
											id={"joyrideStep6"}
										>
											{this.state.deptGroupMap?.map((tab, i) => (
												<Tab
													key={i}
													_selected={{
														color: "teal.600",
														bg: "whiteAlpha.500",
														borderTopRadius: "5",
														borderBottom: "3px solid",
													}}
													_hover={{
														color: "teal.500",
														bg: "whiteAlpha.500",
														borderTopRadius: "5",
														borderBottom: "3px solid",
														borderBottomColor: "teal.600",
													}}
												>
													<Heading size="sm" textTransform="uppercase">
														{tab.deptGroup}
													</Heading>
												</Tab>
											))}
										</TabList>

										<VStack
											spacing={8}
											w="full"
											px={{ base: "0", lg: "4" }}
											py={{ base: "0", lg: "2" }}
										>
											<Container
												maxW="full"
												bg="white"
												rounded="5"
												shadow="xl"
												minH="6xl"
												p="8"
												key={
													this.state.deptGroupMap[this.state.tabPanelVal]?.deptGroup + "-page"
												}
												position="relative"
											>
												{this.state.tabVal === 0 && (
													<Button
														position="absolute"
														top={0}
														right={0}
														m={4}
														fontWeight="bold"
														letterSpacing={1}
														textTransform="uppercase"
														colorScheme="blue"
														size="sm"
														onClick={() => this.startJoyride()}
													>
														<HStack align="center">
															<i className="fas fa-question-circle fa-lg"></i>
															<Text>Tutorial</Text>
														</HStack>
													</Button>
												)}

												<Flex
													pt="2"
													pb="4"
													flex={1}
													justify="space-between"
													align="center"
													w="full"
													key={
														"Avatar-AccessLevel-" +
														this.state.userAccessRow?.accessLevel +
														"-deptGroup-" +
														this.state.deptGroupMap[this.state.tabPanelVal]?.deptGroup
													}
												>
													<HStack spacing={6} align="center" isTruncated>
														<Avatar
															mb={2}
															bg="gray.400"
															size="lg"
															shadow="md"
															color="white"
															icon={
																<i
																	className={
																		"fas " +
																		this.state.deptGroupMap[this.state.tabPanelVal]
																			?.deptGroupIcon +
																		" fa-lg "
																	}
																></i>
															}
														>
															<AvatarBadge
																border="0.125em solid"
																boxSize="1.35em"
																bg="gray.500"
																shadow="sm"
																color="white"
															>
																<i
																	className={
																		"fas " +
																		(this.state.userAccessRow?.accessLevelIcon ??
																			"fa-cog fa-spin") +
																		" fa-xs fa-fw"
																	}
																></i>
															</AvatarBadge>
														</Avatar>

														<Stack isTruncated>
															<Heading
																isTruncated
																textTransform="uppercase"
																letterSpacing={3}
																size="xl"
																color="gray.500"
															>
																{
																	this.state.deptGroupMap[this.state.tabPanelVal]
																		?.deptGroupDesc
																}
															</Heading>
															<Heading
																size="sm"
																color="gray.400"
																textTransform="uppercase"
																letterSpacing={3}
															>
																{this.state.userAccessRow?.accessLevelDesc ??
																	"Thank you for your patience"}
															</Heading>
														</Stack>
													</HStack>

													<Spacer />
												</Flex>

												<VStack
													spacing="6"
													w="full"
													px="4"
													pt={
														this.state.deptGroupMap[this.state.tabPanelVal].rows?.length > 0
															? "0"
															: "6"
													}
												>
													<Skeleton
														isLoaded={
															this.state.deptGroupMap[this.state.tabPanelVal].rows
																?.length > 0 && Boolean(this.state.userAccess)
														}
														w="full"
														rounded="5"
														minH="4xl"
													>
														{Boolean(
															this.state.deptGroupMap[this.state.tabPanelVal].rows
																?.length > 0
														) &&
															Boolean(this.state.userAccess) && (
																<Stack w="full" spacing={6}>
																	{this.state.deptGroupMap[
																		this.state.tabPanelVal
																	].rows?.map((row, i) => (
																		<HistoryTable
																			key={i}
																			joyrideID={i === 0 ? "joyrideStep1" : null}
																			userAccess={this.state.userAccess}
																			row={row}
																			tabVal={this.state.tabVal}
																			isDisabled={this.state.isDisabled}
																			proposalYear={this.state.proposalYear}
																		/>
																	))}
																</Stack>
															)}
													</Skeleton>
												</VStack>
											</Container>
										</VStack>
									</Tabs>
								)}
								{this.state.tabVal === 1 && this.state.isFullExecUser && (
									// <Tabs colorScheme='teal'  maxW='1600px' mx='auto'>

									//   <TabList mx='4' color='gray.600' borderBottomColor='whiteAlpha.500' id={'joyrideStep6'}   >
									//     <Tab  _selected={{ color: 'teal.600', bg: 'whiteAlpha.500', borderTopRadius: '5', borderBottom: '3px solid' }} _hover={{ color: 'teal.500', bg: 'whiteAlpha.500', borderTopRadius: '5', borderBottom: '3px solid', borderBottomColor: 'teal.600' }}><Heading size='sm' textTransform='uppercase'>Salaries</Heading></Tab>
									//     <Tab  _selected={{ color: 'teal.600', bg: 'whiteAlpha.500', borderTopRadius: '5', borderBottom: '3px solid' }} _hover={{ color: 'teal.500', bg: 'whiteAlpha.500', borderTopRadius: '5', borderBottom: '3px solid', borderBottomColor: 'teal.600' }}><Heading size='sm' textTransform='uppercase'>Bonuses</Heading></Tab>
									//   </TabList>

									//   <TabPanels>

									//   <TabPanel>
									<Container maxW="1600px" bg="white" rounded={5} shadow="lg" minH="6xl" p={4}>
										<HStack p={6} spacing={6} align="center" isTruncated>
											<Avatar
												bg="gray.500"
												w={20}
												h={20}
												color="white"
												icon={<i className={"fas fa-dollar-sign fa-2x"}></i>}
											/>
											<Stack>
												<Heading
													size="2xl"
													color="gray.600"
													textTransform="uppercase"
													letterSpacing={4}
												>
													Region Summaries
												</Heading>
												<Text
													textTransform="uppercase"
													fontWeight="bold"
													color="gray.500"
													letterSpacing={4}
													fontSize="xl"
												>
													Salary Proposals for 2024
												</Text>
											</Stack>
										</HStack>
										{this.state.employeeHistory?.length > 0 && (
											<Container maxW="full">
												{this.state.regionArr?.map((region, i) => {
													let regionEmployees = this.state.employeeHistory;

													if (region.regionID !== -1) {
														regionEmployees = new EmployeeHistoryList(
															this.state.employeeHistory.filter(
																(data) => data?.regionID === region?.regionID
															)
														);
													}

													//log('Employees', regionEmployees)
													let regionTotals =
														regionEmployees.getEmployeeHistoryTotalsSummary();

													let departmentMap = regionEmployees.createDepartmentMap("deptID");
													departmentMap = departmentMap.getSubTotalsSummary();

													//log(region.region + ' TOTALS', regionTotals)
													//log(region.region + 'departmentMap', departmentMap)
													let regionPct = 0;
													if (regionTotals.newSalary !== 0) {
														regionPct =
															((regionTotals.proposedSalary - regionTotals.newSalary) /
																regionTotals.newSalary) *
															100;
													}
													let regionNet =
														regionTotals.proposedSalary +
														regionTotals.proposedBonus +
														(regionTotals.proposedCell +
															regionTotals.proposedVehicle +
															regionTotals.proposedHousing) *
															12;

													return (
														<Stack align="flex-start" pb="10">
															<Heading
																textTransform={"uppercase"}
																letterSpacing={2}
																color="gray.600"
															>
																{region.region}
															</Heading>
															<Table
																size="small"
																bg="white"
																color="gray.600"
																key={"tableSummary-" + region.regionID}
															>
																<Thead border="2px" borderColor="blackAlpha.700">
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																	>
																		<Text fontWeight={"bold"}>Department</Text>
																	</Th>
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																		textAlign="right"
																	>
																		<Text fontWeight={"bold"}>
																			Current Salaries
																		</Text>
																	</Th>
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																		textAlign="right"
																	>
																		<Text fontWeight={"bold"}>
																			Proposed Salaries
																		</Text>
																	</Th>
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																		textAlign="right"
																	>
																		<Text fontWeight={"bold"}>
																			Proposed Increase
																		</Text>
																	</Th>
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																		textAlign="right"
																	>
																		<Text fontWeight={"bold"}>
																			Percent Increase
																		</Text>
																	</Th>
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																		textAlign="right"
																	>
																		<Text fontWeight={"bold"}>
																			Proposed Bonuses
																		</Text>
																	</Th>
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																		textAlign="right"
																	>
																		<Text fontWeight={"bold"}>
																			Phone Allowances
																		</Text>
																	</Th>
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																		textAlign="right"
																	>
																		<Text fontWeight={"bold"}>
																			Vehicle Allowances
																		</Text>
																	</Th>
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																		textAlign="right"
																	>
																		<Text fontWeight={"bold"}>
																			Housing Allowances
																		</Text>
																	</Th>
																	<Th
																		p={2}
																		bg={region.color.replace("6", "7")}
																		color="white"
																		textAlign="right"
																	>
																		<Text fontWeight={"bold"}>
																			Total Yearly&nbsp;Comp
																		</Text>
																	</Th>
																</Thead>
																<Tbody
																	borderLeft="2px"
																	borderRight="2px"
																	borderColor="blackAlpha.700"
																>
																	{departmentMap?.map((dept, i) => {
																		let pct = 0;
																		if (dept.totalsRow.newSalary !== 0) {
																			pct =
																				((dept.totalsRow.proposedSalary -
																					dept.totalsRow.newSalary) /
																					dept.totalsRow.newSalary) *
																				100;
																		}
																		let net =
																			dept.totalsRow.proposedSalary +
																			dept.totalsRow.proposedBonus +
																			(dept.totalsRow.proposedCell +
																				dept.totalsRow.proposedVehicle +
																				dept.totalsRow.proposedHousing) *
																				12;

																		return (
																			<Tr textTransform="uppercase">
																				<Td px={2}>
																					<Text isTruncated>
																						{dept.dept +
																							" (" +
																							dept.totalsRow.hasProposal +
																							"/" +
																							dept.totalsRow
																								.employeeCount +
																							")"}
																					</Text>
																				</Td>
																				<Td px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							dept.totalsRow.newSalary,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							dept.totalsRow
																								.proposedSalary,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td px={2}>
																					<Text
																						fontWeight={
																							pct === 0
																								? "normal"
																								: "semibold"
																						}
																						color={
																							pct > 0
																								? "green.600"
																								: pct < 0
																								? "red.600"
																								: ""
																						}
																						textAlign="right"
																					>
																						{(pct > 0 ? "+" : "") +
																							formatValue(
																								dept.totalsRow
																									.proposedSalary -
																									dept.totalsRow
																										.newSalary,
																								0,
																								"currency"
																							)}
																					</Text>
																				</Td>
																				<Td px={2}>
																					<Text
																						fontWeight={
																							pct === 0
																								? "normal"
																								: "semibold"
																						}
																						color={
																							pct > 0
																								? "green.600"
																								: pct < 0
																								? "red.600"
																								: ""
																						}
																						textAlign="right"
																					>
																						{(pct > 0 ? "+" : "") +
																							formatValue(
																								pct,
																								2,
																								"percent"
																							)}
																					</Text>
																				</Td>
																				<Td px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							dept.totalsRow
																								.proposedBonus,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							dept.totalsRow.proposedCell,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							dept.totalsRow
																								.proposedVehicle,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							dept.totalsRow
																								.proposedHousing,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							net,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																			</Tr>
																		);
																	})}
																</Tbody>
																<Tfoot border="2px">
																	<Tr
																		textTransform="uppercase"
																		borderColor="blackAlpha.700"
																	>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"}>
																				{region.region}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.newSalary,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedSalary,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text
																				color={
																					regionPct > 0
																						? "green.600"
																						: regionPct < 0
																						? "red.600"
																						: ""
																				}
																				fontWeight={"bold"}
																				textAlign="right"
																			>
																				{formatValue(
																					regionTotals.proposedSalary -
																						regionTotals.newSalary,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text
																				color={
																					regionPct > 0
																						? "green.600"
																						: regionPct < 0
																						? "red.600"
																						: ""
																				}
																				fontWeight={"bold"}
																				textAlign="right"
																			>
																				{(regionPct > 0 ? "+" : "") +
																					formatValue(
																						regionPct,
																						2,
																						"percent"
																					)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedBonus,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedCell,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedVehicle,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedHousing,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(regionNet, 0, "currency")}
																			</Text>
																		</Td>
																	</Tr>

																	<Tr
																		bg="blackAlpha.100"
																		textTransform="uppercase"
																		borderColor="blackAlpha.700"
																	>
																		<Td colSpan={10}>
																			<Text minH={2}></Text>
																		</Td>
																	</Tr>

																	{this.state.deptTypeArr?.map((type, i) => {
																		let deptTypeEmployees = new EmployeeHistoryList(
																			regionEmployees.filter(
																				(data) =>
																					data?.prDeptType === type?.deptType
																			)
																		);
																		let deptTypeTotals =
																			deptTypeEmployees.getEmployeeHistoryTotalsSummary();

																		let deptTypePct = 0;
																		if (deptTypeTotals.newSalary !== 0) {
																			deptTypePct =
																				((deptTypeTotals.proposedSalary -
																					deptTypeTotals.newSalary) /
																					deptTypeTotals.newSalary) *
																				100;
																		}
																		let deptTypeNet =
																			deptTypeTotals.proposedSalary +
																			deptTypeTotals.proposedBonus +
																			(deptTypeTotals.proposedCell +
																				deptTypeTotals.proposedVehicle +
																				deptTypeTotals.proposedHousing) *
																				12;

																		return (
																			<Tr
																				textTransform="uppercase"
																				borderColor="blackAlpha.700"
																			>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text>{type.description}</Text>
																				</Td>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							deptTypeTotals.newSalary,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							deptTypeTotals.proposedSalary,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text
																						color={
																							deptTypePct > 0
																								? "green.600"
																								: deptTypePct < 0
																								? "red.600"
																								: ""
																						}
																						fontWeight={"bold"}
																						textAlign="right"
																					>
																						{formatValue(
																							deptTypeTotals.proposedSalary -
																								deptTypeTotals.newSalary,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text
																						color={
																							deptTypePct > 0
																								? "green.600"
																								: deptTypePct < 0
																								? "red.600"
																								: ""
																						}
																						fontWeight={"bold"}
																						textAlign="right"
																					>
																						{(deptTypePct > 0 ? "+" : "") +
																							formatValue(
																								deptTypePct,
																								2,
																								"percent"
																							)}
																					</Text>
																				</Td>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							deptTypeTotals.proposedBonus,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							deptTypeTotals.proposedCell,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							deptTypeTotals.proposedVehicle,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							deptTypeTotals.proposedHousing,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																				<Td bg="blackAlpha.100" px={2}>
																					<Text textAlign="right">
																						{formatValue(
																							deptTypeNet,
																							0,
																							"currency"
																						)}
																					</Text>
																				</Td>
																			</Tr>
																		);
																	})}
																	<Tr
																		textTransform="uppercase"
																		borderColor="blackAlpha.700"
																		pb="2"
																	>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"}>
																				{region.region}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.newSalary,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedSalary,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text
																				color={
																					regionPct > 0
																						? "green.600"
																						: regionPct < 0
																						? "red.600"
																						: ""
																				}
																				fontWeight={"bold"}
																				textAlign="right"
																			>
																				{formatValue(
																					regionTotals.proposedSalary -
																						regionTotals.newSalary,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text
																				color={
																					regionPct > 0
																						? "green.600"
																						: regionPct < 0
																						? "red.600"
																						: ""
																				}
																				fontWeight={"bold"}
																				textAlign="right"
																			>
																				{(regionPct > 0 ? "+" : "") +
																					formatValue(
																						regionPct,
																						2,
																						"percent"
																					)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedBonus,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedCell,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedVehicle,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(
																					regionTotals.proposedHousing,
																					0,
																					"currency"
																				)}
																			</Text>
																		</Td>
																		<Td bg="blackAlpha.100" px={2}>
																			<Text fontWeight={"bold"} textAlign="right">
																				{formatValue(regionNet, 0, "currency")}
																			</Text>
																		</Td>
																	</Tr>
																</Tfoot>
															</Table>
														</Stack>
													);
												})}
											</Container>
										)}
									</Container>
								)}

								{this.state.tabVal === 2 && this.state.isFullExecUser && (
									<VStack
										spacing={8}
										w="full"
										px={{ base: "0", lg: "4" }}
										py={{ base: "0", lg: "2" }}
									>
										<Container
											key={"mfrPage1"}
											maxW="8xl"
											bg="white"
											rounded="5"
											shadow="xl"
											minH="7xl"
											p="8"
										>
											{this.state.selectedLeader && (
												<DepartmentLeaderDrawer
													regionArr={this.state.regionArr}
													deptArr={this.state.deptArr}
													employeeHistory={this.state.employeeHistory}
													selectedLeader={this.state.selectedLeader}
													levelsMap={this.state.levelsMap}
													onClose={this.closeLeader}
												/>
											)}

											<SecurityHeader
												title={"Department Leader Access"}
												employeeHistory={this.state.employeeHistory}
												selectedEmployee={this.state.selectedEmployee}
												selectEmployee={this.selectEmployee}
												closeEmployee={this.closeEmployee}
												selectLeader={this.selectLeader}
												showAddressBar
											/>

											<Container maxW="container.xl">
												<Stack spacing="0" pt="2" w="full" align="flex-start">
													{levelMap?.map((level, i) => {
														return (
															<AccessLevel
																level={level}
																showDetail={this.state.showDetail}
																defaultOpen={this.state.defaultOpen}
																selectedEmployee={this.state.selectedEmployee}
																selectLeader={this.selectLeader}
																regionArr={this.state.regionArr}
															/>
														);
													})}
												</Stack>
											</Container>
										</Container>
									</VStack>
								)}
							</Container>
						</Flex>
					</MainContent>
				)}
			</Box>
		);
	}
}

function SecurityHeader(props) {
	let employeeSearch = new EmployeeHistoryList(props.employeeHistory);
	employeeSearch = employeeSearch.sortEmployeesByName();
	let employeeName = props.selectedEmployee?.employeeName ?? null;

	return (
		<VStack spacing="3" w="full" align="center">
			<Flex w="full" justify="space-between" direction={{ base: "column", lg: "row" }} align="flex-start">
				<PPMLogo color="teal.700" h="60px" />
				<VStack spacing={1} px="4" align="center" justify="center">
					<Heading
						textAlign="center"
						size="xl"
						letterSpacing={2}
						color="gray.400"
						textTransform="uppercase"
						isTruncated
					>
						{props.title}
					</Heading>

					<Flex w="full" maxW="md">
						<DataInput
							type="select"
							variant="outline"
							placeholder="Employee Search"
							searchable
							value={employeeName}
							options={employeeSearch}
							static
							optionText="employeeName"
							optionSubheading="subheading"
							onOptionSelect={props.selectEmployee}
						/>
						{Boolean(props.selectedEmployee) && (
							<IconButton
								variant="outline"
								ml={1}
								color="gray.400"
								onClick={props.closeEmployee}
								icon={<i className="fas fa-times fa-fw fa-lg" />}
							/>
						)}
					</Flex>
				</VStack>

				<Flex minW="240px" justify="flex-end">
					{props.project !== null && (
						<HStack align="flex-start" spacing={2}>
							<Tooltip label="Edit Access" hasArrow={true} placement="top">
								<IconButton
									onClick={() => {
										props.selectLeader(new EmployeeHistoryRow());
									}}
									variant="ghost"
									color="gray.400"
									icon={<i className="fas fa-cog fa-lg " />}
								/>
							</Tooltip>
						</HStack>
					)}
				</Flex>
			</Flex>

			{props.showAddressBar && <AddressBar projectInfo={props.project?.projectInfo} />}
		</VStack>
	);
}

export function AddressBar(props) {
	return (
		<Box mx="20" w="full">
			<HStack
				borderTop="2px"
				borderTopColor="gray.400"
				spacing={6}
				py="3"
				w="full"
				justify="center"
				align="center"
				divider={
					<Text px="4" fontSize="xs" color="gray.600">
						<i className="fas fa-circle fa-xs"></i>
					</Text>
				}
			>
				<Text fontSize="md" color="gray.600" isTruncated>
					{props.projectInfo?.officeAddress ?? "18250 Euclid Street"}
				</Text>
				<Text fontSize="md" color="gray.600" isTruncated>
					{props.projectInfo?.officeCity ?? "Fountain Valley"}, {props.projectInfo?.officeState ?? "CA"}{" "}
					{props.projectInfo?.officeZip ?? "92708"}
				</Text>
				<Text fontSize="md" color="gray.600" isTruncated>
					Phone: {props.projectInfo?.officePhone ?? "(949) 474-9170"}
				</Text>
				<Text fontSize="md" color="gray.600" isTruncated>
					Fax: {props.projectInfo?.officeFax ?? "(949) 474-9936"}
				</Text>
			</HStack>
		</Box>
	);
}

function AccessLevel(props) {
	const [show, setShow] = useState(true);

	const handleToggle = () => setShow(!show);

	useMemo(() => setShow(props.defaultOpen), [props.defaultOpen]);

	let level = props.level;

	let employees = level.employees;
	employees = employees.filter((data) => data?.employeeUID !== "AB653524-E7EE-40FA-B095-6A68D8BCEC27");
	employees = employees.filter((data) => data?.showEmployee === true);
	if (level.accessLevel !== 1 && Boolean(props.selectedEmployee)) {
		employees = employees.filter((data) => data?.employeeUID !== props.selectedEmployee?.employeeUID);
	}
	let numberOfEmployees = employees?.length ?? 0;

	return (
		<Box w="full" borderBottom="2px" borderBottomColor="gray.200">
			<HStack p="3" spacing={1} _hover={{ cursor: "pointer", bg: "blackAlpha.50" }}>
				<Avatar
					onClick={handleToggle}
					mb={2}
					bg="gray.400"
					size="lg"
					shadow="md"
					color="white"
					icon={<i className={"fas " + (level.accessLevelIcon ?? "fa-cog fa-spin") + " fa-lg "}></i>}
				>
					<AvatarBadge border="0.125em solid" boxSize="1.35em" bg="gray.500" shadow="sm" color="white">
						<Text fontSize="md" fontWeight="bold">
							{numberOfEmployees}
						</Text>
					</AvatarBadge>
				</Avatar>
				<Stack spacing={1} rounded="5" py="4" px="4" onClick={handleToggle}>
					<HStack spacing={3} wrap="wrap">
						<Heading letterSpacing={2} color="gray.500" textTransform="uppercase" size="md" isTruncated>
							{level.accessLevelDesc}
						</Heading>
						<Heading letterSpacing={1} color="gray.400" textTransform="uppercase" size="sm" isTruncated>
							{"(" + numberOfEmployees + (numberOfEmployees === 1 ? " Person)" : " People)")}
						</Heading>
					</HStack>

					<Text color="gray.400" fontSize="lg" letterSpacing={1}>
						{level.accessLevelSubheading}
					</Text>
				</Stack>
			</HStack>

			<Collapse in={show}>
				<Box pb="6">
					<Box>
						{/* p='4' bg={'blackAlpha.50'} rounded='10' */}
						{/* <HStack spacing={6} pt={6} pb={12}>  */}
						<SimpleGrid minChildWidth="290px" spacingX={4} spacingY={4} columns={{ sm: 2, md: 3, lg: 4 }}>
							{employees?.map((employee, i) => {
								return (
									<DepartmentSecurity
										show={show}
										showDetail={props.showDetail}
										selectLeader={props.selectLeader}
										employee={employee}
										selectedEmployee={props.selectedEmployee}
										accessLevel={level.accessLevel}
										regionArr={props.regionArr}
									/>
								);
							})}
							{/* </HStack> */}
						</SimpleGrid>
					</Box>
				</Box>
			</Collapse>
		</Box>
	);
}

function DepartmentSecurity(props) {
	const [show, setShow] = useState(props.showDetail);

	const handleToggle = () => setShow(!show);

	useMemo(() => setShow(props.showDetail), [props.showDetail]);

	const [isHovering, setHovering] = useBoolean();

	const regionArr = useMemo(() => props.regionArr, [props.regionArr]);
	// let accessLevel = props.accessLevel ?? 0;
	let employee = props.employee;
	let departments = employee?.departments?.filter((data) => data?.hasAccess === true);
	departments = departments?.filter((data) => data?.showDept === true);
	let departmentCount = departments?.length;

	let allDepts = false;
	if (departments?.filter((data) => data?.deptID === 1)?.length > 0) {
		allDepts = true;
		departmentCount = 15;
	}
	let icon = !isHovering && !show ? departments[0]?.parentDeptIcon ?? "fa-plus" : show ? "fa-minus" : "fa-plus";

	let allRegions = false;
	let regionCount = 0;
	for (let i = 0; i < departments?.length; i++) {
		let regions = departments[i].regions.filter((data) => data?.hasAccess === true);
		regions = regions?.filter((data) => data?.showRegion === true);
		let currentRegions = 0;

		for (let j = 0; j < regions?.length; j++) {
			if (regions[j].regionID === -1) {
				allRegions = true;
				regionCount = 3;
				break;
			} else {
				currentRegions += 1;
			}
		}
		if (allRegions) {
			break;
		} else if (currentRegions > regionCount) {
			regionCount = currentRegions;
		}
	}

	return (
		<>
			{employee.showEmployee && (
				<Box onMouseEnter={setHovering.on} onMouseLeave={setHovering.off}>
					<HStack
						spacing={3}
						align="flex-start"
						rounded="5"
						p="2"
						bg={isHovering ? "blackAlpha.50" : "white"}
						cursor="pointer"
						shadow={isHovering ? "md" : ""}
						maxW="305px"
						isTruncated
					>
						<IconButton
							key={icon}
							onClick={handleToggle}
							variant="outline"
							size="sm"
							bg="white"
							color="gray.400"
							icon={<i className={"fas " + icon + " fa-fw fa-lg"}></i>}
						/>

						<Stack spacing={1} isTruncated>
							<Stack spacing={1} isTruncated maxW="305px" onClick={handleToggle}>
								<Heading
									color="gray.500"
									letterSpacing={1}
									textTransform="uppercase"
									size="sm"
									isTruncated
								>
									{employee.firstName + " " + employee.lastName}
								</Heading>
								<Text
									color="gray.500"
									fontWeight="semibold"
									fontSize="sm"
									textTransform="uppercase"
									letterSpacing={1}
									isTruncated
								>
									{employee.title}
								</Text>

								<Text color="gray.400" fontWeight="semibold" textTransform="uppercase" fontSize="sm">
									{(allDepts ? "All" : departmentCount) +
										" Department" +
										(departmentCount === 1 ? "" : "s") +
										",  " +
										(allRegions ? "All" : regionCount) +
										" Region" +
										(regionCount === 1 ? "" : "s")}
								</Text>
								<Collapse in={show} maxH="container.lg">
									<Stack px="0" py="1" spacing={1}>
										{departments?.map((department, i) => {
											let regions = department?.regions?.filter(
												(data) => data?.hasAccess === true
											);
											regions = regions?.filter((data) => data?.showRegion === true);

											return (
												<HStack spacing={2} align="center" rounded="5" maxW="305px" isTruncated>
													<Text
														color="gray.500"
														fontWeight="semibold"
														textTransform="uppercase"
														fontSize="xs"
														isTruncated
													>
														{department.dept}
													</Text>
													<HStack spacing={1} align="center" isTruncated>
														{regions?.map((regiontag, i) => (
															<Tag
																size="sm"
																p={1}
																rounded={5}
																color="white"
																textAlign="center"
																bg={
																	regionArr.find(
																		({ region }) => region === regiontag.region
																	).color
																}
																textTransform="uppercase"
															>
																{regiontag.region}
															</Tag>
														))}
													</HStack>
												</HStack>
											);
										})}
									</Stack>
								</Collapse>
							</Stack>
						</Stack>
					</HStack>
					<Collapse in={isHovering && show}>
						<Flex
							pt="2"
							maxW="305px"
							spacing={1}
							onClick={(ev) => {
								ev.stopPropagation();
								props.selectLeader(employee);
							}}
							justify="flex-start"
							fontWeight="semibold"
							cursor="pointer"
						>
							<Text
								_hover={{ color: "blue.500" }}
								color="gray.300"
								fontSize="xs"
								textTransform="uppercase"
								letterSpacing={1}
							>
								<i className={"fas fa-cog fa-fw"}></i> Edit Access
							</Text>
						</Flex>
					</Collapse>
				</Box>
			)}
		</>
	);
}

function DepartmentLeaderDrawer(props) {
	// const [selectedAccess, setSelectedAccess] = useState(props.selectedAccess);
	const [selectedLeader, setSelectedLeader] = useState(props.selectedLeader);
	const [regions] = useMemo(() => new RegionList(props.regionArr), [props.regionArr]);

	// let levels = props.levelsMap;
	// let defaultLevel = levels[0];
	// if (selectedAccess?.accessLevelDesc !== null) {
	// 	defaultLevel = selectedAccess?.accessLevelDesc;
	// } else if (selectedLeader?.employeeUID !== null) {
	// 	defaultLevel = selectedLeader?.accessLevelDesc;
	// }

	let employees = props.employeeHistory;

	let leaders = employees?.filter((data) => data?.employeeUID !== "AB653524-E7EE-40FA-B095-6A68D8BCEC27");

	const leaderDropdown = (leader) => {
		setSelectedLeader(leader);
	};

	// log('selectedLeader', selectedLeader)

	let departments = selectedLeader?.departments;
	departments = departments.filter((data) => data?.deptID !== 23);
	//log(departments, departments);
	//let parentDepts = departments.createDepartmentMap('parentDeptID')
	//let deptGroups = parentDepts.createDepartmentMap('deptGroupID')
	let deptGroups = departments.createDepartmentMap("deptGroupID");
	//log('selectedLeader', selectedLeader )
	//log('deptGroups',deptGroups)

	return (
		<Drawer placement="right" isOpen onClose={props.onClose} size="lg" key={"Drawer-" + 1}>
			<DrawerOverlay />

			<DrawerContent>
				<DrawerCloseButton />

				<DrawerHeader>
					<HStack align="center" w="full" spacing={2}>
						<IconButton variant="unstyled" color="gray.400" boxSize="30px" minW="20px">
							<i
								className={
									(selectedLeader?.employeeUID === null ? "fas fa-plus-square" : "fas fa-edit") +
									" fa-fw fa-lg"
								}
							/>
						</IconButton>
						<Heading as="h4" size="md" color="gray.400" textTransform="uppercase" letterSpacing={2}>
							{selectedLeader?.employeeUID === null ? "Add Department Leader" : "Edit Leader Access"}
						</Heading>
					</HStack>
				</DrawerHeader>

				<DrawerBody>
					<Stack spacing={2} w="full">
						<HStack align="center" w="full" spacing={1}>
							<IconButton variant="unstyled" color="gray.500" boxSize="30px" minW="20px">
								<i className="fas fa-shield-alt fa-fw fa-lg" />
							</IconButton>
							<Text
								h="full"
								fontSize="md"
								textTransform="uppercase"
								color="gray.500"
								fontWeight="600"
								letterSpacing={1}
							>
								Department Leader Access
							</Text>
						</HStack>

						<Stack
							p="4"
							spacing={4}
							bg="blackAlpha.50"
							border="2px"
							borderColor="blackAlpha.200"
							rounded="10"
						>
							<Stack spacing={2} direction="row">
								<DataInput
									key={"employeeUID-" + selectedLeader?.employeeUID}
									name="employeeUID"
									type="select"
									label="Employee Name"
									placeholder="Search Employees"
									searchable
									static
									value={selectedLeader?.employeeName}
									options={leaders}
									// onBlur={(ev) => { ev.stopPropagation(); props.updatePCO('pco', ev, true) }}
									// inputRef={pcoRef}
									// error={(pcoErrors.length > 0 ? pcoErrors[0] : false)}
									optionText="employeeName"
									optionSubheading="subheading"
									onOptionSelect={leaderDropdown}
									flex={2}
									variant="outline"
									inputProps={{ px: "2" }}
								/>
							</Stack>

							<Collapse in={Boolean(selectedLeader)}>
								<Stack spacing={6}>
									{deptGroups.map((deptGroup, i) => {
										let deptCount = deptGroup?.rows?.length;

										return (
											<GroupItem
												regions={regions}
												deptGroup={deptGroup}
												selectedLeader={selectedLeader}
												deptCount={deptCount}
												isOpen={Boolean(selectedLeader)}
											/>
										);
									})}
								</Stack>
							</Collapse>
						</Stack>
					</Stack>
				</DrawerBody>
				<DrawerFooter></DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}

function GroupItem(props) {
	let deptGroup = props.deptGroup;

	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => setIsOpen(!isOpen);

	let icon = isOpen ? "fa-minus" : "fa-plus";

	// bg={(isHovering ? 'blackAlpha.50' : '')}
	// onMouseEnter={setHovering.on} onMouseLeave={setHovering.off}

	useMemo(() => {
		let depts = deptGroup?.rows?.filter((data) => data?.hasAccess === true);
		let hasAccess = false;
		if (depts?.length > 0) {
			hasAccess = true;
		}
		setIsOpen(hasAccess);
	}, [deptGroup?.rows]);

	//log('DeptGroup',deptGroup)

	let deptCount = props.deptCount;
	let regions = props.regions;

	if (props.selectedLeader?.accessLevel === 1 || props.selectedLeader?.accessLevel === 2) {
		if (deptGroup?.deptGroupID !== 1) {
			//setIsOpen(false)
		}
	}
	// log('SELECTED LEADER', props.selectedLeader)

	return (
		<Stack spacing={1} key={deptGroup?.deptGroupID}>
			<Flex justify={"flex-start"} align="center">
				<IconButton
					key={icon}
					onClick={handleToggle}
					variant="unstyled"
					color="gray.500"
					size="sm"
					icon={<i className={"fas " + icon + " fa-fw fa-lg"}></i>}
				/>
				<Heading isTruncated size="sm" color="gray.500" textTransform="uppercase">
					{deptGroup?.deptGroupDesc}
				</Heading>
			</Flex>
			<Collapse in={isOpen}>
				<Stack spacing={4} bg="white" p="4" border="2px" borderColor="blackAlpha.200" rounded="10">
					<Stack spacing={4} onClick={handleToggle} _hover={{ cursor: "pointer" }}>
						<Stack spacing={1}>
							<Stack spacing={4}>
								<Stack spacing={1} key={deptGroup?.deptGroupID}>
									<Flex w="full" justify="flex-start" align="center">
										<Flex isTruncated flex={1} pr={6}>
											<Heading isTruncated size="sm" color="gray.500" textTransform="uppercase">
												{deptGroup?.deptGroup}
											</Heading>
										</Flex>

										<RowItem
											regions={regions}
											selectedLeader={props.selectedLeader}
											type="header"
										/>
									</Flex>
								</Stack>
							</Stack>
						</Stack>

						<DeptItem deptGroup={deptGroup} selectedLeader={props.selectedLeader} deptCount={deptCount} />
					</Stack>
				</Stack>
			</Collapse>
		</Stack>
	);
}

function DeptItem(props) {
	const [isOpen, setIsOpen] = React.useState(true);

	React.useMemo(() => setIsOpen(Boolean(props.selectedLeader)), [props.selectedLeader]);

	let departments = props.deptGroup?.rows;

	return (
		<Collapse in={isOpen}>
			<Stack spacing={4} pl={6}>
				{departments?.map((dept, i) => {
					let regions = dept?.regions;
					return (
						<Stack spacing={1} key={dept?.deptID}>
							<Flex w="full" justify="space-between" align="center">
								<Flex isTruncated flex={1}>
									<Heading isTruncated size="sm" color="gray.400" textTransform="uppercase">
										{dept?.dept}
									</Heading>
								</Flex>
								<RowItem regions={regions} selectedLeader={props.selectedLeader} type="line" />
							</Flex>
						</Stack>
					);
				})}
			</Stack>
		</Collapse>
	);
}

function RowItem(props) {
	let regions = props.regions;
	let type = props.type;

	return (
		<SimpleGrid columns={4} spacing={5} flex={1} minW="50px" px="2" align="center">
			{regions.map((region, i) => {
				let regionLabel = region?.region === "All Regions" ? "All" : region?.region;

				return (
					<Center minW="40px" align="center">
						<VStack spacing={1}>
							{type === "header" && (
								<Text fontWeight="bold" color={region?.color} textTransform="uppercase" fontSize="sm">
									{regionLabel}
								</Text>
							)}
							{type === "line" && (
								<Checkbox color={region?.color} isChecked={region?.hasAccess}></Checkbox>
							)}
						</VStack>
					</Center>
				);
			})}
		</SimpleGrid>
	);
}

function HistoryTable(props) {
	const title = props.row?.deptByRegion + (props.row?.deptByRegion === "Executive" ? "s" : "");
	const table = props.row?.deptByRegion + "-table";
	const employeeHistory = React.useMemo(() => props.row?.rows, [props.row?.rows]);

	const [selectedEmployee, selectEmployee] = React.useState(null);

	const closeModal = () => {
		selectEmployee(null);
	};
	const [showRates, onToggle] = useBoolean();
	const initEmployeeHistory = useCallback(() => {
		let employeeHistoryList = new EmployeeHistoryList();
		for (let i = 0; i < employeeHistory?.length; i++) {
			employeeHistoryList.push(employeeHistory[i]);
		}
		employeeHistoryList.sortEmployees();
		return employeeHistoryList;
	}, [employeeHistory]);

	const [history, setHistory] = React.useState([]);
	useMemo(() => {
		let employeeHistory = initEmployeeHistory();
		setHistory(employeeHistory);
	}, [initEmployeeHistory]);

	let topBottomBorder = {
		boxShadow: "inset 0 2px 0 #000000, inset 0 -2px 0 #000000, inset 2px 0 0 #000000, inset -2px 0 0 #000000",
	};
	let leftRightBorder = { boxShadow: "inset 2px 0 0 #000000, inset -2px 0 0 #000000, inset 0 -1px 0 #EDF2F7" };
	let leftBorder = { boxShadow: "inset 1px 0 0 #000000" };

	const handleProposalUpdate = async (ev, i) => {
		let name = ev.target.name;
		let value = ev.target.value;
		value = value.replace("'", "`");
		if (name !== "managerNotes") {
			value = value.replace("$", "");
			value = value.replace(",", "");
			value = value.replace("+", "");
			value = value.replace("%", "");
			value = parseFloat(value);
			if (isNaN(value)) {
				value = 0;
			}
		}
		let newHistory = history;

		newHistory[i]?.updateEmployeeRow(name, value);

		let tempHistory = new EmployeeHistoryList();
		Object.assign(tempHistory, newHistory);
		setHistory(tempHistory);
	};

	let proposalYear = props?.proposalYear - 1;

	let historyTotals = {};
	let pctIncreaseTotalsArr = {};
	let proposedIncreaseTotalsArr = {};
	if (history.length > 0) {
		historyTotals = history?.getEmployeeHistoryTotals();
		pctIncreaseTotalsArr = historyTotals.getPctIncrease(historyTotals.newSalary, historyTotals.oldSalary);
		proposedIncreaseTotalsArr = historyTotals.getPctIncrease(historyTotals.proposedSalary, historyTotals.newSalary);
	}

	let salaryTab = props.tabVal === 0;

	return (
		<Skeleton isLoaded={Boolean(history?.length > 0)} id={props.joyrideID ?? table + "-id"}>
			{Boolean(selectedEmployee) && (
				<HistoryModal
					proposalYear={proposalYear}
					isOpen={Boolean(selectedEmployee)}
					onClose={closeModal}
					selectedEmployee={selectedEmployee}
				/>
			)}
			<Flex flex={1} w="full" justify="space-between" align="center">
				<Heading textTransform="uppercase" letterSpacing={2} size="lg" color="gray.400" py={3}>
					{title}
				</Heading>

				<Spacer />
				{salaryTab && (
					<HStack spacing={2} align="center" justify="center" px="4">
						<Text fontSize="sm" textTransform="uppercase" color="gray.400" fontWeight="semibold">
							Show Rates
						</Text>
						<Switch colorScheme="teal" isChecked={showRates} onChange={onToggle.toggle} />
					</HStack>
				)}
			</Flex>
			<Box className="skinnyScroll" overflow="auto" maxH="container.lg" pr="1">
				<Table size="small" bg="white" top="0" left="0" color="gray.600" key={table}>
					<Thead
						zIndex="2"
						top={0}
						position="sticky"
						style={{ borderSpacing: "0", insetBlockStart: "0" }}
						border="0px"
					>
						<Tr textTransform="uppercase" bg={props.table?.deptColor ?? "teal.700"} style={topBottomBorder}>
							<Th w="100px" maxW="100px">
								<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="left">
									Region
								</Heading>
							</Th>
							<Th w="225px" minW="250px">
								<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="left">
									Employee Name
								</Heading>
							</Th>
							<Th w="100px" maxW="100px">
								<Heading fontSize="xs" color="white" isTruncated p="2" textAlign="left">
									Longevity
								</Heading>
							</Th>
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										Last&nbsp;Years Salary&nbsp;In&nbsp;{proposalYear - 1}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										Current Salary&nbsp;In&nbsp;{proposalYear}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="150px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										%&nbsp;Increase In&nbsp;{proposalYear}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="150px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										Proposed Salary&nbsp;for&nbsp;{proposalYear + 1}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="150px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										%&nbsp;Increase for&nbsp;{proposalYear + 1}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										Year&nbsp;End Bonus&nbsp;In&nbsp;{proposalYear - 1}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="150px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										Proposed Bonus&nbsp;for&nbsp;{proposalYear}
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										{proposalYear + 1}&nbsp;Phone Allowance
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										{proposalYear + 1}&nbsp;Vehicle Allowance
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										{proposalYear + 1}&nbsp;Housing Allowance
									</Heading>
								</Th>
							)}
							{salaryTab && (
								<Th w="125px" maxW="125px">
									<Heading fontSize="xs" color="white" p="2" textAlign="center">
										{proposalYear + 1}&nbsp;Total Yearly&nbsp;Comp
									</Heading>
								</Th>
							)}

							<Th>
								<Heading fontSize="xs" color="white" p="2" textAlign="left">
									Manager's Comments
								</Heading>
							</Th>
						</Tr>
					</Thead>

					<Tbody style={{ borderSpacing: "0", insetBlockEnd: "0" }} border="none">
						{history?.map((row, i) => {
							let pctIncreaseArr = row.getPctIncrease(row.newSalary, row.oldSalary);
							let proposedIncreaseArr = row.getPctIncrease(row.proposedSalary, row.newSalary);

							return (
								<Tr
									style={leftRightBorder}
									border="none"
									_hover={{ bg: "blackAlpha.50" }}
									key={row.keyID}
								>
									<Td px="2" style={leftBorder}>
										<Stack spacing={-1}>
											<Text isTruncated>{row.region}</Text>
											<Text isTruncated fontSize="xs">
												{row.employeeType}
											</Text>
										</Stack>
									</Td>
									<Td px="2" style={leftBorder}>
										<Stack spacing={-1}>
											<Text isTruncated>{row.firstName + " " + row.lastName}</Text>
											<Text isTruncated fontSize="xs">
												{row.title}
											</Text>
										</Stack>
									</Td>
									<Td px="2" style={leftBorder}>
										<Stack spacing={-1}>
											<Text isTruncated>{moment(row.hireDate).format("M/D/yyyy")}</Text>
											<Text isTruncated fontSize="xs">
												{row.getLongevity()}
											</Text>
										</Stack>
									</Td>
									{salaryTab && (
										<Td px="2" style={leftBorder} textAlign="right">
											<Stack spacing={-1}>
												<Text isTruncated>{formatValue(row.oldSalary, 0, "currency")}</Text>
												{showRates && (
													<Text isTruncated fontSize="xs">
														{formatValue(row.oldRate, 2, "currency") + "/hr"}
													</Text>
												)}
											</Stack>
										</Td>
									)}
									{salaryTab && (
										<Td px="2" style={leftBorder} textAlign="right">
											<Stack spacing={-1}>
												<Text isTruncated>{formatValue(row.newSalary, 0, "currency")}</Text>
												{showRates && (
													<Text isTruncated fontSize="xs">
														{formatValue(row.newRate, 2, "currency") + "/hr"}
													</Text>
												)}
											</Stack>
										</Td>
									)}
									{salaryTab && (
										<Td
											px="2"
											style={leftBorder}
											textAlign="right"
											onClick={(ev) => {
												ev.stopPropagation();
												selectEmployee(row);
											}}
											id={i === 0 && Boolean(props.joyrideID) ? "joyrideStep2" : null}
										>
											<Stack spacing={-1}>
												<Text
													isTruncated
													fontWeight="bold"
													color={pctIncreaseArr.color}
													_hover={{
														textDecoration: "underline",
														color: "blue.600",
														cursor: "pointer",
													}}
												>
													{pctIncreaseArr.symbol +
														formatValue(pctIncreaseArr.pctIncrease, 2, "percent")}
												</Text>
												{showRates && pctIncreaseArr.increase !== 0 && (
													<Text color={pctIncreaseArr.color} isTruncated fontSize="xs">
														{pctIncreaseArr.symbol +
															formatValue(pctIncreaseArr.increase, 0, "currency")}
													</Text>
												)}
											</Stack>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
											textAlign="right"
											id={i === 0 && Boolean(props.joyrideID) ? "joyrideStep3" : null}
										>
											<Stack spacing={-1}>
												<Input
													key={"proposedSalary-" + row.keyID + "-" + row.proposedSalary}
													// isDisabled={row.locked === "Y" || props.isDisabled}
													// cursor={
													// 	row.locked === "Y" || props.isDisabled
													// 		? "not-allowed"
													// 		: "pointer"
													// }
													_focus={{ fontWeight: "bold" }}
													focusBorderColor="teal.600"
													variant="unstyled"
													textAlign="right"
													placeholder="$0"
													defaultValue={formatValue(row.proposedSalary, 0, "currency")}
													// defaultValue={
													// 	formatValue(row.proposedSalary, 0, "currency") === "$0" &&
													// 	row.locked === "N"
													// 		? null
													// 		: formatValue(row.proposedSalary, 0, "currency")
													// }
													name="proposedSalary"
													onBlur={(ev) => {
														handleProposalUpdate(ev, i);
													}}
													autoComplete="off"
												/>
												{showRates && proposedIncreaseArr.increase !== 0 && (
													<Text isTruncated fontSize="xs">
														{formatValue(row.getProposedRate(), 2, "currency") + "/hr"}
													</Text>
												)}
											</Stack>
										</Td>
									)}

									{salaryTab && (
										<Td
											px="2"
											style={leftBorder}
											textAlign="right"
											id={i === 0 && Boolean(props.joyrideID) ? "joyrideStep4" : null}
										>
											<Stack spacing={-1}>
												<Input
													key={
														"proposedPctIncrease-" +
														row.keyID +
														"-" +
														row.getPctIncrease(row.proposedSalary, row.newSalary).symbol +
														row.getPctIncrease(row.proposedSalary, row.newSalary)
															.pctIncrease
													}
													isTruncated
													fontWeight="bold"
													color={proposedIncreaseArr.color}
													// isDisabled={row.locked === "Y" || props.isDisabled}
													// cursor={
													// 	row.locked === "Y" || props.isDisabled
													// 		? "not-allowed"
													// 		: "pointer"
													// }
													_focus={{ fontWeight: "bold" }}
													focusBorderColor="teal.600"
													variant="unstyled"
													textAlign="right"
													placeholder="0.00%"
													defaultValue={
														(formatValue(proposedIncreaseArr.pctIncrease, 2, "percent") ===
														"0.00%"
															? ""
															: proposedIncreaseArr.symbol) +
														formatValue(proposedIncreaseArr.pctIncrease, 2, "percent")
													}
													name="proposedPctIncrease"
													onBlur={(ev) => {
														handleProposalUpdate(ev, i);
													}}
													autoComplete="off"
												/>
												{showRates && proposedIncreaseArr.increase !== 0 && (
													<Text isTruncated fontSize="xs" color={proposedIncreaseArr.color}>
														{proposedIncreaseArr.symbol +
															formatValue(proposedIncreaseArr.increase, 0, "currency")}
													</Text>
												)}
											</Stack>
										</Td>
									)}
									{salaryTab && (
										<Td
											px="2"
											style={leftBorder}
											textAlign="right"
											id={i === 0 && Boolean(props.joyrideID) ? "joyrideStep8" : null}
										>
											<Text>{formatValue(row.bonusYE, 0, "currency")}</Text>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
										>
											<Input
												key={"proposedBonus-" + row.keyID + "-" + row.proposedBonus}
												// isDisabled={row.locked === "Y" || props.isDisabled}
												// cursor={
												// 	row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"
												// }
												_focus={{ fontWeight: "bold" }}
												focusBorderColor="teal.600"
												variant="unstyled"
												textAlign="right"
												placeholder="$0"
												defaultValue={
													formatValue(row.proposedBonus, 0, "currency") === "$0"
														? ""
														: formatValue(row.proposedBonus, 0, "currency")
												}
												name="proposedBonus"
												onBlur={(ev) => {
													handleProposalUpdate(ev, i);
												}}
												autoComplete="off"
											/>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
										>
											<Input
												key={"proposedCell-" + row.keyID + "-" + row.proposedCell}
												// isDisabled={row.locked === "Y" || props.isDisabled}
												// cursor={
												// 	row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"
												// }
												_focus={{ fontWeight: "bold" }}
												focusBorderColor="teal.600"
												variant="unstyled"
												textAlign="right"
												placeholder="$0"
												defaultValue={
													formatValue(row.proposedCell, 0, "currency") === "$0"
														? ""
														: formatValue(row.proposedCell, 0, "currency")
												}
												name="proposedCell"
												onBlur={(ev) => {
													handleProposalUpdate(ev, i);
												}}
												autoComplete="off"
											/>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
										>
											<Input
												key={"proposedVehicle-" + row.keyID + "-" + row.proposedVehicle}
												// isDisabled={row.locked === "Y" || props.isDisabled}
												// cursor={
												// 	row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"
												// }
												_focus={{ fontWeight: "bold" }}
												focusBorderColor="teal.600"
												variant="unstyled"
												textAlign="right"
												placeholder="$0"
												// defaultValue={formatValue(row.proposedVehicle, 0, "currency")}
												defaultValue={
													formatValue(row.proposedVehicle, 0, "currency") === "$0"
														? ""
														: formatValue(row.proposedVehicle, 0, "currency")
												}
												name="proposedVehicle"
												onBlur={(ev) => {
													handleProposalUpdate(ev, i);
												}}
												autoComplete="off"
											/>
										</Td>
									)}
									{salaryTab && (
										<Td
											style={leftBorder}
											// bg={row.locked === "Y" ? "blackAlpha.50" : "transparent"}
											px="2"
										>
											<Input
												key={"proposedHousing-" + row.keyID + "-" + row.proposedHousing}
												// isDisabled={row.locked === "Y" || props.isDisabled}
												// cursor={
												// 	row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"
												// }
												_focus={{ fontWeight: "bold" }}
												focusBorderColor="teal.600"
												variant="unstyled"
												textAlign="right"
												placeholder="$0"
												defaultValue={
													formatValue(row.proposedHousing, 0, "currency") === "$0"
														? ""
														: formatValue(row.proposedHousing, 0, "currency")
												}
												name="proposedHousing"
												onBlur={(ev) => {
													handleProposalUpdate(ev, i);
												}}
												autoComplete="off"
											/>
										</Td>
									)}
									{salaryTab && (
										<Td px="2" style={leftBorder} textAlign="right">
											<Text>{formatValue(row.getTotalYearlyComp(), 0, "currency")}</Text>
										</Td>
									)}

									<Td
										style={leftBorder}
										px="2"
										id={i === 0 && Boolean(props.joyrideID) ? "joyrideStep5" : null}
									>
										<Input
											key={"managerNotes-" + row.keyID + "-" + row.managerNotes}
											fontSize="sm"
											// isDisabled={row.locked === "Y" || props.isDisabled}
											// cursor={row.locked === "Y" || props.isDisabled ? "not-allowed" : "pointer"}
											_focus={{ fontWeight: "bold" }}
											focusBorderColor="teal.600"
											variant="unstyled"
											textAlign="left"
											placeholder="Add notes here"
											defaultValue={row.managerNotes}
											name="managerNotes"
											onBlur={(ev) => {
												handleProposalUpdate(ev, i);
											}}
											autoComplete="off"
										/>
									</Td>
								</Tr>
							);
						})}
					</Tbody>

					<Tfoot
						bottom={0}
						zIndex="2"
						position="sticky"
						style={{ borderCollapse: "collapse" }}
						bg="whitesmoke"
					>
						<Tr style={topBottomBorder} border="none" fontWeight="bold">
							<Td border="none" py="2" px="3" colspan={3}>
								<Text textTransform="uppercase" letterSpacing={1} isTruncated>
									{title + " Totals:"}
								</Text>
							</Td>
							{salaryTab && (
								<Td border="none" p="2">
									<Text textTransform="uppercase" isTruncated textAlign="right">
										{formatValue(historyTotals.oldSalary, 0, "currency")}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text textTransform="uppercase" isTruncated textAlign="right">
										{formatValue(historyTotals.newSalary, 0, "currency")}
									</Text>
								</Td>
							)}

							{salaryTab && (
								<Td border="none" p="2" textAlign="right">
									<Stack spacing={-1}>
										<Text
											textTransform="uppercase"
											isTruncated
											textAlign="right"
											color={pctIncreaseTotalsArr.color}
										>
											{pctIncreaseTotalsArr.symbol +
												formatValue(pctIncreaseTotalsArr.pctIncrease, 2, "percent")}
										</Text>
										{showRates && pctIncreaseTotalsArr.increase !== 0 && (
											<Text isTruncated fontSize="xs" color={pctIncreaseTotalsArr.color}>
												{pctIncreaseTotalsArr.symbol +
													formatValue(pctIncreaseTotalsArr.increase, 0, "currency")}
											</Text>
										)}
									</Stack>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2" textAlign="right">
									<Text textTransform="uppercase" isTruncated textAlign="right">
										{formatValue(historyTotals.proposedSalary, 0, "currency")}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2" textAlign="right">
									<Stack spacing={-1}>
										<Text
											textTransform="uppercase"
											isTruncated
											textAlign="right"
											color={proposedIncreaseTotalsArr.color}
										>
											{proposedIncreaseTotalsArr.symbol +
												formatValue(proposedIncreaseTotalsArr.pctIncrease, 2, "percent")}
										</Text>
										{showRates && proposedIncreaseTotalsArr.increase !== 0 && (
											<Text isTruncated fontSize="xs" color={proposedIncreaseTotalsArr.color}>
												{proposedIncreaseTotalsArr.symbol +
													formatValue(proposedIncreaseTotalsArr.increase, 0, "currency")}
											</Text>
										)}
									</Stack>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text textTransform="uppercase" isTruncated textAlign="right">
										{formatValue(historyTotals.bonusYE, 0, "currency")}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text textTransform="uppercase" isTruncated textAlign="right">
										{formatValue(historyTotals.proposedBonus, 0, "currency")}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text textTransform="uppercase" isTruncated textAlign="right">
										{formatValue(historyTotals.proposedCell, 0, "currency")}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text textTransform="uppercase" isTruncated textAlign="right">
										{formatValue(historyTotals.proposedVehicle, 0, "currency")}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text textTransform="uppercase" isTruncated textAlign="right">
										{formatValue(historyTotals.proposedHousing, 0, "currency")}
									</Text>
								</Td>
							)}
							{salaryTab && (
								<Td border="none" p="2">
									<Text textTransform="uppercase" isTruncated textAlign="right">
										{formatValue(historyTotals.totalYearlyComp, 0, "currency")}
									</Text>
								</Td>
							)}
							<Td border="none" p="2">
								<Text textTransform="uppercase" isTruncated></Text>
							</Td>
						</Tr>
					</Tfoot>
				</Table>
			</Box>
		</Skeleton>
	);
}

function HistoryModal(props) {
	let history = props.selectedEmployee?.history;
	let topBottomBorder = {
		boxShadow: "inset 0 2px 0 #000000, inset 0 -2px 0 #000000, inset 2px 0 0 #000000, inset -2px 0 0 #000000",
	};
	let bottomBorder = { boxShadow: "inset 0 -2px 0 #000000, inset 2px 0 0 #000000, inset -2px 0 0 #000000" };
	let leftRightBorder = { boxShadow: "inset 2px 0 0 #000000, inset -2px 0 0 #000000, inset 0 -1px 0 #EDF2F7" };
	let leftBorder = { boxShadow: "inset 1px 0 0 #000000" };

	return (
		<Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
			<ModalOverlay />
			<ModalContent minW="2xl">
				<ModalHeader>{props.selectedEmployee?.firstName + " " + props.selectedEmployee?.lastName}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box className="skinnyScroll" overflowY="auto" maxH="md" pr="1">
						<Table size="small" bg="white" top="0" left="0" color="gray.600">
							<Thead
								zIndex="2"
								top={0}
								position="sticky"
								style={{ borderSpacing: "0", insetBlockStart: "0" }}
								border="0px"
							>
								<Tr textTransform="uppercase" bg="teal.700" style={topBottomBorder}>
									<Th minW="120px" maxW="120px" w="120px">
										<Heading fontSize="md" color="white" isTruncated p="2" textAlign="center">
											Year
										</Heading>
									</Th>
									<Th minW="120px" maxW="120px" w="120px">
										<Heading fontSize="md" color="white" isTruncated p="2" textAlign="center">
											Salary
										</Heading>
									</Th>
									<Th minW="120px" maxW="120px" w="120px">
										<Heading fontSize="md" color="white" isTruncated p="2" textAlign="center">
											Increase&nbsp;(%)
										</Heading>
									</Th>
									<Th minW="120px" maxW="120px" w="120px">
										<Heading fontSize="md" color="white" p="2" textAlign="center">
											Year&nbsp;End Bonus
										</Heading>
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{history?.map((row, i) => {
									let pctIncreaseArr = row.getPctIncrease(row.newSalary, row.oldSalary);

									return (
										<Tr
											style={leftRightBorder}
											border="none"
											_hover={{ bg: "blackAlpha.50" }}
											key={row.year}
										>
											<Td px="2" style={leftBorder}>
												<Stack spacing={-1}>
													<Text isTruncated fontSize="lg">
														{row.year}
													</Text>
													<Text isTruncated fontSize="xs">
														{row.description}
													</Text>
												</Stack>
											</Td>
											<Td px="2" style={leftBorder} textAlign="right">
												<Stack spacing={-1}>
													<Text isTruncated fontSize="lg">
														{formatValue(row.newSalary, 0, "currency")}
													</Text>
													{row.increaseDate !== null && (
														<Text isTruncated fontSize="xs">
															{moment(new Date(row.increaseDate)).format("M/D/yy")}
														</Text>
													)}
												</Stack>
											</Td>
											<Td px="2" style={leftBorder} textAlign="right">
												<Stack spacing={-1}>
													<Text
														isTruncated
														fontSize="lg"
														fontWeight="bold"
														color={pctIncreaseArr.color}
													>
														{pctIncreaseArr.symbol +
															formatValue(pctIncreaseArr.pctIncrease, 2, "percent")}
													</Text>
													{pctIncreaseArr.increase !== 0 && (
														<Text isTruncated fontSize="xs" color={pctIncreaseArr.color}>
															{pctIncreaseArr.symbol +
																formatValue(pctIncreaseArr.increase, 0, "currency")}
														</Text>
													)}
												</Stack>
											</Td>
											<Td px="2" style={leftBorder} textAlign="right">
												<Stack spacing={-1}>
													{row.year !== props.proposalYear && (
														<Text isTruncated fontSize="lg">
															{formatValue(row.bonusYE, 0, "currency")}
														</Text>
													)}
													{row.year === props.proposalYear && (
														<Text as="i" isTruncated fontSize="lg">
															TBD
														</Text>
													)}
													{row.bonusDate !== null && (
														<Text isTruncated fontSize="xs">
															{moment(new Date(row.bonusDate)).format("M/D/yy")}
														</Text>
													)}
												</Stack>
											</Td>
										</Tr>
									);
								})}
							</Tbody>
							<Tfoot
								bottom={0}
								zIndex="2"
								position="sticky"
								style={{ borderCollapse: "collapse" }}
								bg="whitesmoke"
							>
								<Tr style={bottomBorder} border="none" fontWeight="bold">
									<Td border="none" px="2" colspan={4}></Td>
								</Tr>
							</Tfoot>
						</Table>
					</Box>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="teal" onClick={props.onClose} textTransform="uppercase">
						Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
