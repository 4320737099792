import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Stack, Flex, useBoolean } from "@chakra-ui/react";

import Section from "../../../core/Reports/components/Section";
import SectionObj from "../../../core/Reports/classes/Section";
import Page from "../../Contracts/common/Page";
import LoadingSection from "../../../core/ProgressIndicators/components/LoadingSection";
import DiversityVendorDrawer from "../components/DiversityVendorDrawer";
import SectionTemplateVendors from "../classes/SectionTemplateVendors";
import Operations from "../../Operations/classes/Operations";

import { portalUser } from "../../../App";

import { convertArrayToMap, log } from "../../../helperFunctions";

export default function DiversityVendorPage() {
	const [selectedVendor, setSelectedVendor] = useState(null);
	const [updateTrigger, setUpdateTrigger] = useState(false);

	const [operations, setOperations] = useState(portalUser?.operations ?? null);
	//DATA IS LOADING FLAG AND CALLBACK FUNCTION
	const [isLoading, setIsLoading] = useBoolean(true);
	const setDataLoading = useCallback(
		async function (callback) {
			if (!isLoading) {
				setIsLoading.on();
			}
			callback();
		},
		[isLoading, setIsLoading]
	);

	//ON PAGE LOAD INIT Operations Data DATA
	const initOperationsData = useCallback(async () => {
		let msUserID = portalUser.user.msUserID ?? portalUser?.msUser?.id ?? null;
		let params = { msUserID };
		let newOperations = operations;
		if (Boolean(msUserID) && !Boolean(newOperations?.operationsAPI)) {
			newOperations = new Operations(portalUser?.operations);
			newOperations = await newOperations?.initOperations(params);
		}
		if (Boolean(newOperations?.operationsAPI)) {
			newOperations = await newOperations.getVendors(params);
			newOperations = await newOperations.getDiversityCerts(params);
		}

		setOperations(newOperations);
		setIsLoading.off();
	}, [operations, setIsLoading]);

	useEffect(() => {
		setDataLoading(() => initOperationsData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Update portalUser operations cache
	useMemo(() => {
		if (Boolean(operations) && !isLoading) {
			portalUser.operations = operations;
			log("portalUser.vendors has been Updated", portalUser);
		}
	}, [isLoading, operations]);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const saveVendor = async () => {
		setSelectedVendor(null);
	};

	const closeVendor = () => {
		setSelectedVendor(null);
	};

	const deleteVendorCert = useCallback(
		async (keyID) => {
			let newVendor = selectedVendor;
			await newVendor.deleteDiversityCert(keyID);
			setSelectedVendor(newVendor);
			setUpdateTrigger(true);
		},
		[setSelectedVendor, selectedVendor]
	);

	const selectVendor = useCallback(
		(vendor) => {
			if (Boolean(vendor?.apvmKeyID) && Boolean(operations)) {
				let certs = operations?.diversityCerts ?? [];
				certs = certs.filter((d) => d.vendorGroup === vendor?.vendorGroup) ?? [];
				let certMap = convertArrayToMap(certs, "vendorID", true);
				if (certMap[vendor?.vendorID]?.length > 0) {
					vendor.diversityCerts = certMap[vendor?.vendorID] ?? [];
				}
				setSelectedVendor(vendor);
			}
		},
		[operations, setSelectedVendor]
	);

	return (
		<Flex
			p={portalUser.styles?.pagePadding}
			bg={portalUser.styles?.pageBG}
			direction="column"
			flex={1}
			justify="flex-start"
			w="full"
			h="full"
		>
			<Stack align="flex-start" w="full" h="full" flex={1} spacing={portalUser.styles?.pageSpacing}>
				{Boolean(selectedVendor) && (
					<DiversityVendorDrawer
						selectedVendor={selectedVendor}
						isOpen={Boolean(selectedVendor)}
						closeVendor={closeVendor}
						saveVendor={saveVendor}
						deleteVendorCert={deleteVendorCert}
					/>
				)}
				{isLoading ? (
					<LoadingSection />
				) : (
					<Page
						minH="6xl"
						maxW="full"
						title={"Diversity Vendors"}
						hideDropdown={true}
						// printForm={printForm}
					>
						<Section
							section={
								new SectionObj(
									new SectionTemplateVendors("VendorDiversity", operations?.vendors, isLoading)
								)
							}
							selectedRow={selectedVendor}
							setSelectedRow={selectVendor}
						/>
					</Page>
				)}
			</Stack>
		</Flex>
	);
}

// 	return (
// 		<Flex bg="blackAlpha.500" direction="column" flex={1} justify="flex-start" w="full" h="full" py={4} px={6}>
// 			<Tabs
// 				tabs={tabs}
// 				tabVal={tabVal}
// 				changeTab={changeTab}
// 				viewerMaxHeight={viewerMaxHeight}
// 				setViewerMaxHeight={setViewerMaxHeight}
// 			>
// 				<VStack spacing={8} w="full">
// 					<Container
// 						maxW={"full"}
// 						w="full"
// 						bg="white"
// 						rounded="5"
// 						shadow="xl"
// 						minH={"8xl"}
// 						px={8}
// 						py={8}
// 						display="flex"
// 						flexDirection="column"
// 						flex={1}
// 					>
// 						<Flex direction="column" align="flex-start" w="full" h="full" flex={1}>
// 							<PageHeader closeForm={closeForm} printForm={printForm} title={tabs[tabVal].header} />
// 							{Boolean(selectedVendor) && (
// 								<DiversityVendorDrawer
// 									selectedVendor={selectedVendor}
// 									isOpen={Boolean(selectedVendor)}
// 									closeVendor={closeVendor}
// 									saveVendor={saveVendor}
// 									deleteVendorCert={deleteVendorCert}
// 								/>
// 							)}

// 							<Section
// 								section={vendors}
// 								selectedRow={selectedVendor}
// 								setSelectedRow={setSelectedVendor}
// 							/>
// 						</Flex>
// 					</Container>
// 				</VStack>
// 			</Tabs>
// 		</Flex>
// 	);
// }

// function PageHeader(props) {
// 	const region = useMemo(() => {
// 		let regionID = props.user?.regionID ?? portalUser.user?.regionID ?? "0";
// 		let regionMap = convertArrayToMap(portalUser?.getRegions(), "regionID");
// 		return regionMap[regionID];
// 	}, [props.user?.regionID]);

// 	return (
// 		<Box spacing={1} w="full" divider={<Divider borderColor={"gray.600"} borderBottomWidth={"1px"} opacity={1} />}>
// 			<Flex w="full" justify="space-between">
// 				<PPMLogo color={"teal.700"} h="55px" minW="225px" />

// 				<VStack spacing={2} flex={1} px="4" py="2" align="center" minH="75px">
// 					<Heading
// 						as={"h1"}
// 						textAlign="center"
// 						fontSize={"3xl"}
// 						letterSpacing={4}
// 						w="full"
// 						color={"gray.500"}
// 						textTransform="uppercase"
// 						isTruncated
// 					>
// 						{props.title}
// 					</Heading>
// 				</VStack>

// 				<Flex minW="225px" justify="flex-end">
// 					<HStack align="flex-start" spacing={2}>
// 						<Tooltip label="Print/Download" hasArrow={true} placement="top">
// 							<IconButton
// 								onClick={props.printForm}
// 								aria-label="printPDF"
// 								variant="ghost"
// 								color="gray.400"
// 								icon={<i className="fas fa-print fa-lg" />}
// 							></IconButton>
// 						</Tooltip>

// 						<Tooltip label="Close Report" hasArrow={true} placement="top">
// 							<IconButton
// 								onClick={props.closeForm}
// 								variant="ghost"
// 								color="gray.400"
// 								icon={<i className="far fa-times-circle fa-lg " />}
// 							/>
// 						</Tooltip>
// 					</HStack>
// 				</Flex>
// 			</Flex>

// 			<Flex w="full" flex={1} justify="center" py={2} borderTopWidth={"2px"} borderTopColor={"gray.400"} px="6">
// 				<HStack
// 					color={"gray.600"}
// 					spacing={6}
// 					w="full"
// 					justify="center"
// 					align="center"
// 					divider={
// 						<Text px="6" fontSize="xs">
// 							<i className="fas fa-circle fa-xs"></i>
// 						</Text>
// 					}
// 				>
// 					<Text fontSize="md" isTruncated>
// 						{region?.address ?? "18250 Euclid Street"}
// 					</Text>
// 					<Text fontSize="md" isTruncated>
// 						{region?.city ?? "Fountain Valley"}, {region?.state ?? "CA"} {region?.zip ?? "92708"}
// 					</Text>
// 					<Text fontSize="md" isTruncated>
// 						Phone: {region?.phone ?? "(949) 474-9170"}
// 					</Text>
// 					<Text fontSize="md" isTruncated>
// 						Fax: {region?.fax ?? "(949) 474-9936"}
// 					</Text>
// 				</HStack>
// 			</Flex>
// 		</Box>
// 	);
// }
