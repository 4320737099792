import React, { useState, useMemo, useCallback, useRef } from "react";
import { useAsyncDebounce } from "react-table";
import {
	Box,
	Flex,
	Button,
	IconButton,
	Skeleton,
	HStack,
	Tooltip,
	Stack,
	Text,
	useBoolean,
	useToast,
} from "@chakra-ui/react";
import FormPageHeader from "./FormPageHeader";
import FormSectionEmployeeInfo from "./FormSectionEmployeeInfo";
import FormSection from "./FormSection";
import FormPageStepper from "./FormPageStepper";
import FormClass from "../classes/Form";
import PPMLogo from "../../Logos/components/PPMLogo";
import MAC5Logo from "../../Logos/components/MAC5Logo";
import FORM_STATUSES from "../constants/FormStatuses";

import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { portalUser } from "../../../App";
import { getDeviceType, convertArrayToMap, formatValue, log } from "../../../helperFunctions";

const statusMap = convertArrayToMap(FORM_STATUSES, "id");

export default function FormPage(props) {
	let ref = useRef(false);
	const toast = useToast();

	const [commenter, setCommenter] = useState(null);
	const formData = useMemo(() => props.formData ?? null, [props.formData]);
	const panelVal = useMemo(() => props.panelVal ?? 0, [props.panelVal]);
	const [form, setForm] = useState(formData?.forms[panelVal] ?? null);
	useMemo(() => setForm(formData?.forms[panelVal] ?? null), [formData?.forms, panelVal]);

	let usersMap = convertArrayToMap(portalUser?.getUsers(), "employeeUID");
	const formPageLogo = useMemo(() => {
		let assignedFor = usersMap[form?.assignedFor] ?? null;
		if (assignedFor?.prco === "250") {
			return <MAC5Logo color={"blue.600"} px="2" h="16" />;
		} else {
			return <PPMLogo color={"teal.600"} h="16" />;
		}
	}, [form?.assignedFor, usersMap]);

	const [pageVal, setPageVal] = useState(0);
	const setPage = useCallback((pageVal = 0) => {
		setPageVal(pageVal);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const pages = useMemo(() => {
		let pages = [];
		if (Boolean(formData && form?.formUID)) {
			pages = formData?.getFormPages(form?.formUID) ?? [];
		}
		return pages;
	}, [form?.formUID, formData]);

	let isLoaded = useMemo(() => Boolean(!props?.isLoading && pages?.length > 0), [pages?.length, props?.isLoading]);
	let [isPrinting, setIsPrinting] = useBoolean(false);

	const steps = useMemo(() => {
		let steps = pages?.map((m) => m.heading) ?? [];
		steps = steps?.filter((d) => Boolean(d)) ?? [];
		if (steps.length === 0) {
			steps.push(form?.title);
		}
		return steps;
	}, [pages, form?.title]);

	const printSections = useMemo(() => {
		let sections = [];
		if (Boolean(formData && form?.formUID)) {
			sections = formData?.getFormSections(form?.formUID) ?? [];
		}
		return sections;
	}, [form?.formUID, formData]);

	const sections = useMemo(() => {
		let sections = printSections;
		if (!isPrinting && Boolean(sections?.length > 0)) {
			let sectionUIDs = pages[pageVal]?.sectionUIDs ?? [];
			sections = printSections?.filter((d) => sectionUIDs?.includes(d.sectionUID)) ?? [];
		}
		return sections;
	}, [isPrinting, pageVal, pages, printSections]);

	const isTesting = useMemo(() => props.isTesting ?? false, [props.isTesting]);
	const isEditing = useMemo(() => props.isEditing ?? false, [props.isEditing]);
	const isComparing = useMemo(() => props.isComparing ?? false, [props.isComparing]);

	var printFontColor = isPrinting ? "black" : "var(--chakra-colors-gray-600)";

	var formStatus = form?.getFormStatus();
	var peerStatus = form?.getPeerStatus();

	const updateForm = useAsyncDebounce(async (ev, attr = null) => {
		let result;
		if (Boolean(attr)) {
			if (!Boolean(ev)) {
				result = await props.form.updateForm(attr, null);
			} else if (typeof ev === "object") {
				result = await props.form.updateForm(attr, ev.target.value);
			} else if (typeof ev === "string") {
				result = await props.form.updateForm(attr, ev);
			}
			result = new FormClass(result);
			setForm(result);
			//TO DO: Maybe add a timeout with confirmation message
			if (attr === "dateSubmitted" && Boolean(result?.dateSubmitted) && props.closeForm) {
				props.closeForm();
			}
		}
	}, 200);

	const updateFormCommenterStatus = useAsyncDebounce(async () => {
		if (commenter) {
			let newStatus = commenter.commenterStatus === 1 ? 0 : 1;
			await commenter.updateFormCommenter("commenterStatus", newStatus.toString());
			props.closeForm();
			toast({
				title: "Comments Completed!",
				description: "Please refresh the page to see your updates.",
				status: "Success",
				duration: 5000,
				isClosable: true,
			});
		}
	}, 200);

	useMemo(() => {
		let commentersMap = convertArrayToMap(portalUser.formData?.commenters, "formUID", true);
		let tempCommenter = commentersMap[form.formUID.toString()]?.find(
			(commenter) => commenter.employeeUID === portalUser.user.employeeUID
		);
		setCommenter(tempCommenter);
		// form.validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.formUID]);

	// const errorArray = useMemo(() => form.validateForm() ?? null, [form, formData.inputs]);

	// log("form.validateForm()", errorArray);
	return (
		<Stack spacing={6} w="full" flex={1} align="center" justify="center">
			<Skeleton isLoaded={isLoaded} rounded="md" shadow="xl" w="full">
				<Box
					bg="white"
					px={8}
					py={8}
					w="full"
					h="full"
					rounded="md"
					shadow={isPrinting ? "none" : "xl"}
					ref={ref}
					style={{ color: printFontColor }}
				>
					<Flex justify="space-between" w="full">
						{formPageLogo}

						{!isPrinting && (
							<HStack justify="flex-end" align="flex-start">
								{props.testingButton && props.testingButton}
								{!Boolean(getDeviceType() === "mobile") && (
									<ReactToPrint
										content={() => ref.current}
										onBeforeGetContent={() => setIsPrinting.on()}
										onAfterPrint={() => setIsPrinting.off()}
										copyStyles={true}
										documentTitle={form?.formTitle + " " + form?.formSubtitle}
									>
										<PrintContextConsumer>
											{({ handlePrint }) => (
												<Tooltip label="Print/Download" hasArrow={true} placement="top">
													<IconButton
														id="button-printform"
														variant="ghost"
														color="gray.400"
														mr={2}
														icon={<i className="fas fa-print fa-lg" />}
														onClick={() => {
															setTimeout(handlePrint, 1000);
														}}
													></IconButton>
												</Tooltip>
											)}
										</PrintContextConsumer>
									</ReactToPrint>
								)}
								{/* {props.editButton && props.editButton} */}
								{props.closeForm && (
									<Tooltip label="Close" hasArrow={true} placement="top">
										<IconButton
											variant="ghost"
											color="gray.400"
											icon={<i className="far fa-times-circle fa-lg " />}
											onClick={props.closeForm}
										/>
									</Tooltip>
								)}
							</HStack>
						)}
					</Flex>

					<Flex w="full" justify="center">
						<FormPageHeader form={form} usersMap={usersMap} isPrinting={isPrinting} isTesting={isTesting} />
					</Flex>
					<Box
						w="full"
						justify="flex-start"
						rounded="md"
						minH="8xl"
						// bg="blue.200"
					>
						{pages?.map((page, p) => (
							<Box
								key={p}
								w="full"
								justify="flex-start"
								rounded="md"
								px={6}
								pb={6}
								// bg="blue.200"
							>
								{p === (isPrinting ? 0 : pageVal) && (
									<Stack
										id={"formPage-" + p}
										key={"formPage-" + p}
										w="full"
										spacing={6}
										// bg="green.100"
										rounded={"md"}
										className={p > 0 ? "page-break" : ""}
									>
										{form?.formType === "2" && p === 0 && (
											<FormSectionEmployeeInfo
												form={form}
												usersMap={usersMap}
												isPrinting={isPrinting}
											/>
										)}

										{sections?.map((section, s) => (
											<FormSection
												key={s}
												formData={formData}
												form={form}
												section={section}
												usersMap={usersMap}
												//VIEWS

												isPrinting={isPrinting}
												isEditing={isEditing}
												isComparing={isComparing}
											/>
										))}
									</Stack>
								)}
							</Box>
						))}
					</Box>
				</Box>
			</Skeleton>
			{isLoaded && !isPrinting && (
				<HStack spacing={4} w="full" align="center" justify="center">
					{pageVal > 0 && (
						<Button
							size="lg"
							colorScheme="teal"
							onClick={() => {
								setPage(pageVal - 1);
							}}
						>
							<HStack
								spacing={3}
								align="center"
								color="white"
								textTransform={"uppercase"}
								fontWeight="bold"
								textShadow={"1px 1px 2px var(--chakra-colors-teal-800)"}
								letterSpacing={1}
							>
								<Text className={"fas fa-arrow-left"}></Text>
								<Text>Back</Text>
							</HStack>
						</Button>
					)}
					{steps?.length > 1 && pageVal < steps?.length - 1 && (
						<Button
							size="lg"
							colorScheme="teal"
							onClick={() => {
								setPage(pageVal + 1);
							}}
						>
							<HStack
								spacing={3}
								align="center"
								color="white"
								textTransform={"uppercase"}
								fontWeight="bold"
								textShadow={"1px 1px 2px var(--chakra-colors-teal-800)"}
								letterSpacing={1}
							>
								<Text>Next</Text>
								<Text className={"fas fa-arrow-right"}></Text>
							</HStack>
						</Button>
					)}

					{((form?.assignedTo === portalUser.user.employeeUID && form?.formType === "1") ||
						(form?.requiresHRApprovalYN === "Y" &&
							form?.assignedTo === portalUser.user.employeeUID &&
							form?.formType === "2") ||
						(form?.assignedTo === portalUser.user.employeeUID &&
							form?.formType === "3" &&
							form?.formUID !== form?.parentFormUID) ||
						(portalUser?.user?.isPortalAdminYN === "Y" &&
							form?.assignedFor !== portalUser.user?.employeeUID &&
							["1", "2"]?.includes(form?.formType)) ||
						(portalUser?.user?.isPortalAdminYN === "Y" &&
							form?.assignedFor !== portalUser.user?.employeeUID &&
							form?.formType === "3" &&
							form?.formUID !== form?.parentFormUID)) &&
						pageVal === steps?.length - 1 && (
							<Button
								size="lg"
								px={3}
								colorScheme={statusMap["2"].color}
								isDisabled={
									(parseInt(formStatus?.id) >= 2 && portalUser.user?.isPortalAdminYN === "N") ||
									parseInt(formStatus?.id) >= 5
								}
								onClick={() =>
									updateForm(
										Boolean(form?.dateSubmitted) ? null : formatValue(new Date(), 0, "date"),
										"dateSubmitted"
									)
								}
							>
								<HStack
									spacing={2}
									align="center"
									color="white"
									textTransform={"uppercase"}
									fontWeight="bold"
									textShadow={"1px 1px 2px var(--chakra-colors-" + statusMap["2"].color + "-800)"}
									letterSpacing={1}
									pl={0}
									key={
										parseInt(formStatus?.id) >= 2 &&
										portalUser.user?.isPortalAdminYN === "Y" &&
										parseInt(formStatus?.id) < 5
											? "fas fa-history"
											: statusMap["2"].icon
									}
								>
									<Text
										className={
											parseInt(formStatus?.id) >= 2 &&
											portalUser.user?.isPortalAdminYN === "Y" &&
											parseInt(formStatus?.id) < 5
												? "fas fa-history"
												: statusMap["2"].icon
										}
									/>
									<Text>
										{parseInt(formStatus?.id) < 2
											? "Submit"
											: portalUser.user?.isPortalAdminYN === "Y" && parseInt(formStatus?.id) < 3
											? "Un-Submit"
											: statusMap["2"].status}
									</Text>
								</HStack>
							</Button>
						)}

					{commenter && (
						<Button
							size="lg"
							px={3}
							colorScheme={statusMap["1"].color}
							isDisabled={parseInt(formStatus?.id) >= 3 || commenter?.commenterStatus === 1}
							onClick={() => updateFormCommenterStatus()}
						>
							<HStack
								spacing={2}
								align="center"
								color="white"
								textTransform={"uppercase"}
								fontWeight="bold"
								textShadow={"1px 1px 2px var(--chakra-colors-" + statusMap["1"].color + "-800)"}
								letterSpacing={1}
								pl={0}
								key="far fa-comment"
							>
								<Text className={"far fa-comment"} />
								<Text>Comments Done</Text>
							</HStack>
						</Button>
					)}

					{form?.requiresHRApprovalYN === "Y" &&
						portalUser.user.isPortalAdminYN === "Y" &&
						parseInt(formStatus?.id) > 1 &&
						parseInt(formStatus?.id) < 5 && (
							<Button
								size="lg"
								px={3}
								colorScheme={statusMap["3"].color}
								isDisabled={parseInt(formStatus?.id) < 2}
								onClick={() =>
									updateForm(
										Boolean(form?.dateApproved) ? null : formatValue(new Date(), 0, "date"),
										"dateApproved"
									)
								}
							>
								<HStack
									spacing={2}
									align="center"
									color="white"
									textTransform={"uppercase"}
									fontWeight="bold"
									textShadow={"1px 1px 2px var(--chakra-colors-" + statusMap["3"].color + "-800)"}
									letterSpacing={1}
									pl={1}
									key={
										parseInt(formStatus?.id) >= 3 &&
										parseInt(formStatus?.id) < 5 &&
										portalUser.user?.isPortalAdminYN === "Y"
											? "fas fa-thumbs-down"
											: statusMap["3"].icon
									}
								>
									<Text
										className={
											parseInt(formStatus?.id) >= 3 &&
											parseInt(formStatus?.id) < 5 &&
											portalUser.user?.isPortalAdminYN === "Y"
												? "fas fa-thumbs-down"
												: statusMap["3"].icon
										}
									/>
									<Text>{parseInt(formStatus?.id) === 2 ? "Approve" : "Un-Approve"}</Text>
								</HStack>
							</Button>
						)}

					{form?.formType === "3" &&
						form?.formUID === form?.parentFormUID &&
						peerStatus?.completedEvals === peerStatus?.totalEvals &&
						[form?.assignedTo, form?.signedOffBy]?.includes(portalUser.user.employeeUID) &&
						parseInt(formStatus?.id) > 2 &&
						parseInt(formStatus?.id) < 5 && (
							<Button
								size="lg"
								px={3}
								colorScheme={statusMap["3"].color}
								isDisabled={parseInt(formStatus?.id) < 3}
								onClick={() =>
									updateForm(
										Boolean(form?.dateCompleted) ? null : formatValue(new Date(), 0, "date"),
										"dateCompleted"
									)
								}
							>
								<HStack
									spacing={2}
									align="center"
									color="white"
									textTransform={"uppercase"}
									fontWeight="bold"
									textShadow={"1px 1px 2px var(--chakra-colors-" + statusMap["5"].color + "-800)"}
									letterSpacing={1}
									pl={1}
									key={statusMap["5"].icon}
								>
									<Text className={statusMap["5"].icon} />
									<Text>{parseInt(formStatus?.id) === 5 ? "Completed" : "Complete"}</Text>
								</HStack>
							</Button>
						)}
				</HStack>
			)}

			{steps?.length > 1 && <FormPageStepper steps={steps} pageVal={pageVal} setPageVal={setPageVal} />}
		</Stack>
	);
}
