import React from "react";
import { Calendar as ReactCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import parse from "html-react-parser";
import {
	Flex,
	SimpleGrid,
	IconButton,
	Box,
	Heading,
	Text,
	Button,
	HStack,
	Center,
	Stack,
	VStack,
	StackDivider,
	Tabs,
	TabPanels,
	Tab,
	TabPanel,
	TabList,
	Container,
} from "@chakra-ui/react";

import UsersAPI from "../../../APIs/UsersAPI";
import { portalUser } from "../../../App";
// import CalendarEvent from "../../AppData/User/CalendarEvent";

import { FaUser, FaUsers, FaUserFriends, FaBan, FaGift, FaGlassCheers, FaTree } from "react-icons/fa";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdRadioButtonChecked, MdRadioButtonUnchecked, MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";

import { IoOptionsOutline } from "react-icons/io5";

import CompanyHolidaysCard from "./CompanyHolidaysCard";
import { convertArrayToMap, formatValue, log } from "../../../helperFunctions";

const localizer = momentLocalizer(moment);

export default class TimeOffCalendar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			usersAPI: new UsersAPI(),
			allEvents: [],
			outlookEvents: [],
			events: [],
			selectedEvent: null,

			tabVal: 0,
			outlook: true,
			holidays: true,
			companyEvents: true,
			birthdays: true,
			timeOffRequests: "department",

			year: moment(new Date()).format("YYYY"),

			filters: ["outlook", "holiday", "company", "birthday"],
		};

		this.init = this.init.bind(this);
		this.handleEventClick = this.handleEventClick.bind(this);
		this.closeEvent = this.closeEvent.bind(this);
		this.onRangeChange = this.onRangeChange.bind(this);
		this.loadOutlookEvents = this.loadOutlookEvents.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.setViewOption = this.setViewOption.bind(this);
		this.applyFilters = this.applyFilters.bind(this);
		this.updateFilter = this.updateFilter.bind(this);
		this.updateTimeOff = this.updateTimeOff.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	async init() {
		let allEvents = this.state.allEvents;
		let startMth = new Date();
		let endMth = new Date();
		startMth = moment(startMth).startOf("month");
		endMth = moment(startMth).endOf("month");
		// startMth = moment(startMth).add("days", -10);
		// endMth = moment(startMth).add("days", 10);

		await this.loadOutlookEvents(startMth?.format("M/D/YYYY"), endMth?.format("M/D/YYYY"));
		let events = portalUser?.getCalendarEvents() ?? [];

		allEvents.push(...events);

		this.setState({
			allEvents: allEvents,
		});

		this.setViewOption();
	}

	async loadOutlookEvents(startDate, endDate) {
		let outlookEventResult = await portalUser.msClient.GetMSCalendarEvents(
			startDate,
			endDate,
			portalUser.user?.msUserID
		);

		let year = moment(new Date(startDate).setDate(new Date(startDate).getDate() + 7)).format("yyyy");

		let outlookEvents = [];
		for (let i = 0; i < outlookEventResult.value?.length; i++) {
			let startDate = moment();
			let endDate = moment();

			let start = new Date(formatValue(outlookEventResult.value[i].start.dateTime, 0, "dateTime"));
			let end = new Date(formatValue(outlookEventResult.value[i].end.dateTime, 0, "dateTime"));
			if (outlookEventResult.value[i].isAllDay) {
				end = start;
			}
			start = moment(start);
			end = moment(end);

			startDate.set(start.toObject());
			endDate.set(end.toObject());

			outlookEvents.push({
				start: startDate.toDate(),
				end: endDate.toDate(),
				title: outlookEventResult.value[i].subject,
				id: outlookEventResult.value[i].id,
				eventType: "Outlook",
				allday: outlookEventResult.value[i].isAllDay,
				description: outlookEventResult.value[i].body.content,
				employeeUID: portalUser.user.employeeUID,
			});
		}

		this.setState(
			{
				year: year,
				outlookEvents: outlookEvents,
			},
			() => {
				this.setViewOption();
			}
		);
	}

	handleEventClick(event, e) {
		this.setState({
			selectedEvent: event,
		});
	}

	closeEvent() {
		this.setState({
			selectedEvent: null,
		});
	}

	async onRangeChange(dates) {
		//DOESNT WORK with WORK WEEK OPTION
		let startDate = moment(dates?.start).format("M/D/YYYY");
		let endDate = moment(dates?.end).format("M/D/YYYY");

		await this.loadOutlookEvents(startDate, endDate);
	}

	handleTabChange(tabVal) {
		this.setState({ tabVal: tabVal });
	}

	setViewOption() {
		let filters = this.state.filters;
		let timeOffRequests = this.state.timeOffRequests;
		let allEvents = this.state.allEvents;

		let events = [];
		let filteredEvents = [];

		let outlookEvents = this.state.outlookEvents;
		if (filters.includes("outlook")) {
			events.push(...outlookEvents);
		}

		for (let i = 0; i < filters?.length; i++) {
			if (["holiday", "company"].includes(filters[i])) {
				filteredEvents = allEvents.filter((d) => d.eventType?.toLowerCase() === filters[i]?.toLowerCase());
				events.push(...filteredEvents);
			}
		}
		let departments = portalUser.getDepartments();
		let deptMap = convertArrayToMap(departments, "deptID");

		filteredEvents = [];

		let timeOffEvents =
			allEvents?.filter((d) => ["Time Off", "Pending Time Off", "Birthday"].includes(d.eventType)) ?? [];

		if (timeOffRequests === "employee") {
			filteredEvents = timeOffEvents?.filter((d) => d.employeeUID === portalUser.user?.employeeUID);
		} else if (timeOffRequests === "department") {
			let dept = deptMap[portalUser.user?.deptID] ?? null;
			let parentDept = deptMap[dept?.parentDeptID] ?? null;
			filteredEvents = timeOffEvents?.filter((d) => d.parentDeptID === dept?.parentDeptID);
			if (parentDept?.showRegionsYN === "Y") {
				filteredEvents = timeOffEvents?.filter((d) => d.regionID === portalUser.user?.regionID);
			}
		} else if (timeOffRequests === "region") {
			filteredEvents = timeOffEvents?.filter((d) => d.regionID === portalUser.user?.regionID);
		}
		events.push(...filteredEvents);

		this.setState({
			events: events,
		});
	}

	updateTimeOff(timeOffRequests) {
		this.setState(
			{
				timeOffRequests: timeOffRequests,
			},
			() => {
				this.setViewOption();
			}
		);
	}

	updateFilter(filterOption) {
		let filters = this.state.filters;
		let foundIndex = filters.indexOf(filterOption);
		if (foundIndex === -1) {
			filters.push(filterOption);
		} else {
			filters.splice(foundIndex, 1);
		}

		this.setState(
			{
				filters: filters,
			},
			() => {
				this.setViewOption();
			}
		);
	}

	applyFilters() {
		this.setState({ tabVal: 0 });
	}

	render() {
		return (
			<Tabs
				isFitted
				w="full"
				colorScheme="teal"
				color="gray.500"
				index={this.state.tabVal}
				onChange={this.handleTabChange}
			>
				<TabList mx="1">
					<Tab>
						<Heading size="md" textTransform="uppercase" fontWeight="semibold" w="full" textAlign="left">
							My Calendar
						</Heading>
					</Tab>

					<Tab textTransform="uppercase" fontWeight="semibold" maxW={{ base: "60px", md: "150px" }}>
						<Flex w="full" justify="flex-end">
							<Button
								as={Button}
								variant="outline"
								// onClick={setCollapseIsOpen.toggle}
								color="gray.500"
								size="md"
								bg="whiteAlpha.700"
								textTransform="uppercase"
								shadow="md"
								_hover={{
									color: "teal.500",
									fontWeight: "bold",
									bg: "whiteAlpha.700",
									borderColor: "teal.500",
									borderWidth: 2,
								}}
							>
								<Center px={2}>
									<IconButton variant="unstyled" as={IoOptionsOutline} w={6} h={6} />
									<Text display={{ base: "none", md: "flex" }} fontSize="md">
										FILTERS
									</Text>
								</Center>
							</Button>
						</Flex>
					</Tab>
				</TabList>

				<TabPanels>
					<TabPanel>
						<Box>
							{!this.state.selectedEvent && (
								<Box rounded="10px" shadow="md" maxW="full">
									<ReactCalendar
										localizer={localizer}
										defaultDate={new Date()}
										defaultView="month"
										events={this.state.events}
										views={["month", "work_week"]}
										onRangeChange={this.onRangeChange}
										style={{
											height: "60vh",
											minHeight: "550px",
											maxWidth: "100%",
											width: "100%",
											maxHight: "100%",
											backgroundColor: "white",
											borderRadius: "10px",
											border: "1px",
											padding: "10px",
										}}
										onSelectEvent={(event, ev) => {
											this.handleEventClick(event, ev);
										}}
										popup={true}
										eventPropGetter={(event) => {
											var backgroundColor = "";
											if (event?.eventType === "Birthday") {
												backgroundColor = "#805AD5";
											}
											if (event?.eventType === "Holiday") {
												backgroundColor = "#2F855A";
											}
											if (event?.eventType === "Company") {
												backgroundColor = "#DD6B20";
											}
											if (event?.eventType === "Pending Time Off") {
												backgroundColor =
													"repeating-linear-gradient(45deg, #D69E2E, #D69E2E 5px, #B7791F 5px, #B7791F 10px)";
											}
											if (event?.eventType === "Time Off") {
												backgroundColor = "var(--chakra-colors-teal-500)";
											}
											return { style: { background: backgroundColor, fontWeight: "600" } };
										}}
									/>
								</Box>
							)}
							{this.state.selectedEvent && (
								<Box>
									<Stack p={4} w="full">
										<Flex w="full" flex={1} justify="space-between">
											<Stack>
												<Heading as="h4" size="lg" color="gray.500">
													{this.state.selectedEvent?.title}
												</Heading>
												<Text fontSize="sm" color="gray.500">
													{formatValue(this.state.selectedEvent?.start, 0, "date")}
												</Text>
											</Stack>
											<Button variant="outline" onClick={this.closeEvent} bg="whiteAlpha.700">
												<HStack>
													<i className="fas fa-times" />
													<Text>Close</Text>
												</HStack>
											</Button>
										</Flex>
										<Box
											bg="whiteAlpha.800"
											p={8}
											className="skinnyScroll"
											overflow="auto"
											rounded="md"
											maxH="lg"
										>
											{Boolean(this.state.selectedEvent?.eventType === "Outlook") ? (
												<Text>{parse(this.state.selectedEvent?.description ?? "")}</Text>
											) : (
												<Text>{this.state.selectedEvent?.description}</Text>
											)}
										</Box>
									</Stack>
								</Box>
							)}
						</Box>
						<Box mt="4" overflow="auto">
							{this.state.year && <CompanyHolidaysCard year={this.state.year} />}
						</Box>
					</TabPanel>

					<TabPanel>
						<Container w="full" p="4" maxW="container.lg">
							<Stack w="full" spacing={4} divider={<StackDivider />}>
								<Stack spacing={1}>
									<Heading
										size="md"
										w="full"
										textAlign="left"
										letterSpacing={1}
										textTransform="uppercase"
										color="gray.500"
									>
										Calendar View Options
									</Heading>
									<Text as="i" color="gray.400">
										Which events would you like to show on your Calendar?{" "}
									</Text>
								</Stack>
								<SimpleGrid maxChildWidth="3xs" minChildWidth="10rem" spacing="4">
									<Flex
										justify="center"
										align="center"
										w="full"
										bg={this.state.filters.includes("outlook") ? "whiteAlpha.700" : ""}
										shadow="sm"
										flex={1}
										rounded="5"
										p={{ base: "1", md: "4" }}
										border={this.state.filters.includes("outlook") ? "2px" : "1px"}
										borderColor={
											this.state.filters.includes("outlook") ? "blue.600" : "blackAlpha.200"
										}
										onClick={() => {
											this.updateFilter("outlook");
										}}
										cursor="pointer"
										_hover={{ bg: "blackAlpha.50" }}
									>
										<VStack textAlign="center" w="full" spacing={1}>
											<Center
												color={this.state.filters.includes("outlook") ? "blue.600" : "gray.400"}
											>
												<Heading>
													<SiMicrosoftoutlook />
												</Heading>
											</Center>
											<Text
												isTruncated
												color={this.state.filters.includes("outlook") ? "blue.600" : "gray.400"}
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
											>
												Outlook Events
											</Text>
											<Text as="i" fontSize="xs">
												Show My Outlook Calendar Events on my Calendar
											</Text>
											<Center
												fontSize="4xl"
												color={this.state.filters.includes("outlook") ? "blue.600" : "gray.300"}
											>
												{this.state.filters.includes("outlook") ? (
													<MdCheckBox />
												) : (
													<MdCheckBoxOutlineBlank />
												)}
											</Center>
										</VStack>
									</Flex>

									<Flex
										justify="center"
										align="center"
										w="full"
										bg={this.state.filters.includes("holiday") ? "whiteAlpha.700" : ""}
										shadow="sm"
										flex={1}
										rounded="5"
										p={{ base: "1", md: "4" }}
										border={this.state.filters.includes("holiday") ? "2px" : "1px"}
										borderColor={
											this.state.filters.includes("holiday") ? "green.600" : "blackAlpha.200"
										}
										onClick={() => {
											this.updateFilter("holiday");
										}}
										cursor="pointer"
										_hover={{ bg: "blackAlpha.50" }}
									>
										<VStack textAlign="center" w="full" spacing={1}>
											<Center
												color={
													this.state.filters.includes("holiday") ? "green.600" : "gray.400"
												}
											>
												<Heading>
													<FaTree />
												</Heading>
											</Center>
											<Text
												isTruncated
												color={
													this.state.filters.includes("holiday") ? "green.600" : "gray.400"
												}
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
											>
												Company Holidays
											</Text>
											<Text as="i" fontSize="xs">
												Show Company Holidays on my Calendar
											</Text>
											<Center
												fontSize="4xl"
												color={
													this.state.filters.includes("holiday") ? "green.600" : "gray.300"
												}
											>
												{this.state.filters.includes("holiday") ? (
													<MdCheckBox />
												) : (
													<MdCheckBoxOutlineBlank />
												)}
											</Center>
										</VStack>
									</Flex>

									<Flex
										justify="center"
										align="center"
										w="full"
										bg={this.state.filters.includes("company") ? "whiteAlpha.700" : ""}
										shadow="sm"
										flex={1}
										rounded="5"
										p={{ base: "1", md: "4" }}
										border={this.state.filters.includes("company") ? "2px" : "1px"}
										borderColor={
											this.state.filters.includes("company") ? "orange.500" : "blackAlpha.200"
										}
										onClick={() => {
											this.updateFilter("company");
										}}
										cursor="pointer"
										_hover={{ bg: "blackAlpha.50" }}
									>
										<VStack textAlign="center" w="full" spacing={1}>
											<Center
												color={
													this.state.filters.includes("company") ? "orange.500" : "gray.400"
												}
											>
												<Heading>
													<FaGlassCheers />
												</Heading>
											</Center>
											<Text
												isTruncated
												color={
													this.state.filters.includes("company") ? "orange.500" : "gray.400"
												}
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
											>
												Company Events
											</Text>
											<Text as="i" fontSize="xs">
												Show Company Events on my Calendar
											</Text>
											<Center
												fontSize="4xl"
												color={
													this.state.filters.includes("company") ? "orange.500" : "gray.300"
												}
											>
												{this.state.filters.includes("company") ? (
													<MdCheckBox />
												) : (
													<MdCheckBoxOutlineBlank />
												)}
											</Center>
										</VStack>
									</Flex>

									<Flex
										justify="center"
										align="center"
										w="full"
										bg={this.state.filters.includes("birthday") ? "whiteAlpha.700" : ""}
										shadow="sm"
										flex={1}
										rounded="5"
										p={{ base: "1", md: "4" }}
										border={this.state.filters.includes("birthday") ? "2px" : "1px"}
										borderColor={
											this.state.filters.includes("birthday") ? "purple.500" : "blackAlpha.200"
										}
										onClick={() => {
											this.updateFilter("birthday");
										}}
										cursor="pointer"
										_hover={{ bg: "blackAlpha.50" }}
									>
										<VStack textAlign="center" w="full" spacing={1}>
											<Center
												color={
													this.state.filters.includes("birthday") ? "purple.500" : "gray.400"
												}
											>
												<Heading>
													<FaGift />
												</Heading>
											</Center>
											<Text
												isTruncated
												color={
													this.state.filters.includes("birthday") ? "purple.500" : "gray.400"
												}
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
											>
												Birthdays
											</Text>
											<Text as="i" fontSize="xs">
												Show Employee Birthdays on my Calendar
											</Text>
											<Center
												fontSize="4xl"
												color={
													this.state.filters.includes("birthday") ? "purple.500" : "gray.300"
												}
											>
												{this.state.filters.includes("birthday") ? (
													<MdCheckBox />
												) : (
													<MdCheckBoxOutlineBlank />
												)}
											</Center>
										</VStack>
									</Flex>
								</SimpleGrid>
							</Stack>

							<Stack w="full" spacing={4} divider={<StackDivider />} mt="8">
								<Stack spacing={1}>
									<Heading
										size="md"
										w="full"
										textAlign="left"
										letterSpacing={1}
										textTransform="uppercase"
										color="gray.500"
									>
										Time Off View Options
									</Heading>
									<Text as="i" color="gray.400">
										Who's time off requests would you like to show on your Calendar?{" "}
									</Text>
								</Stack>
								<SimpleGrid maxChildWidth="3xs" minChildWidth="10rem" spacing="4">
									<Flex
										justify="center"
										align="center"
										w="full"
										bg={this.state.timeOffRequests === "none" ? "whiteAlpha.700" : ""}
										shadow="sm"
										flex={1}
										rounded="5"
										p={{ base: "1", md: "4" }}
										border={this.state.timeOffRequests === "none" ? "2px" : "1px"}
										borderColor={
											this.state.timeOffRequests === "none" ? "teal.600" : "blackAlpha.200"
										}
										onClick={() => {
											this.updateTimeOff("none");
										}}
										cursor="pointer"
										_hover={{ bg: "blackAlpha.50" }}
									>
										<VStack textAlign="center" w="full" spacing={1}>
											<Center
												color={this.state.timeOffRequests === "none" ? "teal.600" : "gray.400"}
											>
												<Heading>
													<FaBan />
												</Heading>
											</Center>
											<Text
												isTruncated
												color={this.state.timeOffRequests === "none" ? "teal.600" : "gray.400"}
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
											>
												No Time Off
											</Text>
											<Text as="i" fontSize="xs">
												Hide All Time Off Requests
											</Text>
											<Center
												fontSize="4xl"
												color={this.state.timeOffRequests === "none" ? "teal.600" : "gray.300"}
											>
												{this.state.timeOffRequests === "none" ? (
													<MdRadioButtonChecked />
												) : (
													<MdRadioButtonUnchecked />
												)}
											</Center>
										</VStack>
									</Flex>

									<Flex
										justify="center"
										align="center"
										w="full"
										bg={this.state.timeOffRequests === "employee" ? "whiteAlpha.700" : ""}
										shadow="sm"
										flex={1}
										rounded="5"
										p={{ base: "1", md: "4" }}
										border={this.state.timeOffRequests === "employee" ? "2px" : "1px"}
										borderColor={
											this.state.timeOffRequests === "employee" ? "teal.600" : "blackAlpha.200"
										}
										onClick={() => {
											this.updateTimeOff("employee");
										}}
										cursor="pointer"
										_hover={{ bg: "blackAlpha.50" }}
									>
										<VStack textAlign="center" w="full" spacing={1}>
											<Center
												color={
													this.state.timeOffRequests === "employee" ? "teal.600" : "gray.400"
												}
											>
												<Heading>
													<FaUser />
												</Heading>
											</Center>
											<Text
												isTruncated
												color={
													this.state.timeOffRequests === "employee" ? "teal.600" : "gray.400"
												}
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
											>
												My Time Off
											</Text>
											<Text as="i" fontSize="xs">
												Show My Time Off Requests Only
											</Text>
											<Center
												fontSize="4xl"
												color={
													this.state.timeOffRequests === "employee" ? "teal.600" : "gray.300"
												}
											>
												{this.state.timeOffRequests === "employee" ? (
													<MdRadioButtonChecked />
												) : (
													<MdRadioButtonUnchecked />
												)}
											</Center>
										</VStack>
									</Flex>

									<Flex
										justify="center"
										align="center"
										w="full"
										bg={this.state.timeOffRequests === "department" ? "whiteAlpha.700" : ""}
										shadow="sm"
										flex={1}
										rounded="5"
										p={{ base: "1", md: "4" }}
										border={this.state.timeOffRequests === "department" ? "2px" : "1px"}
										borderColor={
											this.state.timeOffRequests === "department" ? "teal.600" : "blackAlpha.200"
										}
										onClick={() => {
											this.updateTimeOff("department");
										}}
										cursor="pointer"
										_hover={{ bg: "blackAlpha.50" }}
									>
										<VStack textAlign="center" w="full" spacing={1}>
											<Center
												color={
													this.state.timeOffRequests === "department"
														? "teal.600"
														: "gray.400"
												}
											>
												<Heading>
													<FaUserFriends />
												</Heading>
											</Center>
											<Text
												color={
													this.state.timeOffRequests === "department"
														? "teal.600"
														: "gray.400"
												}
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
											>
												My Department
											</Text>
											<Text as="i" fontSize="xs">
												Show Approved Time Off Requests for My Department
											</Text>
											<Center
												fontSize="4xl"
												color={
													this.state.timeOffRequests === "department"
														? "teal.600"
														: "gray.300"
												}
											>
												{this.state.timeOffRequests === "department" ? (
													<MdRadioButtonChecked />
												) : (
													<MdRadioButtonUnchecked />
												)}
											</Center>
										</VStack>
									</Flex>

									<Flex
										justify="center"
										align="center"
										w="full"
										bg={this.state.timeOffRequests === "region" ? "whiteAlpha.700" : ""}
										shadow="sm"
										flex={1}
										rounded="5"
										p={{ base: "1", md: "4" }}
										border={this.state.timeOffRequests === "region" ? "2px" : "1px"}
										borderColor={
											this.state.timeOffRequests === "region" ? "teal.600" : "blackAlpha.200"
										}
										onClick={() => {
											this.updateTimeOff("region");
										}}
										cursor="pointer"
										_hover={{ bg: "blackAlpha.50" }}
									>
										<VStack textAlign="center" w="full" spacing={1}>
											<Center
												color={
													this.state.timeOffRequests === "region" ? "teal.600" : "gray.400"
												}
											>
												<Heading>
													<FaUsers />
												</Heading>
											</Center>
											<Text
												isTruncated
												color={
													this.state.timeOffRequests === "region" ? "teal.600" : "gray.400"
												}
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
											>
												Region Time Off{" "}
											</Text>
											<Text as="i" fontSize="xs">
												Show Time Off Requests for my Region
											</Text>
											<Center
												fontSize="4xl"
												color={
													this.state.timeOffRequests === "region" ? "teal.600" : "gray.300"
												}
											>
												{this.state.timeOffRequests === "region" ? (
													<MdRadioButtonChecked />
												) : (
													<MdRadioButtonUnchecked />
												)}
											</Center>
										</VStack>
									</Flex>
								</SimpleGrid>
							</Stack>

							<Flex w="full" justify="center" mt="8">
								<Button
									variant="outline"
									onClick={this.applyFilters}
									color="gray.500"
									ml={0}
									pl="0"
									size="sm"
									bg="whiteAlpha.700"
									shadow="sm"
								>
									<Center>
										<IconButton variant="unstyled" as={IoOptionsOutline} w={6} h={6} />
										<Text ml="1" fontSize="md">
											APPLY FILTERS
										</Text>
									</Center>
								</Button>
							</Flex>
						</Container>
					</TabPanel>
				</TabPanels>
			</Tabs>
		);
	}
}
