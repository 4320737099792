import React, { useMemo } from "react";
import { TextareaAutosize } from "@material-ui/core";

export default function TextAreaInput(props) {
	const textareaStyle = useMemo(() => {
		return {
			width: "100%",
			fontSize: "var(--chakra-fontSizes-md)",
			lineHeight: "var(--chakra-lineHeights-short)",
			border: props.inputBorder ?? "1px solid",
			borderColor: "inherit",
			background: props.inputBG ?? "white",
			borderRadius: props.inputBorderRadius ?? "var(--chakra-radii-md)",
			fontFamily: "'Helvetica',sans-serif",
			paddingTop: props.inputPaddingTop ?? "var(--chakra-space-2)",
			paddingBottom: props.inputPaddingBottom ?? "var(--chakra-space-2)",
			paddingLeft: props.inputPaddingLeft ?? "var(--chakra-space-2)",
			paddingRight: props.inputPaddingRight ?? "var(--chakra-space-2)",
			cursor: Boolean(props.readOnly ?? props.isReadOnly) ? "not-allowed" : "pointer",
		};
	}, [
		props.inputBG,
		props.inputBorder,
		props.inputBorderRadius,
		props.inputPaddingBottom,
		props.inputPaddingLeft,
		props.inputPaddingRight,
		props.inputPaddingTop,
		props.isReadOnly,
		props.readOnly,
	]);

	return (
		<TextareaAutosize
			key={props.defaultValue + "-" + props?.inputRef?.current?.value}
			ref={props.inputRef}
			required={props.isRequired}
			className={props.error ? " focusBorderColorRed" : " focusBorderColorTeal"}
			rowsMin={props.rowsMin ? props.rowsMin : 1}
			style={textareaStyle}
			placeholder={props.placeholder}
			name={props.name}
			defaultValue={props.defaultValue}
			value={props.value}
			onChange={props.onChange}
			onBlur={props.onBlur}
			onWheel={(e) => props?.inputRef?.current?.blur()}
			onScroll={(e) => props?.inputRef?.current?.blur()}
			size={props.size}
			readOnly={props.readOnly ?? props.isReadOnly}
			{...props.inputProps}
			autoComplete="off"
		/>
	);
}
