import React, { useEffect, useState, useMemo, useRef, useCallback, forwardRef } from "react";
import {
	Switch,
	Menu,
	MenuItem,
	MenuGroup,
	MenuButton,
	MenuList,
	Flex,
	Text,
	Heading,
	Stack,
	HStack,
	IconButton,
} from "@chakra-ui/react";

import {
	useTable,
	useResizeColumns,
	useGroupBy,
	useExpanded,
	useGlobalFilter,
	useFilters,
	useSortBy,
	useBlockLayout,
	useColumnOrder,
} from "react-table";
import { VariableSizeList } from "react-window";
import { useExportData } from "react-table-plugins";
import SideBarSearchBar from "./SideBarSearchBar";
import getExportFileBlob from "../../../core/ReactTable/functions/getExportFileBlob";
import { log } from "../../../helperFunctions";

export default function SideBarVirtualList({
	columns = [],
	data = [],
	initialState,
	defaultColumn,
	filterTypes,
	renderRow,
	updateMyData,
	skipReset = false,
	onClose,
	sideBarIsOpen = true,
}) {
	const containerRef = useRef(null);
	const [containerHeight, setContainerHeight] = useState(containerRef?.current?.clientHeight ?? 1000);

	// Resize Observer
	useEffect(() => {
		if (!containerRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			if (containerRef?.current?.clientHeight >= 1400) {
				setContainerHeight(1400);
			} else {
				setContainerHeight(containerRef?.current?.clientHeight);
			}
		});
		resizeObserver.observe(containerRef?.current);
		return () => resizeObserver.disconnect();
	}, []);

	const listRef = useRef(null);

	const {
		getTableProps,
		getTableBodyProps,
		prepareRow,
		rows,
		preFilteredRows,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		toggleAllRowsExpanded,
		allColumns,
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			getExportFileBlob,
			autoResetPage: !skipReset,
			autoResetExpanded: false,
			filterTypes,
			initialState,
			updateMyData,
		},
		useBlockLayout,
		useColumnOrder,
		useResizeColumns,
		useFilters,
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		useExportData,
		useExpanded
	);

	const RenderRow = useCallback(
		({ index, style }) => {
			const row = rows[index];
			prepareRow(row);
			let groupRow = row;

			var expandIcon = null;
			if (row.isGrouped && row.canExpand && row.isExpanded) {
				expandIcon = "fas fa-chevron-down";
			} else if (row.isGrouped && row.canExpand && !row.isExpanded) {
				expandIcon = "fas fa-chevron-right";
			}

			if (row?.isGrouped && row?.groupByID === "basepath") {
				groupRow =
					preFilteredRows?.find((d) => d.values?.basepath === row?.groupByVal && d.values?.path === "/")
						?.original ?? row?.original;
				// log("Row that is grouped by basepath", row);
				// log("Subrows", row.subRows);
				// log("GroupRow", groupRow);
			}
			// if (!row?.isGrouped) {
			// 	log("Row", row);
			// }

			if (!sideBarIsOpen && row.canExpand && !row.isExpanded) {
				row.toggleRowExpanded(true);
			}

			return (
				<Flex
					{...row.getRowProps({ style })}
					flex={1}
					w="full"
					overflow="hidden"
					justify="space-between"
					h={10}
					rounded="md"
					align="center"
				>
					{row.isGrouped && row.groupByID === "group" && (
						<Heading
							letterSpacing={2}
							fontSize="lg"
							color="white.800"
							textShadow="1px 1px 1px black"
							textTransform="uppercase"
							isTruncated
							lineHeight={1}
							w="full"
							onClick={() => {
								if (Boolean(listRef?.current)) {
									listRef?.current?.resetAfterIndex(0, false);
								}
								row.toggleRowExpanded();
							}}
						>
							{row?.groupByVal}
						</Heading>
					)}

					{row.isGrouped &&
						row.groupByID === "basepath" &&
						renderRow(
							groupRow,
							sideBarIsOpen,
							<IconButton
								right={0}
								position="absolute"
								variant="ghost"
								key={row?.groupByVal + "-" + expandIcon}
								onClick={() => {
									if (Boolean(listRef?.current)) {
										listRef?.current?.resetAfterIndex(0, false);
									}
									row.toggleRowExpanded();
								}}
								bg="whiteAlpha.200"
								color="whiteAlpha.600"
								size="xs"
								_hover={{ bg: "whiteAlpha.600", color: "whiteAlpha.900" }}
								icon={<i className={expandIcon + " fa-lg fa-fw"} />}
							/>
						)}

					{!row.isGrouped && Boolean(renderRow) ? renderRow(row.original, sideBarIsOpen) : null}
				</Flex>
			);
		},
		[rows, preFilteredRows, prepareRow, renderRow, sideBarIsOpen]
	);

	// log("preFilteredRows", preFilteredRows);

	let ListContainer = useMemo(() => {
		return (
			<VariableSizeList
				ref={listRef}
				height={containerHeight}
				itemCount={rows.length}
				className="skinnyScroll"
				itemSize={(index) => {
					let row = rows[index];
					prepareRow(row);
					// log("RowSize", row);
					return rows[index]?.isGrouped ? (sideBarIsOpen ? 45 : 0) : 45;
				}}
				style={{ overflowX: "hidden" }}
			>
				{RenderRow}
			</VariableSizeList>
		);
	}, [containerHeight, rows, RenderRow, prepareRow, sideBarIsOpen]);

	useEffect(() => {
		if (Boolean(listRef?.current)) {
			listRef?.current?.resetAfterIndex(0, false);
		}
		let groupRows = rows?.filter((d) => d.isGrouped && !d.isExpanded && d.groupByID === "group") ?? [];
		for (let i = 0; i < groupRows?.length; i++) {
			groupRows[0].toggleRowExpanded(true);
		}
		// log("columns", columns);
		// log("data", data);
		// log("initialState", initialState);

		// toggleAllRowsExpanded(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack {...getTableProps()} w="full" flex={1} h="full" spacing={1}>
			<HStack align="center" w="full" position="relative" spacing={1}>
				<SideBarSearchBar
					toggleAllRowsExpanded={toggleAllRowsExpanded}
					preGlobalFilteredRows={preGlobalFilteredRows}
					globalFilter={state.globalFilter}
					setGlobalFilter={setGlobalFilter}
					rowCount={(rows?.length ?? 0) - (rows?.filter((data) => data?.isGrouped === true)?.length ?? 0)}
					listRef={listRef}
				/>

				{/* <GroupByMenu
					allColumns={allColumns}
					toggleAllRowsExpanded={toggleAllRowsExpanded}
					groupBy={state.groupBy}
					initalSort={initialState.sortBy}
					listRef={listRef}
				/> */}
			</HStack>

			<Flex {...getTableBodyProps()} direction="column" flex={1} w="full" overflow="hidden" ref={containerRef}>
				{ListContainer}
			</Flex>
		</Stack>
	);
}

const GroupByMenu = forwardRef(function ({ allColumns, toggleAllRowsExpanded, groupBy }, listRef) {
	const columns = useMemo(() => allColumns?.filter((d) => d.showGroupBy === true), [allColumns]);

	return (
		<Menu closeOnSelect={false} p={0} placement="bottom" isLazy>
			<MenuButton
				size="xs"
				p={2}
				borderRadius="md"
				borderWidth="2px"
				fontWeight="semibold"
				bg="gray.100"
				color="gray.500"
				h={10}
				minW={10}
				_hover={{
					color: "blue.500",
					borderColor: "blue.500",
				}}
				_expanded={{
					bg: "blue.500",
					color: "white",
					borderColor: "blue.500",
				}}
				_focus={{ boxShadow: "outline" }}
			>
				<Text>
					<i className="fas fa-cog fa-lg " />
				</Text>
			</MenuButton>

			<MenuList color="gray.500" bg="gray.50" minW="sm" maxH="md" overflow="auto" className="skinnyScroll">
				<MenuGroup
					title={
						<Flex w="full" flex={1}>
							<Flex align="center" letterSpacing={1} flex={2} isTruncated>
								<Text
									fontWeight="semibold"
									lineHeight={1.15}
									fontSize="sm"
									textTransform="uppercase"
									isTruncated
								>
									Columns
								</Text>
							</Flex>
							<Flex align="center" letterSpacing={1} flex={1} isTruncated justify="center">
								<Text
									fontWeight="semibold"
									lineHeight={1.15}
									fontSize="sm"
									textTransform="uppercase"
									isTruncated
								>
									Group By
								</Text>
							</Flex>
						</Flex>
					}
					fontWeight="semibold"
					letterSpacing={1}
					color="gray.500"
					borderBottom="2px"
					borderColor="gray.400"
				>
					{columns.map((column, i) => {
						return (
							<MenuItem key={i}>
								<Flex align="center" letterSpacing={1} flex={2} isTruncated>
									<Text
										lineHeight={1.15}
										fontSize="sm"
										isTruncated
										fontWeight="semibold"
										textTransform="uppercase"
									>
										{column.Header}
									</Text>
								</Flex>
								<Flex align="center" letterSpacing={1} flex={1} justify="center">
									<Switch
										id={column.id + "-GroupBy"}
										colorScheme="blue"
										isChecked={column.isGrouped}
										{...column.getGroupByToggleProps()}
										key={column.id + (Boolean(column.isGrouped) ? "-Grouped" : "-notGrouped")}
										onChange={() => {
											column.toggleGroupBy();

											for (let i = 0; i < groupBy?.length; i++) {
												let column = columns.find((d) => d.id === groupBy[i]) ?? {};
												if (Boolean(column?.id) && !column?.isSorted) {
													column.toggleSortBy(true, true);
												}
											}
											//column.toggleSortBy(false);
											if (Boolean(listRef?.current)) {
												listRef?.current?.resetAfterIndex(0, false);
											}
											toggleAllRowsExpanded(true);
										}}
									/>
								</Flex>
							</MenuItem>
						);
					})}
				</MenuGroup>
			</MenuList>
		</Menu>
	);
});
