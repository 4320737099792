import React from "react";
import { useColorMode, IconButton, useColorModeValue as mode } from "@chakra-ui/react";

export default function ColorModeSwitcher(props) {
	const { toggleColorMode } = useColorMode();
	var text = mode("dark", "light");
	var icon = mode("fas fa-moon", "fas fa-sun");
	var color = mode("gray.200", "gray.200");
	var hoverColor = mode("whiteAlpha.900", "whiteAlpha.900");
	var hoverBG = mode("teal.600", "whiteAlpha.400");

	return (
		<IconButton
			size="md"
			aria-label={`Switch to ${text} mode`}
			variant="ghost"
			opacity={0.8}
			transition="all 0.2s"
			_hover={{ bg: hoverBG, color: hoverColor, opacity: 1 }}
			color={color}
			onClick={toggleColorMode}
			key={icon}
			icon={<i className={icon + " fa-lg fa-fw"} />}
			{...props}
		/>
	);
}
