import React, { useState, useRef, useCallback, useEffect } from "react";
import {
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	Flex,
	Text,
	Heading,
	Button,
	Collapse,
	Stack,
	HStack,
} from "@chakra-ui/react";
import DataInput from "../../../core/Inputs/data/DataInput";
import DiversityVendorDrawerItem from "./DiversityVendorDrawerItem";
import DIVERSITY_CERTS from "../constants/DiversityCerts";

export default function DiversityVendorDrawer({ selectedVendor, isOpen, closeVendor, saveVendor, deleteVendorCert }) {
	const diversityCerts = DIVERSITY_CERTS?.filter((d) => !["BUDGET"].includes(d.value)) ?? [];
	const [itemCount, setItemCount] = useState(selectedVendor?.diversityCerts?.length);
	const containerRef = useRef(null);
	const descriptionRef = useRef(null);
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);

	const spacing = { vertSpacing: 2, horzSpacing: 2, leftFlex: 4, rightFlex: 2 };

	const addDiversityCert = useCallback(async () => {
		await selectedVendor.insertDiversityCert();
		setUpdateTrigger(true);
		setItemCount(selectedVendor?.diversityCerts?.length);
	}, [selectedVendor]);

	const updateVendor = useCallback(
		(attr, value) => {
			selectedVendor.update(attr, value);
			setUpdateTrigger(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedVendor]
	);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const validateVendor = useCallback(async () => {
		setSaveLoading(true);
		await saveVendor(selectedVendor);
		setSaveLoading(false);
	}, [saveVendor, selectedVendor]);

	return (
		<Drawer placement="right" isOpen={isOpen} onClose={closeVendor} size="md" key={selectedVendor?.apvmKeyID}>
			<DrawerOverlay />

			<DrawerContent ref={containerRef}>
				<DrawerCloseButton />

				<DrawerHeader>
					<HStack align="center" w="full" spacing={2}>
						<Heading as="h2" size="md" color="gray.400" textTransform="uppercase" letterSpacing={2}>
							Diversity Vendor
						</Heading>
					</HStack>
				</DrawerHeader>

				<DrawerBody overflowX={"hidden"} overflowY={"auto"}>
					<Stack spacing={4} w="full" h="full">
						<Stack spacing={spacing.vertSpacing} w="full">
							<Stack
								p={4}
								spacing={spacing.vertSpacing}
								bg="blackAlpha.50"
								borderWidth={2}
								rounded="10"
								borderColor={"blackAlpha.100"}
							>
								<HStack
									w="full"
									pb={1}
									borderBottomWidth={2}
									borderBottomColor="blackAlpha.300"
									align="center"
								>
									<Heading
										w="full"
										color={"gray.500"}
										textTransform="uppercase"
										fontSize="lg"
										letterSpacing={2}
									>
										Vendor Info
									</Heading>
								</HStack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.vendorGroup}
										name="diversityContact"
										type="text"
										label="Company"
										isDisabled
										placeholder="Company"
										defaultValue={selectedVendor?.vendorGroup}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.vendor}
										name="vendor"
										isDisabled
										type="text"
										label="Vendor"
										placeholder="Vendor"
										defaultValue={selectedVendor?.vendorID}
										flex={spacing.leftFlex * 2}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
								</Stack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.vendorName}
										name="vendorName"
										type="text"
										label="Vendor Name"
										isDisabled
										defaultValue={selectedVendor?.vendorName}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
								</Stack>
								<Stack spacing={spacing.horzSpacing} direction="row" w="full" alignItems="flex-end">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.diversityURL}
										name="diversityURL"
										type="text"
										label="Certification Link"
										placeholder="Certification Link"
										defaultValue={selectedVendor?.diversityURL}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityURL) {
												updateVendor("diversityURL", ev.target.value);
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
									<Button
										colorScheme={"blue"}
										as="a"
										target="_blank"
										href={selectedVendor?.diversityURL}
										px={3}
										fontSize="sm"
									>
										View
									</Button>
								</Stack>
								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={
											selectedVendor?.apvmKeyID +
											"-diversity-" +
											selectedVendor?.diversityServiceType
										}
										name="diversityServiceType"
										type="textarea"
										rowsMin={4}
										label="Service Type"
										placeholder="Service Type"
										defaultValue={selectedVendor?.diversityServiceType}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityServiceType) {
												updateVendor("diversityServiceType", ev.target.value);
											}
										}}
										inputRef={descriptionRef}
										flex={spacing.leftFlex}
										labelProps={{ py: 1, letterSpacing: 1 }}
										variant="outline"
										inputBG="white"
										inputProps={{ px: 2 }}
										isModal
									/>
								</Stack>
							</Stack>
						</Stack>
						<Stack spacing={spacing.vertSpacing} w="full">
							<Stack
								p={4}
								spacing={spacing.vertSpacing}
								bg="blackAlpha.50"
								borderWidth={2}
								rounded="10"
								borderColor={"blackAlpha.100"}
							>
								<HStack
									w="full"
									pb={1}
									borderBottomWidth={2}
									borderBottomColor="blackAlpha.300"
									align="center"
								>
									<Heading
										w="full"
										color={"gray.500"}
										textTransform="uppercase"
										fontSize="lg"
										letterSpacing={2}
									>
										Diversity Contact
									</Heading>
								</HStack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={
											selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.diversityContact
										}
										name="diversityContact"
										type="text"
										label="Contact Name"
										placeholder="Diversity Contact"
										defaultValue={selectedVendor?.diversityContact}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityContact) {
												updateVendor("diversityContact", ev.target.value);
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
								</Stack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.diversityPhone}
										name="diversityPhone"
										type="text"
										label="Contact Phone"
										placeholder="(###) ###-####"
										defaultValue={selectedVendor?.diversityPhone}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityPhone) {
												updateVendor("diversityPhone", ev.target.value);
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
								</Stack>

								<Stack spacing={spacing.horzSpacing} direction="row" w="full">
									<DataInput
										key={selectedVendor?.apvmKeyID + "-diversity-" + selectedVendor?.diversityEmail}
										name="diversityEmail"
										type="text"
										label="Contact Email"
										placeholder="Email"
										defaultValue={selectedVendor?.diversityEmail}
										onBlur={(ev) => {
											if (ev.target.value !== selectedVendor?.diversityEmail) {
												updateVendor("diversityEmail", ev.target.value);
											}
										}}
										flex={spacing.leftFlex}
										variant="outline"
										inputProps={{ px: 2 }}
										isModal
									/>
								</Stack>
							</Stack>
						</Stack>
						<Stack spacing={spacing.vertSpacing} w="full">
							<Stack
								p={4}
								spacing={spacing.vertSpacing}
								bg="blackAlpha.50"
								borderWidth={2}
								rounded="10"
								borderColor={"blackAlpha.100"}
							>
								<HStack
									w="full"
									pb={1}
									borderBottomWidth={2}
									borderBottomColor="blackAlpha.300"
									align="center"
								>
									<Heading
										w="full"
										color={"gray.500"}
										textTransform="uppercase"
										fontSize="lg"
										letterSpacing={2}
									>
										Certifications
									</Heading>
								</HStack>

								<Stack spacing={spacing.horzSpacing} w="full">
									<Stack p={0} spacing={spacing.horzSpacing} rounded="10">
										{selectedVendor?.diversityCerts?.map((cert, i) => {
											return (
												<DiversityVendorDrawerItem
													key={cert?.keyID}
													cert={cert}
													diversityCerts={diversityCerts}
													spacing={spacing}
													i={i}
													itemCount={itemCount}
													deleteVendorCert={deleteVendorCert}
												/>
											);
										})}
									</Stack>
								</Stack>

								<Collapse in={Boolean(itemCount < DIVERSITY_CERTS.length)}>
									<Button size="sm" colorScheme="teal" onClick={addDiversityCert} w="full">
										ADD DIVERSITY CERT
									</Button>
								</Collapse>
							</Stack>
						</Stack>
					</Stack>
				</DrawerBody>

				<DrawerFooter>
					<Flex justify="space-between" flex={1} w="full">
						<Button
							variant="outline"
							colorScheme="red"
							onClick={closeVendor}
							borderWidth={2}
							px={3}
							fontSize="sm"
						>
							<HStack>
								<i className={"fas fa-times fa-fw"}></i>
								<Text letterSpacing={1} textTransform={"uppercase"}>
									CANCEL
								</Text>
							</HStack>
						</Button>

						<HStack spacing={2} flex={1} w="full" justify="flex-end" align="center">
							<Button
								colorScheme={"blue"}
								onClick={validateVendor}
								px={3}
								fontSize="sm"
								isLoading={saveLoading}
								loadingText={"Saving..."}
							>
								<HStack>
									<i className={"fas fa-save fa-fw"}></i>
									<Text fontSize="sm" letterSpacing={1} textTransform={"uppercase"}>
										Save
									</Text>
								</HStack>
							</Button>
						</HStack>
					</Flex>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
