import React, { useState, useMemo, useEffect, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme, useBoolean, Flex, Box, ScaleFade } from "@chakra-ui/react";
import { theme } from "./theme";
import { AnimatePresence } from "framer-motion";
import "react-datepicker/dist/react-datepicker.css";

import LoadingScreen from "./modules/App/pages/LoadingScreen";
import AppContainer from "./legacy/App/components/AppContainer";
import AppContainerNew from "./modules/App/components/AppContainer";
import NewAppButton from "./modules/App/components/NewAppButton";

import { PPMUser } from "./PPM_SDK/PPMUser";
import { PortalUser } from "./PortalUser/PortalUser";
import PortalData from "./PortalUser/PortalData";
import { formatValue, log } from "./helperFunctions";
import moment from "moment";

export let ppmUser = new PPMUser(); // LEGACY
export let portalUser = new PortalUser();
export let portalData = new PortalData(); //new PortalUser();

export var appLogs = [];
var loginSteps = [
	{
		step: 0,
		function: "initMSGraph",
		label: "Authenticating User...",
		value: 5,
		start: null,
		seconds: null,
	},
	{
		step: 1,
		function: "initAppData",
		label: "Loading Employees...",
		value: 16,
		start: null,
		seconds: null,
	},

	{
		step: 2,
		function: "initUserData",
		label: "Loading Notifications...",
		value: 33,
		start: null,
		seconds: null,
	},
	{
		step: 3,
		function: "initPayrollData",
		label: "Loading Timecard...",
		value: 50,
		start: null,
		seconds: null,
	},
	{
		step: 4,
		function: "initOpsData",
		label: "Loading Projects",
		value: 66,
		start: null,
		seconds: null,
	},
	{
		step: 5,
		function: "initProjectData",
		label: "Loading Cost Codes",
		value: 89,
		start: null,
		seconds: null,
	},
	{
		step: 6,
		function: "initScheduleData",
		label: "Activating Portal...",
		value: 95,
		start: null,
		seconds: null,
	},
	{
		step: 7,
		function: "initPortal",
		label: "Activating Portal...",
		value: 100,
		start: null,
		seconds: null,
	},
];

export function App({ msAuth }) {
	const [loadingV2, setLoadingV2] = useBoolean(false);

	const msUserID = useMemo(() => msAuth.msUser?.id ?? null, [msAuth.msUser?.id]);

	const [progressBar, setProgressBar] = useState(loginSteps[0]);
	const [newAppIsOpen, setNewAppIsOpen] = useBoolean(false);
	const [initalScale, setInitialScale] = useState(1);

	const getLoadingTimes = useCallback((i = 0) => {
		let currentTime = moment(new Date());
		let appLog = {
			queryData: "AppLog",
			event: "App Loading- Step " + (i + 1) + ": " + loginSteps[i]?.function ?? "N/A",
			start: currentTime.format("MMM Do h:mm:ss A"),
			end: null,
			duration: null,
			total: formatValue(0, 4, "number"),
			startTime: currentTime,
			endTime: null,
		};
		if (i === 0) {
			appLogs.push(appLog);
		}

		if (i !== 0) {
			let previousTime = appLogs[i - 1].startTime;
			let previousDuration = currentTime.diff(previousTime) / 1000;
			let previousTotal = parseFloat(appLogs[i - 1]?.total ?? 0) + parseFloat(previousDuration);

			appLogs[i - 1].endTime = previousTime;
			appLogs[i - 1].end = previousTime.format("MMM Do h:mm:ss A");
			appLogs[i - 1].duration = formatValue(previousDuration, 4, "number");
			appLogs[i - 1].total = formatValue(previousTotal, 4, "number");
			appLog.total = formatValue(previousTotal, 4, "number");
		}

		if (i > 0 && i < loginSteps.length) {
			appLogs.push(appLog);
		}

		if (i < loginSteps.length) {
			setProgressBar(loginSteps[i]);
		}
	}, []);

	const initPortal = useCallback(
		async (data) => {
			getLoadingTimes(7);
			// portalUser = data;
			portalData = data;

			if (loadingV2) {
				portalData = data;
			} else {
				portalUser = data;
				log("portalUser", portalUser);
				// let msUsers = await portalUser.initMSUsers();
				// log("msUsers", msUsers);
			}
			// log("FINAL LOG", appLogs);
			getLoadingTimes(8);
			setInitialScale(0.95);
		},
		[getLoadingTimes, loadingV2]
	);

	const initSchedData = useCallback(
		async (data) => {
			getLoadingTimes(6);
			if (loadingV2) {
				data = await data.initSchedData(data);
			} else {
			}

			initPortal(data);
		},
		[getLoadingTimes, initPortal, loadingV2]
	);

	const initProjectData = useCallback(
		async (data) => {
			getLoadingTimes(5);
			if (loadingV2) {
				data = await data.initProjectData(data);
			} else {
				ppmUser = await ppmUser.initPPMUser(data.msUser);
			}
			initSchedData(data);
		},
		[getLoadingTimes, initSchedData, loadingV2]
	);

	const initOpsData = useCallback(
		async (data) => {
			getLoadingTimes(4);
			if (loadingV2) {
				data = await data.initOpsData(data);
			} else {
				// await data.initUserJobs();
			}

			initProjectData(data);
		},
		[getLoadingTimes, initProjectData, loadingV2]
	);

	const initPayrollData = useCallback(
		async (data) => {
			getLoadingTimes(3);
			if (loadingV2) {
				data = await data.initPayrollData(data);
			} else {
				await data.initUser();
			}

			initOpsData(data);
		},
		[getLoadingTimes, initOpsData, loadingV2]
	);

	const initUserData = useCallback(
		async (data) => {
			getLoadingTimes(2);
			if (loadingV2) {
				data = await data.initUserData();
			} else {
				await data.initUserData();
			}

			initPayrollData(data);
		},

		[getLoadingTimes, initPayrollData, loadingV2]
	);

	const initAppData = useCallback(
		async (data) => {
			getLoadingTimes(1);
			if (loadingV2) {
				data = await data.initAppData();
			} else {
				await data.initAppData();
			}

			initUserData(data);
		},
		[getLoadingTimes, initUserData, loadingV2]
	);

	const initMSGraph = useCallback(
		async (data, msAuth) => {
			getLoadingTimes(0);
			if (loadingV2) {
				data = await data.initMSGraph(data, msAuth);
			} else {
				await data.initMSUser(msAuth);
			}

			initAppData(data);
		},
		[getLoadingTimes, initAppData, loadingV2]
	);

	useEffect(() => {
		if (Boolean(msUserID)) {
			if (loadingV2) {
				initMSGraph(portalData, msAuth);
			} else {
				initMSGraph(portalUser, msAuth);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [msUserID]);

	const loadingMessage = {
		icon: "fas fa-cog fa-spin fa-lg ",
		title: "Hey PPM Team!",
		body1: "We're still hard at work to improve your overall portal experience! Pages should start loading faster and data should start saving more smoothly.",
		body2: "Please bear with us as we work through any unexpected bugs, your patience is greatly appreciated!",
		footer: "Sincerely, your friends in Dev Ops",
	};

	return (
		<ChakraProvider theme={extendTheme(theme)}>
			<BrowserRouter>
				<main style={{ maxHeight: "100vh", width: "100%", position: "relative" }}>
					{progressBar?.value < 100 ? (
						<LoadingScreen progressBar={progressBar} loadingMessage={loadingMessage} />
					) : (
						<AnimatePresence mode="wait">
							<Box w="full" h="full" bg="blackAlpha.400" position="relative">
								<ScaleFade
									in={newAppIsOpen}
									transition={{
										enter: { duration: 1 },
										delay: newAppIsOpen && initalScale !== 1 ? 1 : 0,
										delayChildren: 0.2,
									}}
									initialScale={initalScale}
									unmountOnExit
								>
									<AppContainerNew />
								</ScaleFade>
								<ScaleFade
									in={!newAppIsOpen}
									transition={{
										enter: { duration: 1 },
										delay: !newAppIsOpen && initalScale !== 1 ? 1 : 0,
										delayChildren: 0.2,
									}}
									initialScale={initalScale}
									unmountOnExit
								>
									<AppContainer />
								</ScaleFade>
							</Box>
						</AnimatePresence>
					)}
					{portalUser.user?.isDevOpsUserYN === "Y" && (
						<Flex position="fixed" bottom={0} right={0} p={2} zIndex={200}>
							<NewAppButton isOpen={newAppIsOpen} setIsOpen={setNewAppIsOpen} />
						</Flex>
					)}
				</main>
			</BrowserRouter>
		</ChakraProvider>
	);
}
