import React from "react";
import { Box, Button, Text, Skeleton, Tooltip, HStack } from "@chakra-ui/react";
import ToastPopup from "../../../core/Alerts/ToastPopup";
import TimecardEntryTable from "./TimecardEntryTable";
import TimecardEntryList from "./TimecardEntryList";
import PayrollAPI from "../../../APIs/PayrollAPI";
import TimecardClassLegacy from "../classes/TimecardClassLegacy";
import TimecardJobPhaseClassLegacy from "../classes/TimecardJobPhaseClassLegacy";
import PR_WEEK_DAYS from "../constants/PRWeekDays";

import { portalUser } from "../../../App";
import { getDeviceType, convertArrayToMap } from "../../../helperFunctions";
import { v4 } from "uuid";

export default class TimecardEntryContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			payrollAPI: new PayrollAPI(),
			prEndDate: this.props.prEndDate ?? portalUser.user?.prEndDate,
			employee: this.props.employee ?? null,
			timecardJobs: this.props.timecardJobs ?? [],
			timecardList: null,
			dataView: getDeviceType() === "mobile" ? "list" : props.dataView,
			ready: false,
			uploadStatus: null,
			updates: null,
			updateTitle: "",
		};

		this.init = this.init.bind(this);
		this.getAvailableJobPhases = this.getAvailableJobPhases.bind(this);
		this.loadTimecardHours = this.loadTimecardHours.bind(this);
		this.addHours = this.addHours.bind(this);
		this.updateTimecardItem = this.updateTimecardItem.bind(this);
		this.toastShown = this.toastShown.bind(this);
		this.handleUploadRetry = this.handleUploadRetry.bind(this);
		this.changeDataView = this.changeDataView.bind(this);
		this.cloneTimecard = this.cloneTimecard.bind(this);
		this.saveTimecard = this.saveTimecard.bind(this);
		this.sortHours = this.sortHours.bind(this);
	}

	componentDidMount() {
		//navigator.geolocation.getCurrentPosition((position) => {log('Geolocation', position);});
		if (this.props.timecardList) {
			this.setState({
				timecardList: this.props.timecardList,
				ready: true,
			});

			if (this.props.readyCallback) {
				this.props.readyCallback(true);
			}
		} else {
			this.init();
		}
	}

	componentDidUpdate() {
		if (this.props.employee.prco !== this.state.employee.prco) {
			this.setState({
				employee: this.state.employee,
			});
			setTimeout(this.init(), 100);
		}

		if (this.props?.prEndDate !== this.state.prEndDate) {
			this.setState(
				{
					ready: false,
					timecardList: null,
					prEndDate: this.props?.prEndDate,
				},
				() => {
					this.init();
				}
			);

			if (this.props.timecardList) {
				this.setState({
					timecardList: this.props.timecardList,
					ready: true,
				});
			} else {
				this.init();
			}
		}
		if (this.state.timecardList?.payweekHours?.length === 0) {
			this.addHours();
		}
	}

	async init() {
		await this.loadTimecardHours();
	}

	async loadTimecardHours() {
		let timecardList = new TimecardClassLegacy(this.props.employee, this.props.prEndDate);
		await timecardList.loadTimecardInfo();
		await timecardList.loadPayweekTime();

		if (Boolean(timecardList?.timecardHeader)) {
			this.setState({
				timecardList: timecardList,
			});

			if (timecardList.payweekHours?.length === 0 && this.state.tabVal === 0) {
				this.addHours();
			}
		}
		this.setState({
			ready: true,
		});
	}

	addHours() {
		let timecardList = this.state.timecardList;
		timecardList.addItem();
		this.setState(
			{
				timecardList: timecardList,
			},
			() => {
				if (this.props.triggerTableUpdate) {
					this.props.triggerTableUpdate();
				}
			}
		);
	}

	async getAvailableJobPhases(job) {
		let timecardJobs = this.state.timecardJobs;
		let phases = null;
		if (job.job === "N/A") {
			phases = [{ key: "N/A", value: "N/A" }];
		} else {
			phases = await this.state.payrollAPI.GetTimecardJobPhases(job.job, job.jcco);

			if (phases.status === 200) {
				phases = phases.value;
			}
			if (phases.status === 400) {
				if (job.job === "N/A") {
					phases = [{ key: "N/A", value: "N/A", phase: "N/A", phaseGroup: null }];
				}
			}
		}

		for (let i = 0; i < phases.length; i++) {
			phases[i] = new TimecardJobPhaseClassLegacy(phases[i]);
		}

		for (let i = 0; i < timecardJobs.length; i++) {
			if (job.keyId === timecardJobs[i].keyId) {
				timecardJobs[i].phases = phases;
				break;
			}
		}
		this.setState({
			timecardJobs: timecardJobs,
		});
	}

	async cloneTimecard() {
		let employee = this.state.employee;
		let timecardList = this.state.timecardList;
		let prEndDate = timecardList?.prEndDate ?? portalUser.user?.prEndDate;
		let cloneResult = await this.state.payrollAPI.CloneTimecardRows(employee.employeeUID, prEndDate, employee.prco);
		if (cloneResult.status === 200) {
			timecardList.loadTimeFromArray(cloneResult.value);
		}
		this.setState({
			timecardList: timecardList,
		});
	}

	async updateTimecardItem(update) {
		let timecardList = this.state.timecardList;
		let status = await timecardList.updateTimecardEntry(update);
		if (Boolean(timecardList.payweekHours[update.rowIndex]?.keyId) && update.attr !== "delete") {
			update.uploadId = v4();

			let statusStr = status ? "Success" : "Error";
			let updateTitle = "";

			if (update.attr === "hours") {
				let days = PR_WEEK_DAYS;
				updateTitle = statusStr + ": " + days[update.hourIndex] + " Hours " + (status ? "Saved" : "Not Saved");
				if (!update.upload) {
					statusStr = null;
				}
			} else if (update.attr === "delete") {
				updateTitle = statusStr + ": Entry Row " + (status ? "Deleted" : "Not Deleted");
			} else {
				updateTitle = statusStr + ": Timecard " + (status ? "Saved" : "Not Saved");
			}

			this.setState({
				uploadStatus: statusStr,
				updates: update,
				updateTitle: updateTitle,
			});
		}
		this.setState({
			timecardList: timecardList,
		});
		if (
			update.attr === "job" &&
			this.state.timecardJobs.find(({ job }) => job === update.value.job).phases === null
		) {
			this.getAvailableJobPhases(update.value);
		}
	}

	async saveTimecard(rowIndex, item) {
		let timecardList = this.state.timecardList;

		if (!Boolean(timecardList.payweekHours[rowIndex]?.keyId)) {
			let keyId = timecardList.payweekHours[rowIndex]?.keyId;
			item.keyId = keyId;
			timecardList.payweekHours[rowIndex] = item;

			if (
				timecardList.isUnique(timecardList.payweekHours[rowIndex]) &&
				timecardList.payweekHours[rowIndex].isFilled()
			) {
				await timecardList.payweekHours[rowIndex].insertTimecardEntry();
				await timecardList.payweekHours[rowIndex]?.save();
			}
		} else {
			let keyId = timecardList.payweekHours[rowIndex]?.keyId;
			item.keyId = keyId;
			timecardList.payweekHours[rowIndex] = item;
			await timecardList.payweekHours[rowIndex]?.save();
		}
		this.setState({
			timecardList: timecardList,
		});
	}

	toastShown() {
		this.setState({
			uploadStatus: null,
			updateTitle: "",
		});
	}

	handleUploadRetry(updates) {
		if (updates.attr === "hours") {
			this.updateTimecardItem(updates);
		} else {
			this.updateTimecardItem(updates);
		}
	}

	changeDataView(view) {
		this.setState({ dataView: view });
	}

	sortHours(sortBy) {
		let timecardList = this.state.timecardList;
		timecardList.sortPayweekHours(sortBy);
		this.setState({
			timecardList: timecardList,
		});
	}

	render() {
		let jobs = [];

		for (let i = 0; i < this.state.timecardJobs?.length; i++) {
			if (this.state.timecardJobs[i].jcco === this.state.employee?.prco) {
				jobs.push(this.state.timecardJobs[i]);
			}
		}

		let showCloneTime = false;

		let timecardRows = this.state.timecardList?.payweekHours ?? [];
		if (Boolean(this.state.prEndDate)) {
			if (timecardRows.length === 0) {
				showCloneTime = true;
			} else if (timecardRows.length === 1) {
				if (!Boolean(timecardRows[0]?.keyId) || timecardRows[0]?.earnCode === 7) {
					showCloneTime = true;
				}
			}
		}

		let dataView = this.props.dataView ?? "table";

		return (
			<Box w="full">
				{dataView === "list" ? (
					<TimecardEntryList
						companyMap={convertArrayToMap(portalUser.getCompanies(), "prco")}
						key={this.props.employee.prco + "-" + this.props.employee.employee + "-list"}
						dataView={"list"}
						prEndDate={this.state.prEndDate}
						payPeriods={this.props.payPeriods}
						prco={this.props.prco}
						employee={this.state.employee}
						timecardList={this.state.timecardList}
						timecardJobs={jobs}
						getAvailableJobPhases={this.getAvailableJobPhases}
						addHours={this.addHours}
						updateTimecardItem={this.updateTimecardItem}
						cloneTimecard={this.cloneTimecard}
						ready={this.state.ready}
						saveTimecard={this.saveTimecard}
						sortHours={this.sortHours}
					/>
				) : (
					<TimecardEntryTable
						companyMap={convertArrayToMap(portalUser.getCompanies(), "prco")}
						key={this.props.employee.prco + "-" + this.props.employee.employee + "-table"}
						dataView={"table"}
						prEndDate={this.state.prEndDate}
						payPeriods={this.props.payPeriods}
						prco={this.props.prco}
						employee={this.state.employee}
						timecardList={this.state.timecardList}
						timecardJobs={jobs}
						getAvailableJobPhases={this.getAvailableJobPhases}
						addHours={this.addHours}
						updateTimecardItem={this.updateTimecardItem}
						cloneTimecard={this.cloneTimecard}
						ready={this.state.ready}
						saveTimecard={this.saveTimecard}
						sortHours={this.sortHours}
					/>
				)}
				{!this.state.ready && (
					<Box w="full" px={dataView !== "list" && "32px"}>
						<Skeleton
							isLoaded={this.state.ready}
							borderBottomRadius="md"
							shadow="md"
							w="full"
							minH="60px"
							mb="25px"
						/>
					</Box>
				)}

				<HStack spacing={4} w="full" justify="flex-end" align="center" px={dataView !== "list" && "32px"}>
					<Button
						isLoading={!this.state.ready}
						loadingText="LOADING"
						id="addHourBtn"
						size="md"
						variant="solid"
						colorScheme={this.props.prco === "250" ? "blue" : "teal"}
						onClick={this.addHours}
						px={2}
					>
						<HStack spacing={1} px={1}>
							<i className="fas fa-plus fa-fw "></i>
							<Text textTransform="uppercase" letterSpacing={1}>
								HOURS
							</Text>
						</HStack>
					</Button>

					{showCloneTime && (
						<Tooltip
							label="Clone Timecard Jobs, Phases, and Hours from the Previous Week"
							placement="bottom"
						>
							<Button
								isLoading={!this.state.ready}
								loadingText="LOADING"
								bg="white"
								id="cloneHourBtn"
								variant="outline"
								colorScheme="teal"
								size="md"
								onClick={this.cloneTimecard}
								px={2}
							>
								<HStack spacing={1} px={1}>
									<i className="fas fa-history fa-fw"></i>
									<Text textTransform="uppercase" letterSpacing={1}>
										CLONE
									</Text>
								</HStack>
							</Button>
						</Tooltip>
					)}
				</HStack>

				<ToastPopup
					status={this.state.uploadStatus}
					updates={this.state.updates}
					updateTitle={this.state.updateTitle}
					toastShown={this.toastShown}
					handleUploadRetry={this.handleUploadRetry}
				/>
			</Box>
		);
	}
}

// (this.state.timecardList?.payweekHours?.length < 1 ||
// 	(this.state.timecardList?.payweekHours?.length === 1 &&
// 		!Boolean(this.state.timecardList?.payweekHours[0].keyId)) ||
// 	(this.state.timecardList?.payweekHours?.length === 1 &&
// 		this.state.timecardList?.payweekHours[0].earnCode === 7)) &&
// 	Boolean(this.props.payPeriods?.currentPeriod)
