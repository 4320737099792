import Company from "./Company";
import Region from "./Region";
import Department from "./Department";
import Role from "./Role";
import Trade from "./Trade";
import PREmployee from "../modules/Employee/classes/PREmployee";
import User from "../modules/Employee/classes/User";
import Leader from "../modules/Employee/classes/Leader";
// import LongevityAward from "../modules/Employee/classes/LongevityAward";

import Announcement from "../modules/Home/classes/Announcement";
import TimeOffRequest from "../modules/TimeOffRequests/classes/TimeOffRequest";
import Notification from "../modules/Employee/classes/Notification";
import CalendarEvent from "../core/Calendars/classes/CalendarEvent";
import SecurityGroup from "../modules/Employee/classes/SecurityGroup";

import Operations from "../modules/Operations/classes/Operations";

import MSAuthAPI from "../APIs/MSAuthAPI";
import MSGraphAPI from "../APIs/MSGraphAPI";
import AppAPI from "../APIs/InitAPI";

// import { log, convertArrayToMap } from "../helperFunctions";

export default class PortalData {
	constructor(data) {
		if (data instanceof PortalData) {
			Object.assign(this, data);
		} else {
			//MSGRAPH
			this.msGraph = data?.msGraph ?? null;

			//PORTAL DATA
			this.org = data?.org ?? null;
			this.payroll = data?.payroll ?? null;
			this.operations = data?.operations ?? null;
			this.user = data?.user ?? null;
			this.forms = data?.forms ?? null;
			this.sched = data?.sched ?? null;
			this.logs = data?.logs ?? [];
		}
	}

	async initMSGraph(data = this, msAuth = null) {
		if (msAuth instanceof MSAuthAPI && !Boolean(data.msGraph)) {
			data.msGraph = new MSGraphAPI(msAuth);
		}
		return data;
	}

	async initAppData(data = this) {
		let params = { msUserID: data.msGraph?.msUser?.id };
		if (Boolean(params?.msUserID) && !Boolean(data?.app)) {
			let app = new AppData();
			app = await app.initAppData(params);
			data.app = app;
		}
		return data;
	}

	async initUserData(data = this) {
		let params = { msUserID: data.msGraph?.msUser?.id };
		if (Boolean(params?.msUserID) && !Boolean(data?.user)) {
			let user = new UserData();
			user = await user.initUserData(params);
			data.user = user;
		}
		return data;
	}

	async initPayrollData(data = this) {
		let params = { msUserID: data.msGraph?.msUser?.id };
		if (Boolean(params?.msUserID) && !Boolean(data?.payroll)) {
			let payroll = new PayrollData();
			payroll = await payroll.initPayrollData(params);
			data.payroll = payroll;
		}
		return data;
	}

	async initOperations(data = this) {
		let params = { msUserID: data.msGraph?.msUser?.id };
		if (Boolean(params?.msUserID) && !Boolean(data?.operations)) {
			let operations = new Operations();
			operations = await operations.initOperations(params);
			data.operations = operations;
		}
		return data;
	}

	async initProjectData(data = this) {
		let params = {};
		if (Boolean(params?.msUserID) && !Boolean(data?.project)) {
			let project = new ProjectData();
			project = await project.initProjectData(params);
			data.project = project;
		}
		return data;
	}

	async initSchedData(data = this) {
		let params = {};
		if (Boolean(params?.msUserID) && !Boolean(data?.payroll)) {
			let sched = null; // new PayrollData();
			sched = await sched.initSchedData(params);
			data.sched = sched;
		}
		return data;
	}

	async initFormData(data = this) {
		let params = {};
		if (Boolean(params?.msUserID) && !Boolean(data?.payroll)) {
			let forms = null; // new PayrollData();
			forms = await forms.initPayrollData(params);
			data.forms = forms;
		}
		return data;
	}
}

class AppData {
	constructor(app) {
		if (app instanceof AppData) {
			Object.assign(this, app);
		} else {
			//APP
			this.appAPI = app?.appAPI ?? null; //new UsersAPI();
			this.companies = app?.companies ?? [];
			this.regions = app?.regions ?? [];
			this.trades = app?.trades ?? [];
			this.departments = app?.departments ?? [];
			this.roles = app?.roles ?? [];
			this.leaders = app?.leaders ?? [];
			this.users = app?.users ?? [];
			this.employees = app?.employees ?? [];
			this.logs = app?.logs ?? [];
		}
	}

	classifyData(app = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			let queryData = sqlData[i]?.queryData;

			if (queryData === "Company") {
				app.companies.push(new Company(sqlData[i]));
			} else if (queryData === "Region") {
				app.regions.push(new Region(sqlData[i]));
			} else if (queryData === "Trade") {
				app.trades.push(new Trade(sqlData[i]));
			} else if (queryData === "Department") {
				app.departments.push(new Department(sqlData[i]));
			} else if (queryData === "Role") {
				app.roles.push(new Role(sqlData[i]));
			} else if (queryData === "Leader") {
				app.leaders.push(new Leader(sqlData[i]));
			} else if (queryData === "User") {
				app.users.push(new User(sqlData[i]));
			} else if (queryData === "Employee") {
				app.employees.push(new PREmployee(sqlData[i]));
			} else if (queryData === "EventLog") {
				app.logs.push(sqlData[i]);
			}
		}

		return app;
	}

	async initAppData(params = null) {
		let app = this;

		if (Boolean(params?.msUserID) && !Boolean(app?.appAPI)) {
			let appAPI = new AppAPI();
			let sqlData = await appAPI.InitApp(params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				app = this.classifyData(app, sqlData);
				app.appAPI = appAPI;
			}
		}
		return app;
	}
}

class UserData {
	constructor(user) {
		if (user instanceof UserData) {
			Object.assign(this, user);
		} else {
			//APP
			this.userAPI = user?.userAPI ?? null;
			this.notifications = user?.notifications ?? [];
			this.announcements = user?.announcements ?? [];
			this.calendarEvents = user?.calendarEvents ?? [];
			this.timeOffRequests = user?.timeOffRequests ?? [];
			this.emergencyContacts = user?.emergencyContacts ?? [];
			this.longevityAwards = user?.longevityAwards ?? [];
			this.permissions = user?.permissions ?? [];
			this.settings = user?.settings ?? [];
		}
	}

	classifyData(user = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			let queryData = sqlData[i]?.queryData;

			if (queryData === "Notification") {
				user.notifications.push(new Notification(sqlData[i]));
			} else if (queryData === "CalendarEvent") {
				user.calendarEvents.push(new CalendarEvent(sqlData[i]));
			} else if (queryData === "Announcement") {
				user.announcements.push(new Announcement(sqlData[i]));
			} else if (queryData === "TimeOffRequest") {
				user.timeOffRequests.push(new TimeOffRequest(sqlData[i]));
			} else if (queryData === "SecurityGroup") {
				user.securityGroups.push(new SecurityGroup(sqlData[i]));
			}
		}

		return user;
	}

	async initUserData(params = null) {
		let user = this;

		if (Boolean(params?.msUserID) && !Boolean(user?.userAPI)) {
			let userAPI = null; //new UsersAPI();
			let sqlData = null; //await userAPI.InitUser(params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				user = this.classifyData(user, sqlData);
				user.userAPI = userAPI;
			}
		}
		return user;
	}
}

class PayrollData {
	constructor(payroll) {
		if (payroll instanceof PayrollData) {
			Object.assign(this, payroll);
		} else {
			//PAYROLL
			this.payrollAPI = payroll?.payrollAPI ?? null; //new PayrollAPI();
			this.prEndDate = payroll?.prEndDate ?? null;
			this.payPeriods = payroll?.payPeriods ?? [];
			this.resourceGroups = payroll?.resourceGroups ?? [];
			this.groupMembers = payroll?.groupMembers ?? [];
			this.timecardHeaders = payroll?.timecardHeaders ?? [];
			this.timecardRows = payroll?.timecardRows ?? [];
			this.paystubs = payroll?.paystubs ?? [];
		}
	}

	classifyData(payroll = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			// let queryData = sqlData[i]?.queryData;
			// if (queryData === "Notification") {
			// 	user.notifications.push(new Notification(sqlData[i]));
			// } else if (queryData === "CalendarEvent") {
			// 	user.calendarEvents.push(new CalendarEvent(sqlData[i]));
			// } else if (queryData === "Announcement") {
			// 	user.announcements.push(new Announcement(sqlData[i]));
			// } else if (queryData === "TimeOffRequest") {
			// 	user.timeOffRequests.push(new TimeOffRequest(sqlData[i]));
			// } else if (queryData === "SecurityGroup") {
			// 	user.securityGroups.push(new SecurityGroup(sqlData[i]));
			// }
		}

		return payroll;
	}

	async initPayrollData(params = null) {
		let payroll = this;

		if (Boolean(params?.msUserID) && !Boolean(payroll?.payrollAPI)) {
			let payrollAPI = null; //new PayrollAPI();
			let sqlData = null; //await userAPI.InitUser(params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				payroll = this.classifyData(payroll, sqlData);
				payroll.payrollAPI = payrollAPI;
			}
		}
		return payroll;
	}
}

class ProjectData {
	constructor(project) {
		if (project instanceof ProjectData) {
			Object.assign(this, project);
		} else {
			//PROJECT
			this.projectAPI = project?.projectAPI ?? null;
			this.contracts = project?.contracts ?? [];
			this.items = project?.items ?? [];
			this.jobs = project?.jobs ?? [];
			this.costcodes = project?.costcodes ?? [];
			this.acos = project?.acos ?? [];
			this.pcos = project?.pcos ?? [];
			this.rfis = project?.rfis ?? [];
		}
	}

	classifyData(project = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			// let queryData = sqlData[i]?.queryData;
			// if (queryData === "ARCustomer") {
			// 	project.customers.push(new ARCustomer(sqlData[i]));
			// } else if (queryData === "APVendor") {
			// 	project.vendors.push(new APVendor(sqlData[i]));
			// } else if (queryData === "DiversityCert") {
			// 	project.diversityCerts.push(new DiversityCert(sqlData[i]));
			// } else if (queryData === "JCProjectMgr") {
			// 	project.projectMgrs.push(new JCProjectMgr(sqlData[i]));
			// } else if (queryData === "ProjectAcct") {
			// 	project.projectAccts.push(new ProjectAcct(sqlData[i]));
			// } else if (queryData === "Purchaser") {
			// 	project.purchasers.push(new Purchaser(sqlData[i]));
			// } else if (queryData === "Phase") {
			// 	project.costcodes.push(new JCPhase(sqlData[i]));
			// } else if (queryData === "CostType") {
			// 	project.costtypes.push(new JCCostType(sqlData[i]));
			// } else if (queryData === "ScopePct") {
			// 	project.scopes.push(new Scope(sqlData[i]));
			// }
		}

		return project;
	}

	async initProjectData(params = null) {
		let project = this;

		if (Boolean(params?.msUserID) && !Boolean(project?.projectAPI)) {
			let projectAPI = null; //new OpsAPI();
			let sqlData = null; //await userAPI.InitUser(params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				project = this.classifyData(project, sqlData);
				project.projectAPI = projectAPI;
			}
		}
		return project;
	}
}
