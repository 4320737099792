import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import FormQuestion from "./FormQuestion";
import FormSectionCommenters from "./FormSectionCommenters";
import FormSectionEmployeeFeedback from "./FormSectionEmployeeFeedback";
import { portalUser } from "../../../App";

export default function FormSection(props) {
	const form = useMemo(() => props.form ?? null, [props.form]);
	const section = useMemo(() => props.section ?? null, [props.section]);
	const usersMap = useMemo(() => props.usersMap, [props.usersMap]);
	const formData = useMemo(() => props.formData, [props.formData]);
	const questions = useMemo(() => {
		let questions = [];
		if (Boolean(section?.sectionUID)) {
			questions = formData?.getFormQuestions(section?.sectionUID) ?? [];
		}
		return questions;
	}, [formData, section?.sectionUID]);

	const isPrinting = useMemo(() => props.isPrinting ?? false, [props.isPrinting]);
	const isComparing = useMemo(() => props.isComparing ?? false, [props.isComparing]);
	const isEditing = useMemo(() => props.isEditing ?? false, [props.isEditing]);

	const showCommenters = useMemo(() => {
		let showCommenters = false;
		if (form?.formType === "2" && section?.heading === "Employee Feedback") {
			if (!Boolean(form?.dateSubmitted) && form?.assignedTo === portalUser.user?.employeeUID) {
				showCommenters = true;
			}
		}
		return showCommenters;
	}, [form?.assignedTo, form?.dateSubmitted, form?.formType, section?.heading]);

	const showSignature = useMemo(() => {
		let showSignature = false;
		if (form?.formType === "2" && section?.heading === "Employee Feedback") {
			if (Boolean(form?.dateCompleted)) {
				showSignature = true;
			} else if (form?.assignedFor === portalUser.user?.employeeUID) {
				showSignature = true;
			}
		}
		return showSignature;
	}, [form?.assignedFor, form?.dateCompleted, form?.formType, section?.heading]);

	const styles = useMemo(() => {
		let flexDirection = isPrinting ? "row" : { base: "column", md: "row" };

		let headingFontColor = isPrinting ? "var(--chakra-colors-gray-800)" : "gray.400";
		let headingFontSize = isPrinting ? "22px" : "2xl";
		let headingLetterSpacing = 3;
		let headingTextTransform = "uppercase";

		let subheadingTextAlign = "right";
		let subheadingMinW = isPrinting ? "150px" : 32;
		let subheadingMaxW = isPrinting ? "250px" : 40;
		let subheadingFontColor = isPrinting ? "var(--chakra-colors-gray-800)" : "gray.500";
		let subheadingFontSize = isPrinting ? "12px" : "sm";

		let labelTextAlign = "right";
		let labelMinW = isPrinting ? "150px" : 32;
		let labelMaxW = isPrinting ? "250px" : 40;
		let labelFontColor = isPrinting ? "var(--chakra-colors-gray-800)" : "gray.500";
		let labelFontSize = isPrinting ? "16px" : "lg";

		let valueTextAlign = "left";
		let valueMinW = isPrinting ? "150px" : "2xs";
		let valueMaxW = isPrinting ? "200px" : "xs";
		let valueFontColor = isPrinting ? "black" : "gray.600";
		let valueFontSize = isPrinting ? "16px" : "lg";
		let valueFontWeight = isPrinting ? "normal" : form?.assignedFor ? "semibold" : "normal";
		let valueFontStyle = isPrinting ? "normal" : form?.assignedFor ? "normal" : "italic";
		let valueBottomWidth = 2;

		return {
			headingFontColor,
			headingFontSize,
			headingLetterSpacing,
			headingTextTransform,

			flexDirection,

			subheadingMinW,
			subheadingMaxW,
			subheadingTextAlign,
			subheadingFontColor,
			subheadingFontSize,

			labelMinW,
			labelMaxW,
			labelTextAlign,
			labelFontColor,
			labelFontSize,

			valueMinW,
			valueMaxW,
			valueTextAlign,
			valueFontColor,
			valueFontSize,
			valueFontWeight,
			valueFontStyle,
			valueBottomWidth,
		};
	}, [form?.assignedFor, isPrinting]);

	return (
		<Box
			id={"formSection-" + section?.sectionUID}
			key={"formSection-" + section?.sectionUID}
			className="page-break"
			w="full"
			rounded={"md"}
		>
			<Stack w="full">
				<Flex w="full" flex={1} justify="space-between">
					{!isEditing && (
						<Heading
							fontSize={styles.headingFontSize}
							color={styles.headingFontColor}
							letterSpacing={styles.headingLetterSpacing}
							textTransform={styles.headingTextTransform}
							minH={"40px"}
						>
							{section?.heading}
						</Heading>
					)}
				</Flex>
				<Stack w="full" px={4}>
					{Boolean(section?.subheading) && (
						<Text
							fontStyle="italic"
							fontSize={styles.subheadingFontSize}
							color={styles.subheadingFontColor}
							dangerouslySetInnerHTML={{ __html: section?.subheading }}
						/>
					)}

					{!showCommenters &&
						questions?.map((question, q) => (
							<FormQuestion
								key={q}
								formData={formData}
								form={form}
								section={section}
								question={question}
								questionNum={q}
								usersMap={usersMap}
								isPrinting={isPrinting}
								isEditing={isEditing}
								isComparing={isComparing}
							/>
						))}

					{showSignature && <FormSectionEmployeeFeedback form={form} usersMap={usersMap} />}

					{!isPrinting && showCommenters && (
						<FormSectionCommenters form={form} usersMap={usersMap} isPrinting={isPrinting} />
					)}
				</Stack>
			</Stack>
		</Box>
	);
}
