import React, { useMemo } from "react";
import {
	Box,
	Heading,
	Button,
	useBoolean,
	Center,
	Stack,
	StackDivider,
	VStack,
	Text,
	Badge,
	Collapse,
} from "@chakra-ui/react";
import { convertArrayToMap } from "../../../helperFunctions";

const holidays = [
	{
		year: "2023",
		holidays: [
			{ month: "January", days: [{ name: "New Year's Day", day: "Monday, January 2nd", date: "1/2/2023" }] },
			{
				month: "February",
				days: [{ name: "President's Day", day: "Monday, February 20th", date: "2/20/2023" }],
			},
			{ month: "May", days: [{ name: "Memorial Day", day: "Monday, May 29th", date: "5/29/2023" }] },
			{ month: "July", days: [{ name: "Independence Day", day: "Tuesday, July 4th", date: "7/4/2023" }] },
			{ month: "September", days: [{ name: "Labor Day", day: "Monday, September 4th", date: "9/4/2023" }] },
			{
				month: "November",
				days: [
					{
						name: "Thanksgiving",
						day: "Thursday, November 23rd and Friday, November 24th",
						date: "11/23/2023",
					},
				],
			},
			{
				month: "December",
				days: [
					{
						name: "Christmas",
						day: "Friday, December 22nd and Monday December 25th",
						date: "12/22/2023",
					},
					{ name: "New Year's Eve", day: "Friday, December 29th", date: "12/29/2023" },
				],
			},
		],
	},
	{
		year: "2024",
		holidays: [
			{ month: "January", days: [{ name: "New Year's Day", day: "Monday, January 1st", date: "1/1/2024" }] },
			{
				month: "February",
				days: [{ name: "President's Day", day: "Monday, February 19th", date: "2/19/2024" }],
			},
			{ month: "May", days: [{ name: "Memorial Day", day: "Monday, May 27th", date: "5/27/2024" }] },
			{ month: "July", days: [{ name: "Independence Day", day: "Thursday, July 4th", date: "7/4/2024" }] },
			{ month: "September", days: [{ name: "Labor Day", day: "Monday, September 2nd", date: "9/2/2024" }] },
			{
				month: "November",
				days: [
					{ name: "Veterans Day", day: "Monday, November 11th", date: "11/11/2024" },
					{
						name: "Thanksgiving",
						day: "Thursday, November 28th and Friday, November 29th",
						date: "11/28/2024",
					},
				],
			},
			{
				month: "December",
				days: [
					{
						name: "Christmas",
						day: "Tuesday, December 24th and Wednesday December 25th",
						date: "12/24/2024",
					},
					{ name: "New Year's Eve", day: "Tuesday, December 31st", date: "12/31/2024" },
				],
			},
		],
	},
];

export default function CompanyHolidaysCard({ year }) {
	const [collapseIsOpen, setCollapseIsOpen] = useBoolean();

	const companyHolidays = useMemo(() => {
		let companyHolidays = convertArrayToMap(holidays, "year");
		companyHolidays = companyHolidays[year]?.holidays ?? [];

		for (let i = 0; i < companyHolidays?.length; i++) {
			for (let j = 0; j < companyHolidays[i]?.days?.length; j++) {
				let today = new Date();
				let date = new Date(companyHolidays[i]?.days[j]?.date);

				if (today.getTime() < date.getTime()) {
					Object.assign(companyHolidays[i]?.days[j], { isUpcoming: true });
					return companyHolidays;
				}
			}
		}
		return companyHolidays;
	}, [year]);

	return (
		<>
			<Collapse in={collapseIsOpen}>
				<Box>
					<Heading px={2} py={1} fontSize="md" variant="title" color="gray.400" textTransform="uppercase">
						{"Company Holidays in " + year}
					</Heading>
					<Box p={2} bg="white" rounded="10" border="1px" borderColor="blackAlpha.200">
						<Stack
							w="full"
							direction={{ base: "column", lg: "row" }}
							spacing="6"
							py="4"
							divider={<StackDivider />}
							overflow="auto"
						>
							{companyHolidays?.map((month, i) => (
								<CompanyHolidayRow key={i} month={month} />
							))}
						</Stack>
					</Box>
				</Box>
			</Collapse>

			<Center py={2}>
				<Button
					variant="outline"
					onClick={setCollapseIsOpen.toggle}
					color="gray.500"
					px={2}
					size="md"
					bg="whiteAlpha.700"
					textTransform="uppercase"
					shadow="md"
					_hover={{
						color: "teal.500",
						fontWeight: "bold",
						bg: "whiteAlpha.700",
						borderColor: "teal.500",
						borderWidth: 2,
					}}
				>
					{(collapseIsOpen ? "Hide" : "Show") + " Holidays in " + year}
				</Button>
			</Center>
		</>
	);
}

function CompanyHolidayRow({ month }) {
	return (
		<VStack flex={1} spacing="4">
			<Heading w="full" textAlign="center" as="h5" size="sm" color="gray.400" variant="title">
				{month?.month}
			</Heading>
			<VStack color="gray.500" spacing="1" w="full">
				{month?.days?.map((day, i) => (
					<Box key={i} textAlign="center">
						{day?.isUpcoming && (
							<Badge colorScheme="green" my="1" color="green" fontSize="md">
								Upcoming
							</Badge>
						)}
						<Text isTruncated fontWeight="semibold">
							{day?.name}
						</Text>
						<Text>Office Closed {day?.day}</Text>
					</Box>
				))}
			</VStack>
		</VStack>
	);
}
