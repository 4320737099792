import { portalUser } from "../../../App";

export default class FormQuestion {
	constructor(question) {
		if (question instanceof FormQuestion) {
			Object.assign(this, question);
		} else {
			this.queryData = question?.queryData ?? "FormQuestion";

			//FormUID
			this.templateUID = question?.templateUID ?? null;
			this.sectionUID = question?.sectionUID ?? null;
			this.questionUID = question?.questionUID ?? null;

			//Integer
			this.keyID = question?.keyID ?? null;
			this.order = question?.order ?? null;

			//String
			this.heading = question?.heading ?? null;
			this.subheading = question?.subheading ?? null;
			this.inputType = question?.inputType ?? null;
			this.inputFormat = question?.inputFormat ?? null;
		}
	}
}
