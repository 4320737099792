import React, { useState } from "react";
import {
	Flex,
	Center,
	Button,
	Heading,
	Textarea,
	useBoolean,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	Badge,
	Stack,
	IconButton,
	SimpleGrid,
	HStack,
	VStack,
} from "@chakra-ui/react";
import TimecardEntryListRowModal from "./TimecardEntryListRowModal";
import PayrollAPI from "../../../APIs/PayrollAPI";
import TimecardEntryRowClassLegacy from "../classes/TimecardEntryRowClassLegacy";
import TimecardJobPhaseClassLegacy from "../classes/TimecardJobPhaseClassLegacy";
import PR_WEEK_DAYS from "../constants/PRWeekDays";
import EARN_CODES from "../constants/EarnCodes";
import { formatDate, convertArrayToMap } from "../../../helperFunctions";
import moment from "moment";

const earnCodeMap = convertArrayToMap(EARN_CODES, "value");

export default function TimecardEntryListRow(props) {
	const [preOpen, setPreOpen] = useState(false);
	const [notesOpen, setNotesOpen] = useState(false);
	const [dayNum, setDayNum] = useState(0);
	const [modalIsOpen, toggleModal] = useBoolean();

	const [timecardRow, setTimecardRow] = useState(new TimecardEntryRowClassLegacy(props.item));
	const [timecardJobs, setAvailableJobs] = useState(props.timecardJobs);

	const closePreOpen = () => {
		setPreOpen(false);
	};

	const loadData = (dataName) => {
		if (dataName === "phases") {
			getJobPhases();
		}
	};

	const getJobPhases = async () => {
		let jobs = timecardJobs;
		let lookupJob = timecardJobs?.find(({ job }) => job === timecardRow.job);
		let phases;
		if (lookupJob.job === "N/A") {
			phases = [{ key: "N/A", value: "N/A" }];
		} else {
			let payrollAPI = new PayrollAPI();
			phases = await payrollAPI.GetTimecardJobPhases(lookupJob.job, lookupJob.jcco);
			if (phases.status === 200) {
				phases = phases.value;
			} else if (phases.status === 400) {
				if (lookupJob.job === "N/A") {
					phases = [{ key: "N/A", value: "N/A" }];
				}
			}
		}

		for (let i = 0; i < phases.length; i++) {
			phases[i] = new TimecardJobPhaseClassLegacy(phases[i]);
		}

		for (let i = 0; i < jobs.length; i++) {
			if (lookupJob.keyId === jobs[i].keyId) {
				jobs[i].phases = phases;
				break;
			}
		}

		setAvailableJobs([...jobs]);
	};

	const selectJob = (job) => {
		timecardRow.changeJob(job, false);
		if (!Boolean(timecardRow?.keyId) && timecardRow.isFilled()) {
			timecardRow.keyId = 1;
		}
		setTimecardRow(new TimecardEntryRowClassLegacy(timecardRow));
	};

	const selectEarnCode = (earnCode) => {
		earnCode = earnCode.value;

		timecardRow.changeEarnCode(earnCode, false);
		if (!Boolean(timecardRow?.keyId) && timecardRow.isFilled()) {
			timecardRow.keyId = 1;
		}
		setTimecardRow(new TimecardEntryRowClassLegacy(timecardRow));
	};

	const selectPhase = (phase) => {
		timecardRow.changePhase(phase, false);
		if (!Boolean(timecardRow?.keyId) && timecardRow.isFilled()) {
			timecardRow.keyId = 1;
		}
		setTimecardRow(new TimecardEntryRowClassLegacy(timecardRow));
	};

	const selectShift = (ev) => {
		let shift = ev.target.value;

		timecardRow.changeShift(shift, false);
		if (!Boolean(timecardRow?.keyId) && timecardRow.isFilled()) {
			timecardRow.keyId = 1;
		}
		setTimecardRow(new TimecardEntryRowClassLegacy(timecardRow));
	};

	const updateHours = (ev, index, upload = false) => {
		let value = ev.target.value;
		timecardRow.updateHours(index, value, false);
		setTimecardRow(new TimecardEntryRowClassLegacy(timecardRow));
	};

	const updateNotes = (ev) => {
		let notesIndex = dayNum;
		let value = ev.target.value;
		timecardRow.updateNotes(notesIndex, value, false);
		setTimecardRow(new TimecardEntryRowClassLegacy(timecardRow));
	};

	const deleteEntry = (ev) => {
		ev.stopPropagation();
		let deletion = { rowIndex: props.rowIndex, attr: "delete", keyId: props.item?.keyId };
		props.updateTimecardItem(deletion);
	};

	const openNotes = (dayIndex) => {
		setDayNum(dayIndex);
		setNotesOpen(true);
	};

	const closeNotes = () => {
		setNotesOpen(false);
	};

	const save = () => {
		props.saveTimecard(props.rowIndex, timecardRow);
		toggleModal.off();
	};

	const cancel = () => {
		setTimecardRow(new TimecardEntryRowClassLegacy(props.item));
		toggleModal.off();
	};

	let disabledEarnCodes = [...props.disabledEarnCodes];
	let timecardJobsCopy = [...timecardJobs];
	let disabledPhases = [];

	let checkEarnCode = timecardRow?.earnCode;
	let checkJob = timecardRow?.job;
	let checkPhase = timecardRow?.phase;
	let checkShift = timecardRow?.shift;

	if (checkJob === "N/A" && checkPhase === "N/A") {
		disabledEarnCodes.push("OT");
		disabledEarnCodes.push("DT");
	} else if (checkJob !== null && checkJob !== "N/A") {
		disabledEarnCodes.push("VAC");
		disabledEarnCodes.push("SICK");
		disabledEarnCodes.push("HOL");
	}

	for (let i = 0; i < props.payweekHours?.length; i++) {
		if (props.payweekHours[i].keyId === undefined || props.payweekHours[i].keyId === props.item?.keyId) {
			continue;
		}

		if (checkJob === props.payweekHours[i].job && checkPhase === props.payweekHours[i].phase) {
			disabledEarnCodes.push(props.payweekHours[i].earnCode);
		}

		for (let j = 0; j < timecardJobsCopy?.length; j++) {
			if (
				checkJob === props.payweekHours[i].job &&
				checkEarnCode === props.payweekHours[i].earnCode &&
				checkShift === props.payweekHours[i].shift
			) {
				if (timecardJobsCopy[j].disabledPhases === undefined) {
					timecardJobsCopy[j].disabledPhases = [];
				}
				disabledPhases.push(props.payweekHours[i].phase);
			}
		}
	}

	let disabledJobs = [...props.disabledJobs];
	for (let i = 0; i < props.timecardJobs?.length; i++) {
		if (checkEarnCode === 5 || checkEarnCode === 6 || checkEarnCode === 7) {
			if (i === 0) {
				continue;
			}
			disabledJobs.push(props.timecardJobs[i].key);
		}
	}

	if (modalIsOpen) {
		for (let i = 0; i < props.payweekHours?.length; i++) {
			if (props.payweekHours[i].earnCode === timecardRow.earnCode && props.payweekHours[i].job === "N/A") {
				disabledJobs.push(props.payweekHours[i].job);
			}

			if (
				props.payweekHours[i].earnCode === timecardRow.earnCode &&
				props.payweekHours[i].job === timecardRow.job
			) {
				disabledPhases.push(props.payweekHours[i].phase);
			}

			if (
				props.payweekHours[i].earnCode === timecardRow.earnCode &&
				props.payweekHours[i].job === timecardRow.job &&
				props.payweekHours[i].phase === timecardRow.phase
			) {
				disabledEarnCodes.push(EARN_CODES.find(({ value }) => value === props.payweekHours[i].earnCode)?.key);
			}
		}
	}

	return (
		<VStack
			key={props.id}
			id={props.id}
			onClick={toggleModal.on}
			w="full"
			flex={1}
			bg="white"
			shadow="md"
			borderWidth={2}
			borderColor="gray.400"
			rounded="5"
			direction="column"
			spacing={4}
			p="4"
			mb="4"
		>
			<HStack spacing="2" flex={1} w="full" align="flex-start">
				<Stack spacing="2" w="full">
					<HStack spacing="2" wrap="wrap" align="center">
						<Badge
							fontSize="md"
							textAlign="center"
							colorScheme={Boolean(timecardRow?.job) ? "blue" : "gray"}
							color={Boolean(timecardRow?.job) ? "blue.500" : "gray.500"}
						>
							{!timecardRow?.job
								? "JOB #"
								: timecardRow?.earnCode <= 3 && timecardRow?.job !== "N/A"
								? timecardRow?.job
								: EARN_CODES.find(({ value }) => value === timecardRow?.earnCode)?.key + " Hrs"}
						</Badge>

						<Heading
							fontWeight="semibold"
							textTransform="uppercase"
							letterSpacing={0}
							as="span"
							fontSize="md"
							ml="2"
							color={timecardRow?.job ? "gray.500" : "gray.400"}
						>
							{timecardRow?.earnCode <= 3
								? Boolean(timecardRow?.job)
									? timecardRow?.jobDescription
									: ""
								: EARN_CODES.find(({ value }) => value === timecardRow?.earnCode)?.description +
								  " Hours"}
						</Heading>
					</HStack>
					<HStack spacing="2" wrap="wrap" align="center">
						<Badge
							fontSize="md"
							textAlign="center"
							colorScheme={Boolean(timecardRow?.phase) ? "blue" : "gray"}
							color={Boolean(timecardRow?.phase) ? "blue.500" : "gray.500"}
						>
							{timecardRow?.phase ?? "PHASE"}
						</Badge>
						<Heading
							fontWeight="semibold"
							textTransform="uppercase"
							letterSpacing={0}
							as="span"
							fontSize="md"
							ml="2"
							color={timecardRow?.phase ? "gray.500" : "gray.400"}
						>
							{timecardRow?.phaseDescription}
						</Heading>
					</HStack>
				</Stack>

				<VStack spacing="2" display={{ base: "none", sm: "flex" }}>
					<Badge
						w="full"
						fontSize="md"
						textAlign="center"
						colorScheme={Boolean(timecardRow?.earnCode) ? "blue" : "gray"}
						color={Boolean(timecardRow?.earnCode) ? "blue.500" : "gray.500"}
					>
						{Boolean(timecardRow?.earnCode)
							? EARN_CODES.find(({ value }) => value === timecardRow?.earnCode)?.key + " Hrs"
							: "TYPE"}
					</Badge>
					<Badge w="full" fontSize="md" textAlign="center" colorScheme="gray" color="gray.500">
						SHIFT {timecardRow?.shift}
					</Badge>
				</VStack>

				<IconButton
					size="lg"
					minW="30px"
					maxW="30px"
					minH="30px"
					maxH="30px"
					colorScheme={props.timecardHeader?.isApproved() || timecardRow?.isLocked() ? "gray" : "red"}
					variant="ghost"
					onClick={deleteEntry}
					isDisabled={props.timecardHeader?.isApproved() || timecardRow?.isLocked()}
				>
					<i
						className={
							"fas fa-fw fa-lg hoverActivated " +
							(props.timecardHeader?.isApproved() || timecardRow?.isLocked()
								? "fa-lock"
								: "fa-times-circle")
						}
					></i>
				</IconButton>
			</HStack>

			<HStack w="full" align="center" spacing="2">
				<Flex w="full" justify="center">
					<SimpleGrid minChildWidth="90px" w="full" spacing={1} columns={4} textTransform="uppercase">
						<VStack
							display={{ base: "flex", sm: "none" }}
							p={2}
							spacing={1}
							align="center"
							fontWeight="semibold"
							rounded="md"
							border="2px"
							borderColor="blackAlpha.200"
							minH="65px"
						>
							<Badge w="full" fontSize="sm" textAlign="center" colorScheme="blue" color="blue.500">
								{(earnCodeMap[timecardRow?.earnCode]?.key ?? "TYPE") +
									(earnCodeMap[timecardRow?.earnCode]?.key && " Hrs")}
							</Badge>
							<Badge w="full" fontSize="sm" textAlign="center" colorScheme="gray" color="gray.500">
								SHIFT {timecardRow?.shift}
							</Badge>
						</VStack>

						{timecardRow?.hours?.map((hour, j) => (
							<Center
								key={j}
								fontWeight="semibold"
								rounded="md"
								border="2px"
								borderColor="blackAlpha.200"
								minH="65px"
							>
								<VStack spacing={1}>
									<Badge bg="white" fontSize="sm" color="gray.500">
										{moment(formatDate(timecardRow?.prEndDate))
											.subtract(6 - j, "days")
											.format("ddd M/D")}
									</Badge>
									<Heading as="h4" size="sm" color="gray.400">
										{hour === "" ? "0.0" : hour}
									</Heading>
								</VStack>
							</Center>
						))}

						<Center
							fontWeight="semibold"
							rounded="md"
							border="2px"
							borderColor="blackAlpha.200"
							minH="65px"
						>
							<VStack spacing={1}>
								<Badge fontSize="sm" bg="white" color="gray.500">
									Total
								</Badge>
								<Heading as="h4" size="sm" color="gray.400">
									{timecardRow?.getTotalHours().toFixed(1)}
								</Heading>
							</VStack>
						</Center>
					</SimpleGrid>
				</Flex>
			</HStack>

			{Boolean(modalIsOpen) && (
				<TimecardEntryListRowModal
					modalIsOpen={modalIsOpen}
					cancel={cancel}
					save={save}
					preOpen={preOpen}
					closePreOpen={closePreOpen}
					loadData={loadData}
					timecardHeader={props.timecardHeader}
					timecardRow={timecardRow}
					selectEarnCode={selectEarnCode}
					selectPhase={selectPhase}
					selectJob={selectJob}
					selectShift={selectShift}
					updateHours={updateHours}
					openNotes={openNotes}
					disabledJobs={disabledJobs}
					disabledEarnCodes={disabledEarnCodes}
					disabledPhases={disabledPhases}
				/>
			)}

			<Modal isOpen={notesOpen} onClose={closeNotes} size="xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader py={2}>Notes For {PR_WEEK_DAYS[dayNum]}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Textarea
							defaultValue={timecardRow?.notes[dayNum] ?? null}
							index={dayNum}
							onBlur={updateNotes}
							name={"DayNotes" + dayNum}
						/>
					</ModalBody>
					<ModalFooter>
						<Button size="sm" colorScheme="red" py={2} onClick={closeNotes}>
							CLOSE
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</VStack>
	);
}
