import moment from "moment";
import { portalUser } from "../../../App";
import FormTemplate from "./FormTemplate";
import FormCommenter from "./FormCommenter";
import { convertArrayToMap, formatValue, log } from "../../../helperFunctions";
import FORM_STATUSES from "../constants/FormStatuses";
// import FORM_TEMPLATE_TYPES from "../constants/FormTemplateTypes";
import Email from "../../../modules/Employee/classes/Email";
import Notification from "../../../modules/Employee/classes/Notification";

const formStatusMap = convertArrayToMap(FORM_STATUSES, "id");

const formColumns = [
	{ id: "assignedTo", sqlColumn: "AssignedTo", valueType: "uid" },
	{ id: "assignedFor", sqlColumn: "AssignedFor", valueType: "uid" },
	{ id: "reviewedBy", sqlColumn: "SitDownReviewer", valueType: "uid" },
	{ id: "signedOffBy", sqlColumn: "RequiresApprovalEmployee", valueType: "uid" },
	{ id: "dateDue", sqlColumn: "DateDue", valueType: "dateTime" },
	{ id: "dateExpired", sqlColumn: "DateExpired", valueType: "dateTime" },
	{ id: "dateSubmitted", sqlColumn: "DateSubmitted", valueType: "dateTime" },
	{ id: "dateApproved", sqlColumn: "DateApproved", valueType: "dateTime" },
	{ id: "dateReview", sqlColumn: "DateReview", valueType: "dateTime" },
	{ id: "dateCompleted", sqlColumn: "DateCompleted", valueType: "dateTime" },
	{ id: "dateSignedOff", sqlColumn: "DateEmployeeApproved", valueType: "dateTime" },
	{ id: "dateCreated", sqlColumn: "DateCreated", valueType: "dateTime" },
	{ id: "isLockedYN", sqlColumn: "Locked", valueType: "boolean" },
	{ id: "isVisibleYN", sqlColumn: "IsVisible", valueType: "boolean" },
	{ id: "isArchivedYN", sqlColumn: "Archived", valueType: "boolean" },
	{ id: "requiresHRApprovalYN", sqlColumn: "RequiresApprovalHR", valueType: "boolean" },
	{ id: "requiresReviewYN", sqlColumn: "RequiresReview", valueType: "boolean" },
];

const formColumnsMap = convertArrayToMap(formColumns, "id");

export default class Form extends FormTemplate {
	constructor(form, template = null) {
		super(template);

		if (form instanceof Form && !Boolean(template)) {
			Object.assign(this, form);
		} else {
			// let templateMap = convertArrayToMap(portalUser.formData.templates, "templateUID");
			// template = templateMap[form?.templateUID] ?? null;

			this.queryData = form?.queryData ?? "Form";
			this.keyID = form?.keyID ?? null;
			this.formYear = template?.formYear ?? form?.formYear ?? null;
			this.formType = template?.formType ?? form?.formType ?? "0";
			this.formTypeDesc = template?.formTypeDesc ?? form?.formTypeDesc ?? null;

			//FormUID
			this.templateUID = (form?.templateUID ?? template?.templateUID)?.toLowerCase() ?? null;
			this.parentFormUID = (form?.parentFormUID ?? form?.formUID ?? template?.templateUID)?.toLowerCase() ?? null;
			this.formUID = (form?.formUID ?? template?.templateUID)?.toLowerCase() ?? null;

			//EmployeeUID
			this.assignedTo = form?.assignedTo?.toLowerCase() ?? null;
			this.assignedFor = form?.assignedFor?.toLowerCase() ?? null;
			this.approvedBy = form?.approvedBy?.toLowerCase() ?? null;
			this.reviewedBy = form?.reviewedBy?.toLowerCase() ?? null;
			this.sitDownReviewer = form?.sitDownReviewer?.toLowerCase() ?? null;
			this.signedOffBy = form?.signedOffBy?.toLowerCase() ?? null;
			this.createdBy = form?.createdBy?.toLowerCase() ?? null;
			this.modifiedBy = form?.modifiedBy?.toLowerCase() ?? null;

			//String
			this.assignedToName = form?.assignedToName ?? null;
			this.assignedForName = form?.assignedForName ?? null;
			this.approvedByName = form?.approvedByName ?? null;
			this.reviewedByName = form?.reviewedByName ?? null;
			this.sitDownReviewerName = form?.sitDownReviewerName ?? null;
			this.signedOffByName = form?.signedOffByName ?? null;

			this.title = form?.title ?? template?.title ?? null;
			this.subtitle = form?.subtitle ?? template?.subtitle ?? null;
			this.formTitle = form?.formTitle ?? template?.title ?? null;
			this.formSubtitle = form?.formSubtitle ?? template?.subtitle ?? null;
			this.status = form?.status ?? null;

			//Dates
			this.dateCreated = moment(form?.dateCreated ?? new Date());
			this.dateModified = form?.dateModified ? moment.utc(form?.dateModified) : null;
			this.dateDue = form?.dateDue ? moment.utc(form?.dateDue) : null;
			this.dateExpired = form?.dateExpired ? moment.utc(form?.dateExpired) : null;
			this.dateSubmitted = form?.dateSubmitted ? moment.utc(form?.dateSubmitted) : null;
			this.dateApproved = form?.dateApproved ? moment.utc(form?.dateApproved) : null;
			this.dateReview = form?.dateReview ? moment(form?.dateReview) : null;
			this.dateCompleted = form?.dateCompleted ? moment.utc(form?.dateCompleted) : null;
			this.dateSignedOff = form?.dateSignedOff ? moment.utc(form?.dateSignedOff) : null;

			//Boolean
			this.requiresReviewYN = form?.requiresReviewYN ?? "N";
			this.requiresHRApprovalYN = form?.requiresHRApprovalYN ?? "N";
			this.isLockedYN = form?.isLockedYN ?? "N";
			this.isVisibleYN = form?.isVisibleYN ?? "N";
			this.isArchivedYN = form?.isArchivedYN ?? "N";
			this.isTemplateYN = form?.isTemplatetYN ?? "N";

			//Arrays
			this.sections = form?.sections ?? [];
			this.commenters = form?.commenters ?? [];
			this.pages = form?.pages ?? [];

			this.lastUpdated = null;
			this.error = false;
			this.errorArray = [];
		}
		this.getNamesAndTitles();
		this.getIsTemplateYN();
		this.getFormTypeDesc();
		this.getFormStatus();
		this.getIsVisibleYN();
	}

	//FORM STATUSES & PROPERTIES

	getNamesAndTitles() {
		let usersMap = convertArrayToMap(
			portalUser?.userData?.filter((d) => d.queryData === "User"),
			"employeeUID"
		);
		let commentersMap = convertArrayToMap(portalUser.formData?.commenters, "formUID", true);
		this.assignedToName = usersMap[this.assignedTo]?.name ?? "N/A - Not Assigned";
		this.assignedForName = usersMap[this.assignedFor]?.name ?? null;
		this.approvedByName = usersMap[this.approvedBy]?.name ?? null;
		this.reviewedByName = usersMap[this.reviewedBy]?.name ?? null;
		this.sitDownReviewerName = usersMap[this.sitDownReviewer]?.name ?? null;
		this.signedOffByName = usersMap[this.signedOffBy]?.name ?? null;

		this.formTitle = this.title?.replace("Self Evaluation", "Self-Evaluation");
		this.formSubtitle =
			this.subtitle + (Boolean(usersMap[this.assignedFor]?.name) ? " - " + usersMap[this.assignedFor]?.name : "");
		this.formSubtitle = this.formSubtitle?.replace("Self Evaluation", "Self-Evaluation");
		this.commenters = commentersMap[this.formUID] ?? [];
	}

	getIsTemplateYN() {
		let isTemplateYN = Boolean(this.formUID === this.templateUID) ? "Y" : "N";
		this.isTemplateYN = isTemplateYN;
		return isTemplateYN;
	}

	getFormStatus() {
		let status = "0";
		if (Boolean(this.dateCompleted)) {
			status = "5";
		} else if (Boolean(this.dateReview)) {
			status = "4";
		} else if (Boolean(this.dateApproved)) {
			status = "3";
		} else if (Boolean(this.dateSubmitted)) {
			status = "2";
		} else if (Boolean(this.dateModified)) {
			status = "1";
		}
		let formStatus = formStatusMap[status];
		this.status = formStatus.status;
		return formStatus;
	}

	getIsVisibilityDisabled() {
		let formStatus = this.getFormStatus();
		if (this.formType === "1") {
			return false;
		} else if (parseInt(formStatus.id) >= 3) {
			return false;
		} else {
			return true;
		}
	}

	getIsVisibleYN() {
		let isVisibleYN = "N";
		let isVisibilityDisabled = this.getIsVisibilityDisabled();
		let formStatus = this.getFormStatus();

		if (isVisibilityDisabled) {
			isVisibleYN = "N";
		} else if (this.formType === "1") {
			isVisibleYN = "Y";
		} else if (parseInt(formStatus.id) === 5) {
			isVisibleYN = "Y";
		} else if (this.formType === "3" && this.formUID !== this.parentFormUID) {
			isVisibleYN = "Y";
		} else {
			isVisibleYN = this.isVisibleYN;
		}
		this.isVisibleYN = isVisibleYN;
		return isVisibleYN;
	}

	isDisabled() {
		let formStatus = this.getFormStatus();
		let isVisibleYN = this.getIsVisibleYN();
		let commenterUIDs = this.getCommenterUIDs();

		let reviewerUID =
			portalUser?.userData?.find((d) => d.queryData === "User" && d.employeeUID === this.assignedFor)
				?.reviewerUID ?? null;

		if (this.formType === "1") {
			//Self evaluation form should always be available
			return false;
			// if (portalUser.user?.employeeUID === this.assignedFor) {
			// 	return false;
			// } else if (portalUser.user?.isPortalAdminYN === "Y" && parseInt(formStatus.id) >= 2) {
			// 	return false;
			// } else if (parseInt(formStatus.id) === 5) {
			// 	return false;
			// } else {
			// 	return true;
			// }
		} else if (this.formType === "2") {
			//Employee can see their form if visible
			if (portalUser.user?.employeeUID === this.assignedFor && isVisibleYN === "Y") {
				return false;
			}
			//Portal Admin can see form if it has been Submitted
			else if (portalUser.user?.isPortalAdminYN === "Y" && parseInt(formStatus.id) >= 2) {
				return false;
			}
			//Form is assigned to this user
			else if (portalUser.user?.employeeUID === this.assignedTo) {
				return false;
			}
			//This user is this employee's current reviewer
			else if (reviewerUID === portalUser.user?.employeeUID) {
				return false;
			}
			//This user is one of the commenters for this form
			else if (commenterUIDs.includes(portalUser.user?.employeeUID)) {
				return false;
			} else {
				return true;
			}
		} else if (this.formType === "3") {
			//Employee can see their form if visible
			if (
				portalUser.user?.employeeUID === this.assignedFor &&
				isVisibleYN === "N" &&
				parseInt(formStatus.id) < 2
			) {
				return false;
			} else if (
				portalUser.user?.employeeUID === this.assignedFor &&
				isVisibleYN === "Y" &&
				parseInt(formStatus.id) >= 3
			) {
				return false;
			}
			//Portal Admin can see form if it has been Submitted
			else if (portalUser.user?.isPortalAdminYN === "Y" && parseInt(formStatus.id) >= 2) {
				return false;
			}
			//Form is assigned to this user
			else if (portalUser.user?.employeeUID === this.assignedTo && parseInt(formStatus.id) !== 2) {
				return false;
			}
			// //This user is this employee's current reviewer
			// else if (reviewerUID === portalUser.user?.employeeUID) {
			// 	return false;
			// }
			else {
				return true;
			}
		}
	}

	getIsReadOnly() {
		let formStatus = this.getFormStatus();
		if (parseInt(formStatus.id) < 5) {
			if (portalUser.user?.isPortalAdminYN === "Y" && parseInt(formStatus.id) < 3) {
				return false;
			} else if (
				portalUser.user?.isPortalAdminYN === "N" &&
				(portalUser.user?.employeeUID === this.assignedTo ||
					portalUser.user.employeeUID !== this.assignedFor) &&
				parseInt(formStatus.id) < 3
			) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

	getCommenterUIDs() {
		let commenterUIDs = [this.assignedTo, ...new Set(this.commenters?.map((d) => d.employeeUID) ?? [])];
		if (this.formType !== "3") {
			commenterUIDs = commenterUIDs?.filter((d) => Boolean(d) && d !== this.assignedFor) ?? [];
		}
		return commenterUIDs;
	}

	canAddEditComments() {
		let readOnly = this.getIsReadOnly();
		let commenterUIDs = this.getCommenterUIDs();
		if (!readOnly) {
			if (
				this.formType === "3" &&
				parseInt(this.getFormStatus) < 4 &&
				commenterUIDs.includes(portalUser.user?.employeeUID)
			) {
				return true;
			}
			if (commenterUIDs.includes(portalUser.user?.employeeUID)) {
				return true;
			} else if (portalUser.user?.isPortalAdminYN === "Y") {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	//INIT FORM PAGES

	initFormPages() {
		let form = this;

		let pages = [];
		let page = {};
		for (let s = 0; s < form?.sections?.length; s++) {
			let section = form?.sections[s];
			section.pageVal = pages.length;
			if (s === 0 || section?.isNewPageYN === "Y") {
				if (section?.isNewPageYN === "Y") {
					pages.push(page);
				}
				page = {
					templateUID: section?.templateUID,
					pageVal: pages?.length,
					heading: section?.heading,
					sections: [],
				};

				let formStatus = this.getFormStatus();
				if (this.formType === "2" && parseInt(formStatus?.id) < 2 && page.heading === "Employee Feedback") {
					page.heading = "Invite Commenters";
				}
			}
			page.sections.push(section);
		}
		pages.push(page);

		form.pages = pages;

		return form;
	}

	async updateSQL() {
		if (Boolean(this.formUID)) {
			let data = (({ isVisibleYN }) => ({
				isVisibleYN,
			}))(this);

			let result = await portalUser.formData?.formsAPI?.UpdateForm(this.formUID, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = new Form(result?.value[0]);
				Object.assign(this, result);
			}
			return result;
		}
	}

	async updateForm(attr = null, value = null) {
		value = value?.toString() ?? null;
		let column = formColumnsMap[attr] ?? null;
		if (Boolean(column)) {
			let sqlValue = null;
			if (column?.valueType === "dateTime") {
				value = Boolean(value) ? formatValue(value, 0, "moment") : null;
				sqlValue = Boolean(value) ? value?.format("MM/DD/YYYY hh:mm:ss") : null;
			} else if (column?.valueType === "uid") {
				value = Boolean(value?.length === 36) ? value?.toLowerCase() : this[attr];

				sqlValue = value;
			} else if (column?.valueType === "boolean") {
				value = value === "Y" ? "N" : "Y";
				sqlValue = value === "N" ? "0" : "1";
			}

			if (this[attr] !== value) {
				this[attr] = value;
				let params = { formUID: this.formUID };
				let lastUpdated = new Date();
				let updates = {
					[column.sqlColumn]: sqlValue,
					DateModified: formatValue(lastUpdated, 0, "datetimeSQL"),
					ModifiedBy: portalUser.user.employeeUID,
				};

				// log("attr", attr);
				// log("value", value);
				// log("sqlValue", sqlValue);
				if (attr === "dateSubmitted" && this.formType === "1") {
					this.dateCompleted = value;
					updates.DateCompleted = sqlValue;
				} else if (attr === "dateApproved") {
					let approvedBy = Boolean(value) ? portalUser.user.employeeUID : null;
					this.approvedBy = approvedBy;
					updates.ApprovedBy = approvedBy;
				}

				// log("updates", updates);

				let result = await portalUser.formData?.formsAPI?.UpdateForm(params, updates);

				if (result?.status === 200 && result?.value?.length > 0) {
					this.lastUpdated = formatValue(lastUpdated, 0, "moment");
					this.getNamesAndTitles();
					this.getIsTemplateYN();
					this.getFormTypeDesc();
					this.getFormStatus();
					this.getIsVisibleYN();

					return this;
				} else {
					this.error = true;
					return this;
				}
			} else {
				return this;
			}
		}
	}

	async resetForm() {
		if (Boolean(this.formUID) && Boolean(this.keyID)) {
			let params = { formUID: this.formUID };
			let result = await portalUser.formData?.formsAPI?.ResetForm(params);
			if (result?.status === 200) {
				this.keyID = null;
				log("RESET FORM", this.formTitle + " - " + this.formSubtitle);
				return this;
			} else {
				this.error = true;
				return this;
			}
		} else {
			return this;
		}
	}

	async resetFormDates() {
		if (Boolean(this.formUID) && Boolean(this.keyID)) {
			let params = { formUID: this.formUID };
			let result = await portalUser.formData?.formsAPI?.ResetFormDates(params);
			if (result?.status === 200) {
				this.keyID = null;
				log("RESET FORM DATES", this.formTitle + " - " + this.formSubtitle);
				return this;
			} else {
				this.error = true;
				return this;
			}
		} else {
			return this;
		}
	}

	async deleteForm() {
		if (Boolean(this.formUID) && Boolean(this.keyID)) {
			let params = { formUID: this.formUID };
			let result = await portalUser.formData?.formsAPI?.DeleteForm(params);
			if (result?.status === 200) {
				this.keyID = null;
				log("DELETE FORM", this.formTitle + " - " + this.formSubtitle);
			} else {
				this.error = true;
			}
		}
		return this;
	}

	async insertFormCommenter(employeeUID = null, assignedForName, formUID, remove) {
		// Check if the commenter already exists
		const commenterExists = this.commenters.find((commenter) => commenter.employeeUID === employeeUID) ?? null;
		if (commenterExists) {
			log("form - duplicate commenter not inserted");
			return null;
		}

		let commenter = new FormCommenter({
			formUID: this.formUID,
			employeeUID: employeeUID,
		});

		// log("form - insertFormCommenter 1", commenter);
		commenter = await commenter.insertFormCommenter();
		if (Boolean(commenter?.keyID)) {
			this.commenters.push(commenter);
		}
		this.getNamesAndTitles();
		// log("form - insertFormCommenter 4", this);
		this.notifyEmployee(employeeUID, assignedForName, formUID, false);
		return this;
	}

	async notifyEmployee(employeeUID, assignedForName, formUID, remove = false) {
		let notification = new Notification();
		notification.employeeUID = employeeUID;
		notification.type = remove ? "Notification" : "Request";
		notification.category = "commenter";
		if (remove) {
			notification.subject = `Review Comments Request Removed`;
			notification.body = `${portalUser.user.name} removed the request to add comments to ${assignedForName}'s review.`;
		} else {
			notification.subject = `Review Comments Requested`;
			notification.body = `${portalUser.user.name} has requested you add comments to ${assignedForName}'s review.`;
		}
		notification.referenceUID = formUID;
		if (!remove) {
			notification.link = `/team/reviews/${formUID}`;
		}

		let result = await this.insertNotificationSQL(notification);

		if (Boolean(result)) {
			let email = new Email();
			await email.addRecipientById(employeeUID);
			email.subject = `Review Comments Request${remove ? "Removed" : ""}`;
			remove
				? (email.body = (
						<p>
							{portalUser.user.name} has removed the request to add comments to {assignedForName}'s
							performance review.
						</p>
				  ))
				: (email.body = (
						<p>
							{portalUser.user.name} has requested you add comments to {assignedForName}'s performance
							review.
							<br />
							<a href={`https://ppmportal.com/team/reviews/${formUID}`}>Click here to view.</a>
						</p>
				  ));
			email.send();
		}
	}

	async insertNotificationSQL(notification) {
		log("Notification INSERT SQL", notification);
		let insertResult = await portalUser.usersAPI.InsertNotification(notification);
		log("Notification INSERT RESULT SQL", notification);
		if (insertResult.status === 200) {
			return true;
		} else {
			return false;
		}
	}

	async deleteFormCommenter(employeeUID = null) {
		if (Boolean(employeeUID)) {
			let commenter = this.commenters.find((d) => d.employeeUID === employeeUID);
			commenter = await commenter.deleteFormCommenter();
			if (!Boolean(commenter)) {
				this.commenters.filter((d) => d.employeeUID !== employeeUID);
			}
		}
		this.getNamesAndTitles();
		return this;
	}

	//Completion Checks
	// checkFormCompletion() {
	// 	let completed = true;
	// 	for (let i = 0; i < this.sections.length; i++) {
	// 		if (
	// 			this.assignedFor !== portalUser.user.employeeUID &&
	// 			this.sections[i].title.toLowerCase() === "employee feedback"
	// 		) {
	// 			continue;
	// 		}
	// 		completed = this.sections[i].isCompleted();
	// 		if (!completed) {
	// 			return completed;
	// 		}
	// 	}
	// 	return completed;
	// }

	async sendReviewDateEmail(form, date) {
		let notification = new Notification();
		notification.employeeUID = form.assignedFor;
		notification.category = "review";
		notification.subject = `Review Scheduled`;
		notification.body = `${form.assignedToName} has scheduled your ${this.title} on ${moment(date).format(
			"MM/DD/YYYY hh:mm"
		)}`;
		notification.referenceUID = form.formUID;
		notification.link = `/team/reviews/${form.formUID}`;

		let result = await this.insertNotificationSQL(notification);

		if (Boolean(result)) {
			let email = new Email();
			await email.addRecipientById(form.assignedFor);
			email.subject = `${this.title} Scheduled`;
			email.body = (
				<p>
					{this.title} scheduled for {moment(date).format("MM/DD/YYYY hh:mm")} with {form.assignedToName}. The
					invite can be found in your calendar.
				</p>
			);
			email.send();
		}
	}

	//NOTIFICATIONS

	async sendReminder(form) {
		let toEmail;
		let emailBody;
		let subject = "REMINDER: Unfinished Review";

		let status = this.getFormStatus().status.toLowerCase();
		if (status === "not started" || status === "in progress") {
			toEmail = form.assignedTo;
			emailBody = (
				<p>
					This is a reminder to begin/complete {form.assignedForName}'s {this.title}.<br />
					<a href={`https://ppmportal.com/team/reviews/${form.formUID}`}>Go to review.</a>
				</p>
			);
		} else if (status === "approved" && !Boolean(this.dateReview)) {
			toEmail = form.assignedTo;
			emailBody = (
				<p>
					“This is a reminder to complete your {this.title} for {form.assignedForName}.
					<br />
					<a href={`https://ppmportal.com/team/reviews/${form.formUID}`}>Go to review.</a>
				</p>
			);
		} else if (status === "review scheduled" && new Date().getTime() > new Date(this.dateReview).getTime()) {
			toEmail = form.assignedFor;
			emailBody = (
				<p>
					This is a reminder to sign and provide feedback on your {this.title}.
					<br />
					<a href={`https://ppmportal.com/employee/reviews/${form.formUID}`}>Go to review.</a>
				</p>
			);
			subject = "REMINDER: Sign Completed Review";
		}

		if (Boolean(toEmail)) {
			let email = new Email();
			await email.addRecipientById(toEmail);
			email.subject = subject;
			email.body = emailBody;
			await email.send();
		}
	}

	getPeerStatus(formUID = this.parentFormUID) {
		let formData = portalUser?.formData ?? null;
		if (Boolean(formData)) {
			let form = formData?.forms?.find((d) => d.formUID === formUID) ?? null;

			if (form.formType === "3" && form?.formUID !== form?.parentFormUID) {
				form = formData?.forms?.find((d) => d.formUID === form?.parentFormUID) ?? null;
			}
			if (form.formType === "3" && form?.formUID === form?.parentFormUID) {
				let peerForms =
					formData?.forms?.filter(
						(d) => d.formType === "3" && d.parentFormUID === form?.formUID && d.formUID !== d.parentFormUID
					) ?? [];

				let completedEvals = 0;
				let totalEvals = peerForms?.length ?? 1;

				for (let i = 0; i < peerForms?.length; i++) {
					let formStatus = peerForms[i].getFormStatus();
					if (parseInt(formStatus?.id) >= 2) {
						completedEvals += 1;
					}
				}

				return { completedEvals, totalEvals };
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	validateForm() {
		this.errorArray = [];
		let formStatus = this.getFormStatus();
		let test = Boolean(parseInt(formStatus?.id > 1));
		log("portalUser in validateForm function", portalUser);

		log("formStatus in valiarteFOR", formStatus);
		log("should be true", Boolean(parseInt(formStatus?.id > 1) ? "true" : "false"));
		//Only validate if the form is in progress
		log("test", test);
		if (Boolean(parseInt(formStatus?.id > 1))) {
			log("STOP");
			return this.errorArray;
		} else if (Boolean(portalUser.formData)) {
			for (let section of portalUser?.formData?.sections) {
				this.validateSection(section);
			}
		}

		log("errorARray", this.errorArray);
		// Return errors or empty array if no errors
		this.errorArray = this.errorArray.filter(
			(obj, index, self) => index === self.findIndex((o) => o.inputUID === obj.inputUID)
		);

		return this.errorArray.length > 0 ? this.errorArray : [];
	}

	// Section Validation
	validateSection(section) {
		for (let question of portalUser?.formData?.questions) {
			this.validateQuestion(question, section);
		}
	}

	// Question Validation
	validateQuestion(question, section) {
		for (let input of portalUser?.formData?.inputs) {
			this.validateInput(input, question, section);
		}
	}

	// Input Validation
	validateInput(input, question, section) {
		if (!Boolean(input.value) || input.value.trim().length === 0) {
			this.errorArray.push({
				sectionUID: section.sectionUID,
				questionUID: question.questionUID,
				inputUID: input.inputUID,
				value: input.value,
				message: `All questions must be filled out. Please enter N/A where applicable. ${input.inputUID}`,
			});
		} else if (input.description === "Overall Performance" && input.value === "1") {
			this.errorArray.push({
				sectionUID: section.sectionUID,
				questionUID: question.questionUID,
				inputUID: input.inputUID,
				value: input.value,
				message: `All ratings with a score of 1 must have a comment. ${input.inputUID}`,
			});
		} else if (input.value === "1") {
			this.errorArray.push({
				sectionUID: section.sectionUID,
				questionUID: question.questionUID,
				inputUID: input.inputUID,
				value: input.value,
				message: `All ratings with a score of 1 must have a comment. ${input.inputUID}`,
			});
		} else {
			for (let comment of portalUser.formData.comments) {
				this.validateComment(comment, input);
			}
		}
	}

	// Comment Validation
	validateComment(comment, input) {
		if (!Boolean(comment.value) || comment.value.trim().length === 0) {
			this.errorArray.push({
				inputUID: input.inputUID,
				commentUID: comment.commentUID,
				value: comment.value,
				message: `Comments cannot be empty. Please fill out the comment or delete it. ${comment.commentUID}`,
			});
		}
	}
}
