import React from "react";
import { Switch, Route, useLocation, useParams, Redirect } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Payroll from "../Payroll";
import MeritIncreases from "../MeritIncreases";
import Contacts from "../../../modules/Employee/Contacts";

import { portalUser } from "../../../App";
import { formatValue, dateIsValid } from "../../../helperFunctions";

export default function PayrollRoutes({ pageName, tabVal }) {
	let location = useLocation();
	return (
		<Switch location={location}>
			<Route
				exact
				path={`/payroll/upload`}
				children={<Redirect to={"/payroll/upload/" + formatValue(portalUser?.user?.prEndDate, 0, "dateURL")} />}
			/>
			<Route path={`/payroll/employees`} children={<Contacts pageName={pageName} tabVal={tabVal} isPayroll />} />
			<Route path={`/payroll/merit`} children={<MeritIncreases pageName={pageName} tabVal={tabVal} />} />
			<Route
				path={`/payroll/upload/:prEndDate`}
				children={<PayrollRouteViews pageName={pageName} tabVal={tabVal} />}
			/>
		</Switch>
	);
}

function PayrollRouteViews({ pageName, tabVal }) {
	let { prEndDate } = useParams();
	if (!dateIsValid(prEndDate)) {
		prEndDate = null;
	}

	return (
		<Box>
			{Boolean(prEndDate) ? (
				<Payroll pageName={pageName} tabVal={tabVal} prEndDate={formatValue(prEndDate, 0, "date")} />
			) : !Boolean(prEndDate) && Boolean(portalUser?.user?.prEndDate) ? (
				<Redirect to={"/payroll/upload/" + formatValue(portalUser?.user?.prEndDate, 0, "dateURL")} />
			) : (
				<Box>Something went wrong - there is an issue with the Week Ending Date</Box>
			)}
		</Box>
	);
}
