import { portalUser } from "../../../App";
import { convertArrayToMap } from "../../../helperFunctions";

//const modules = ['Employee', 'DevOps', 'HRForms', 'Payroll', ]

export default class PageTemplateEmployees {
	constructor(employees = [], params = { module: "Employee" }, page = {}) {
		//params: isPayrollEmployees, isDirectoryEmployees, isHREmployees
		this.pageTemplate = "Employees";
		this.pageName = page?.pageName ?? "Employees";
		this.pageTitle = page?.pageTitle ?? "Employees";
		this.size = page?.size ?? "LETTER";
		this.orientation = "landscape";
		this.bg = "white";
		this.region = this.getRegion();

		this.pageBody = this.getSections(employees, params);
	}

	getSections(employees = [], params = {}) {
		let pageBody = [];
		pageBody.push(new SectionTemplatePREmployees(employees, params, this));
		return pageBody;
	}

	getRegion(employees = [], params = {}) {
		let regionID = portalUser.user?.regionID ?? "0";
		if (Boolean(params?.regionID)) {
			regionID = params?.regionID;
		} else if (Boolean(employees?.length > 0)) {
			regionID = employees[0]?.regionID ?? "0";
		}
		return portalUser?.getRegions()?.find((p) => p.regionID === regionID)[0];
	}
}

export class SectionTemplatePREmployees {
	constructor(employees = [], params = { module: "Employee" }, page = {}) {
		this.template = "PR Employees";
		this.name = page?.pageName ?? "Employees";
		this.heading = page?.pageTitle ?? "Employees";

		this.type = "virtualTable";

		this.maxRows = 22;
		this.overflowX = "auto";

		this.isCollapsable = true;

		this.template = "Employees";
		this.name = "Employees";
		// this.heading = Boolean(job?.jobID) ? job?.job + " - " + job?.jobName : null;
		// this.subheading = Boolean(job?.jobID) ? job?.contract + " " + job?.contractName + " - " + job?.scopeDesc : null;

		this.subSections = this.getSubSections(employees, params);
	}

	getSubSections(employees = [], params) {
		let subSections = [new SubSectionTemplatePREmployees(employees, params)];
		return subSections;
	}
}

class SubSectionTemplatePREmployees {
	constructor(employees = [], params = { module: "Employee" }) {
		this.template = "Employees";
		this.heading = null;
		this.subheading = null;
		this.footer = this.getFooter(employees);
		this.columns = [];

		let disableExport = !Boolean(portalUser.user.isDevOpsUserYN === "Y");

		let usersMap = convertArrayToMap(portalUser.getUsers(), "employeeUID");

		if (params?.module === "Employee") {
			this.sortBy = [
				{ id: "prco", desc: false },
				{ id: "regionID", desc: false },
				{ id: "parentDeptID", desc: false },
				{ id: "deptID", desc: false },
				{ id: "longevityMths", desc: true },
			];

			this.columns = [
				{
					accessor: "employeeUID",
					Header: "Employee UID",
					width: "300%",
					disableExport,
					show: false,
				},
				{
					accessor: "prehKeyID",
					Header: "Preh KeyID",
					valueType: "integer",
					textAlign: "center",
					disableExport,
					show: false,
				},
				{
					accessor: "prco",
					Header: "PRCo",
					width: "65%",
					disableExport,
					show: false,
				},
				{
					accessor: "companyAbbr",
					Header: "Company",
					width: "65%",
				},

				{
					accessor: "regionID",
					Header: "Region ID",
					disableExport,
					show: false,
				},

				{
					accessor: "region",
					Header: "Region",
					width: "75%",
				},
				{
					accessor: "parentDeptID",
					Header: "Parent Dept ID",
					disableExport,
					show: false,
				},

				{
					accessor: "parentDept",
					Header: "Parent Dept",
					show: false,
					width: "125%",
				},
				{
					accessor: "deptID",
					Header: "Dept ID",
					disableExport,
					show: false,
				},
				{
					accessor: "dept",
					Header: "Department",
					width: "150%",
				},

				{
					accessor: "name",
					Header: "Name",
					width: "150%",
				},
				{
					accessor: "title",
					Header: "Title",
					width: "200%",
				},

				{
					accessor: "email",
					Header: "Email",
					width: "225%",
				},

				{
					accessor: "officePhone",
					Header: "Office Phone",
					width: "125%",
				},
				{
					accessor: "cellPhone",
					Header: "Cell Phone",
					width: "125%",
				},
				{
					accessor: "longevityMths",
					Header: "Longevity Months",
					disableExport,
					show: false,
				},
				{
					accessor: "tradeID",
					Header: "Trade ID",
					disableExport,
					show: false,
				},
				{
					accessor: "trade",
					Header: "Trade",
					width: "75%",
				},
			];
		} else if (params?.module === "Payroll") {
			this.columns = [
				{
					accessor: "prehKeyID",
					Header: "Preh Key ID",
					valueType: "integer",
					textAlign: "center",
					width: "65%",
					textSize: "center",
					disableExport,
					show: false,
				},
				{
					accessor: "queryData",
					Header: "Query Data",
					disableExport,
					width: "200%",
					show: false,
				},
				{
					accessor: "name",
					Header: "Employee Name",
					width: "250%",
					Footer: (rows = []) => {
						return this.getFooter(rows, this.footer);
					},
				},
				{
					accessor: "employeeUID",
					Header: "Employee UID",
					show: false,
					width: "200%",
				},
				{
					accessor: "prco",
					Header: "PRCo",
					width: "50%",
					// show: false,
					// formInput: "text",
					// placeholder: "N/A",
				},
				{
					accessor: "prGroup",
					Header: "PR Group",
					width: "75%",
					// show: false,
				},
				{
					accessor: "prDept",
					Header: "PR Dept",
					width: "75%",
					// show: false,
				},
				{
					accessor: "deptID",
					Header: "Department ID",
					show: false,
				},
				{
					accessor: "roleID",
					Header: "Role ID",
					show: false,
				},
				{
					accessor: "regionID",
					Header: "Region ID",
					show: false,
				},
				{
					accessor: "tradeID",
					Header: "Trade ID",
					show: false,
				},
				{
					accessor: "employee",
					Header: "Employee",
					// show: false,
				},
				{
					accessor: "legalFirstName",
					Header: "Legal First Name",
					show: false,
				},
				{
					accessor: "legalLastName",
					Header: "Legal Last Name",
					show: false,
				},
				{
					accessor: "firstName",
					Header: "First Name",
					show: false,
				},
				{
					accessor: "lastName",
					Header: "Last Name",
					show: false,
				},
				{
					accessor: "midName",
					Header: "Middle Initial",
					show: false,
				},

				{
					accessor: "craft",
					Header: "Craft",
				},
				{
					accessor: "class",
					Header: "Class",
				},
				{
					accessor: "classDesc",
					Header: "Class Description",
					show: false,
				},
				{
					accessor: "title",
					Header: "Title",
					width: "250%",
				},
				{
					accessor: "employeeRole",
					Header: "Role",
					show: false,
				},
				{
					accessor: "employeeType",
					Header: "Type",
					show: false,
				},
				{
					accessor: "activeYN",
					Header: "Active Status",
					show: false,
				},
				{
					accessor: "hireDate",
					Header: "Hire Date",
					show: false,
				},
				{
					accessor: "termDate",
					Header: "Termination Date",
					show: false,
				},
				{
					accessor: "recentRehireDate",
					Header: "Recent Rehire Date",
					show: false,
				},
				{
					accessor: "availableSick",
					Header: "Available Sick",
					show: false,
				},
				{
					accessor: "availableVacation",
					Header: "Available Vacation",
					show: false,
				},
				{
					accessor: "accrualRate",
					Header: "Accrual Rate",
					show: false,
				},
				{
					accessor: "maxAvailable",
					Header: "Max Available",
					show: false,
				},
				{
					accessor: "paystubEmail",
					Header: "Paystub Email",
					show: false,
				},
				// {
				// 	accessor: "loginEmail",
				// 	Header: "Login Email",
				// 	show: false,
				// },
				// {
				// {
				// 	accessor: "email",
				// 	Header: "Email",
				// 	show: false,
				// },
				// {
				// 	accessor: "cellPhone",
				// 	Header: "Cell Phone",
				// 	show: false,
				// },
				// {
				// 	accessor: "cellCarrier",
				// 	Header: "Cell Carrier",
				// 	show: false,
				// },
				// {
				// 	accessor: "ext",
				// 	Header: "Ext",
				// 	show: false,
				// },
				{
					accessor: "parentDeptID",
					Header: "parent Department ID",
					show: false,
				},

				{
					accessor: "birthday",
					Header: "Birthday",
					show: false,
				},
				{
					accessor: "longevityMths",
					Header: "Longevity Months",
					show: false,
				},
				{
					accessor: "longevityYears",
					Header: "Longevity Years",
					show: false,
				},
				{
					accessor: "lastLongevityAward",
					Header: "Last Longevity Award",
					show: false,
				},
				{
					accessor: "lastLongevityAwardYear",
					Header: "Last Longevity Award Year",
					show: false,
				},
				{
					accessor: "homeAddress",
					Header: "Home Address",
					show: false,
				},
				{
					accessor: "homeCity",
					Header: "Home City",
					show: false,
				},
				{
					accessor: "homeState",
					Header: "Home State",
					show: false,
				},
				{
					accessor: "homeZip",
					Header: "Home Zip",
					show: false,
				},
				{
					accessor: "homePhone",
					Header: "Home Phone",
					show: false,
				},
				// {
				// 	accessor: "jcFixedRate",
				// 	Header: "JC Fixed Rate",
				// 	show: false,
				// },
				// {
				// 	accessor: "shirtSize",
				// 	Header: "Shirt Size",
				// 	show: false,
				// },
				// {
				// 	accessor: "managerUID",
				// 	Header: "Manager UID",
				// 	show: false,
				// },
				// {
				// 	accessor: "reviewerUID",
				// 	Header: "reviewer UID",
				// 	show: false,
				// },
				// {
				// 	accessor: "approverUID",
				// 	Header: "Approver UID",
				// 	show: false,
				// },
				// {
				// 	accessor: "vpUserName",
				// 	Header: "VP Username",
				// 	show: false,
				// },
				// {
				// 	accessor: "wpUserID",
				// 	Header: "WP User ID",
				// 	show: false,
				// },
			];
		} else if (params?.module === "HRForms") {
			this.sortBy = [{ id: "name", desc: false }];

			this.columns = [
				{
					accessor: "queryData",
					Header: "Query Data",
					disableExport,
					show: false,
					width: "200%",
				},
				{
					accessor: "msUserID",
					Header: "msUser ID",
					disableExport,
					show: false,
					width: "300%",
				},
				{
					accessor: "employeeUID",
					Header: "EmployeeUID",
					disableExport,
					show: false,
					width: "300%",
				},
				{
					accessor: "prehKeyID",
					Header: "Preh Key ID",
					valueType: "integer",
					textAlign: "center",
					width: "65%",
					textSize: "center",
					disableExport,
					show: false,
				},
				{
					accessor: "companyAbbr",
					Header: "Company",
					width: "65%",
					textAlign: "center",
				},
				{
					accessor: "companyName",
					Header: "Company Name",
					width: "200%",
					show: false,
				},
				{
					accessor: "prco",
					Header: "PRCo",
					width: "65%",
					valueType: "integer",
					textAlign: "center",
					show: false,
				},
				{
					accessor: "employee",
					Header: "Employee",
					width: "65%",
					valueType: "integer",
					textAlign: "center",
				},
				{
					accessor: "name",
					Header: "Employee Name",
					width: "125%",
				},
				{
					accessor: "firstName",
					Header: "First Name",
					show: false,
				},
				{
					accessor: "lastName",
					Header: "Last Name",
					show: false,
				},
				{
					accessor: "midName",
					Header: "Middle Initial",
					show: false,
				},
				{
					accessor: "legalFirstName",
					Header: "Legal First Name",
					show: false,
				},
				{
					accessor: "legalLastName",
					Header: "Legal Last Name",
					show: false,
				},

				{
					accessor: "title",
					Header: "Title",
					width: "200%",
				},
				{
					accessor: "email",
					Header: "Email",
					show: false,
					width: "250%",
				},
				{
					accessor: "cellPhone",
					Header: "Cell Phone",
					show: false,
				},
				{
					accessor: "officePhone",
					Header: "Office Phone",
					show: false,
				},
				{
					accessor: "regionID",
					Header: "Region ID",
					valueType: "integer",
					textAlign: "center",
					disableExport,
					show: false,
				},
				{
					accessor: "region",
					Header: "Region",
				},
				{
					accessor: "parentDeptID",
					Header: "Parent Dept ID",
					valueType: "integer",
					textAlign: "center",
					disableExport,
					show: false,
				},
				{
					accessor: "parentDept",
					Header: "Parent Dept",
					show: false,
				},

				{
					accessor: "deptID",
					Header: "Dept ID",
					valueType: "integer",
					textAlign: "center",
					disableExport,
					show: false,
				},
				{
					accessor: "dept",
					Header: "Department",
				},

				{
					accessor: "roleID",
					Header: "Role ID",
					valueType: "integer",
					textAlign: "center",
					show: false,
				},
				{
					accessor: "role",
					Header: "Role",
					show: false,
				},

				{
					accessor: "tradeID",
					Header: "Trade ID",
					valueType: "integer",
					textAlign: "center",
					show: false,
				},

				{
					accessor: "managerUID",
					Header: "Manager UID",
					disableExport,
					show: false,
					width: "300%",
				},
				{
					id: "managerName",
					accessor: (row) => usersMap[row.managerUID]?.name ?? null,
					Header: "Manager Name",
					width: "125%",
				},
				{
					accessor: "reviewerUID",
					Header: "reviewer UID",
					disableExport,
					show: false,
					width: "300%",
				},
				{
					id: "reviewerName",
					accessor: (row) => usersMap[row.reviewerUID]?.name ?? null,
					Header: "Reviewer Name",
					width: "125%",
				},
				{
					accessor: "approverUID",
					Header: "Approver UID",
					disableExport,
					show: false,
					width: "300%",
				},
				{
					id: "approverName",
					accessor: (row) => usersMap[row.approverUID]?.name ?? null,
					Header: "Approver Name",
					width: "125%",
				},

				{
					id: "activeYN",
					accessor: (row) => (row?.activeYN === "Y" ? "Yes" : "No"),
					Header: "Payroll Status",
					textAlign: "center",
					valueType: "boolean",
					width: "65%",
				},

				{
					accessor: "userType",
					Header: "User Type",
					show: false,
					width: "75%",
				},
				{
					accessor: "userStatus",
					Header: "Employment Status",
					textAlign: "center",
					width: "75%",
				},

				// {
				// 	accessor: "employeeType",
				// 	Header: "Union Status",
				// 	show: false,
				// },
				// {
				// 	accessor: "employeeRole",
				// 	Header: "Employee Type ID",
				// 	disableExport,
				// 	show: false,
				// },
				{
					id: "isPortalUserYN",
					accessor: (row) => (Boolean(row?.msUserID) ? "Yes" : "No"),
					Header: "Is Portal User",
					textAlign: "center",
					valueType: "boolean",
					width: "65%",
					show: false,
				},

				{
					accessor: "birthday",
					Header: "Birthday",
					show: false,
					valueType: "date",
				},

				{
					accessor: "hireDate",
					Header: "Hire Date",
					valueType: "date",
					textAlign: "center",
					show: false,
				},
				{
					accessor: "termDate",
					Header: "Termination Date",
					valueType: "date",
					textAlign: "center",
					show: false,
				},

				{
					accessor: "longevity",
					Header: "Longevity",
					show: false,
				},
				{
					accessor: "longevityMths",
					Header: "Longevity Mths",
					textAlign: "center",
					width: "65%",
					show: false,
				},
				{
					accessor: "longevityYears",
					Header: "Longevity Years",
					textAlign: "center",
					width: "65%",
					show: false,
				},
				{
					accessor: "lastLongevityAward",
					Header: "Last Longevity Award",
					textAlign: "center",
					width: "65%",
					show: false,
				},
				{
					accessor: "lastLongevityAwardYear",
					Header: "Last Year Awarded",
					textAlign: "center",
					width: "65%",
					show: false,
				},

				{
					accessor: "homeAddress",
					Header: "Home Address",
					width: "250%",
					show: false,
				},
				{
					accessor: "homeCity",
					Header: "Home City",
					show: false,
				},
				{
					accessor: "homeState",
					Header: "Home State",
					width: "65%",
					show: false,
				},
				{
					accessor: "homeZip",
					Header: "Home Zip",
					width: "65%",
					show: false,
				},
				{
					accessor: "homePhone",
					Header: "Home Phone",
					show: false,
				},

				{
					accessor: "prGroup",
					Header: "PR Group",
					show: false,
				},
				{
					accessor: "prDept",
					Header: "PR Dept",
					show: false,
				},

				{
					accessor: "craft",
					Header: "Craft",
					show: false,
				},
				{
					accessor: "class",
					Header: "Class",
					show: false,
				},
				{
					accessor: "classDesc",
					Header: "Class Description",
					show: false,
				},

				{
					accessor: "recentRehireDate",
					Header: "Recent Rehire Date",
					show: false,
				},
				{
					accessor: "availableSick",
					Header: "Available Sick",
					show: false,
				},
				{
					accessor: "availableVacation",
					Header: "Available Vacation",
					show: false,
				},
				{
					accessor: "accrualRate",
					Header: "Accrual Rate",
					show: false,
				},
				{
					accessor: "maxAvailable",
					Header: "Max Available",
					show: false,
				},
				{
					accessor: "paystubEmail",
					Header: "Paystub Email",
					show: false,
				},
				// {
				// 	accessor: "loginEmail",
				// 	Header: "Login Email",
				// 	show: false,
				// },
				// {
				// 	accessor: "title",
				// 	Header: "Title",
				// 	show: false,
				// },

				// {
				// 	accessor: "ext",
				// 	Header: "Ext",
				// 	show: false,
				// },

				// {
				// 	accessor: "jcFixedRate",
				// 	Header: "JC Fixed Rate",
				// 	show: false,
				// },
				// {
				// 	accessor: "shirtSize",
				// 	Header: "Shirt Size",
				// 	show: false,
				// },
				// {
				// 	accessor: "vpUserName",
				// 	Header: "VP Username",
				// 	show: false,
				// },
				// {
				// 	accessor: "wpUserID",
				// 	Header: "WP User ID",
				// 	show: false,
				// },
			];
		}

		this.data = employees ?? [];
		this.footer = (rows = employees) => {
			return this.getFooter(rows);
		};
	}
	getFooter(rows = []) {
		let footer = [{ LegalName: rows.length }];
		return footer;
	}
}
