import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import MainContent from "../../legacy/App/components/MainContent";
import Tabs from "../../core/Layout/tabs/Tabs";
import IFrame from "../../core/Layout/modals/IFrame";

import PayrollAPI from "../../APIs/PayrollAPI";
import SectionObj from "../../core/Reports/classes/Section";
// import { portalUser } from "../../App";
// import { log } from "../../helperFunctions";

export default function PowerBIReport(props) {
	const [attachmentURL, setAttachmentURL] = useState(null);
	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [viewerMaxHeight, setViewerMaxHeight] = useState(800);
	const [tabs, setTabs] = useState([]);

	const [[tabVal, tabDirection], setTabVal] = useState([0, 0]);

	const changeTab = useCallback(
		(newTab, newDirection) => {
			if (!newDirection) newDirection = newTab - tabVal;
			setTabVal([newTab, newDirection]);
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		[tabVal]
	);

	const getAttachmentURL = async (attachmentID = null) => {
		let attachmentURL = null;
		if (Boolean(attachmentID)) {
			let payrollAPI = new PayrollAPI();
			let attachment = await payrollAPI.GetAttachmentBlobFile(attachmentID);
			// Note to Dustin -  attachmentID is an integer - not a attachmentUID like the the api routes

			if (attachment.status === 200) {
				attachment = attachment.value;
				let blob = new Blob([new Uint8Array(attachment[0].AttachmentData.data)], { type: "application/pdf" });
				attachmentURL = URL.createObjectURL(blob);

				// const pdfWindow = window.open();
				// pdfWindow.location.href = attachmentURL;
			}
		}
		return attachmentURL;
	};

	useEffect(() => {
		const init = async () => {
			let invoiceURL = null;

			invoiceURL = await getAttachmentURL(1511446);
			setAttachmentURL(invoiceURL);
			setUpdateTrigger(true);
		};
		init();
	}, [props.pageName]);

	useMemo(() => {
		let tabs = [];
		let sections = [
			{
				name: "Power BI Reports",
				heading: "Operations Backlog (OPS)",
				url: "https://app.powerbi.com/reportEmbed?reportId=9a4dfe56-4fb7-42ec-9ebb-5d6b31f6c9ce&autoAuth=true&ctid=cd746618-c246-4bce-aff1-9e1ead46ece7",
			},
			{
				name: "Power BI Reports",
				heading: "AI Invoices (AI)",
				url: "https://app.powerbi.com/reportEmbed?reportId=4206a1aa-2599-42d5-a40d-870459432a32&autoAuth=true&ctid=cd746618-c246-4bce-aff1-9e1ead46ece7",
			},
			{
				name: "Power BI Reports",
				heading: "Exec Dashboard (WIP)",
				url: "https://app.powerbi.com/reportEmbed?reportId=4206a1aa-2599-42d5-a40d-870459432a32&autoAuth=true&ctid=cd746618-c246-4bce-aff1-9e1ead46ece7",
			},
			{
				name: "Power BI Reports",
				heading: "Operations Manpower (MAN)",
				url: "https://app.powerbi.com/reportEmbed?reportId=7fc18ace-1dc2-42bb-a9e5-9dd8b4ab143f&autoAuth=true&ctid=cd746618-c246-4bce-aff1-9e1ead46ece7",
			},

			{
				name: "Power BI Reports",
				heading: "Productivity Analytics (PAS)",
				url: "https://app.powerbi.com/reportEmbed?reportId=604db21a-11fc-40e8-8c80-40379d6d08f9&groupId=9f770122-14eb-41d5-af4c-dc8fe94fd875&autoAuth=true&ctid=cd746618-c246-4bce-aff1-9e1ead46ece7&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D",
			},
			{
				name: "Power BI Reports",
				heading: "DevOps Dept 2023",
				url: "https://app.powerbi.com/reportEmbed?reportId=e763ac0c-0892-4503-88f1-aba489281c5d&autoAuth=true&ctid=cd746618-c246-4bce-aff1-9e1ead46ece7",
			},
			{ name: "Power BI Reports", heading: "PDF Attachments", url: attachmentURL },

			// {
			// 	name: "Productivity",
			// 	heading: "Material Escalation",
			// 	url: "https://app.powerbi.com/reportEmbed?reportId=f8a9426c-32ed-4d23-a24c-6e78c87d1841&autoAuth=true&ctid=cd746618-c246-4bce-aff1-9e1ead46ece7",
			// },
			// {
			// 	name: "Productivity",
			// 	heading: "Manpower Report",
			// 	url: "https://app.powerbi.com/reportEmbed?reportId=4185f4c2-7c25-42de-982f-ae4466049696&autoAuth=true&ctid=cd746618-c246-4bce-aff1-9e1ead46ece7",
			// },
		];

		sections = sections?.filter((d) => [props.pageName].includes(d.name)) ?? [];

		for (let i = 0; i < sections?.length; i++) {
			if (Boolean(sections[i])) {
				let section = new SectionObj(sections[i]);

				tabs.push({
					header: section.name,
					name: section.heading,

					pages: [
						<IFrame variant={"simple"} minH={viewerMaxHeight} url={sections[i]?.url ?? attachmentURL} />,
					],
				});
				setTabs(tabs);
			}
		}
	}, [attachmentURL, props.pageName, viewerMaxHeight]);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	return (
		<MainContent subtitle={tabs[tabVal]?.header ?? props.pageName} title={tabs[tabVal]?.name}>
			<Flex bg="blackAlpha.500" direction="column" flex={1} justify="flex-start" w="full" h="full" py={4} px={6}>
				<Tabs
					tabs={tabs}
					tabVal={tabVal}
					tabDirection={tabDirection}
					changeTab={changeTab}
					viewerMaxHeight={viewerMaxHeight}
					setViewerMaxHeight={setViewerMaxHeight}
					updateTrigger={updateTrigger}
				>
					{tabs[tabVal]?.pages?.map((page, i) => {
						return <React.Fragment key={i}>{page}</React.Fragment>;
					})}
				</Tabs>
			</Flex>
		</MainContent>
	);
}
