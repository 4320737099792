import UsersAPI from "../../../APIs/UsersAPI";

import { portalUser } from "../../../App";
import { log, formatValue, convertArrayToMap } from "../../../helperFunctions";
import moment from "moment";

export default class User {
	constructor(user) {
		if (user instanceof User) {
			Object.assign(this, user);
		} else {
			this.usersAPI = new UsersAPI();
			this.queryData = user?.queryData ?? user?.QueryData ?? "User";
			this.msUserID = user?.msUserID ?? user?.MSUserID ?? null; //include in HR Employees (DevOps Only)
			this.employeeUID = user?.employeeUID ?? user?.EmployeeUID ?? null; //include in HR Employees (DevOps Only)
			this.vpUserName = user?.vpUserName ?? user?.VPUserName ?? null; //include in HR Employees (DevOps Only)
			this.wpUserID = user?.wpUserID ?? user?.WPUserID ?? null; //include in HR Employees (DevOps Only)

			this.firstName = user?.firstName ?? user?.FirstName ?? null;
			this.lastName = user?.lastName ?? user?.LastName ?? null;
			this.name = user?.name ?? null;
			this.title = user?.title ?? user?.Title ?? null;
			this.email = user?.email ?? user?.Email ?? null;
			this.loginEmail = user?.loginEmail ?? user?.LoginEmail ?? null;
			this.cellPhone = user?.cellPhone ?? user?.CellPhone ?? null;
			this.cellCarrier = user?.cellCarrier ?? user?.PhoneCarrier ?? null;
			this.ext = user?.ext ?? user?.Ext ?? null;
			this.shirtSize = user?.shirtSize ?? user?.ShirtSize ?? null; //include in HR Employees
			this.birthDay = formatValue(user?.birthDay ?? user?.BirthDay, 0, "date") ?? null;
			this.hireDate = formatValue(user?.hireDate ?? user?.HireDate, 0, "date") ?? null;
			this.longevityMths = user?.longevityMths ?? user?.LongevityMths ?? 0;
			this.longevityYears = user?.longevityYears ?? user?.LongevityYears ?? 0;

			this.longevity = user?.longevity ?? null;
			this.lastLongevityAward = user?.lastLongevityAward ?? null;
			this.lastLongevityAwardYear = user?.lastLongevityAwardYear ?? null;

			this.userType = user?.userType ?? user?.UserType ?? "Employee";
			this.userStatus = user?.userStatus ?? user?.status ?? user?.Status ?? "Active";

			this.prco = user?.prco ?? user?.PRCo ?? "1";
			this.regionID = user?.regionID ?? user?.RegionID ?? null;
			this.tradeID = user?.tradeID ?? user?.TradeID ?? "1";
			this.deptID = user?.deptID ?? user?.DeptID ?? null;
			this.roleID = user?.roleID ?? user?.RoleID ?? null;

			this.managerUID = user?.managerUID ?? user?.ManagerUID ?? null; //include in HR Employees (DevOps Only)
			this.reviewerUID = user?.reviewerUID ?? user?.ReviewerUID ?? null; //include in HR Employees (DevOps Only)
			this.approverUID = user?.approverUID ?? user?.ApproverUID ?? null; //include in HR Employees (DevOps Only)

			//Route Access - Employee
			this.showPortalTourYN = user?.showPortalTourYN ?? user?.ShowPortalTourYN ?? "Y";
			this.shareBirthdayYN = user?.shareBirthdayYN ?? user?.ShowBirthdayYN ?? "N";
			this.sharePhotosYN = user?.sharePhotosYN ?? user?.SharePhotosYN ?? "N";
			this.showDevOpsPresentationYN = user?.showDevOpsPresentationYN ?? user?.ShowDevOpsPresentationYN ?? "N";
			//Route Access - Admin
			this.isDevOpsUserYN = user?.isDevOpsUserYN ?? user?.IsDevOpsUserYN ?? "N";
			this.isPortalAdminYN = user?.isPortalAdminYN ?? user?.IsPortalAdminYN ?? "N";
			this.showVacationCalendarYN = user?.showVacationCalendarYN ?? user?.ShowVacationCalendarYN ?? "N";
			//Route Access - Team
			this.isTeamManagerYN = user?.isTeamManagerYN ?? "N";
			this.isTeamApproverYN = user?.isTeamApproverYN ?? "N";
			this.isTeamReviewerYN = user?.isTeamReviewerYN ?? "N";
			//Route Access - Exec
			this.isFullExecUserYN = user?.isFullExecUserYN ?? "N";
			this.isExecUserYN = user?.isExecUserYN ?? user?.IsExecUserYN ?? "N";
			//Route Access - Payroll
			this.isPayrollUserYN = user?.isPayrollUserYN ?? user?.IsPayrollUserYN ?? "N";
			this.isAccountingUserYN = user?.isAccountingUserYN ?? user?.IsAccountingUserYN ?? "N";
			this.showEmployeeHistoryYN = user?.showEmployeeHistoryYN ?? user?.ShowEmployeeHistoryYN ?? "N";
			this.showTimecardCrewEntryYN = user?.showTimecardCrewEntryYN ?? "N";
			this.showTimecardApprovalYN = user?.showTimecardApprovalYN ?? "N";
			//Route Access - Operations
			this.isOperationsExecUserYN = user?.isOperationsExecUserYN ?? user?.IsOperationsExecUserYN ?? "N";
			this.isOperationsUserYN = user?.isOperationsUserYN ?? user?.IsOperationsUserYN ?? "N";
			this.showPEReviewYN = user?.showPEReviewYN ?? "N";
			//Route Access - Departments
			this.isEstimatingUserYN = user?.isEstimatingUserYN ?? user?.IsEstimatingUserYN ?? "N";
			this.isPurchasingUserYN = user?.isPurchasingUserYN ?? user?.IsPurchasingUserYN ?? "N";
			this.isDetailingUserYN = user?.isDetailingUserYN ?? user?.IsDetailingUserYN ?? "N";
			this.isStartUpUserYN = user?.isStartUpUserYN ?? "N";

			this.initPhoto = user?.initPhoto ?? true;
			this.profilePhoto = user?.profilePhoto ?? null;

			//TODO: Move to TimecardUser
			this.prEndDate = formatValue(user?.prEndDate ?? user?.PREndDate, 0, "date") ?? null;

			this.jobs = user?.jobs ?? [];

			//TODO: Remove

			this.role = user?.role ?? null;
			this.trade = user?.trade ?? null;
			this.manager = user?.manager ?? null;
			this.reviewer = user?.reviewer ?? null;
			this.approver = user?.approver ?? null;
		}
		this.getName();
	}

	getName(firstName = this.firstName, lastName = this.lastName, midName = this.midName, display = "firstLast") {
		let name = "";
		if (display === "firstLast" && Boolean(firstName) && Boolean(lastName)) {
			name = firstName + " " + lastName;
		} else if (display === "lastFirst" && Boolean(firstName) && Boolean(lastName)) {
			name = lastName + " ," + firstName + (Boolean(midName) && " " + midName);
		}
		this.name = name;
		return name;
	}

	getBannerMessage() {
		let longevityMths = parseFloat(this.longevityMths ?? 0);
		let message = null;
		let birthdate = moment(new Date(this.birthDay));
		let hiredate = moment(new Date(this.hireDate));

		var monday = moment().clone().weekday(1);
		var sunday = moment().clone().weekday(7);

		if (this.shareBirthdayYN === "Y" && birthdate.isBetween(monday, sunday, null, "[]")) {
			message = "Happy Birthday!";
		} else if (longevityMths < 3) {
			message = "Welcome to the Team!";
		} else if (
			longevityMths % 12 === 0 &&
			hiredate.date() / 7 >= monday.date() / 7 &&
			hiredate.date() / 7 <= sunday.date() / 7
		) {
			message = "Happy Pan-niversary!";
		} else if (longevityMths >= 3 && longevityMths < 12) {
			message = `THANK YOU FOR ${longevityMths} MONTHS!`;
		} else if (longevityMths === 12) {
			message = `THANK YOU FOR 1 YEAR`;
		} else if (longevityMths > 12) {
			message = `THANK YOU FOR ${Math.round((longevityMths / 12) * 10) / 10} YEARS!`;
		}
		return message;
	}

	getBannerImage() {
		let bannerImage = "/images/Banner_SFSkyline.jpg";
		let longevityMths = parseFloat(this.longevityMths ?? 0);
		let birthdate = moment(new Date(this.birthDay));
		let hiredate = moment(new Date(this.hireDate));

		var monday = moment().clone().weekday(1);
		var sunday = moment().clone().weekday(7);

		if (birthdate.isBetween(monday, sunday, null, "[]")) {
			bannerImage = "/images/Banner_Fireworks.jpg";
		} else if (
			longevityMths % 12 === 0 &&
			hiredate.date() / 7 >= monday.date() / 7 &&
			hiredate.date() / 7 <= sunday.date() / 7
		) {
			bannerImage = "/images/Banner_Fireworks.jpg";
		}
		return bannerImage;
	}

	//Updates budPPMPortal_Employees
	async updateUserDB(data) {
		if (!Boolean(this.employeeUID)) {
			return;
		}
		try {
			let result = await this.usersAPI.PortalUser_UserData_UpdateUser(this.employeeUID, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = result?.value[0];
			}
			log("Database Result (update user)", result);
			return result;
		} catch (error) {
			log("Update DB Error: ", error);
			alert("An error occurred! Please refresh the page and try again.");
			return null;
		}
	}

	//Updates budPPMPortal_Employees_Management
	async updateManagementDB(data) {
		if (!Boolean(this.employeeUID)) {
			return;
		}
		try {
			let result = await this.usersAPI.PortalUser_EmployeeData_UpdateManagement(this.employeeUID, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = result?.value[0];

				// result = new User(result?.value[0]);
				// Object.assign(this, result);
			}

			log("Database Result (update management)", result);
			return result;
		} catch (error) {
			log("Update DB Error: ", error);
			alert("An error occurred! Please refresh the page and try again.");
			return null;
		}
	}

	//Updates budPPMPortal_Payroll_Employees
	async updatePREmployeeDB(data) {
		if (!Boolean(this.employeeUID)) {
			return;
		}
		try {
			let result = await this.usersAPI.UpdatePREmployee(this.employeeUID, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = result?.value[0];
				// result = new User(result?.value[0]);
				// Object.assign(this, result);
			}

			log("Database Result (update employee)", result);
			return result;
		} catch (error) {
			log("Update DB Error: ", error);
			alert("An error occurred! Please refresh the page and try again.");
			return null;
		}
	}

	async updateUser(id = null, value = null) {
		value = value?.toString();
		const columns = [
			{ valueType: "string", id: "firstName", sqlColumn: "FirstName" },
			{ valueType: "string", id: "lastName", sqlColumn: "LastName" },
			{ valueType: "string", id: "title", sqlColumn: "Title" },
			{ valueType: "string", id: "cellCarrier", sqlColumn: "PhoneCarrier" },
			{ valueType: "string", id: "deptID", sqlColumn: "DeptID" },
			{ valueType: "integer", id: "roleID", sqlColumn: "RoleID" },
		];
		const attrMap = convertArrayToMap(columns, "id");

		let column = attrMap[id] ?? null;
		log("column");
		log("value", value);
		if (Boolean(column) && value !== this[id]) {
			let updates = {};
			if (column?.valueType === "string") {
				value = value?.replace("'", "`") ?? "";
				updates = { [column.sqlColumn]: value };
				let result = await this.updateUserDB(updates);
				this[id] = value;
				this.getName();
				return result;
			} else if (column?.valueType === "integer") {
				value = formatValue(value, 0, "numeric");

				updates = { [column.sqlColumn]: value };
				log("updates", updates);
				let result = await this.updateUserDB(updates);
				this[id] = value?.toString();

				return result;
			}
		} else {
			return;
		}
	}

	async updateFirstName(value) {
		value = value?.replace("'", "`") ?? "";
		if (value !== this.firstName) this.firstName = value;
		this.name = this.getName();
		let updates = {
			FirstName: this.firstName,
		};
		let result = await this.updateUserDB(updates);
		return result;
	}

	async updateLastName(value) {
		value = value?.replace("'", "`") ?? "";
		this.lastName = value;
		this.name = this.getName();
		let updates = {
			LastName: this.lastName,
		};
		return await this.updateUserDB(updates);
	}

	async updateTitle(value) {
		value = value?.replace("'", "`") ?? "";
		this.title = value;
		let update = {
			Title: value,
		};
		return await this.updateUserDB(update);
	}

	async updateDepartment(value = null) {
		this.deptID = value;
		let update = {
			DeptID: value,
		};
		return await this.updateUserDB(update);
	}

	async updateCellPhone(value) {
		this.cellPhone = value?.replace("'", "`");
		let updates = {
			CellPhone: this.cellPhone,
		};
		return await this.updateUserDB(updates);
	}

	async updateCellCarrier(value = null) {
		if (Boolean(value)) {
			this.cellCarrier = value.replace("'", "`");
			let updates = {
				PhoneCarrier: this.cellCarrier,
			};
			return await this.updateUserDB(updates);
		}
	}

	async updateShirtSize(value) {
		this.shirtSize = value.replace("'", "`");
		let updates = {
			ShirtSize: this.shirtSize,
		};
		return await this.updateUserDB(updates);
	}
	// PORTAL OPTIONS
	async updateShowPortalTour(value = "N") {
		value = value?.replace("'", "`")?.toString();
		let viewValue = "N";
		let sqlValue = "0";
		if (["1", "Y", "true"].includes(value)) {
			viewValue = "Y";
			sqlValue = "1";
		}
		this.showPortalTourYN = viewValue;
		let updates = { showPortalTour: sqlValue };

		let result = await this.updateUserDB(updates);

		return result;
	}

	async updateShowBirthday(value = "N") {
		value = value?.replace("'", "`")?.toString();
		let viewValue = "N";
		let sqlValue = "0";
		if (["1", "Y", "true"].includes(value)) {
			viewValue = "Y";
			sqlValue = "1";
		}
		this.shareBirthdayYN = viewValue;
		let updates = { ShowBirthday: sqlValue };
		return await this.updateUserDB(updates);
	}

	async updateSharePhotos(value = "N") {
		value = value?.replace("'", "`")?.toString();
		let viewValue = "N";
		let sqlValue = "0";
		if (["1", "Y", "true"].includes(value)) {
			viewValue = "Y";
			sqlValue = "1";
		}
		this.sharePhotosYN = viewValue;
		let updates = { SharePhotos: sqlValue };
		return await this.updateUserDB(updates);
	}

	//MOVE API TO USER-DATA-API
	async dismissAllNotifications() {
		let dateAcknowledged = moment(new Date()).format("MM/DD/YYYY HH:mm");
		let notifications = portalUser?.getNotifications(false) ?? [];
		if (Boolean(this?.msUserID) && notifications?.length > 0) {
			for (let i = 0; i < notifications?.length; i++) {
				notifications[i].dateAcknowledged = dateAcknowledged;
				notifications[i].status = notifications[i].getStatus();
			}
			await this.usersAPI.PortalUser_DismissAllNotifications(this?.msUserID, {
				DateAcknowledged: dateAcknowledged,
			});
			return true;
		} else {
			return false;
		}
	}
}
