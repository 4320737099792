import React, { useState, useCallback, useEffect } from "react";
import { Flex, useBoolean } from "@chakra-ui/react";
import { portalUser } from "../../App";
import MainContent from "../../legacy/App/components/MainContent";
import EmployeesPage from "./pages/EmployeesTablePage";
import EmployeeDrawer from "./components/EmployeeEditDrawer";

// import { log } from "../../helperFunctions";

export default function Employees({ isPayroll, isContacts, isHR }) {
	const title = "Contacts";

	const tabs = ["Employees"];
	const [tabVal, setTabVal] = useState(0);
	const changeTab = useCallback((e) => setTabVal(e), []);

	// const [params, setParams] = useState({});

	const [isLoading, setIsLoading] = useBoolean(true);
	const setDataLoading = useCallback(
		async function (callback) {
			if (!isLoading) {
				setIsLoading.on();
			}
			callback();
		},
		[isLoading, setIsLoading]
	);

	const [prEmployees, setPREmployees] = useState([]); // ??
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const initPREmployees = useCallback(
		() => {
			let prEmployees = portalUser.getPREmployees() ?? [];

			setPREmployees(prEmployees);
			setIsLoading.off();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		initPREmployees();
	}, [initPREmployees]);

	const saveEmployee = async (employee) => {
		let employees = portalUser.getPREmployees() ?? [];
		setPREmployees(employees);
		setIsLoading.off();
		setSelectedEmployee(null);
	};

	const closeEmployee = () => {
		setSelectedEmployee(null);
	};

	const [formData, setFormData] = useState(portalUser?.formData ?? null);
	useEffect(() => {
		const initFormData = async () => {
			let formData = portalUser?.formData ?? null;
			if (!Boolean(formData)) {
				formData = new FormData();
				formData = await formData.initFormData(portalUser?.msUser);
				// portalUser.formData = formData;
				setFormData(formData);
			}

			setIsLoading.off();
		};

		setDataLoading(() => initFormData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<MainContent title={title} tabVal={tabVal} tabs={tabs} changeTab={changeTab}>
			<Flex bg="blackAlpha.400" direction="column" flex={1} justify="flex-start" h="full" px={4}>
				{tabVal === 0 && (
					<EmployeesPage
						// reportSettings={reportSettings}
						// updateBanner={updateBanner}
						employees={prEmployees}
						// params={params}
						// initParams={initParams}
						tabVal={tabVal}
						changeTab={changeTab}
						isLoading={isLoading}
						setSelectedRow={!isContacts && setSelectedEmployee}
					/>
				)}
				{Boolean(selectedEmployee) && (
					<EmployeeDrawer
						selectedEmployee={selectedEmployee}
						isOpen={Boolean(selectedEmployee)}
						closeEmployee={closeEmployee}
						saveEmployee={saveEmployee}
					/>
				)}
			</Flex>
		</MainContent>
	);
}
