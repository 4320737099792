import React from "react";
import { Box } from "@chakra-ui/react";
import UsersAPI from "../../../APIs/UsersAPI";
import { PPMUser } from "../../../PPM_SDK/PPMUser";
import TimeOffRequestLegacy from "../classes/TimeOffRequestLegacy";
import TimeOffAcknowledgeModal from "./TimeOffAcknowledgeModal";
// import { log } from "../../../helperFunctions";

export default class TimeOffRequest extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			usersAPI: new UsersAPI(),
			requestId: null,
			request: null,
			employee: null,
		};

		this.init = this.init.bind(this);
		this.decline = this.decline.bind(this);
		this.approve = this.approve.bind(this);
		this.updateNote = this.updateNote.bind(this);
		this.cancel = this.cancel.bind(this);
		this.updateRequest = this.updateRequest.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentDidUpdate() {
		if (this.props.requestId !== this.state.requestId) {
			this.setState({ requestId: this.props.requestId });
			setTimeout(this.init, 200);
		}

		if (
			this.props.request &&
			this.props.request?.id !== this.state.request?.id &&
			this.props.isOpen &&
			this.props.view === "request"
		) {
			this.setState({
				request: this.props.request?.request,
				user: this.props.request?.employee,
			});
		}
	}

	async init() {
		if (this.props.requestId) {
			let requestResult = await this.state.usersAPI.GetTimeOffRequest(this.props.requestId);
			if (requestResult.status === 200 && requestResult.value.length > 0) {
				let employee = new PPMUser(requestResult.value[0]);
				let request = new TimeOffRequestLegacy(requestResult.value[0]);

				this.setState({
					requestId: this.props.requestId,
					request: request,
					employee: employee,
				});
			}
		} else {
		}
	}

	async approve() {
		let request = this.state.request;
		// log("approve request", request);
		await request.approve();

		this.setState({
			request: request,
		});
		this.props.onClose();
	}

	async decline() {
		let request = this.state.request;
		// log("decline request", request);
		await request.decline();

		this.setState({
			request: request,
		});
		this.props.onClose();
	}

	async cancel() {
		let request = this.state.request;
		// log("cancel request", request);
		await request.cancel();
		this.props.cancelRequest(request?.id);

		this.setState({
			request: request,
		});
		this.props.onClose();
	}

	async updateNote(ev) {
		let note = ev.target.value;
		let request = this.state.request;
		await request.updateNote(note);
		this.setState({ request: request });
	}

	async updateRequest(changes) {
		let request = this.state.request;
		let resetNeeded = false;
		if (changes.startDate) {
			if (
				changes?.startDate.toLocaleDateString() !== new Date(this.state.request?.startDate).toLocaleDateString()
			) {
				request.startDate = changes.startDate.toLocaleDateString();
				resetNeeded = true;
			}
			if (changes.endDate.toLocaleDateString() !== new Date(this.state.request?.endDate).toLocaleDateString()) {
				request.endDate = changes.endDate.toLocaleDateString();
				resetNeeded = true;
			}
			if (changes.requestNote !== this.state.request?.requestNote) {
				request.requestNote = changes.requestNote;
				resetNeeded = true;
			}
			if (changes?.type !== this.state.request?.type) {
				request.type = changes?.type;
				resetNeeded = true;
			}
		}
		if (resetNeeded) {
			await request.reset();
		}
		this.setState({
			request: request,
		});
		if (this.props.updateRequest) {
			this.props.updateRequest(request);
		}
		this.props.onClose();
	}

	render() {
		if (this.props.view === "modal") {
			if (this.props.isOpen && this.state.request) {
				return (
					<TimeOffAcknowledgeModal
						isOpen={this.props.isOpen}
						onClose={this.props.onClose}
						request={this.state.request}
						employee={this.state.employee}
						decline={this.decline}
						approve={this.approve}
						updateNote={this.updateNote}
						cancel={this.cancel}
						updateRequest={this.updateRequest}
						holidays={this.props.holidays}
					/>
				);
			} else {
				return null;
			}
		} else {
			return <Box></Box>;
		}
	}
}
