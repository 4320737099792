import OperationsAPI from "../../../APIs/OperationsAPI";
import { formatValue, log } from "../../../helperFunctions";

export default class DiversityCert {
	constructor(cert) {
		this.operationsAPI = new OperationsAPI();
		this.queryData = cert?.queryData ?? "DiversityCert";
		this.keyID = cert?.keyID ?? null;
		this.vendorGroup = cert?.vendorGroup ?? null;
		this.vendorID = cert?.vendorID ?? null;
		this.diversityCert = cert?.diversityCert ?? null;
		this.dateExpires = formatValue(cert?.dateExpires, 0, "date") ?? null;
	}

	getCertAttribute(attribute) {
		// STRINGS
		if (attribute === "diversityCert") {
			return "CertType";
		} else if (attribute === "vendorGroup") {
			return "VendorGroup";
		} else if (attribute === "vendorID") {
			return "Vendor";
		} else if (attribute === "dateExpires") {
			return "Expiration";
		}
	}

	// SQL DELETE Diversity Cert
	async deleteDiversityCert() {
		let result;
		if (Boolean(this.keyID)) {
			result = await this.operationsAPI.PortalUser_DeleteDiversityCert(this.keyID);
			log("DATABASE RESULT DELETE Diversity Cert", result?.value);
		}
		return result;
	}

	async updateDiversity(updates) {
		let result = await this.operationsAPI.PortalUser_UpdateDiversity(this.keyID, updates);
		log("DATABASE RESULT", result?.value);
	}

	// Update the database
	async update(attribute = null, value = null) {
		// log(attribute, value);

		let strings = ["diversityCert"];
		let numbers = ["vendorGroup", "vendorID"];
		let dates = ["dateExpires"];

		let certAttributes = ["diversityCert", "vendorGroup", "vendorID", "dateExpires"];

		let val = null;

		// STRINGS
		if (strings.includes(attribute)) {
			val = value?.replace("'", "`") ?? null;
			if (attribute !== "notes") {
				val = val?.replace(/\n/g, " ")?.trim() ?? null;
			}

			this[attribute] = val;
		}
		// NUMBERS
		else if (numbers.includes(attribute)) {
			val = parseFloat(formatValue(value, 2, "numeric"));

			if (!Boolean(val) || isNaN(val)) {
				val = 0;
			}

			this[attribute] = val;
		}
		// DATES
		else if (dates.includes(attribute)) {
			if (Boolean(value)) {
				val = formatValue(value, 0, "date");
				this[attribute] = val;
			}
		}

		// Update cert
		if (certAttributes.includes(attribute) && Boolean(this.keyID)) {
			let dbAttribute = this.getCertAttribute(attribute);

			let update = { [dbAttribute]: val };

			let result = await this.updateDiversity(update);

			return result;
		}
	}
}
