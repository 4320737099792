import React, { useState, useCallback, useMemo } from "react";
import { Heading, Container, Tabs, Tab, TabList, Flex, useBoolean } from "@chakra-ui/react";
import ToggleButton from "../../ReactTable/buttons/ToggleButton";
import LoadingSection from "../../ProgressIndicators/components/LoadingSection";
import FormPage from "../components/FormPage";
import { portalUser } from "../../../App";
import { log } from "../../../helperFunctions";

export default function FormViewerPage(props) {
	const isLoading = useMemo(() => props.isLoading, [props.isLoading]);
	const formUID = useMemo(() => props.formData?.formUID, [props.formData?.formUID]);
	const formsCount = useMemo(() => props.formData?.forms?.length, [props.formData?.forms?.length]);

	//NEW
	const [panelVal, setPanelVal] = useState(0);
	const setPanel = useCallback((i) => {
		setPanelVal(i);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const newFormData = useMemo(() => {
		if (Boolean(formUID) && Boolean(formsCount > 0) && !isLoading) {
			let newFormData = props.formData.initFormPages(formUID);
			let form = newFormData?.forms?.find((d) => d.formUID === formUID) ?? null;

			if (Boolean(form) && form?.formType === "3") {
				let peerStatus = form?.getPeerStatus();
				let forms = newFormData.forms?.filter((d) => d.parentFormUID === form?.formUID) ?? [];

				if (
					peerStatus?.completedEvals < peerStatus?.totalEvals &&
					[form?.assignedTo, form?.assignedFor].includes(portalUser.user?.employeeUID)
				) {
					forms =
						forms?.filter(
							(d) => d.formUID !== form?.formUID && d.assignedTo === portalUser.user?.employeeUID
						) ?? [];
				} else if (
					peerStatus?.completedEvals === peerStatus?.totalEvals &&
					form?.assignedFor === portalUser.user?.employeeUID &&
					form?.isVisibleYN === "N"
				) {
					forms =
						forms?.filter(
							(d) => d.formUID !== form?.formUID && d.assignedTo === portalUser.user?.employeeUID
						) ?? [];
				} else if (peerStatus?.completedEvals === peerStatus?.totalEvals) {
					forms = [form];
				}
				newFormData.forms = forms;
			}

			return newFormData;
		} else {
			return null;
		}
	}, [formUID, formsCount, isLoading, props.formData]);

	const newForm = useMemo(() => newFormData?.forms[panelVal], [newFormData?.forms, panelVal]);

	const [isComparing, setIsComparing] = useBoolean(false);
	const compareButton = useMemo(() => {
		let compareButton = null;
		if (newForm?.formType === "3") {
			compareButton = <CompareButton isOpen={isComparing} setIsOpen={setIsComparing} />;
		}
		return compareButton;
	}, [newForm?.formType, isComparing, setIsComparing]);

	const [isEditing, setIsEditing] = useBoolean(false);
	const editButton = useMemo(() => {
		let compareButton = null;
		if (newForm?.isTemplateYN === "Y") {
			compareButton = <EditButton isOpen={isEditing} setIsOpen={setIsEditing} />;
		}
		return compareButton;
	}, [newForm?.isTemplateYN, isEditing, setIsEditing]);

	const [isTesting, setIsTesting] = useBoolean(false);
	const testingButton = useMemo(() => {
		let compareButton = null;
		if (portalUser.user?.isDevOpsUserYN === "Y") {
			compareButton = <TestingButton isOpen={isTesting} setIsOpen={setIsTesting} />;
		}
		return compareButton;
	}, [isTesting, setIsTesting]);

	return (
		<Container maxW="8xl" px="0" w="full">
			<Tabs colorScheme="teal" isLazy index={panelVal} onChange={setPanel}>
				<TabList color="gray.600" borderBottomColor="whiteAlpha.500">
					{newFormData?.forms?.map((form, f) => (
						<Tab
							key={f}
							_selected={{
								color: "teal.600",
								bg: "whiteAlpha.500",
								borderTopRadius: "md",
								borderBottom: "3px solid",
							}}
							_hover={{
								color: "teal.500",
								bg: "whiteAlpha.500",
								borderTopRadius: "md",
								borderBottom: "3px solid",
								borderBottomColor: "teal.600",
							}}
						>
							<Heading size="sm" textTransform="uppercase" letterSpacing={1}>
								{form?.formType === "3" && form?.formUID === form?.parentFormUID
									? "Peer Review"
									: form?.formType === "3" && form?.assignedTo === form?.assignedFor
									? "Peer Self-Evalution"
									: form?.formTypeDesc}
								{/* +
									" - " +
									form?.assignedForName} */}
							</Heading>
						</Tab>
					))}
				</TabList>
				<Flex w="full" flex={1} h="full" py={2}>
					{isLoading ? (
						<LoadingSection />
					) : (
						<FormPage
							form={newForm}
							formData={newFormData}
							panelVal={panelVal}
							isEditing={isEditing}
							editButton={editButton}
							isComparing={isComparing}
							compareButton={compareButton}
							isTesting={isTesting}
							testingButton={testingButton}
							closeForm={() => props.selectForm(null)}
						/>
					)}
				</Flex>
			</Tabs>
		</Container>
	);
}

function CompareButton({ isOpen = true, setIsOpen }) {
	if (Boolean(setIsOpen)) {
		return (
			<ToggleButton
				toggleIsOpen={isOpen}
				onClick={setIsOpen?.toggle}
				toggleOptions={[
					{
						isOpen: true,
						label: "Close Compare",
						icon: "fas fa-external-link-alt",
						color: "green.600",
						bg: "green.100",
					},
					{
						isOpen: false,
						label: "Compare Form",
						icon: "fas fa-external-link-alt",
						color: "gray.400",
						bg: "whiteAlpha.700",
					},
				]}
			/>
		);
	} else {
		return <></>;
	}
}

function EditButton({ isOpen = true, setIsOpen }) {
	if (Boolean(setIsOpen)) {
		return (
			<ToggleButton
				toggleIsOpen={isOpen}
				onClick={setIsOpen?.toggle}
				toggleOptions={[
					{
						isOpen: true,
						label: "Edit Template",
						icon: "fas fa-cog fa-spin",
						color: "blue.600",
						bg: "blue.100",
					},
					{
						isOpen: false,
						label: "Edit Template",
						icon: "fas fa-cog",
						color: "gray.400",
						bg: "whiteAlpha.700",
					},
				]}
			/>
		);
	} else {
		return <></>;
	}
}

function TestingButton({ isOpen = true, setIsOpen }) {
	if (Boolean(setIsOpen)) {
		return (
			<ToggleButton
				toggleIsOpen={isOpen}
				onClick={setIsOpen?.toggle}
				toggleOptions={[
					{
						isOpen: true,
						label: "Turn Off Testing Mode",
						icon: "fas fa-cog fa-spin",
						color: "blue.600",
						bg: "blue.100",
					},
					{
						isOpen: false,
						label: "Turn On Testing Mode",
						icon: "fas fa-cog",
						color: "gray.400",
						bg: "whiteAlpha.700",
					},
				]}
			/>
		);
	} else {
		return <></>;
	}
}
