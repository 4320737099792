import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
	Flex,
	Heading,
	Avatar,
	Stack,
	Text,
	Icon,
	Portal,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	HStack,
	Checkbox,
} from "@chakra-ui/react";
import { useAsyncDebounce } from "react-table";
import { FaRegCommentAlt } from "react-icons/fa";
import { GoCalendar } from "react-icons/go";
import moment from "moment";
import { motion } from "framer-motion";
import LabelValuePair from "../../../core/Reports/components/LabelValuePair";
import { portalUser } from "../../../App";
import {
	convertArrayToMap,
	//  arrayToString,
	log,
} from "../../../helperFunctions";

export default function HRReviewsToDoListRow(props) {
	const form = useMemo(() => {
		let form = props.form;
		form.getNamesAndTitles();
		return form;
	}, [props.form]);

	const formStatus = useMemo(() => {
		if (Boolean(form)) {
			return form?.getFormStatus();
		} else {
			return null;
		}
	}, [form]);

	// const invitedToComment = useMemo(() => {
	// 	if (Boolean(form?.commenters?.length > 0)) {
	// 		let commenters = arrayToString(form?.commenters ?? [], ", ", false, "commenterName") ?? null;

	// 		return commenters;
	// 	} else {
	// 		return null;
	// 	}
	// }, [form]);

	var deptMap = convertArrayToMap(portalUser?.getDepartments(), "deptID");

	const [assignedForUser, setAssignedForUser] = useState(null);
	const [assignedToUser, setAssignedToUser] = useState(null);

	const getUser = useCallback(
		async (employeeUID = null, role = "assignedFor") => {
			let user = await portalUser.getUser(employeeUID, "employeeUID");
			if (!Boolean(user)) {
				user = props.usersMap[employeeUID] ?? null;
			}
			if (role === "assignedFor") {
				setAssignedForUser(user);
			} else if (role === "assignedTo") {
				setAssignedToUser(user);
			}
		},
		[props.usersMap]
	);

	useMemo(() => getUser(form?.assignedFor, "assignedFor"), [getUser, form?.assignedFor]);
	useMemo(() => getUser(form?.assignedTo, "assignedTo"), [getUser, form?.assignedTo]);

	const [updateTrigger, setUpdateTrigger] = useState(false);
	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const updateFormCommenterStatus = async (commenter) => {
		if (commenter) {
			let newStatus = commenter.commenterStatus === 1 ? 0 : 1;
			let result = await commenter.updateFormCommenter("commenterStatus", newStatus.toString());
			if (result) {
				commenter.commenterStatus = result.commenterStatus;
				// log("result", result);
				// if (result.commenterStatus === "1") {
				// 	commenter.commenterStatus = "1";
				// } else if (result.commenterStatus !== "1") {
				// 	commenter.commenterStatus = "0";
				// }
				// commenter = (prev) => ({ ...prev, commenterStatus: newStatus });
				setUpdateTrigger(true);
			}
			log("commenter", commenter);
		}
	};

	return (
		<Stack p={4} flex={1} spacing={2} w="full">
			<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
				<Flex
					p={2}
					rounded="md"
					bg="whiteAlpha.800"
					_hover={{
						borderWidth: 2,
						borderColor: "teal.500",
						bg: "white",
					}}
					borderWidth={2}
					flex={1}
					w="full"
					shadow={portalUser.styles?.cardShadow}
					borderColor={portalUser.styles?.cardBorderColor}
					justify="space-between"
				>
					<HStack flex={1} p={3} spacing={4} justify="space-between" align="center">
						<Stack flex={1}>
							<HStack
								flex={1}
								spacing={4}
								fontWeight="semibold"
								align="center"
								textTransform={"uppercase"}
							>
								<Avatar
									size="md"
									bg={deptMap[assignedForUser?.deptID]?.color}
									color="whiteAlpha.900"
									name={assignedForUser?.name ?? form?.assignedForName}
									src={assignedForUser?.profilePhoto ?? null}
								></Avatar>
								<Stack spacing={1}>
									<Heading size="md" color="gray.500" whiteSpace={"nowrap"} letterSpacing={2}>
										{assignedForUser?.name ?? form?.assignedForName}
									</Heading>
									<Text fontSize="md" color="gray.400" letterSpacing={1}>
										{assignedForUser?.title}
									</Text>
									<Flex>
										<HStack
											align="center"
											bg={formStatus?.color + ".100"}
											py={1}
											px={2}
											rounded="md"
											borderWidth={1}
											borderColor={formStatus?.color + ".500"}
										>
											<Text
												fontSize="lg"
												className={formStatus?.icon + " fa-lg fa-fw"}
												color={formStatus?.color + ".500"}
											/>

											<Text fontSize="sm" color={formStatus?.color + ".500"}>
												{formStatus?.status}
											</Text>
										</HStack>
									</Flex>
								</Stack>
							</HStack>
						</Stack>

						<Stack flex={1}>
							<HStack flex={1}>
								<Avatar
									size="sm"
									bg={deptMap[assignedForUser?.deptID]?.color}
									color="whiteAlpha.900"
									name={assignedToUser?.name ?? form?.assignedToName}
									src={assignedToUser?.profilePhoto ?? null}
								/>
								<LabelValuePair
									variant="smallText"
									direction="vertical"
									label="Reviewer"
									value={assignedToUser?.name}
								/>
							</HStack>

							<HStack
								flex={1}
								onClick={() => {
									props.selectForm(form?.formUID);
								}}
								_hover={{
									textDecoration: "underline",
									cursor: "pointer",
									fontWeight: 600,
								}}
							>
								<Icon color="gray.500" boxSize="30px">
									{<Text as="i" className="far fa-file-alt" />}
								</Icon>
								<LabelValuePair
									HRReviewList
									variant="smallText"
									direction="vertical"
									label="Review Form"
									value={form?.title}
								/>
							</HStack>
						</Stack>

						<Stack flex={1} spacing={4}>
							<HStack flex={1}>
								<Icon as={FaRegCommentAlt} color="gray.500" boxSize="30px" />
								<Stack>
									<LabelValuePair
										variant="smallText"
										direction="vertical"
										label="Invited To Comment"
									/>
									<Stack isInline>
										<Flex direction="row" wrap="wrap" align="center" pl={2}>
											{form?.commenters.map((commenter, i) => (
												<Flex key={i} direction="row" align="center" mr={1}>
													<Text variant="smallText" pr={1}>
														{commenter.commenterName}
													</Text>
													<Checkbox
														isChecked={[1, "1"].includes(commenter?.commenterStatus)}
														onChange={(ev) => {
															ev.stopPropagation();
															updateFormCommenterStatus(commenter);
														}}
													></Checkbox>
													{/* {commenter.commenterStatus === 1 ? (
														<i className="fas fa-check-square"></i>
													) : (
														<i className="far fa-square"></i>
													)} */}
													{form.commenters.length - 1 !== i && <Text pl={0.5}>,</Text>}
												</Flex>
											))}
										</Flex>
									</Stack>
								</Stack>

								{/* <LabelValuePair
									variant="smallText"
									direction="vertical"
									label="Invited To Comment"
									value={invitedToComment ?? "N/A"}
									// value={
									// 	form?.commentersWaiting > 0
									// 		? `Awaiting ${form?.commentersWaiting} Commenters`
									// 		: "N/A"
									// }
								/> */}
							</HStack>

							<HStack flex={1}>
								<Icon as={GoCalendar} color="gray.500" boxSize={"30px"} />
								<LabelValuePair
									flex={1}
									variant="smallText"
									direction="vertical"
									label={"Review Date"}
									placeholder="mm/dd/yyyy"
									value={
										Boolean(form?.dateReview)
											? moment(form?.dateReview)?.format("MM/DD/YYYY")
											: "N/A"
									}
								/>
							</HStack>
						</Stack>
					</HStack>
					<Flex justify="flex-end" align="flex-start" h="full">
						<Menu isLazy>
							{({ isOpen }) => (
								<>
									<MenuButton
										isActive={isOpen}
										as={IconButton}
										onClick={(ev) => {
											ev.stopPropagation();
										}}
										variant="ghost"
										color={isOpen ? "blue.400" : "gray.400"}
										key={form?.formUID + "- fas fa-cog " + (isOpen && "fa-spin ") + " fa-lg"}
										size="sm"
										icon={
											<Text
												fontSize="lg"
												className={"fas fa-cog " + (isOpen && "fa-spin ") + " fa-lg"}
											/>
										}
									/>

									<Portal>
										<MenuList color="gray.500" fontWeight="semibold">
											<MenuItem
												icon={
													<Text
														fontSize="xl"
														color="blue.400"
														className={"fas fa-info-circle fa-fw"}
													/>
												}
												onClick={(ev) => {
													ev.stopPropagation();
													props.openDrawer(form);
												}}
											>
												<Text
													fontSize="lg"
													color="blue.500"
													textTransform="uppercase"
													letterSpacing={1}
												>
													Edit Info
												</Text>
											</MenuItem>

											<MenuItem
												icon={
													<Text
														fontSize="xl"
														color="teal.400"
														className={"fas fa-file-alt fa-fw"}
													/>
												}
												onClick={(ev) => {
													ev.stopPropagation();
													props.selectForm(form?.formUID);
												}}
											>
												<Text
													fontSize="lg"
													color="teal.500"
													textTransform="uppercase"
													letterSpacing={1}
												>
													Open Form
												</Text>
											</MenuItem>
										</MenuList>
									</Portal>
								</>
							)}
						</Menu>
					</Flex>
				</Flex>
			</motion.div>
		</Stack>
	);
}
