import React, { useState, useMemo, useEffect } from "react";
import {
	Box,
	Center,
	Flex,
	Heading,
	Button,
	Text,
	HStack,
	Stat,
	StatLabel,
	StatNumber,
	Divider,
	StatHelpText,
	VStack,
	Collapse,
	useDisclosure,
	Portal,
	Stack,
} from "@chakra-ui/react";
import moment from "moment";
import DataInput from "../../../core/Inputs/data/DataInput";
import { formatValue } from "../../../helperFunctions";

export default function AccrualCalculator({ prEmployee = {}, upcomingRequests = [] }) {
	const [availableVacation, setAvailableVacation] = useState(0);
	const [availableSick, setAvailableSick] = useState(0);
	const [accrualRate, setAccrualRate] = useState(0);
	const [futureTimeOff, setFutureTimeOff] = useState(0);
	const [maxAvailable, setMaxAvailable] = useState(0);
	const { isOpen, onToggle } = useDisclosure();
	const [endDate, setEndDate] = useState(null);
	const [numberofMths, setNumberOfMths] = useState(0);
	const [accrualHours, setAccrualHours] = useState(0);
	const [futureAvailable, setFutureAvailable] = useState(0);
	// const [futureRemainingHours, setFutureRemainingHours] = useState(0);
	const [numOfRemainingMths, setNumOfRemainingMths] = useState(0);
	const [futureMth, setFutureMth] = useState(moment(new Date()).startOf("month").format("MMM YYYY"));
	const [sickDayResetMth, setSickDayResetMth] = useState(moment(new Date()).startOf("month").format("MMM YYYY"));
	const [numOfDaySickReset, setNumOfDaySickReset] = useState(0);

	useMemo(() => {
		setAvailableVacation(prEmployee?.availableVacation ?? 0);
		setAvailableSick(prEmployee?.availableSick ?? 0);
		setAccrualRate(prEmployee?.accrualRate ?? 0);
		setMaxAvailable(prEmployee?.maxAvailable ?? 0);
	}, [prEmployee?.accrualRate, prEmployee?.availableSick, prEmployee?.availableVacation, prEmployee?.maxAvailable]);

	const updateEndDate = (date) => {
		let startDate = new Date();
		let endDate = date;
		if (moment(startDate).startOf("month").diff(moment(endDate).startOf("month")) >= 0) {
			endDate = null;
		}
		setEndDate(endDate);
	};

	useEffect(() => {
		const startDate = new Date();
		let thruDate = startDate;
		if (Boolean(endDate)) {
			thruDate = endDate;
		}
		let futureTimeOff = 0;

		// for (let i = 0; i < upcomingRequests?.length; i++) {
		// 	if (
		// 		new Date(upcomingRequests[i]?.endDate)?.getTime() >= startDate?.getTime() &&
		// 		new Date(upcomingRequests[i]?.endDate)?.getTime() <= endDate?.getTime()
		// 	) {
		// 		futureTimeOff += upcomingRequests[i].requestedHours;
		// 	}
		// }
		setFutureTimeOff(futureTimeOff ?? 0);

		let years = (thruDate.getFullYear() - startDate.getFullYear()) * 12;
		let months = startDate.getMonth() - thruDate.getMonth();
		let numberOfMths = years - months;
		let accuralHours = accrualRate * numberOfMths;
		let futureAvailable = availableVacation + accuralHours - futureTimeOff;
		if (futureAvailable > maxAvailable) {
			futureAvailable = maxAvailable;
		}

		// let futureRemainingHours = maxAvailable - futureAvailable;
		let numOfRemainingMths = 0;
		if (accrualRate > 0) {
			numOfRemainingMths = availableVacation / accrualRate;
		}
		if (numOfRemainingMths < 0) {
			numOfRemainingMths = 0;
		}
		numOfRemainingMths = Math.ceil(numOfRemainingMths);

		setNumberOfMths(numberOfMths);
		setAccrualHours(accuralHours);
		setFutureAvailable(futureAvailable);
		// setFutureRemainingHours(futureRemainingHours);
		setNumOfRemainingMths(numOfRemainingMths);
		setFutureMth(moment(new Date())?.startOf("month")?.add(numOfRemainingMths, "months").format("MMM YYYY"));

		let sickDayResetMth = moment(startDate).startOf("year").add(1, "years");
		let numOfDaySickReset = sickDayResetMth.diff(moment(startDate).startOf("month"), "months");
		numOfDaySickReset = Math.ceil(numOfDaySickReset);

		setSickDayResetMth(sickDayResetMth.format("MMM YYYY"));
		setNumOfDaySickReset(numOfDaySickReset);
	}, [endDate, accrualRate, availableVacation, futureTimeOff, maxAvailable, upcomingRequests]);

	return (
		<Box id="availableTimeOff" px={2} py={0} rounded={5}>
			<Flex justify="space-evenly" w="full">
				<HStack
					align="center"
					justify="center"
					w="full"
					spacing={2}
					// _hover={{ shadow: "sm", borderColor: "blackAlpha.100", bg: "blackAlpha.50" }}
					borderWidth={2}
					borderColor="transparent"
					p={4}
					rounded={8}
				>
					<Heading as="h2" size="lg" color="teal.600" p={1}>
						<i className="fas fa-plane fa-rotate-270 fa-2x fa-fw " />
					</Heading>
					<Flex>
						<Stat size="lg">
							<StatLabel
								textTransform="uppercase"
								letterSpacing={2}
								fontWeight="medium"
								isTruncated
								fontSize="xl"
								color="gray.400"
							>
								Vacation
							</StatLabel>
							<StatNumber color="gray.500">
								<HStack align="flex-end" spacing={2}>
									<Heading fontWeight={"normal"} size="xl">
										{formatValue(availableVacation, 1, "number")}
									</Heading>

									<Heading fontWeight="semibold" size="md" lineHeight={1.5} letterSpacing={2}>
										HRS
									</Heading>
								</HStack>
							</StatNumber>
							<StatHelpText color="gray.500" fontWeight={400}>
								{formatValue(availableVacation / 8, 1, "number") + " Work Days *"}
							</StatHelpText>
						</Stat>
					</Flex>
				</HStack>

				<Center mx={4}>
					<Divider orientation="vertical" borderColor="gray.400" borderLeftWidth={2} />
				</Center>

				<HStack
					align="center"
					justify="center"
					w="full"
					spacing={2}
					// _hover={{ shadow: "sm", borderColor: "blackAlpha.100", bg: "blackAlpha.50" }}
					borderWidth={2}
					borderColor="transparent"
					rounded={8}
					p={4}
				>
					<Heading as="h2" size="lg" color="teal.600" p={1}>
						<i className="fas fa-stethoscope fa-2x fa-fw " />
					</Heading>
					<Flex>
						<Stat size="lg">
							<StatLabel
								textTransform="uppercase"
								letterSpacing={2}
								fontWeight="medium"
								isTruncated
								fontSize="xl"
								color="gray.400"
							>
								Sick Time
							</StatLabel>

							<StatNumber color="gray.500">
								<HStack align="flex-end" spacing={2}>
									<Heading fontWeight={"normal"} size="xl">
										{formatValue(availableSick, 1, "number")}
									</Heading>
									<Heading fontWeight="semibold" size="md" lineHeight={1.5} letterSpacing={2}>
										HRS
									</Heading>
								</HStack>
							</StatNumber>

							<StatHelpText color="gray.500" fontWeight={400}>
								{formatValue(availableSick / 8, 1, "number") + " Work Days *"}
							</StatHelpText>
						</Stat>
					</Flex>
				</HStack>
			</Flex>

			<Divider borderColor="gray.400" borderBottomWidth={2} />

			<Collapse in={isOpen} animateOpacity w="full" flex={1} display="flex">
				<VStack
					spacing={3}
					w="full"
					align="center"
					borderWidth={2}
					borderColor="transparent"
					rounded={8}
					flex={1}
					pt={4}
					px={2}
				>
					<Flex flex={1} w="full" align="center" justify="center">
						<Flex>
							<DataInput
								inputProps={{
									showMonthYearPicker: true,
									dateFormat: "MMM yyyy",
									popperContainer: Portal,
									bg: "whiteAlpha.700",

									letterSpacing: 1,
									borderWidth: 1,
									shadow: "md",
									py: 0,
									fontSize: "lg",
									color: "gray.500",
									fontWeight: "bold",

									textTransform: "uppercase",
								}}
								textAlign="center"
								name="start"
								type="month"
								label="Accrual Calculator"
								placeholder="Select Month"
								labelProps={{
									fontWeight: 500,
									letterSpacing: 2,
									fontFamily: "body",
									fontSize: "xl",
									color: "gray.500",
									w: "full",
									align: "center",
									pb: 2,
									whiteSpace: "nowrap",
									mx: 0,
									textAlign: "center",
								}}
								value={endDate}
								my={1}
								onChange={updateEndDate}
							/>
						</Flex>
					</Flex>

					<Flex direction="row" align="flex-start" justify="center" w="full" py={2} h="full" flex={1}>
						<HStack align="center" justify="center" w="full" spacing={2} flex={1} isTruncated rounded={8}>
							<Heading size="lg" color="teal.600" p={1} flex={1} maxW="150px">
								<i className="fas fa-calculator fa-lg fa-fw " />
							</Heading>
							<Flex flex={5} isTruncated>
								<Stack align="flex-start" justify="center" w="full" spacing={1} h="full" px={0}>
									<StatLabel
										isTruncated
										textTransform="uppercase"
										fontWeight={500}
										letterSpacing={2}
										fontSize="lg"
										color="gray.400"
									>
										Calculated Accrual
									</StatLabel>

									<HStack align="flex-end" justify="center" spacing={2} color="gray.500">
										<Heading fontWeight={700} size="lg" fontFamily="body">
											{formatValue(availableVacation, 1, "number") +
												(accrualHours > 0 ? "+" + formatValue(accrualHours, 1, "number") : "")}
										</Heading>

										<Heading
											color="gray.500"
											fontSize="lg"
											fontFamily="body"
											lineHeight={1.5}
											letterSpacing={1}
											textTransform="uppercase"
										>
											{" Hrs"}
										</Heading>
									</HStack>
									<Stack align="flex-start" justify="center" w="full" spacing={1} h="full" px={1}>
										<HStack align="flex-start" justify="center" spacing={1}>
											<Text color="gray.500" fontSize="sm" fontFamily="body">
												{formatValue(numberofMths, 1, "number") +
													" Mth" +
													(numberofMths !== 1 ? "s" : "")}
											</Text>
											<Text color="gray.500" fontSize="sm" fontFamily="body">
												{" x " + formatValue(accrualRate, 1, "number") + " Hrs/Mth "}
											</Text>
											<Text color="gray.500" fontSize="sm" fontFamily="body">
												{"= " + formatValue(accrualHours, 1, "number") + " Hrs"}
											</Text>
										</HStack>
										<Text color="gray.500" fontSize="sm" fontFamily="body">
											{formatValue(accrualHours, 1, "number") +
												" Accrued Hrs = " +
												formatValue(accrualHours / 8, 1, "number") +
												" Work Days"}
										</Text>
									</Stack>
								</Stack>
							</Flex>
						</HStack>

						<Divider
							orientation="vertical"
							mx={4}
							borderLeftWidth={2}
							minH="125px"
							borderColor="gray.400"
						/>

						<HStack align="center" justify="center" w="full" spacing={2} flex={1} isTruncated rounded={8}>
							<Heading
								size="lg"
								color={accrualHours + availableVacation <= futureAvailable ? "teal.600" : "yellow.500"}
								key={accrualHours + availableVacation <= futureAvailable ? "underMax" : "overMax"}
								p={1}
								flex={1}
								maxW="150px"
							>
								<i
									className={
										"fas " +
										(accrualHours + availableVacation <= futureAvailable
											? "fa-calendar-check"
											: "fa-exclamation-circle") +
										" fa-lg fa-fw "
									}
								/>
							</Heading>
							<Flex flex={5} isTruncated>
								<Stack align="flex-start" justify="center" w="full" spacing={1} h="full" px={0}>
									<StatLabel
										isTruncated
										textTransform="uppercase"
										fontWeight={500}
										letterSpacing={2}
										fontSize="lg"
										color="gray.400"
									>
										Projected Time Off
									</StatLabel>

									<HStack align="flex-end" justify="center" spacing={2} color="gray.500">
										<Heading fontWeight={700} size="lg" fontFamily="body">
											{formatValue(futureAvailable, 1, "number") +
												(accrualHours + availableVacation > futureAvailable ? "*" : "")}
										</Heading>

										<Heading
											color="gray.500"
											fontSize="lg"
											fontFamily="body"
											lineHeight={1.5}
											letterSpacing={1}
											textTransform="uppercase"
										>
											{" Hrs"}
										</Heading>
									</HStack>
									<Stack align="flex-start" justify="center" w="full" spacing={1} h="full" px={1}>
										{Boolean(accrualHours + availableVacation <= futureAvailable) && (
											<HStack align="flex-start" justify="center" spacing={1}>
												<Text color="gray.500" fontSize="sm" fontFamily="body">
													{formatValue(availableVacation, 1, "number") +
														" Hr" +
														(availableVacation !== 1 ? "s" : "")}
												</Text>
												<Text color="gray.500" fontSize="sm" fontFamily="body">
													{" + " +
														formatValue(futureAvailable - availableVacation, 1, "number") +
														" Hrs "}
												</Text>
												<Text color="gray.500" fontSize="sm" fontFamily="body">
													{"= " + formatValue(futureAvailable, 1, "number") + " Hrs"}
												</Text>
											</HStack>
										)}
										{Boolean(accrualHours + availableVacation > futureAvailable) && (
											<Text color="gray.500" fontSize="sm" fontFamily="body">
												{"* You have reached Max Accrual"}
											</Text>
										)}
										<Text color="gray.500" fontSize="sm" fontFamily="body">
											{formatValue(futureAvailable, 1, "number") +
												" Accrued Hrs = " +
												formatValue(futureAvailable / 8, 1, "number") +
												" Work Days"}
										</Text>
									</Stack>
								</Stack>
							</Flex>
						</HStack>
					</Flex>

					<Flex direction="row" align="flex-start" justify="center" w="full" py={2} h="full" flex={1}>
						<HStack align="center" justify="center" w="full" spacing={2} rounded={8}>
							<Heading size="lg" color="teal.600" p={1} flex={1} maxW="150px">
								<i className="fas fa-stopwatch fa-lg fa-fw " />
							</Heading>
							<Flex flex={5} isTruncated>
								<Stack align="flex-start" justify="center" w="full" spacing={1} h="full" px={0}>
									<StatLabel
										isTruncated
										textTransform="uppercase"
										fontWeight={500}
										letterSpacing={2}
										fontSize="lg"
										color="gray.400"
									>
										My Accrual Rate
									</StatLabel>

									<HStack align="flex-end" justify="center" spacing={2} color="gray.500">
										<Heading fontWeight={700} size="lg" fontFamily="body">
											{formatValue(accrualRate, 1, "number")}
										</Heading>

										<Heading
											color="gray.500"
											fontSize="lg"
											fontFamily="body"
											lineHeight={1.5}
											letterSpacing={1}
											textTransform="uppercase"
										>
											{" Hrs/Mth"}
										</Heading>
									</HStack>
									<Stack align="flex-start" justify="center" w="full" spacing={1} h="full" px={1}>
										<Text color="gray.500" fontSize="sm" fontFamily="body">
											{formatValue(accrualRate, 1, "number") +
												" Hrs/Mth = " +
												formatValue(accrualRate / 8, 2, "number") +
												" PTO Days/Mth"}
										</Text>
										<Text color="gray.500" fontSize="sm" fontFamily="body">
											{formatValue(5, 0, "number") +
												" Sick Days reset in " +
												moment(new Date(sickDayResetMth)).format("MMM YYYY") +
												" (" +
												formatValue(numOfDaySickReset, 0, "number") +
												" Mths)"}
										</Text>
									</Stack>
								</Stack>
							</Flex>
						</HStack>

						<Divider
							orientation="vertical"
							mx={4}
							borderLeftWidth={2}
							minH="125px"
							borderColor="gray.400"
						/>

						<HStack align="center" justify="center" w="full" spacing={2} rounded={8}>
							<Heading size="lg" color="teal.600" p={1} flex={1} maxW="150px">
								<i className="fas fa-calendar-times fa-lg fa-fw " />
							</Heading>
							<Flex flex={5} isTruncated>
								<Stack align="flex-start" justify="center" w="full" spacing={1} h="full" px={0}>
									<StatLabel
										isTruncated
										textTransform="uppercase"
										fontWeight={500}
										letterSpacing={2}
										fontSize="lg"
										color="gray.400"
									>
										Max Accrual Cap
									</StatLabel>

									<HStack align="flex-end" justify="center" spacing={2} color="gray.500">
										<Heading fontWeight={700} size="lg" fontFamily="body">
											{formatValue(maxAvailable, 1, "number")}
										</Heading>

										<Heading
											color="gray.500"
											fontSize="lg"
											fontFamily="body"
											lineHeight={1.5}
											letterSpacing={1}
											textTransform="uppercase"
										>
											{" Hrs"}
										</Heading>
									</HStack>
									<Stack align="flex-start" justify="center" w="full" spacing={1} h="full" px={1}>
										<Text color="gray.500" fontSize="sm" fontFamily="body">
											{formatValue(maxAvailable, 1, "number") +
												" Hrs = " +
												formatValue(maxAvailable / 8, 1, "number") +
												" Work Days"}
										</Text>
										<Text color="gray.500" fontSize="sm" fontFamily="body">
											{"Max Accrual in " +
												futureMth +
												" (" +
												formatValue(numOfRemainingMths, 0, "number") +
												" Mths)"}
										</Text>
									</Stack>
								</Stack>
							</Flex>
						</HStack>
					</Flex>

					<Collapse
						in={Boolean(endDate)}
						style={{ width: "full", marginLeft: 0, marginRight: 0 }}
						display="flex"
						flex={1}
						px={0}
						w="full"
					></Collapse>

					<Center pt={4} w="full">
						<Divider borderColor="gray.400" borderBottomWidth={2} />
					</Center>
				</VStack>
			</Collapse>
			<Flex w="full" justify="center" py="4">
				<Button
					variant="outline"
					onClick={onToggle}
					color="gray.500"
					px={2}
					size="md"
					bg="whiteAlpha.700"
					textTransform="uppercase"
					shadow="md"
					_hover={{
						color: "teal.500",
						fontWeight: "bold",
						bg: "whiteAlpha.700",
						borderColor: "teal.500",
						borderWidth: 2,
					}}
				>
					<HStack align="center" spacing={2} key={isOpen ? "fas fa-times fa-fw" : "fas fa-calculator fa-fw"}>
						<Text
							lineHeight={1}
							className={isOpen ? "fas fa-times fa-fw" : "fas fa-calculator fa-fw"}
							fontSize="md"
						/>
						<Text lineHeight={1} fontSize="md" letterSpacing={1} pr={1}>
							{isOpen ? "Close" : "Calculate"}
						</Text>
					</HStack>
				</Button>
			</Flex>
		</Box>
	);
}
