export default class FormSection {
	constructor(section) {
		if (section instanceof FormSection) {
			Object.assign(this, section);
		} else {
			this.queryData = section?.queryData ?? "FormSection";

			//FormUID
			this.templateUID = section?.templateUID ?? null;
			this.sectionUID = section?.sectionUID ?? null;

			//Integer
			this.keyID = section?.keyID ?? null;
			this.order = section?.order ?? null;

			//String
			this.heading = section?.heading ?? null;
			this.subheading = section?.subheading ?? null;

			//Boolean
			this.isNewPageYN = section?.isNewPageYN ?? "N";
			this.isLockedYN = section?.isLockedYN ?? "N";

			this.pageVal = section?.pageVal ?? 0;
		}
	}
}
