import { formatDate } from "../../../helperFunctions";

export default class BillingsByMth {
	constructor(billing) {
		this.queryData = billing?.queryData ?? billing?.QueryData ?? "BillingsByMth";
		this.jccmKeyID = billing?.jccmKeyID ?? billing?.JCCMKeyID ?? null;
		this.jcco = billing?.jcco ?? billing?.JCCo ?? null;
		this.contract = billing?.contract ?? billing?.Contract?.trim() ?? null;
		this.type = billing?.type ?? billing?.ARTransGroup ?? null;
		this.arTransGroup = billing?.arTransGroup ?? billing?.ARTransGroup ?? null;
		this.billNo = billing?.billNo ?? billing?.ARTransGroup ?? null;
		this.mth = formatDate(billing?.mth ?? billing?.Mth) ?? null;
		this.netBillingAmt = billing?.netBillingAmt ?? billing?.NetBilling ?? 0;
		this.dateSubmitted = formatDate(billing?.dateSubmitted ?? billing?.DateSubmitted) ?? null;
		this.dateDueBy = formatDate(billing?.dateDueBy ?? billing?.DueBy) ?? null;
		this.datePaidOn = formatDate(billing?.datePaidOn ?? billing?.PaidOn) ?? null;
		this.earlyLate = billing?.earlyLate ?? billing?.EarlyLate ?? null;
		this.expectedCollection = formatDate(billing?.expectedCollection ?? billing?.ExpectedCollection) ?? null;
	}
}
