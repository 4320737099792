import { v4 } from "uuid";

import DFDeliverable from "./DFDeliverable";

import SchedulesAPI from "../../../APIs/SchedulesAPI";
import EgnyteAPI from "../../../APIs/EgnyteAPI";
import EGNYTE_DOMAIN_URL from "../constants/EgnyteDomainURL";
import EGNYTE_PATH from "../constants/EgnytePath";

import { portalUser } from "../../../App";
import { formatDate, convertArrayToMap, log } from "../../../helperFunctions";

export default class DFSequence {
	constructor(sequence) {
		if (sequence instanceof DFSequence) {
			Object.assign(this, sequence);
		} else {
			this.queryData = sequence?.queryData ?? "DFSequence";
			this.schedulesAPI = new SchedulesAPI();
			this.egnyteAPI = new EgnyteAPI();
			this.uid = sequence?.uid ?? v4();
			this.id = sequence?.id?.toString() ?? null;
			this.sequenceID = sequence?.sequenceID?.toString() ?? null;
			this.jobID = sequence?.jobID?.toString() ?? null;

			this.seq = sequence?.seq?.toString() ?? null;
			this.seqOrder = sequence?.seqOrder?.toString() ?? null;
			this.cpmSchedID = sequence?.cpmSchedID ?? null;
			this.shopDrawings = sequence?.shopDrawings ?? null;
			this.pipingLevel = sequence?.pipingLevel ?? null;
			this.deckingLevel = sequence?.deckingLevel ?? null;
			this.area = sequence?.area ?? null;
			this.quad = sequence?.quad ?? null;
			this.system = sequence?.system ?? null;
			this.installStart = formatDate(sequence?.installStart) ?? null;
			this.installOffset = sequence?.installOffset ?? 0;
			this.comments = sequence?.comments ?? null;

			this.isDeleted = sequence?.isDeleted ?? "0";
			this.templateType = sequence?.templateType ?? "D&F";

			// this.dateUpdated = formatDate(sequence?.dateUpdated) ?? new Date();
			// this.updatedBy = sequence?.updatedBy ?? null;
			// this.dateCreated = formatDate(sequence?.dateCreated) ?? new Date();
			// this.createdBy = sequence?.createdBy ?? null; // ?? portalUser?.user?.employeeUID;

			this.workflows = sequence?.workflows ?? [];
			this.deliverables = sequence?.deliverables ?? [];
		}
	}

	initDFSequence(workflows = this.workflows, deliverables = this.deliverables, installOffset = this.installOffset) {
		Object.assign(this, { installOffset });

		let workflowMap = convertArrayToMap(workflows, "deliverableNum");
		for (let i = 0; i < deliverables?.length; i++) {
			let workflow = workflowMap[deliverables[i]?.deliverableNum] ?? null;

			if (Boolean(deliverables[i]?.deliverableNum && workflow?.deliverableNum)) {
				deliverables[i].initDFDeliverable(deliverables[i], workflow);
				deliverables[i].deliverableType = this.initSequenceOverrides(deliverables[i]);
				// log("deliverables[i].deliverableType", deliverables[i].deliverableType);
				this[deliverables[i].accessor] = deliverables[i]?.calcDate ?? null;
				this[deliverables[i].accessor + "FormInput"] =
					deliverables[i]?.deliverableType ?? workflow?.deliverableType;
				this[deliverables[i].accessor + "Bg"] = deliverables[i]?.bg ?? "transparent";
				this[deliverables[i].accessor + "dateCompleted"] = deliverables[i]?.dateCompleted ?? null;
				this[deliverables[i].accessor + "calcDate"] = deliverables[i]?.calcDate ?? null;
				this[deliverables[i].accessor + "showDeliverable"] = deliverables[i]?.showDeliverable ?? "1";
				this[deliverables[i].accessor + "egnyteLink"] = deliverables[i]?.egnyteLink
					? `${EGNYTE_DOMAIN_URL}${deliverables[i]?.egnyteLink}`
					: null;
			}
			// 	log("check here", deliverables[i]);
			// 	deliverables[i].getEgnyteFolder();
		}
		Object.assign(this, { workflows, deliverables });
	}

	initSequenceOverrides(deliverable) {
		const comments = this?.comments?.replace(/\s/g, "")?.toLowerCase();
		const system = this?.system?.replace(/\s/g, "")?.toLowerCase();

		//********************************************************************************************/
		//*****************  D&F Display Override Rules for Deliverables  **********************/
		//******************************************************************************************/

		// if TemplateType is D&F
		if (deliverable?.templateID !== "13") {
			//and if System is Finish then Deliverable columns 0-7, 11-14 are displayOnly
			if (
				system === "finish" &&
				["0", "1", "2", "3", "4", "5", "6", "7", "11", "12", "13", "14"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}

			//or if System contains risers, shaft risers or risers/shaft risers
			else if (["risers"].includes(system)) {
				//if TemplateID is -2 or -6 then Deliverable columns 0-6, 11, 12 and 20 are displayOnly
				if (
					["-2", "-6"].includes(deliverable?.templateID) &&
					["0", "1", "2", "3", "4", "5", "6", "11", "12", "20"].includes(deliverable?.deliverableNum)
				) {
					return "displayOnly";
					//if TemplateID is not -2 or -6 then Deliverable columns 0-4, and 7 are displayOnly
				} else if (["0", "1", "2", "3", "4", "7"].includes(deliverable?.deliverableNum)) {
					return "displayOnly";
				}
			}

			//if System contains * or + and TemplateID is -8
			else if (["*", "+"].includes(system) && ["-8"].includes(deliverable?.templateID)) {
				// then if System has a *, then Deliverable columns 0-7, 11-12, 18 and 20 are displayOnly
				if (
					["*"].includes(system) &&
					["0", "1", "2", "3", "4", "5", "6", "7", "11", "12", "18", "20"].includes(
						deliverable?.deliverableNum
					)
				) {
					return "displayOnly";

					// else if System has a +, then Deliverable columns 5, 6, 11 & 12 are displayOnly
				} else if (["+"].includes(system) && ["5", "6", "11", "12"].includes(deliverable?.deliverableNum)) {
					return "displayOnly";
				}
			}

			//else if Comments contain Do Not Fab and TemplateID is 9, 10 or 11, then Deliverable columns 15-18 & 20 are displayOnly
			else if (
				comments?.includes("donotfab") &&
				["9", "10", "11"].includes(deliverable?.templateID) &&
				["15", "16", "17", "18", "20"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}
		}

		//********************************************************************************************/
		//*****************  SUP Display Override Rules for Deliverables  **********************/
		//******************************************************************************************/

		// if TemplateType is SUP
		else {
			// comment
			if (system === "ahu" && ["11", "12", "13"].includes(deliverable?.deliverableNum)) {
				return "displayOnly";
			}
			// comment
			else if (system === "boilers" && ["9", "18"].includes(deliverable?.deliverableNum)) {
				return "displayOnly";
			}
			// comment
			else if (system === "pumps" && ["1", "9", "13", "18"].includes(deliverable?.deliverableNum)) {
				return "displayOnly";
			}
			// comment
			else if (
				["exhaustfans", "labexhaustfans"].includes(system) &&
				["1", "3", "6", "7", "8", "11", "12", "13"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}
			// comment
			else if (
				["vrf", "splitsystems"].includes(system) &&
				["1", "3", "6", "7", "8", "10", "11", "12", "13"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}
			// comment
			else if (
				["waterheaters"].includes(system) &&
				["9", "10", "11", "18"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}
			// comment
			else if (["chillers"].includes(system) && ["1", "9", "18"].includes(deliverable?.deliverableNum)) {
				return "displayOnly";
			}
			// comment
			else if (["fancoils"].includes(system) && ["1", "13"].includes(deliverable?.deliverableNum)) {
				return "displayOnly";
			}
			// comment
			else if (
				["cda"].includes(system) &&
				["1", "3", "6", "7", "8", "9", "10", "12", "13", "18"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}
			// comment
			else if (
				["vacuumpump"].includes(system) &&
				["1", "3", "6", "7", "8", "9", "10", "11", "12", "13", "18"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}
			// comment
			else if (
				["rodi"].includes(system) &&
				["1", "9", "10", "11", "12", "18"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}
			// comment
			else if (
				["condensatepumps"].includes(system) &&
				["3", "6", "7", "8", "9", "10", "18"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}
			// comment
			else if (["coolingtower"].includes(system) && ["1", "18"].includes(deliverable?.deliverableNum)) {
				return "displayOnly";
			}
			// comment
			else if (
				["vavs", "airvalves"].includes(system) &&
				["1", "7", "8", "10", "13"].includes(deliverable?.deliverableNum)
			) {
				return "displayOnly";
			}
		}
	}

	formatEgnyteName(value) {
		value = value?.replace(/\s*(.)/gm, "$1");
		if (Boolean(value) && value[value?.length - 1] === ".") {
			value = value?.substr(0, value?.length - 1);
		}
		return value;
	}

	async getEgnyteFolder() {
		let job = portalUser.DFData?.jobs?.find((d) => d.jobID === this.jobID) ?? {};
		let contract = this.formatEgnyteName(job.contract);
		let dfJobNumber = job?.job;
		let result = await this.egnyteAPI.getFolder(`${EGNYTE_PATH}/${contract}/${dfJobNumber}/${this.seq}`);
		log("Egnyte Folder", [result, `${EGNYTE_PATH}/${contract}/${dfJobNumber}/${this.seq}`]);
		if (Boolean(result.errorMessage)) {
			result = await this.egnyteAPI.createFolder(
				encodeURI(`${EGNYTE_PATH}/${contract}/${dfJobNumber}/${this.seq}`)
			);

			let deliverableResult = await this.schedulesAPI.PortalUser_DFData_GetDFDeliverables({
				sequenceID: this.sequenceID,
			});
			let deliverablesArray = deliverableResult.value;

			// let newDeliverable = new DFDeliverable(deliverablesArray[0]);
			// newDeliverable.getEgnyteFolder();
			for (let i = 0; i < deliverablesArray.length; i++) {
				setTimeout(() => {
					let newDeliverable = new DFDeliverable(deliverablesArray[i]);
					newDeliverable.getEgnyteFolder();
				}, 2000 * i);
			}
		}
		return result;
	}

	async updateRow(accessor, value, isEditing = false, isShowDeliverable = false) {
		if (accessor?.substring(0, 12) === "deliverable_") {
			let deliverableMap = convertArrayToMap(this.deliverables, "accessor");
			let deliverable = deliverableMap[accessor];
			let deliverableType = deliverable?.deliverableType ?? "checkDate";

			if (!isEditing && !isShowDeliverable) {
				if (!Boolean(this[accessor + "dateCompleted"])) {
					deliverable.dateCompleted = value;
				} else {
					deliverable.dateCompleted = null;
				}
			} else if (isEditing && !isShowDeliverable) {
				deliverable.editDate = value;
			} else if (isShowDeliverable) {
				deliverable.showDeliverable = value;
			}

			deliverable.initDFDeliverable();
			await deliverable.updateDB();

			deliverable.deliverableType = this.initSequenceOverrides(deliverable);
			this[deliverable.accessor] = deliverable?.editDate ?? deliverable?.calcDate;
			this[deliverable.accessor + "FormInput"] = deliverableType;
			this[deliverable.accessor + "Bg"] = deliverable?.bg ?? "transparent";
			this[deliverable.accessor + "dateCompleted"] = deliverable?.dateCompleted ?? null;
			this[deliverable.accessor + "calcDate"] = deliverable?.calcDate ?? null;
			this[deliverable.accessor + "showDeliverable"] = deliverable?.showDeliverable ?? "1";
			this[deliverable.accessor + "egnyteLink"] = deliverable?.egnyteLink
				? `${EGNYTE_DOMAIN_URL}${deliverable?.egnyteLink}`
				: null;
		} else {
			value = value?.replace("'", "`") ?? null;
			Object.assign(this, { [accessor]: value });

			await this.updateDB();
			// if (accessor === "installStart") {
			// 	//TODO: Write a date function that comes back with the updated calcDates
			// 	let responseMap = convertArrayToMap(response.value.deliverables, "queryData", true);
			// 	this.initDFSequence(null, responseMap, this.installStart);
			// }
		}
		return this;
	}

	async insertDB() {
		//TODO: Insert deliverables for that workflow (initDeliverableDates())
		if (!Boolean(this.id)) {
			let data = (({
				jobID,
				cpmSchedID,
				shopDrawings,
				pipingLevel,
				deckingLevel,
				area,
				quad,
				system,
				installStart,
				comments,
			}) => ({
				jobID,
				cpmSchedID,
				shopDrawings,
				pipingLevel,
				deckingLevel,
				area,
				quad,
				system,
				installStart,
				comments,
			}))(this);

			let result = await this.schedulesAPI.PortalUser_DFData_InsertSequence(data);
			log("Database Result (insert sequence)", result);
			if (result.status === 200 && result.value.length > 0) {
				let newSequence = new DFSequence(result.value[0]);
				Object.assign(this, newSequence);
				this.getEgnyteFolder();
				result.value = this;
			}
			return result;
		}
	}

	async updateDB() {
		if (Boolean(this.id)) {
			//TODO: Update sequence orders and it should reset sequence orders for other sequences
			//TODO: Sequence exceptions for deliverable types (override the deliverableType given what's in the system)
			let data = (({
				cpmSchedID,
				shopDrawings,
				pipingLevel,
				deckingLevel,
				area,
				quad,
				system,
				installStart,
				comments,
				isDeleted,
			}) => ({
				cpmSchedID,
				shopDrawings,
				pipingLevel,
				deckingLevel,
				area,
				quad,
				system,
				installStart,
				comments,
				isDeleted,
			}))(this);

			let result = await this.schedulesAPI.PortalUser_DFData_UpdateSequence(this.id, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = new DFSequence(result?.value[0]);
				result.initDFSequence();
				Object.assign(this, result);
			}
			log("Database Result (update sequence)", result);
			return result;
		}
	}

	async deleteDB() {
		if (Boolean(this.id)) {
			let result = await this.schedulesAPI.PortalUser_DFData_DeleteSequence(this.id);
			log("Database Result (DELETE sequence)", result);
			return result;
		}
	}

	// Calculate deliverable due dates given installOffset and duration
	// calcDueDates(deliverables = this.deliverables, workflows = this.workflows, installOffset = 0) {
	// 	let workflowsMap = convertArrayToMap(workflows, "accessor");
	// 	let installStart = new Date(this.installStart);
	// 	if (!isNaN(installOffset)) {
	// 		installStart = subBusinessDays(installStart, installOffset);
	// 	}
	// 	if (deliverables?.length > 0) {
	// 		// make sure to go in deliverable num order
	// 		let newDeliverables = [];
	// 		for (let i = deliverables?.length - 1; i >= 0; i--) {
	// 			let deliverable = deliverables[i];
	// 			let duration = workflowsMap[deliverable.accessor].duration;
	// 			deliverable.calcDate = new Date(subBusinessDays(installStart, duration));
	// 			// and get the color now
	// 			newDeliverables.push(deliverable);
	// 		}

	// 		this.deliverables = newDeliverables;
	// 		let newDeliverablesMap = convertArrayToMap(newDeliverables, "accessor");
	// 		Object.assign(this, newDeliverablesMap);

	// 		// Object.assign(this, new DeliverableList(Object.values(deliverables).flat()));
	// 		return this;
	// 	}
	// }
}
